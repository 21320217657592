import script from "./StoreReceipt.vue?vue&type=script&setup=true&lang=js"
export * from "./StoreReceipt.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import FormElement from "@/components/neue/FormElement.vue";
import FormSection from "@/components/neue/FormSection.vue";
import ImageUploaderNeue from "@/components/neue/ImageUploaderNeue.vue";
import InputArea from "@/components/neue/InputArea.vue";
import NeueToggle from "@/components/neue/NeueToggle.vue";
script.components = Object.assign({}, { FormElement, FormSection, ImageUploaderNeue, InputArea, NeueToggle }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { FormElement, FormSection, ImageUploaderNeue, InputArea, NeueToggle }, script.__o.components);}