import script from "./MessagesTab.vue?vue&type=script&setup=true&lang=js"
export * from "./MessagesTab.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import Alert from "@/components/Alert.vue";
import ConversationsMenu from "@/components/customers/ConversationsMenu.vue";
import MessageBar from "@/components/customers/MessageBar.vue";
import MessageStream from "@/components/customers/MessageStream.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
script.components = Object.assign({}, { Alert, ConversationsMenu, MessageBar, MessageStream, SecondaryNav, SecondaryTop }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { Alert, ConversationsMenu, MessageBar, MessageStream, SecondaryNav, SecondaryTop }, script.__o.components);}