
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { BellIcon, Square2StackIcon } from '@heroicons/vue/20/solid'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

import ExplanationBox from '@/components/ExplanationBox.vue'
import { serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { BellIcon, Square2StackIcon, TabGroup, TabList, Tab, TabPanels, TabPanel, ExplanationBox, SellSidebar },
  setup() {
    const store = useStore()

    const loading = ref(true)

    const data = reactive({
      list: computed(() =>
        Object.values(store.state.objects.bottle_cycle || {})
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => {
            const aDay = mapRelationships(a.relationships.fulfillment_availabilities.data).sort(
              (x, y) => new Date(x.attributes.date) - new Date(y.attributes.date)
            )[0]
            const bDay = mapRelationships(b.relationships.fulfillment_availabilities.data).sort(
              (x, y) => new Date(x.attributes.date) - new Date(y.attributes.date)
            )[0]
            return new Date(aDay?.attributes.date) - new Date(bDay?.attributes.date)
          })
      ),
      page: 0
    })

    // TODO(alicja): think about the exact separation of how much formatting/processing
    // happens in those functions vs in the view template above

    const groupsForDrop = drop =>
      mapRelationships(drop.relationships.bottle_cycle_audience_pairs.data).map(x =>
        mapRelationship({ type: 'audience', id: x.attributes.audience_id })
      )

    const dropFilterSelected = () => {
      data.page = 0
    }

    const dropErrorStatusBackground = drop => {
      if (
        drop.attributes.bottle_cycle_errors.no_store_error ||
        drop.attributes.bottle_cycle_errors.no_group_error ||
        drop.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error
      ) {
        return 'bg-red-100 text-red-600'
      } else {
        return 'bg-green-100 text-green-800'
      }
    }

    const dropErrorDescription = drop => {
      if (
        drop.attributes.bottle_cycle_errors.no_store_error ||
        drop.attributes.bottle_cycle_errors.no_group_error ||
        drop.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error
      ) {
        return 'Has errors'
      } else {
        return 'Active'
      }
    }

    const storeForDrop = drop => drop.relationships.store.data && mapRelationship(drop.relationships.store.data)

    const duplicatorForDrop = drop =>
      drop.relationships.duplicator.data && mapRelationship(drop.relationships.duplicator.data)

    const autochargeTimeForDrops = drop => {
      if (!drop.relationships.bottle_autocharge_automation.data) return
      const autocharge = mapRelationship(drop.relationships.bottle_autocharge_automation.data)
      const automation = mapRelationship(autocharge.relationships.automation_scheduler.data)
      if (!automation) return
      return new Intl.DateTimeFormat([], {
        hour: 'numeric',
        minute: 'numeric'
      }).format(new Date(automation.attributes.datetime))
    }

    const remindersForDrop = drop => {
      if (!drop.relationships.bottle_reminder_automations.data) return []
      return mapRelationships(drop.relationships.bottle_reminder_automations.data)
    }

    const fulfillmentDaysForDrop = drop =>
      mapRelationships(drop.relationships.fulfillment_availabilities.data).map(x =>
        Intl.DateTimeFormat([], {
          day: 'numeric',
          month: 'short'
        }).format(serverDayToDateObject(x.attributes.date))
      )

    const getList = () => {
      const includes = [
        'audiences',
        'bottle_reminder_automations',
        'bottle_autofill_automation',
        'bottle_autocharge_automation',
        'fulfillment_availabilities',
        'duplicator',
        'store',
        'bottle_cycle_audience_pairs',
        'bottle_reminder_automations.automation_scheduler',
        'bottle_reminder_automations.message_template',
        'bottle_reminder_automations.message_template.attachments',
        'bottle_autofill_automation.automation_fields',
        'bottle_autocharge_automation.automation_scheduler'
      ]
      api.call('GET', `bottle_cycles?included=${includes.join(',')}`).then(() => {
        loading.value = false
      })
    }

    onMounted(() => {
      getList()
    })

    const onPageChange = e => {
      this.page = e
    }

    const upcomingDrops = computed(() =>
      data.list.filter(x =>
        mapRelationships(x.relationships.fulfillment_availabilities.data).some(
          y => serverDayToDateObject(y.attributes.date) > new Date()
        )
      )
    )

    const pastDrops = computed(() =>
      data.list.filter(x =>
        mapRelationships(x.relationships.fulfillment_availabilities.data).some(
          y => serverDayToDateObject(y.attributes.date) < new Date()
        )
      )
    )

    const noDaysDrops = computed(() =>
      data.list.filter(x => x.relationships.fulfillment_availabilities.data.length === 0)
    )

    return {
      data,
      onPageChange,
      mapRelationship,
      mapRelationships,
      groupsForDrop,
      storeForDrop,
      autochargeTimeForDrops,
      remindersForDrop,
      fulfillmentDaysForDrop,
      duplicatorForDrop,
      dropFilterSelected,
      upcomingDrops,
      pastDrops,
      noDaysDrops,
      dropErrorStatusBackground,
      dropErrorDescription,
      loading
    }
  }
}
