import Cookies from 'js-cookie'

import router from '@/router'

import store from '.'

let api = {
  call(method, endpoint, data = {}, options = {}) {
    options = {
      receive: true,
      save: true,
      delete: true,
      abortController: new AbortController(),
      noProcessing: false,
      ...options
    }
    if (['POST', 'PUT', 'DELETE'].includes(method) && options.save && !options.noProcessing) store.commit('INC_SAVING')

    return fetch(
      (options.noProcessing
        ? process.env.VUE_APP_API_SERVER.replace('/dashboard/', '/')
        : process.env.VUE_APP_API_SERVER) + endpoint,
      {
        method,
        headers: {
          Authorization: `Bearer ${options.authToken || Cookies.get('auth_token')}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        signal: options.abortController.signal,
        ...(method !== 'GET' && { body: JSON.stringify(data) })
      }
    )
      .then(async response => {
        const data = await response.json()
        if (response.status === 401) {
          if (data.reason === 'no-stripe-subscription') {
            router.push('/stripe-integration-required')
          }
          if (data.reason === 'canceled-stripe-subscription') {
            router.push('/restart-stripe-integration')
          }
          if (data.reason === 'past-due-stripe-subscription') {
            router.push('/pay-past-due-stripe-balance')
          }
        }
        if (response.status === 403) {
          // prevent redirect loops
          if (window.location.pathname !== '/login') {
            window.location = '/login?to=' + window.location.href
          }
        }
        if (!response.ok) throw new Error(`${response.status} ${method} /${endpoint}`)
        return { ...response, status: response.status, data }
      }) // compat with Axios
      .then(response => {
        if (options.noProcessing) return response

        if (options.receive) {
          if (method === 'DELETE' && options.delete) {
            store.commit('DESTROY_OBJECT', response.data)
          } else {
            store.commit('RECEIVE_OBJECT', response.data)
          }
        }
        return response
      })
      .finally(() => {
        if (['POST', 'PUT', 'DELETE'].includes(method) && options.save && !options.noProcessing)
          store.commit('DEC_SAVING')
      })
  }
}

export default api
