
import { ClipboardDocumentListIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import { Field, Form } from 'vee-validate'
import { defineComponent, ref } from 'vue'

import { serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'

export default defineComponent({
  components: {
    ClipboardDocumentListIcon,
    InformationCircleIcon,
    Field,
    Form
  },
  props: {
    bottles: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    isEmpty: {
      type: Boolean,
      required: true,
      default: false
    },
    hasError: {
      type: Boolean,
      required: true,
      default: false
    },
    emitPagination: {
      type: Boolean,
      required: false,
      default: false
    },
    pageSize: {
      type: Number,
      required: false,
      default: 25
    },
    onPageChange: {
      type: Function,
      required: false,
      default: null
    },
    totalRecords: {
      type: Number,
      required: false,
      default: null
    },
    initialPage: {
      type: Number,
      required: false,
      default: 0
    },
    emptyStateHeader: {
      type: String,
      required: false,
      default: 'No results found'
    },
    emptyStateSubheader: {
      type: String,
      required: false,
      default: "Try adjusting your search to find what you're looking for"
    },
    tab: {
      type: String,
      required: true,
      default: 'contacts'
    }
  },
  emits: ['on-page-change', 'on-action-executed'],
  setup(props, { emit }) {
    const local = ref({
      bottles: props.bottles.map(bottle => {
        return {
          ...bottle,
          expanded: false
        }
      }),
      page: 0,
      modal: {
        mode: '',
        type: '',
        content: {}
      },
      status: {
        isLoading: {
          refundModal: false
        }
      }
    })

    const getStoreObject = id => {
      return mapRelationship({ type: 'store', id: id })
    }

    const expandCollapseCartItemsForRow = bottle => {
      bottle.expanded = !bottle.expanded
    }

    const getCheckoutObject = id => {
      return mapRelationship({ type: 'checkout', id: id })
    }

    const getBottlePaymentObject = id => {
      return mapRelationship({ type: 'bottle_payment', id: id })
    }

    const getFulfilledDate = id => {
      const fulfillment = mapRelationship({ type: 'fulfillment_slot', id: id })
      if (!fulfillment) return null
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium'
      }).format(serverDayToDateObject(fulfillment.attributes.fulfillment_date))
    }

    const cartItemsForBottle = (bottle, expanded) => {
      const cart = mapRelationship(bottle.relationships.cart.data)
      if (expanded) {
        return mapRelationships(cart.relationships.cart_items.data)
      } else {
        return mapRelationships(cart?.relationships.cart_items.data).slice(0, 3)
      }
    }

    const getFulfillmentSlot = item => {
      return mapRelationship(item?.relationships?.fulfillment_slot?.data)
    }

    const getFulfillmentMethod = id => {
      const fulfillmentSlot = mapRelationship({ type: 'fulfillment_slot', id: id })
      const fulfillmentMethod = mapRelationship(fulfillmentSlot?.relationships.fulfillment_method.data)
      if (!fulfillmentMethod) return null
      return fulfillmentMethod
    }

    const getCustomerObject = id => {
      return mapRelationship({ type: 'conversation_user_pair', id: id })
    }

    const getPaidDate = id => {
      const payment = mapRelationship({ type: 'bottle_payment', id: id })
      if (!payment) return null
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium'
      }).format(Date.parse(payment.attributes.created_at))
    }

    const getPaidTime = id => {
      const payment = mapRelationship({ type: 'bottle_payment', id: id })
      if (!payment) return null
      return new Intl.DateTimeFormat([], {
        hour: 'numeric',
        minute: '2-digit'
      }).format(new Date(payment.attributes.created_at))
    }

    const getMembershipTier = id => {
      return mapRelationship({ type: 'membership_tier', id: id })
    }

    const getDistributionList = id => {
      return mapRelationship({ type: 'distribution_list', id: id })
    }

    const getBottleCycle = id => {
      return mapRelationship({ type: 'bottle_cycle', id: id })
    }

    const getBottleCancellationStatus = bottle => {
      return bottle?.attributes?.cancellation_status
    }

    const getItemActionsMenu = attributes => {
      let actionsMenu = {
        label: 'Actions',
        items: []
      }
      if (attributes.link)
        actionsMenu.items.push({
          name: 'View order',
          handler: 'handleViewOrder'
        })
      if (attributes.actions.refund)
        actionsMenu.items.push({
          name: 'Refund order',
          handler: 'handleRefund'
        })
      if (attributes.actions.mark_as_paid)
        actionsMenu.items.push({
          name: 'Mark as paid',
          handler: 'handleMarkAsPaid'
        })
      if (attributes.actions.mark_as_unpaid)
        actionsMenu.items.push({
          name: 'Mark as unpaid',
          handler: 'handleMarkAsUnpaid'
        })
      if (attributes.actions.cancel_order)
        actionsMenu.items.push({
          name: 'Cancel order',
          handler: 'handlerCancelOrder'
        })
      if (attributes.actions.un_cancel_order)
        actionsMenu.items.push({
          name: 'Reactivate order',
          handler: 'handlerReactivateOrder'
        })
      return actionsMenu
    }

    // POST: mark as paid
    const handleMarkAsPaid = id => {
      api.call('POST', `bottles/${id}/pay`, {}, { receive: false }).then(() => {
        // Update status
        props.bottles.find(x => parseFloat(x.id) === id).attributes.status = 'paid'
      })
    }

    // POST: mark as unpaid
    const handleMarkAsUnpaid = id => {
      api.call('POST', `bottles/${id}/unpay`, {}, { receive: false }).then(() => {
        // Update status
        props.bottles.find(x => parseFloat(x.id) === id).attributes.status = 'pending'
      })
    }

    // POST: cancel order
    const handlerCancelOrder = id => {
      api
        .call('PUT', `bottles/${id}`, {
          bottle: {
            cancellation_status: 'cancelled'
          }
        })
        .then(() => {
          emit('on-action-executed')
        })
    }

    // POST: reactivate order
    const handlerReactivateOrder = id => {
      api
        .call('PUT', `bottles/${id}`, {
          bottle: {
            cancellation_status: 'active'
          }
        })
        .then(() => {
          emit('on-action-executed')
        })
    }

    // POST: apply refund
    const saveRefundModal = () => {
      const id = local.value.modal.content.bottleId
      const refundAmountCents = local.value.modal.content.refundAmount * 100
      local.value.status.isLoading.refundModal = true

      api
        .call('POST', `bottles/${id}/refunds`, {
          refund: {
            amount: refundAmountCents
          }
        })
        .then(() => {
          local.value.status.isLoading.refundModal = false
          resetModalContent()
        })
        .catch(error => {
          local.value.status.isLoading.refundModal = false
          local.value.modal.content.onSubmitMessage = 'Something went wrong!'
          local.value.modal.content.onSubmitLog = error
        })
    }

    const handleRefund = id => {
      local.value.modal.type = 'refundModal'
      local.value.modal.mode = 'add'
      local.value.modal.content = {
        bottleId: id,
        refundAmount: null
      }
    }

    const refundAmount = item => {
      if (item.attributes.refund_status !== 'not_refunded') {
        return ` - ${getCheckoutObject(item.relationships.checkout?.data?.id)?.attributes?.refunded_amount?.format}`
      }
    }

    const handleViewOrder = id => {
      const bottleLink = props.bottles.find(x => parseFloat(x.id) === id).attributes.link ?? null
      if (bottleLink) window.open(bottleLink, '_blank')
      return
    }

    const handleActionsMenuClick = (action, id) => {
      switch (action) {
        case 'handleMarkAsPaid':
          return handleMarkAsPaid(id)
        case 'handleMarkAsUnpaid':
          return handleMarkAsUnpaid(id)
        case 'handleRefund':
          return handleRefund(id)
        case 'handleViewOrder':
          return handleViewOrder(id)
        case 'handlerCancelOrder':
          return handlerCancelOrder(id)
        case 'handlerReactivateOrder':
          return handlerReactivateOrder(id)
      }
    }

    const handlePageChange = p => {
      if (props.emitPagination) emit('on-page-change', p)
      else local.value.page = p
    }

    const resetModalContent = () => {
      local.value.modal.type = ''
      local.value.modal.mode = ''
      local.value.modal.content = {}
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    const paymentStatus = (paymentStatus, bottlePaymentId) => {
      // return paymentStatus, bottlePaymentId
      if (bottlePaymentId) {
        const bottlePayment = getBottlePaymentObject(bottlePaymentId)
        const orderMarkedAsPaid =
          bottlePayment?.attributes?.reason == 'mark_as_paid' || bottlePayment?.attributes?.reason == 'less_than_50c'
        if (orderMarkedAsPaid && paymentStatus == 'paid')
          return {
            text: 'Marked Paid',
            classTheme: 'success'
          }
      }
      if (paymentStatus == 'paid')
        return {
          text: 'Paid',
          classTheme: 'success'
        }
      else if (paymentStatus == 'expired')
        return {
          text: 'Expired',
          classTheme: 'error'
        }
      else if (paymentStatus == 'benign' || paymentStatus == 'skipped')
        return {
          text: paymentStatus.replace('-', ' '),
          classTheme: 'default'
        }
      else
        return {
          text: paymentStatus.replace('-', ' '),
          classTheme: 'warning'
        }
    }

    return {
      local,
      getStoreObject,
      getCheckoutObject,
      getBottlePaymentObject,
      getFulfilledDate,
      getFulfillmentMethod,
      getFulfillmentSlot,
      getCustomerObject,
      getPaidDate,
      refundAmount,
      getPaidTime,
      getItemActionsMenu,
      getMembershipTier,
      getDistributionList,
      getBottleCycle,
      handleActionsMenuClick,
      saveRefundModal,
      resetModalContent,
      fieldIsRequired,
      handlePageChange,
      paymentStatus,
      handlerCancelOrder,
      handlerReactivateOrder,
      getBottleCancellationStatus,
      cartItemsForBottle,
      expandCollapseCartItemsForRow,
      mapRelationship,
      serverDayToDateObject
    }
  }
})
