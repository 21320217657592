
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const refs = ref({})
    const router = useRouter()
    const data = reactive({
      referredBy: ''
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const referredByQuestion = computed(
      () => onboardingQuestions.value.filter(obj => obj.attributes.target_column === 'referred_by')[0]
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const referredByAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === referredByQuestion.value.id)
    )

    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: emailAnswer.value?.attributes.answer,
          onboarding_referral: data.referredBy
        }
      ])
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    const formSubmitted = () => {
      submitAnswer(referredByQuestion.value.id)
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'Referral', answer: data.referredBy })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.referredBy,
                  onboarding_question_id: referredByQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values['nextPage'] = 5
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            router.push(`/area-code`)
          })
        })
    }

    const validateKeyPress = event => {
      if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13) {
        return true
      } else {
        event.preventDefault()
      }
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    const skipClicked = () => {
      const values = savedValues() || {}
      values['nextPage'] = 5
      router.push(`/area-code`)
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      savedValues,
      refs,
      steps,
      totalProgress,
      validateKeyPress,
      getQuestions,
      onboardingToken,
      populateAnswer,
      identifyInHubspot,
      skipClicked
    }
  }
})
