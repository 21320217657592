const availableMembershipTierTypes = {
  Paid: 'PaidMembershipTier'
}

const availableMembershipBillingFrequencyOptions = {
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Yearly: 'yearly'
}

export { availableMembershipBillingFrequencyOptions, availableMembershipTierTypes }
