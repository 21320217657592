
import { format } from 'timeago.js'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timestamp: ''
    }
  },
  mounted() {
    this.timestamp = format(this.data.created_at)

    // recalculate timeago every minute
    setInterval(() => {
      this.timestamp = format(this.data.created_at)
    }, 60000)
  }
}
