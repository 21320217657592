<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop
        :text="discount_code_name || 'New discount code'"
        :parent="{ name: 'Discount codes', link: '/store/discount-codes' }"
      />
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <form
          class="flex flex-1 flex-col justify-between"
          @submit.prevent="handleSubmit()"
          @keydown.enter="handleKeydown($event)"
          @keydown.meta.enter.exact="handleSubmit()"
        >
          <PageGrid>
            <MainCol>
              <BasicContainer>
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-12">
                    <label for="name" class="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input
                      id="name"
                      v-model="discount_code_name"
                      type="text"
                      name="name"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div class="col-span-12">
                    <label for="code" class="block text-sm font-medium text-gray-700 mb-1">Code</label>
                    <input
                      id="code"
                      v-model="code"
                      type="text"
                      name="code"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-mono"
                    />
                  </div>
                  <div v-if="type === 'PercentOffDiscountCode'" class="col-span-12">
                    <label for="discount_rate" class="block text-sm font-medium text-gray-700 mb-1">
                      Discount rate
                    </label>
                    <input
                      id="discount_rate"
                      v-model="discount_rate"
                      type="text"
                      inputmode="numeric"
                      pattern="^(0|[1-9][0-9]*)$"
                      title="Numbers only"
                      name="discount_rate"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div v-if="type === 'PercentOffDiscountCode'" class="col-span-12">
                    <label for="max_discount_amount" class="block text-sm font-medium text-gray-700 mb-1">
                      Maximum discount amount
                      <!-- ({{ discountCode.maximum_discount_amount.cents }}) -->
                    </label>

                    <input
                      id="max_discount_amount"
                      v-model="maximum_discount_amount.dollars"
                      type="text"
                      inputmode="numeric"
                      pattern="^(([0-9]*)|(([0-9]*)\.([0-9]*)))$"
                      title="Numbers only"
                      name="max_discount_amount"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div v-if="type === 'AmountOffDiscountCode'" class="col-span-12">
                    <label for="discount_amount" class="block text-sm font-medium text-gray-700 mb-1">
                      Discount amount
                      <!-- ({{ discountCode.discount_amount.cents }}) -->
                    </label>

                    <input
                      id="discount_amount"
                      v-model="discount_amount.dollars"
                      type="text"
                      inputmode="numeric"
                      pattern="^(0|[1-9][0-9]*)$"
                      title="Numbers only"
                      name="discount_amount"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div class="col-span-12">
                    <label for="max_uses" class="block text-sm font-medium text-gray-700 mb-1">
                      Max use times per user
                    </label>
                    <input
                      id="max_uses"
                      v-model="max_use_times_per_user"
                      type="text"
                      inputmode="numeric"
                      pattern="^(0|[1-9][0-9]*)$"
                      title="Numbers only"
                      name="max_uses"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-mono"
                    />
                  </div>
                </div>
              </BasicContainer>
              <BasicContainer>
                <h3 class="text-lg leading-6 font-medium text-gray-900">Options</h3>
                <div class="grid grid-cols-12 gap-6 border-b border-gray-300 pb-6">
                  <div class="col-span-6">
                    <label for="can_be_combined" class="block text-sm font-medium text-gray-700 mb-1">
                      Can be combined
                    </label>

                    <button
                      id="can_be_combined"
                      type="button"
                      class="relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-blue-500 focus:ring-offset-2 focus:ring-2"
                      :class="[can_be_combined ? 'bg-blue-600' : 'bg-gray-200']"
                      aria-pressed="false"
                      @click="can_be_combined = !can_be_combined"
                    >
                      <span
                        aria-hidden="true"
                        class="inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                        :class="[can_be_combined ? 'translate-x-5' : 'translate-x-0']"
                      ></span>
                    </button>
                  </div>
                  <div v-if="can_be_combined" class="col-span-6">
                    <label for="can_be_combined_always" class="block text-sm font-medium text-gray-700 mb-1">
                      Can be combined always
                    </label>
                    <button
                      id="can_be_combined_always"
                      type="button"
                      class="relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-blue-500 focus:ring-offset-2 focus:ring-2"
                      :class="[can_be_combined_always ? 'bg-blue-600' : 'bg-gray-200']"
                      aria-pressed="false"
                      @click="can_be_combined_always = !can_be_combined_always"
                    >
                      <span
                        aria-hidden="true"
                        class="inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                        :class="[can_be_combined_always ? 'translate-x-5' : 'translate-x-0']"
                      ></span>
                    </button>
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-6 border-b border-gray-300 pb-6">
                  <div class="col-span-6">
                    <label for="can_expire" class="block text-sm font-medium text-gray-700 mb-1">Can expire</label>
                    <button
                      id="can_expire"
                      type="button"
                      class="relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-blue-500 focus:ring-offset-2 focus:ring-2"
                      :class="[code_can_expire ? 'bg-blue-600' : 'bg-gray-200']"
                      aria-pressed="false"
                      @click="code_can_expire = !code_can_expire"
                    >
                      <span
                        aria-hidden="true"
                        class="inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                        :class="[code_can_expire ? 'translate-x-5' : 'translate-x-0']"
                      ></span>
                    </button>
                  </div>

                  <div v-if="code_can_expire" class="col-span-6">
                    <label for="expiration_date" class="block text-gray-700 text-sm font-medium sm:mt-px sm:pt-2">
                      Expiration date
                    </label>
                    <input
                      id="expiration_date"
                      v-model="expiration_date"
                      type="date"
                      name="expiration_date"
                      class="block w-full max-w-lg border-gray-300 focus:border-blue-500 rounded-md shadow-sm focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-6">
                    <label for="first_time" class="block text-sm font-medium text-gray-700 mb-1">
                      First time customer only
                    </label>

                    <button
                      id="first_time"
                      type="button"
                      class="relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-blue-500 focus:ring-offset-2 focus:ring-2"
                      :class="[first_time_customer_only ? 'bg-blue-600' : 'bg-gray-200']"
                      aria-pressed="false"
                      @click="first_time_customer_only = !first_time_customer_only"
                    >
                      <span
                        aria-hidden="true"
                        class="inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0"
                        :class="[first_time_customer_only ? 'translate-x-5' : 'translate-x-0']"
                      ></span>
                    </button>
                  </div>
                </div>
              </BasicContainer>
            </MainCol>
            <SideCol>
              <BasicContainer>
                <div>
                  <!-- TODO:@llb replace with dropdown -->
                  <label for="type" class="block text-sm font-medium text-gray-700 mb-2">Type</label>
                  <div class="space-y-2">
                    <div class="flex items-center">
                      <input
                        id="percent_off"
                        v-model="type"
                        name="type"
                        type="radio"
                        value="PercentOffDiscountCode"
                        class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                      />
                      <label for="percent_off" class="ml-2 block text-sm font-medium text-gray-700 mb-1">
                        Percent off
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="amount_off"
                        v-model="type"
                        name="type"
                        value="AmountOffDiscountCode"
                        type="radio"
                        class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                      />
                      <label for="amount_off" class="ml-2 block text-sm font-medium text-gray-700 mb-1">
                        Amount off
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="free_shipping"
                        v-model="type"
                        name="type"
                        type="radio"
                        value="FreeShippingDiscountCode"
                        class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                      />
                      <label for="free_shipping" class="ml-2 block text-sm font-medium text-gray-700 mb-1">
                        Free shipping
                      </label>
                    </div>
                  </div>
                </div>
              </BasicContainer>
            </SideCol>
          </PageGrid>
          <PageFooter>
            <template #left>
              <button class="btn-ghost mr-3" @click="$router.back">Cancel</button>
            </template>
            <template #right>
              <button class="btn-primary" type="submit">Save</button>
            </template>
          </PageFooter>
        </form>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { SellSidebar },
  data() {
    return {
      type: '',
      discount_code_name: '',
      code: '',
      discount_rate: '',
      maximum_discount_amount: { dollars: '' },
      discount_amount: { dollars: '' },
      max_use_times_per_user: '',
      can_be_combined: false,
      can_be_combined_always: false,
      code_can_expire: false,
      expiration_date: '',
      first_time_customer_only: false
    }
  },
  watch: {
    code() {
      const regexPattern = new RegExp('[^A-Za-z0-9_-]*$', 'g')
      this.code = this.code.toUpperCase().replace(/\s/g, '-').replace(regexPattern, '')
    }
  },
  methods: {
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    handleSubmit() {
      this.discount_rate = this.discount_rate / 100

      const sendData = { ...this.$data }

      sendData.discount_amount_cents = sendData.discount_amount.dollars * 100
      delete sendData.discount_amount
      sendData.maximum_discount_amount_cents = sendData.maximum_discount_amount.dollars * 100
      delete sendData.maximum_discount_amount

      api
        .call('POST', `discount_codes`, {
          discount_code: sendData
        })
        .then(response => {
          this.$router.push('/store/discount-codes/' + response.data.data.id)
        })
    }
  }
}
</script>
