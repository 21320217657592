<template>
  <label :for="for" :class="[{ 'mb-2': !isInline }, 'block cursor-pointer text-sm font-medium text-gray-700']">
    <slot>{{ label }}</slot>
  </label>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    for: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Field'
    },
    isInline: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
</script>
