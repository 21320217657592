<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop
        :text="data.name || 'New fulfillment method'"
        :parent="{ name: 'Fulfillment methods', link: '/store/fulfillment-methods' }"
      />
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <Form
          v-show="data"
          v-slot="{ errors }"
          class="flex flex-1 flex-col justify-between"
          @submit="handleSubmit()"
          @keydown.enter="handleKeydown($event)"
          @invalid-submit="onInvalidSubmit"
        >
          <PageGrid>
            <MainCol>
              <!-- General -->
              <BasicContainer>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <FormLabel for="methodName" label="Name" />
                    <Field
                      id="methodName"
                      v-model="data.name"
                      name="methodName"
                      :rules="fieldIsRequired"
                      :class="[
                        errors.methodName ? 'border-red-300' : 'border-gray-300',
                        'mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                      ]"
                    />
                    <InputError v-show="errors.methodName" />
                  </div>
                  <div class="col-span-12">
                    <FormLabel for="methodDescription" label="Description" />
                    <textarea
                      id="methodDescription"
                      v-model="data.description"
                      name="methodDescription"
                      rows="8"
                      class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                    ></textarea>
                  </div>
                </div>
              </BasicContainer>
              <!-- Locales -->
              <BasicContainer>
                <h3 class="text-lg leading-6 font-medium text-gray-900">Fulfillment Zones</h3>
                <div>
                  <FormLabel for="selectedZipcodes" label="Eligible Zipcodes" />
                  <Multiselect
                    v-if="data.zipcodeOptions"
                    id="selectedZipcodes"
                    v-model="data.selectedZipcodes"
                    name="selectedZipcodes"
                    :options="data.zipcodeOptions"
                    track-by="value"
                    label="value"
                    :taggable="true"
                    :multiple="true"
                    :show-labels="false"
                    tag-placeholder="Add this zipcode"
                    @tag="pushTag"
                  ></Multiselect>
                </div>
                <div>
                  <FormLabel for="methodStates" label="Eligible States" />
                  <Multiselect
                    v-if="data.stateOptions"
                    id="methodStates"
                    v-model="data.selectedStates"
                    name="methodStates"
                    :options="data.usaStates"
                    track-by="value"
                    label="label"
                    :taggable="true"
                    :multiple="true"
                    :show-labels="false"
                    tag-placeholder="Add this state"
                  ></Multiselect>
                </div>
              </BasicContainer>
              <!-- Pickup Address -->
              <BasicContainer v-if="data.type === 'PickupFulfillmentMethod'">
                <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">Address</h3>
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-6">
                    <FormLabel for="methodAddress1" label="Address" />
                    <Field
                      id="methodAddress1"
                      v-model="data.address.address1"
                      name="methodAddress1"
                      :rules="fieldIsRequired"
                      :class="[
                        errors.methodAddress1 ? 'border-red-300' : 'border-gray-300',
                        'mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                      ]"
                    />
                    <InputError v-show="errors.methodAddress1" />
                  </div>
                  <div class="col-span-6">
                    <FormLabel for="methodAddress2" label="Apartment, suite, etc. (optional)" />
                    <Field
                      id="methodAddress2"
                      v-model="data.address.address2"
                      name="methodAddress2"
                      :class="[
                        errors.address2 ? 'border-red-300' : 'border-gray-300',
                        'mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                      ]"
                    />
                  </div>
                  <div class="col-span-6">
                    <FormLabel for="methodAddress3" label="City" />
                    <Field
                      id="methodAddress3"
                      v-model="data.address.city"
                      name="methodAddress3"
                      :rules="fieldIsRequired"
                      :class="[
                        errors.methodAddress3 ? 'border-red-300' : 'border-gray-300',
                        'mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                      ]"
                    />
                    <InputError v-show="errors.methodAddress3" />
                  </div>
                  <div class="col-span-6">
                    <FormLabel for="methodAddress4" label="State" />
                    <Multiselect
                      id="methodAddress4"
                      v-model="data.address.selectedState"
                      name="methodAddress4"
                      :options="data.usaStates"
                      track-by="value"
                      label="label"
                      :show-labels="false"
                      @open="fieldErrors.pickupState = false"
                      @close="
                        data.address.selectedState
                          ? (fieldErrors.pickupState = false)
                          : (fieldErrors.pickupState = true)
                      "
                    ></Multiselect>
                    <InputError v-show="fieldErrors.pickupState" />
                  </div>
                  <div class="col-span-12">
                    <FormLabel for="methodAddress5" label="Zipcode" />
                    <Field
                      id="methodAddress5"
                      v-model="data.address.zip"
                      name="methodAddress5"
                      :rules="fieldIsRequired"
                      :class="[
                        errors.methodAddress5 ? 'border-red-300' : 'border-gray-300',
                        'mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                      ]"
                    />
                    <InputError v-show="errors.methodAddress5" />
                  </div>
                </div>
              </BasicContainer>
              <!-- Stores -->
              <BasicContainer>
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900 mb-1">Stores</h3>
                  <p class="mb-4 text-sm text-gray-500">Stores where this method is available</p>
                  <SimpleEmptyState
                    v-show="!Object.keys(data.availableStores).length"
                    class="mt-8 mb-10"
                    heading="No stores yet"
                    description="Get started by adding a Store under the Stores tab"
                    icon="ClipboardDocumentListIcon"
                  />
                </div>
                <div
                  v-for="store in data.availableStores"
                  v-show="Object.keys(data.availableStores).length"
                  :key="`store-${store.attributes.id}`"
                  class="relative flex items-start mb-3"
                >
                  <div class="flex items-center h-5">
                    <input
                      :id="`store${store.attributes.id}`"
                      :name="`store${store.attributes.id}`"
                      :checked="data.storeMap[store.attributes.id]"
                      :aria-describedby="`store${store.attributes.id}Description`"
                      type="checkbox"
                      class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      @click="clickedStore(store.attributes.id)"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <FormLabel :for="`store${store.attributes.id}`" :label="store.attributes.store_name" />
                    <p
                      v-if="store.attributes.store_description"
                      :id="`store-${store.attributes.id}-description`"
                      class="text-gray-500"
                    >
                      {{ store.attributes.store_description }}
                    </p>
                  </div>
                </div>
              </BasicContainer>
              <!-- Fulfillment Slots -->
              <!-- TODO(alicja): the date logic in this component is pretty broken, commenting out for now -->
              <BasicContainer v-if="false">
                <div class="flex justify-between items-center pb-4">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">Fulfillment Slots</h3>
                  <button class="btn-primary justify-center" type="button" @click="addSlot">+ Fulfillment slot</button>
                </div>
                <!-- Add slot modal -->
                <TheModal
                  v-if="slotModal.isVisible"
                  :is-open="slotModal.isVisible"
                  modal-title="Add fulfillment slot"
                  :overflow="'overflow-visible'"
                  @close-modal="slotModal.isVisible = false"
                >
                  <template #main>
                    <div class="grid grid-cols-12 gap-6 pb-6">
                      <div class="col-span-12">
                        <FormLabel for="newLimit">
                          Overall inventory available
                          <InformationCircleIcon
                            v-tippy="{
                              content:
                                'The amount this product can be ordered before being marked \'out of stock\' - must be reset manually'
                            }"
                            class="w-4 h-4 inline"
                          />
                        </FormLabel>
                        <button
                          id="newLimit"
                          name="newLimit"
                          :class="[
                            `mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                            slotModal.slot.limitQuantity ? 'bg-blue-600' : 'bg-gray-200'
                          ]"
                          type="button"
                          role="switch"
                          :aria-checked="slotModal.slot.limitQuantity"
                          aria-labelledby="newLimit"
                          @click="slotModal.slot.limitQuantity = !slotModal.slot.limitQuantity"
                        >
                          <span
                            aria-hidden="true"
                            :class="[
                              `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                              slotModal.slot.limitQuantity ? 'translate-x-5' : 'translate-x-0'
                            ]"
                          ></span>
                        </button>
                      </div>
                      <div v-show="slotModal.slot.limitQuantity" class="col-span-12">
                        <FormLabel for="newQtyLimit" label="Quantity Limit" />
                        <div class="mt-1">
                          <input
                            id="newQtyLimit"
                            v-model="slotModal.slot.quantityLimit"
                            name="newQtyLimit"
                            type="number"
                            class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div class="col-span-6">
                        <FormLabel for="newDisplayCutoff" label="Display Cutoff" />
                        <div class="mt-1">
                          <DatePicker
                            :ref="
                              el => {
                                datePickerRefs['newDisplayCutoff'] = el
                              }
                            "
                            v-model="slotModal.slot.displayCutoff"
                            mode="dateTime"
                            :popover="slotModal.datePickerConfigs.popover"
                          >
                            <template #default="{ inputValue, inputEvents }">
                              <input
                                id="newDisplayCutoff"
                                name="newDisplayCutoff"
                                class="bg-white border-gray-300 border p-2 sm:text-sm rounded-md shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500"
                                :value="inputValue"
                                v-on="inputEvents"
                                @click="handleClickPicker"
                              />
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                      <div class="col-span-6">
                        <FormLabel for="newActualCutoff" label="Actual Cutoff" />
                        <div class="mt-1">
                          <DatePicker
                            :ref="
                              el => {
                                datePickerRefs['newActualCutoff'] = el
                              }
                            "
                            v-model="slotModal.slot.actualCutoff"
                            mode="dateTime"
                            :popover="slotModal.datePickerConfigs.popover"
                          >
                            <template #default="{ inputValue, inputEvents }">
                              <input
                                id="newActualCutoff"
                                name="newActualCutoff"
                                class="bg-white border-gray-300 border p-2 sm:text-sm rounded-md shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500"
                                :value="inputValue"
                                v-on="inputEvents"
                                @click="handleClickPicker"
                              />
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                      <div class="col-span-12">
                        <FormLabel for="newFulfillmentDate" label="Production Date" />
                        <div class="mt-1">
                          <DatePicker
                            :ref="
                              el => {
                                datePickerRefs['newFulfillmentDate'] = el
                              }
                            "
                            v-model="slotModal.slot.fulfillmentDate"
                            :popover="slotModal.datePickerConfigs.popover"
                            :model-config="slotModal.datePickerConfigs.kebabMask"
                          >
                            <template #default="{ inputValue, inputEvents }">
                              <input
                                id="newFulfillmentDate"
                                name="newFulfillmentDate"
                                class="bg-white border-gray-300 border p-2 sm:text-sm rounded-md shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500"
                                :value="inputValue"
                                v-on="inputEvents"
                                @click="handleClickPicker"
                              />
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #footer>
                    <button class="btn-primary justify-center mr-3" type="button" @click="saveSlot()">Save</button>
                    <button class="btn-ghost justify-center" type="button" @click="resetFulfillmentSlotModal">
                      Cancel
                    </button>
                  </template>
                </TheModal>
                <SimpleEmptyState
                  v-show="!data.slots.length"
                  class="mt-4 mb-6"
                  heading="No fulfillment slots yet"
                  description="Get started by adding fulfillment slots"
                  icon="ClipboardDocumentListIcon"
                />
                <!-- Fulfillment List -->
                <div v-show="data.slots">
                  <ul role="list" class="space-y-6">
                    <li v-for="(item, i) in data.slots" :key="`method-${item.id}`" class="border-t pt-4 px-2">
                      <div class="flex justify-between">
                        <p class="text-sm font-medium text-gray-900">Fulfillment #{{ i + 1 }}</p>
                        <div class="sm:w-1/4 text-right">
                          <span class="relative z-0 inline-flex shadow-sm rounded-md">
                            <button
                              type="button"
                              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              @click.prevent="removeSlot(i)"
                            >
                              Remove
                            </button>
                            <button
                              type="button"
                              class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              @click.prevent="editSlot(i)"
                            >
                              Edit
                            </button>
                          </span>
                        </div>
                      </div>

                      <dl class="divide-y divide-gray-100 ml-4">
                        <div class="py-2 sm:grid sm:grid-cols-3 sm:gap-2">
                          <dt class="text-sm font-medium text-gray-500">Overall Inventory Limit</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ item.limitQuantity === null || item.limitQuantity === false ? 'False' : 'True' }}
                          </dd>
                        </div>
                        <div v-show="item.quantityLimit" class="py-2 sm:grid sm:grid-cols-3 sm:gap-2">
                          <dt class="text-sm font-medium text-gray-500">Quantity Limit</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ item.quantityLimit }}
                          </dd>
                        </div>
                        <div v-show="item.displayCutoff" class="py-2 sm:grid sm:grid-cols-3 sm:gap-2">
                          <dt class="text-sm font-medium text-gray-500">Display Cutoff</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{
                              new Intl.DateTimeFormat('en-US', {
                                dateStyle: 'medium',
                                timeStyle: 'short'
                              }).format(Date.parse(item.displayCutoff))
                            }}
                          </dd>
                        </div>
                        <div v-show="item.actualCutoff" class="py-2 sm:grid sm:grid-cols-3 sm:gap-2">
                          <dt class="text-sm font-medium text-gray-500">Actual Cutoff</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{
                              new Intl.DateTimeFormat('en-US', {
                                dateStyle: 'medium',
                                timeStyle: 'short'
                              }).format(Date.parse(item.actualCutoff))
                            }}
                          </dd>
                        </div>
                        <div v-show="item.fulfillmentDate" class="py-2 sm:grid sm:grid-cols-3 sm:gap-2">
                          <dt class="text-sm font-medium text-gray-500">Production Date</dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{
                              new Intl.DateTimeFormat('en-US', {
                                dateStyle: 'medium'
                              }).format(serverDayToDateObject(item.fulfillmentDate))
                            }}
                          </dd>
                        </div>
                      </dl>
                    </li>
                  </ul>
                </div>
              </BasicContainer>
            </MainCol>
            <SideCol>
              <!-- Type -->
              <BasicContainer>
                <div>
                  <FormLabel for="methodType" label="Type" />
                  <Field
                    v-model="data.type"
                    name="methodType"
                    :rules="fieldIsRequired"
                    :class="[
                      errors.methodType ? 'border-red-300' : 'border-gray-300',

                      'mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md'
                    ]"
                    as="select"
                  >
                    <option disabled value="">Select a method type</option>

                    <option v-for="(value, name) in availableMethodTypes" :key="'option' + value" :value="value">
                      {{ name }}
                    </option>
                  </Field>
                  <InputError v-show="errors.methodType" />
                </div>
              </BasicContainer>
              <!-- Taxable + Price -->
              <BasicContainer>
                <!-- Taxable  -->
                <div class="flex justify-between">
                  <FormLabel for="methodTaxable" label="Taxable" />
                  <button
                    id="methodTaxable"
                    name="methodTaxable"
                    type="button"
                    :class="[
                      `relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                      data.isTaxed ? 'bg-blue-600' : 'bg-gray-200'
                    ]"
                    role="switch"
                    :aria-checked="data.isTaxed"
                    aria-labelledby="method-taxed-label"
                    @click="taxableClicked()"
                  >
                    <span
                      aria-hidden="true"
                      :class="[
                        `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                        data.isTaxed ? 'translate-x-5' : 'translate-x-0'
                      ]"
                    ></span>
                  </button>
                </div>
                <!-- Price -->
                <div>
                  <FormLabel for="methodPrice" label="Price" />
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <span
                      class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                    >
                      $
                    </span>
                    <Field
                      id="methodPrice"
                      v-model="data.price"
                      name="methodPrice"
                      :rules="fieldIsRequired"
                      :class="[
                        errors.methodPrice ? 'border-red-300' : 'border-gray-300',
                        'flex-1 min-w-0 block w-full px-3 py-2 border rounded-none rounded-r-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                      ]"
                    />
                  </div>
                  <InputError v-show="errors.methodPrice" />
                </div>
              </BasicContainer>
            </SideCol>
          </PageGrid>
          <PageFooter>
            <template #left>
              <router-link class="btn-ghost" to="/store/fulfillment-methods">Cancel</router-link>
            </template>
            <template #right>
              <button class="btn-primary" type="submit">Save</button>
            </template>
          </PageFooter>
        </Form>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import { DatePicker } from 'v-calendar'
import { Field, Form } from 'vee-validate'
import { computed, onMounted, reactive, ref } from 'vue'
import { defineComponent } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { toSnakeCase } from '@/helpers/CaseHelper'
import { serverDayToDateObject } from '@/helpers/dates'
import { availableFulfillmentTypes } from '@/models/fulfillmentMethodTypes'
import usaStates from '@/models/usaStates'
import ApiService from '@/services/api.service'
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'
export const FulfillmentMethodService = new ApiService({
  uri: 'fulfillment_methods'
})

export default defineComponent({
  components: {
    DatePicker,
    Form,
    Field,
    Multiselect,
    SellSidebar
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const methodId = router.currentRoute.value.params.i

    const data = reactive({
      statesMapped: [],
      type: '',
      name: '',
      description: '',
      isTaxed: false,
      usaStates: usaStates,
      stateOptions: [],
      zipcodeOptions: [],
      selectedStates: [],
      selectedZipcodes: [],
      storePairs: [],
      storeMap: { string: false },
      address: {
        address1: '',
        selectedStateTitle: ''
      },
      slots: [],
      availableStores: computed(() => Object.values(store.state.objects.store || {}))
    })

    // We need to store refs to pickers to force the other ones to close when one of them is clicked.
    const datePickerRefs = ref({})

    // Select Options + Methods
    const pushTag = (tag, id) => {
      if (typeof tag !== 'object') return data[id].push({ value: tag, label: tag })
    }
    const availableMethodTypes = availableFulfillmentTypes

    // Modal Data + Methods
    const slotModal = reactive({
      isVisible: false,
      mode: 'edit',
      slot: {},
      datePickerConfigs: {
        popover: {
          visibility: 'click'
        },
        kebabMask: {
          type: 'string',
          mask: 'YYYY-MM-DD'
        }
      }
    })

    const resetFulfillmentSlotModal = () => {
      slotModal.slot = {}
      slotModal.isVisible = false
    }

    const timeFormat = new Intl.DateTimeFormat('en-CA', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    })

    const addSlot = () => {
      const now = new Date()
      now.setHours(8, 0, 0, 0)
      slotModal.mode = 'add'
      slotModal.slot = {
        limitQuantity: false,
        startTime: now,
        endTime: now
      }
      slotModal.isVisible = true
    }

    const editSlot = i => {
      const now = new Date()
      slotModal.mode = 'edit'
      data.slotToEdit = i

      slotModal.slot = {
        id: data.slots[i].id,
        limitQuantity: data.slots[i].limitQuantity,
        displayCutoff: data.slots[i].displayCutoff,
        actualCutoff: data.slots[i].actualCutoff,
        date: data.slots[i].date,
        startTime: data.slots[i].startTime,
        endTime: data.slots[i].endTime,
        set_quantity_changes_attributes: [
          {
            value: data.slots[i].quantityLimit
          }
        ]
      }
      slotModal.isVisible = true
    }

    const saveSlot = () => {
      if (slotModal.mode === 'add') {
        data.slots.push(slotModal.slot)
      } else if (slotModal.mode === 'edit') {
        data.slots.splice(data.slotToEdit, 1, slotModal.slot)
      }

      resetFulfillmentSlotModal()
    }

    const removeSlot = i => {
      deleteSlot(i)
      data.slots.splice(i, 1)
    }

    const deleteSlot = i => {
      api.call('DELETE', `fulfillment_methods/${methodId}/fulfillment_slots/${data.slots[i].id}`)
    }

    const clickedStore = i => {
      data.storeMap[i] = !data.storeMap[i]
    }

    const taxableClicked = () => {
      data.isTaxed = !data.isTaxed
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    const fieldErrors = ref({
      pickupState: false
    })

    const handleInvalidMultiSelects = () => {
      if (data.type === 'PickupFulfillmentMethod' && !data.address.selectedState) {
        fieldErrors.value.pickupState = true
        return true
      }
      return false
    }

    const onInvalidSubmit = () => {
      handleInvalidMultiSelects()
      document.querySelector('form').scrollIntoView({ behavior: 'smooth' })
    }

    const handleSubmit = () => {
      if (handleInvalidMultiSelects()) return

      const fulfillmentMethod = {
        type: data.type,
        name: data.name,
        taxable: data.isTaxed,
        description: data.description,
        price_cents: Math.round(data.price * 100)
      }

      if (data.selectedStates.length) {
        fulfillmentMethod.eligibleStates = data.selectedStates.map(x => x.value)
      }

      if (data.selectedZipcodes.length) {
        fulfillmentMethod.eligibleZipCodes = data.selectedZipcodes.map(x => x.value)
      }

      if (data.type === 'PickupFulfillmentMethod') {
        fulfillmentMethod.deliveryAddressAttributes = {
          address1: data.address.address1,
          address2: data.address.address2,
          city: data.address.city,
          state: data.address.selectedState.value,
          zip: data.address.zip
        }
      }

      let fulfillmentMethods = []
      Object.keys(data.storeMap).map(k => {
        if (data.storeMap[k]) {
          fulfillmentMethods.push({ storeId: parseInt(k) })
        }
      })
      fulfillmentMethod.fulfillmentMethodStorePairsAttributes = fulfillmentMethods

      if (data.slots) {
        fulfillmentMethod.fulfillmentSlotsAttributes = [
          ...data.slots.map(slot => ({
            start_time: timeFormat.format(slot.startTime),
            end_time: timeFormat.format(slot.endTime),
            display_cutoff: slot.displayCutoff.toISOString(),
            actual_cutoff: slot.actualCutoff.toISOString(),
            date: slot.fulfillmentDate,
            limit_quantity: slot.limitQuantity,
            set_quantity_changes_attributes: [
              {
                value: slot.quantityLimit
              }
            ]
          }))
        ]
      }

      const resource = toSnakeCase({ fulfillmentMethod: fulfillmentMethod })
      FulfillmentMethodService.createResource(resource).then(() => {
        router.push('/store/fulfillment-methods')
      })
    }

    const handleClickPicker = refName => {
      // When clicking directly inside another picker, the previous one doesn't close.
      // Its a v-calendar bug and this is the workaround for now.
      Object.keys(datePickerRefs.value).forEach(itemName => {
        if (itemName != refName.target.name) {
          datePickerRefs.value[itemName].hidePopover()
        }
      })
    }

    const handleKeydown = event => {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    }

    onMounted(() => {
      api.call('GET', 'stores')
    })

    return {
      data,
      onInvalidSubmit,
      fieldErrors,
      fieldIsRequired,
      handleSubmit,
      pushTag,
      availableMethodTypes,
      slotModal,
      resetFulfillmentSlotModal,
      taxableClicked,
      addSlot,
      saveSlot,
      removeSlot,
      clickedStore,
      editSlot,
      handleClickPicker,
      datePickerRefs,
      handleKeydown,
      serverDayToDateObject
    }
  }
})
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.multiselect {
  display: block;
  border: 0;
}
</style>
