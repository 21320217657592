
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      handle: '',
      showSpecification: false,
      business_type: '',
      dropdownOptions: {
        items: [
          { name: 'Bakery', showInputFieldOnSelect: false },
          { name: 'Meal delivery', showInputFieldOnSelect: false },
          { name: 'Beverage', showInputFieldOnSelect: false },
          { name: 'Charcuterie', showInputFieldOnSelect: false },
          { name: 'Flowers', showInputFieldOnSelect: false },
          { name: 'Grocery / Market', showInputFieldOnSelect: false },
          { name: 'Delivery / Pickup', showInputFieldOnSelect: false },
          { name: 'Meat', showInputFieldOnSelect: false },
          { name: 'Seafood', showInputFieldOnSelect: false },
          { name: 'Other', showInputFieldOnSelect: true }
        ]
      }
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const businessTypeQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'business_type')
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const businessTypeAnswer = computed(() =>
      onboardingAnswers.value.find(
        obj => obj.relationships.onboarding_question.data.id === businessTypeQuestion.value.id
      )
    )

    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )

    watch(onboardingAccount, (newValue, oldValue) => {
      data.business_type = businessTypeAnswer.value?.attributes.answer || ''
      if (optionForBusinessType(data.business_type)) {
        optionForBusinessType(data.business_type).checked = true
      } else if (data.business_type) {
        data.dropdownOptions.items[data.dropdownOptions.items.length - 1].checked = true
        data.showSpecification = true
      } else {
      }
    })

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: emailAnswer.value?.attributes.answer,
          onboarding_business_type: data.business_type
        }
      ])
    }

    const optionSelected = index => {
      uncheckSelectedOption()
      data.dropdownOptions.items[index].checked = true
      if (data.dropdownOptions.items[index].showInputFieldOnSelect === true) {
        data.showSpecification = true
        data.business_type = ''
      } else if (data.dropdownOptions.items[index].showInputFieldOnSelect === false) {
        data.showSpecification = false
        data.business_type = data.dropdownOptions.items[index].name
        submitAnswer(businessTypeQuestion.value.id)
      }
    }

    const uncheckSelectedOption = () => {
      if (selectedOption()) {
        selectedOption().checked = false
      }
    }

    const selectedOption = () => {
      return data.dropdownOptions.items.find(option => option.checked)
    }

    const optionForBusinessType = businessType => {
      return data.dropdownOptions.items.find(option => option.name === businessType)
    }

    const formSubmitted = () => {
      submitAnswer(businessTypeQuestion.value.id)
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'BusinessType', answer: data.business_type })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.business_type,
                  onboarding_question_id: businessTypeQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values.nextPage = 4
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            router.push('/referral')
          })
        })
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      savedValues,
      optionSelected,
      uncheckSelectedOption,
      selectedOption,
      optionForBusinessType,
      steps,
      totalProgress,
      populateAnswer,
      onboardingToken,
      getQuestions,
      submitAnswer,
      identifyInHubspot
    }
  }
})
