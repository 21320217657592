<template>
  <p class="mt-2 text-sm text-red-600">
    <slot>{{ errorMessage }}</slot>
  </p>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: 'This field is required'
    }
  }
})
</script>
