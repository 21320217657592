import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]


export default {
  __name: 'DescriptionListDropdown',
  props: ['options', 'modelValue'],
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("select", {
    value: __props.modelValue,
    class: "block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm",
    onInput: _cache[0] || (_cache[0] = e => emit('update:modelValue', e.target.value))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (value, key) => {
      return (_openBlock(), _createElementBlock("option", { value: value }, _toDisplayString(key), 9, _hoisted_2))
    }), 256))
  ], 40, _hoisted_1))
}
}

}