import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10 mb-6" }
const _hoisted_2 = { class: "space-y-1" }
const _hoisted_3 = { class: "text-2xl font-bold text-gray-600 tracking-tight" }
const _hoisted_4 = { class: "max-w-2xl text-sm text-gray-500" }


export default {
  __name: 'DescriptionHeader',
  props: ['name', 'description'],
  setup(__props) {

const props = __props

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(props.name), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(props.description), 1)
    ])
  ]))
}
}

}