<template>
  <SignupMainContainer :title="'Try Bottle for free'" :image-position="'left'" @form-submitted="formSubmitted">
    <template #stepper>
      <SignupHeaderComponent :total-progress="totalProgress()" :steps="steps" :current-step="5"></SignupHeaderComponent>
    </template>
    <template #body>
      <div class="mx-auto w-full">
        <div class="mt-2">
          <div class="space-y-6 flex flex-col">
            <h2 class="text-gray-800 text-xl tracking-tighter">
              What area code would you like for your Bottle business phone number?
            </h2>
            <div>
              <div>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    id="handle"
                    v-model="data.areaCode"
                    type="text"
                    required
                    autocomplete="off"
                    class="placeholder-gray-400 block px-3 py-3.5 rounded-l-lg w-1/2 focus:border-gray-800 border-2 border-gray-200 border-r-0 focus:ring-0 appearance-none transition duration-150 ease-in-out text-sm leading-5 focus:outline-none font-semibold tracking-wide"
                    placeholder="205"
                    @keypress="validateKeyPress"
                  />
                  <span
                    class="w-full inline-flex items-center rounded-r-lg border-2 border-l-0 border-gray-200 bg-gray-100 px-3 text-gray-600 text-sm tracking-tight"
                  >
                    555-4444
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end w-full">
        <button
          type="submit"
          class="ml-2 inline-flex items-center border-gray-800 border-2 font-bold text-md hover:shadow-lg transition-all rounded-full px-6 py-3.5 leading-tight bg-yellow-200 text-gray-800"
        >
          <span class="ml-auto transform -translate-y-px mr-4">Finish</span>
          <span class="mr-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="13" viewBox="0 0 34 13">
              <path
                d="M3.11,6.82A20,20,0,0,1,1.2,6a.46.46,0,0,0-.67.15A2.32,2.32,0,0,0,0,7.25C0,7.64,0,7.84.3,8c.59.25,1.18.52,1.78.72"
                transform="translate(0 0)"
              ></path>
              <path
                d="M1.83,8.58A23.56,23.56,0,0,0,7.36,10a36.41,36.41,0,0,0,8.79,0A53.55,53.55,0,0,0,29.69,6.72l.19-.07,0,0c-.33.4-.65.8-1,1.19-.72.87-1.45,1.73-2.16,2.61a1.75,1.75,0,0,0,0,2.28.68.68,0,0,0,.52.22.73.73,0,0,0,.5-.26c.67-.78,1.33-1.58,2-2.39l3.65-4.41A2.13,2.13,0,0,0,34,4.5,1.24,1.24,0,0,0,33.91,4a.87.87,0,0,0-.28-.36,1.55,1.55,0,0,0-.32-.2L26.59.11c-.65-.31-1.25.05-1.54.92a1.22,1.22,0,0,0,.4,1.5c.95.49,1.91,1,2.86,1.42l1.78.89-.19.08A55.6,55.6,0,0,1,17.44,8a36.08,36.08,0,0,1-8.87.12A21.78,21.78,0,0,1,2.82,6.7c-.3-.12-.73-.1-1-.25"
                fill="#000000"
                transform="translate(0 0)"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </template>
  </SignupMainContainer>
</template>
<script>
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const refs = ref({})
    const router = useRouter()
    const data = reactive({
      areaCode: ''
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const areaCodeQuestion = computed(
      () => onboardingQuestions.value.filter(obj => obj.attributes.target_column === 'preferred_provision_area_code')[0]
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const areaCodeAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === areaCodeQuestion.value.id)
    )

    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )

    watch(data, (currentValue, oldValue) => {
      if (currentValue.areaCode.length > 3) {
        data.areaCode = currentValue.areaCode.slice(0, 3)
      }
    })
    watch(onboardingAccount, (newValue, oldValue) => {
      data.areaCode = areaCodeAnswer.value?.attributes.answer || ''
    })

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: emailAnswer.value?.attributes.answer,
          onboarding_area_code: data.areaCode
        }
      ])
    }

    const identifySignupInHubspot = signupDetails => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: signupDetails?.email,
          bottle_id: signupDetails?.id,
          bottle_handle: signupDetails?.handle,
          stripe_customer_id: signupDetails?.stripe_customer_id
        }
      ])
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    const formSubmitted = () => {
      submitAnswer(areaCodeQuestion.value.id)
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const areaInputContainerClicked = () => {
      refs.value.areaCodeInputRef.focus()
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'AreaCode', answer: data.areaCode })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.areaCode,
                  onboarding_question_id: areaCodeQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values['nextPage'] = 6
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            api.call('POST', 'signup', { onboarding_account_secret: onboardingToken() }, false).then(response => {
              Cookies.set('auth_token', response.data.token, { expires: 6 * 31 })
              store.dispatch('getLoggedInUser')
              store.dispatch('clearSignUpPropertyValues')
              Cookies.remove('onboarding_auth_token', null)
              identifySignupInHubspot(response.data.merchant_details)
              router.push('/stripe-integration-required')
            })
          })
        })
    }

    const validateKeyPress = event => {
      if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13) {
        return true
      } else {
        event.preventDefault()
      }
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      savedValues,
      areaInputContainerClicked,
      refs,
      steps,
      totalProgress,
      validateKeyPress,
      getQuestions,
      onboardingToken,
      populateAnswer,
      identifyInHubspot,
      identifySignupInHubspot
    }
  }
})
</script>
