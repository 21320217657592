<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    viewBox="0 0 140 140"
    width="140"
    height="140"
  >
    <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
      <path
        d="M23.25,9.9,12.273,20.878a6.75,6.75,0,0,1-9.546-9.546l9.016-9.015A4.5,4.5,0,1,1,18.106,8.68L9.091,17.7a2.25,2.25,0,0,1-3.182-3.181L14.925,5.5"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
