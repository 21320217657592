<template>
  <SignupMainContainer
    :title="'Log in to Bottle'"
    :image-position="'right'"
    :max-width="'md:max-w-xl'"
    @form-submitted="loginAction"
  >
    <template #body>
      <div>
        <label for="handle" class="block text-gray-700 text-sm font-medium leading-5 tracking-tighter">
          Your username
        </label>
        <div>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input
              id="handle"
              v-model="handle"
              type="text"
              required
              class="peer placeholder-gray-400 block px-3 py-3.5 rounded-l-lg w-full focus:border-gray-800 border-2 border-gray-200 border-r-0 focus:ring-0 appearance-none transition duration-150 ease-in-out text-sm leading-5 focus:outline-none font-semibold tracking-wide"
              placeholder="BananaStand"
              tabindex="1"
            />
            <span
              class="w-full inline-flex items-center rounded-r-lg border-2 border-l-0 peer-focus:border-gray-800 border-gray-200 bg-gray-100 px-3 text-gray-600 text-sm tracking-tight"
            >
              .bottle.com
            </span>
          </div>
        </div>
      </div>

      <div>
        <div class="flex justify-between text-gray-700 text-sm font-medium leading-5 tracking-tighter">
          <label for="password">Password</label>
          <router-link :to="'/forgot-password'" class="underline">Forgot your password?</router-link>
        </div>
        <div class="mt-1 rounded-md shadow-sm">
          <input
            id="password"
            v-model="password"
            type="password"
            placeholder="Password"
            required
            :class="[
              error ? 'border-red-400' : 'border-gray-300',
              flash && 'bg-red-400',
              'placeholder-gray-400 block px-3 py-3.5 rounded-lg w-full focus:border-gray-800 border-2 border-gray-200 focus:ring-0 appearance-none transition duration-150 ease-in-out text-sm leading-5 focus:outline-none font-semibold tracking-wide'
            ]"
            tabindex="1"
          />
        </div>
      </div>
      <div v-if="error" class="bg-yellow-200 text-sm text-gray-800 mt-2 py-1 px-2 rounded-md tracking-tighter">
        {{ error }}
        <div v-if="tries >= 3">Contact team@bottle.com for help</div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-col">
        <div class="w-full flex justify-between">
          <button
            type="button"
            class="inline-flex items-center justify-center border-gray-800 border-2 text-md hover:shadow-lg transition-all rounded-lg px-6 py-3.5 leading-tight bg-white text-gray-800 tracking-tighter"
            @click="signUpClicked()"
          >
            <span>
              New to Bottle?
              <br class="block sm:hidden" />
              <span class="font-bold">Get your free site</span>
            </span>
          </button>
          <button
            type="submit"
            class="inline-flex shrink-0 items-center border-gray-800 border-2 font-bold text-md hover:shadow-lg transition-all rounded-full px-6 py-3.5 leading-tight bg-yellow-200 text-gray-800"
            tabindex="1"
          >
            <span class="ml-auto transform -translate-y-px mr-4">Log in</span>
            <span class="mr-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="13" viewBox="0 0 34 13">
                <path
                  d="M3.11,6.82A20,20,0,0,1,1.2,6a.46.46,0,0,0-.67.15A2.32,2.32,0,0,0,0,7.25C0,7.64,0,7.84.3,8c.59.25,1.18.52,1.78.72"
                  transform="translate(0 0)"
                ></path>
                <path
                  d="M1.83,8.58A23.56,23.56,0,0,0,7.36,10a36.41,36.41,0,0,0,8.79,0A53.55,53.55,0,0,0,29.69,6.72l.19-.07,0,0c-.33.4-.65.8-1,1.19-.72.87-1.45,1.73-2.16,2.61a1.75,1.75,0,0,0,0,2.28.68.68,0,0,0,.52.22.73.73,0,0,0,.5-.26c.67-.78,1.33-1.58,2-2.39l3.65-4.41A2.13,2.13,0,0,0,34,4.5,1.24,1.24,0,0,0,33.91,4a.87.87,0,0,0-.28-.36,1.55,1.55,0,0,0-.32-.2L26.59.11c-.65-.31-1.25.05-1.54.92a1.22,1.22,0,0,0,.4,1.5c.95.49,1.91,1,2.86,1.42l1.78.89-.19.08A55.6,55.6,0,0,1,17.44,8a36.08,36.08,0,0,1-8.87.12A21.78,21.78,0,0,1,2.82,6.7c-.3-.12-.73-.1-1-.25"
                  fill="#000000"
                  transform="translate(0 0)"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </template>
  </SignupMainContainer>
  <NotificationSuccess :show="passwordReset" />
</template>
<script>
import Cookies from 'js-cookie'

import Notification from '@/components/NotificationSuccess.vue'
import api from '@/store/api'

export default {
  components: { Notification },
  data() {
    return {
      handle: '',
      password: '',
      error: '',
      tries: 0,
      flash: false,
      passwordReset: false
    }
  },
  mounted() {
    this.passwordReset = this.$route.fullPath.includes('reset-success')
  },
  methods: {
    loginAction: function () {
      const apiEndpoint = `tokens`
      api
        .call(
          'POST',
          apiEndpoint,
          {
            handle: this.handle,
            password: this.password
          },
          false
        )
        .then(response => {
          this.authenticate(response.data.token)
        })
        .catch(() => {
          this.error = 'Incorrect handle or password'
          this.tries++
          if (this.tries > 1) {
            this.flash = true
            setTimeout(() => {
              this.flash = false
            }, 200)
          }
        })
    },
    signUpClicked: function () {
      this.$router.push(`/sign-up`)
    },
    authenticate: function (token) {
      Cookies.set('auth_token', token, { expires: 6 * 31 })
      this.$store.dispatch('getLoggedInUser')
      window.pusherInit()
      this.$router.push('/')
    }
  }
}
</script>
