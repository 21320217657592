
export default {
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lightboxVisible: false
    }
  },
  computed: {
    isImage: function () {
      return this.attachmentType === 'image'
    },
    isVideo: function () {
      return this.attachmentType === 'video'
    },
    isFile: function () {
      return this.attachmentType === 'file'
    },
    attachmentType: function () {
      return this.attachment?.attributes?.attachment_type
    },
    secureUrl: function () {
      return this.attachment?.attributes?.secure_url
    },
    originalFilename: function () {
      return this.attachment?.attributes?.original_filename
    },
    width: function () {
      return this.attachment?.attributes?.width
    },
    height: function () {
      return this.attachment?.attributes?.height
    },
    thumbnailUrl: function () {
      return this.attachment?.attributes?.thumbnail_url
    }
  }
}
