<template>
  <div
    class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:items-start sm:justify-end sm:p-6"
  >
    <div
      class="w-full max-w-sm bg-white rounded-lg shadow-lg pointer-events-auto overflow-hidden ring-black ring-opacity-5 ring-1"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <component :is="statusIcon" :class="`text-${statusTheme}-400`" class="block w-6 h-6" />
          </div>
          <div class="flex-1 ml-3 pt-0.5 w-0">
            <p class="text-gray-900 text-sm font-medium">
              {{ msg }}
            </p>
          </div>
          <div class="flex flex-shrink-0 ml-4">
            <button
              class="inline-flex text-gray-400 hover:text-gray-500 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:ring-offset-2 focus:ring-2"
              @click="close"
            >
              <span class="sr-only">Close</span>
              <!-- Heroicon name: solid/x -->
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import errorIcon from '@/icon/ErrorIcon'
import infoIcon from '@/icon/InfoIcon'
import successIcon from '@/icon/SuccessIcon'

export default {
  components: {
    infoIcon,
    errorIcon,
    successIcon
  },
  props: {
    status: {
      type: String,
      required: false,
      default: 'Info'
    },
    msg: {
      type: String,
      required: true
    }
  },
  emits: ['closed'],
  computed: {
    statusTheme() {
      if (this.status === 'success') return 'green'
      else if (this.status === 'error') return 'red'
      else return 'blue'
    },
    statusIcon() {
      if (this.status === 'success') return 'successIcon'
      else if (this.status === 'error') return 'errorIcon'
      else return 'infoIcon'
    }
  },
  methods: {
    close() {
      this.$emit('closed')
    }
  }
}
</script>
