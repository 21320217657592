
import { BoltIcon, SparklesIcon } from '@heroicons/vue/24/outline'

import { mapRelationship, mapRelationships } from '@/store/mappers'

export default {
  components: {
    BoltIcon,
    SparklesIcon
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    senderIsMerchant() {
      return this.conversationItemUser.attributes.user_type === 'merchant'
    },
    senderIsBottlebot() {
      return this.senderIsMerchant && this.conversationItemUser.attributes.display_name === '@bottlebot'
    },
    attachments() {
      return mapRelationships(this.message.relationships.attachments.data)
    },
    conversationItemUser() {
      return mapRelationship(this.message.relationships.user.data)
    },
    undelivered() {
      const send = this.message.attributes.sms_send_status
      const delivery = this.message.attributes.sms_delivery_status
      return (
        delivery === 'undelivered' ||
        send === 'do_not_text' ||
        send === 'block_marketing_messages' ||
        send === 'not_allowed_to_send_texts'
      )
    },
    absoluteTimestamp() {
      return new Intl.DateTimeFormat('en-US', {
        timeStyle: 'short'
      }).format(new Date(this.message?.attributes.created_at))
    },

    // classes

    backgroundClass() {
      if (this.undelivered) {
        return 'bg-gray-600'
      }

      if (this.message.attributes.originating_class) {
        return 'bg-blue-500'
      }

      if (this.senderIsMerchant) {
        return 'bg-blue-600'
      }

      return 'bg-gray-200'
    },

    textClass() {
      return this.senderIsMerchant ? 'text-gray-100 ml-2' : 'text-gray-800'
    },

    roundedClass() {
      return this.message.attributes.originating_class ? 'rounded-md' : 'rounded-2xl'
    },

    borderClass() {
      if (this.undelivered) {
        return 'border border-red-400 transform translate-x-px -translate-y-px'
      }

      if (this.message.attributes.originating_class) {
        return 'border border-blue-900 transform translate-x-px -translate-y-px'
      }

      return ''
    },

    computedClasses() {
      return [
        this.backgroundClass,
        this.textClass,
        'text-nearly-base',
        'leading-5',
        'whitespace-pre-line',
        'w-fit',
        'py-2',
        'px-2.5',
        'right-0',
        this.roundedClass,
        'max-w-full',
        'overflow-hidden',
        this.borderClass
      ]
    }
  }
}
