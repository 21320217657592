<template>
  <transition
    enter-active-class="transition-opacity ease-out duration-300"
    enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
    leave-active-class="ease-in duration-200"
    leave-class="opacity-100 translate-y-0 sm:scale-100"
    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  >
    <div
      v-if="active"
      class="fixed z-20 bottom-0 inset-x-0 top-0 flex flex-col justify-center bg-black bg-opacity-40 sm:inset-0"
    >
      <div
        class="flex flex-col justify-center mx-auto w-4/5 max-h-screen-80 bg-white rounded-lg shadow-xl overflow-hidden transform transition-all"
      >
        <button
          class="duration-250 absolute right-0 top-0 mr-4 mt-4 text-gray-500 hover:text-gray-900 transition-colors md:mr-6 md:mt-6"
          @click="lightboxHidden()"
        >
          <XMarkIcon class="w-5 h-5"></XMarkIcon>
          <span class="sr-only">close lightbox</span>
        </button>
        <div v-if="media[mediaIndex].attachment_type == 'image'">
          <figure>
            <img
              class="mx-auto p-6 max-h-screen-80"
              :src="media[mediaIndex].secure_url"
              :alt="media[mediaIndex].original_filename"
              draggable="false"
            />
          </figure>
        </div>
        <div v-if="media[mediaIndex].attachment_type == 'video'" class="pb-4 pt-5 px-4 sm:p-6">
          <video
            ref="video"
            class="mx-auto w-full max-w-screen-lg max-h-screen-80"
            controls
            width="250"
            draggable="false"
          >
            <source v-if="media[mediaIndex].webmSrc" :src="media[mediaIndex].webmSrc" type="video/webm" />

            <source v-if="media[mediaIndex].mp4Src" :src="media[mediaIndex].mp4Src" type="video/mp4" />
            <div
              class="absolute bottom-0 left-1/2 mb-8 px-5 py-3 text-gray-800 text-sm bg-white bg-opacity-75 transform -translate-x-2/4"
            >
              HTML5 Video is not supported in your browser
            </div>
          </video>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { XMarkIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    XMarkIcon
  },
  props: {
    media: {
      type: Array,
      required: true
    },
    mediaIndex: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ['lightbox-hidden', 'lightbox-show-next', 'lightbox-show-previous', 'lightbox-hidden'],
  watch: {
    active: 'handleActiveState'
  },
  methods: {
    handleKeys() {
      window.addEventListener('keyup', e => {
        if (e.key === 'Escape') this.$emit('lightbox-hidden')
        if (e.key === 'ArrowRight') this.$emit('lightbox-show-next')
        if (e.key === 'ArrowLeft') this.$emit('lightbox-show-previous')
      })
    },
    handleActiveState() {
      if (this.$props.active) {
        this.handleKeys()
        if (this.media[this.$props.mediaIndex].attachment_type === 'video') this.handleVideo()
      }
    },
    handleVideo() {
      setTimeout(() => {
        const video = this.$refs['video']
        if (video.paused) video.play()
        else video.pause()
      }, 150)
    },
    lightboxHidden() {
      this.$emit('lightbox-hidden')
    }
  }
}
</script>
