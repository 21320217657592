
import {
  ChartBarIcon,
  CheckCircleIcon,
  ClipboardDocumentListIcon,
  ExclamationCircleIcon,
  FolderOpenIcon
} from '@heroicons/vue/20/solid'

export default {
  components: {
    ChartBarIcon,
    CheckCircleIcon,
    ClipboardDocumentListIcon,
    ExclamationCircleIcon,
    FolderOpenIcon
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    heading: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    }
  }
}
