import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex items-start" }
const _hoisted_2 = { class: "flex h-5 items-center" }
const _hoisted_3 = ["id", "aria-describedby", "checked"]
const _hoisted_4 = { class: "ml-3 text-sm" }
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id"]

import { ulid } from 'ulid'

export default {
  __name: 'Checkbox',
  props: ['modelValue', 'name', 'description'],
  emits: ['update:modelValue', 'change'],
  setup(__props, { emit: __emit }) {

const id = ulid()

const props = __props
const emit = __emit

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        id: _unref(id),
        "aria-describedby": _unref(id) + '-description',
        type: "checkbox",
        class: "h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500",
        checked: props.modelValue,
        onChange: _cache[0] || (_cache[0] = 
          e => {
            emit('update:modelValue', e.target.checked)
            emit('change', e.target.checked)
          }
        )
      }, null, 40, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", {
        for: _unref(id),
        class: "font-medium text-gray-700"
      }, _toDisplayString(props.name), 9, _hoisted_5),
      _createElementVNode("p", {
        id: _unref(id) + '-description',
        class: "text-gray-500"
      }, _toDisplayString(props.description), 9, _hoisted_6)
    ])
  ]))
}
}

}