<template>
  <div class="col-span-2 pb-6 md:col-span-1">
    <section-header heading="Tax structure" />
    <h1 class="mt-3">{{ selectedTaxStructureName }}</h1>
    <div class="flex flex-col mt-3">
      <button-dropdown v-if="taxBoxActive" :width="'full'" class="w-40" :button-text="selectedTaxStructureName">
        <button
          type="button"
          class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
          role="menuitem"
          @click="taxOptionSelected(null)"
        >
          {{ 'Not Selected' }}
        </button>
        <button
          type="button"
          class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
          role="menuitem"
          @click="taxOptionSelected('None')"
        >
          {{ 'None' }}
        </button>
        <button
          type="button"
          class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
          role="menuitem"
          @click="taxOptionSelected('Manual')"
        >
          {{ 'Manual' }}
        </button>
        <button
          type="button"
          class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
          role="menuitem"
          @click="taxOptionSelected('Taxjar')"
        >
          {{ 'Taxjar' }}
        </button>
      </button-dropdown>

      <div class="mt-5">
        <button rel="noteboxButton" class="btn-ghost -xs" @click="taxButtonClick">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { closable } from '@/directives/closable'
import { capatilizeFirstLetter } from '@/helpers/CaseHelper'
import api from '@/store/api'

const NOTES_KEY = 'notes-01EWNTQ20SV8YJAEC2F2Q3X6C2'

export default {
  directives: {
    closable
  },
  props: {
    conversationUserPairId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      note: { value: '' },
      taxBoxActive: false,
      conversationUserPairTaxStructure: 'Not Selected'
    }
  },
  computed: {
    apiEndpoint() {
      return `conversation_user_pairs/${this.$props.conversationUserPairId}`
    },
    buttonText() {
      if (!this.taxBoxActive) return 'Edit'
      else return 'Save'
    },
    selectedTaxStructureName() {
      if (!this.conversationUserPairTaxStructure) return 'Not Selected'
      return this.conversationUserPairTaxStructure
    }
  },
  watch: {
    '$store.state.objects.conversation_user_pair': function () {
      if (this.$store.state.objects.conversation_user_pair[this.conversationUserPairId].attributes.tax_structure) {
        this.conversationUserPairTaxStructure = capatilizeFirstLetter(
          this.$store.state.objects.conversation_user_pair[this.conversationUserPairId].attributes.tax_structure
        )
      }
    }
  },
  methods: {
    taxOptionSelected(selectedOption) {
      this.conversationUserPairTaxStructure = selectedOption
    },
    taxButtonClick() {
      if (this.taxBoxActive) return this.saveTaxOption()
      else return this.noteboxFocus()
    },
    noteboxFocus() {
      this.taxBoxActive = true
    },
    saveTaxOption() {
      this.taxBoxActive = false
      api.call('PUT', this.apiEndpoint, {
        tax_structure: this.conversationUserPairTaxStructure
          ? this.conversationUserPairTaxStructure.toLowerCase()
          : null
      })
    }
  }
}
</script>
