<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop text="Drops" :parent="{ link: '/sell', name: 'Sell' }">
        <template #right>
          <router-link to="/store/drops/new">
            <NeueButton type="primary">New</NeueButton>
          </router-link>
        </template>
      </SecondaryTop>
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <div class="px-12 md:px-16 pt-10">
          <NeueWell class="mb-8">
            Drops define a certain ordering window. Within each drop, choose the Store and the Group(s) of customers who
            should be ordering from that Store, setup automatic order reminders which automatically include a unique
            link to each customer's cart, and optionally setup autofill and autocharge to run within the ordering
            window.
          </NeueWell>
          <NeueLoading :loading="loading">
            <TabGroup v-if="data.list.length" :default-index="1">
              <TabList class="mb-4 space-x-1">
                <Tab
                  v-for="x in ['All', 'Upcoming', 'Past', 'No production days']"
                  :key="x"
                  v-slot="{ selected }"
                  as="template"
                >
                  <button :class="selected ? 'btn-primary' : 'btn-ghost'" @click="dropFilterSelected">{{ x }}</button>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel v-for="(list, i) in [data.list, upcomingDrops, pastDrops, noDaysDrops]" :key="i">
                  <div class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div v-show="list.length" class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Drop
                                </th>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Description
                                </th>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Groups
                                </th>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Store
                                </th>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Production
                                </th>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Autocharge
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                  <span class="sr-only">Reminders and duplicates</span>
                                </th>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr
                                v-for="item in list.slice(data.page * 8, data.page * 8 + 8)"
                                :key="item.id"
                                class="cursor-pointer"
                                @click="$router.push('/store/drops/' + item.id)"
                              >
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="text-sm text-gray-900 max-w-2xl truncate font-bold mono">
                                    #{{ item.id }}
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap" style="max-width: 15rem">
                                  <div class="text-sm font-medium text-gray-900 truncate group-hover:text-blue-900">
                                    {{ item.attributes.description }}
                                    <span v-if="duplicatorForDrop(item)">
                                      ({{ duplicatorForDrop(item)?.attributes?.display_description }})
                                    </span>
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap" style="max-width: 20rem">
                                  <div class="text-sm font-medium text-gray-900 truncate group-hover:text-blue-900">
                                    {{
                                      groupsForDrop(item)
                                        .map(x => x?.attributes?.audience_name)
                                        .join(', ')
                                    }}
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="text-sm font-medium text-gray-900 truncate group-hover:text-blue-900">
                                    {{ storeForDrop(item)?.attributes.store_name }}
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap" style="max-width: 20rem">
                                  <div class="text-sm font-medium text-gray-900 truncate group-hover:text-blue-900">
                                    {{ fulfillmentDaysForDrop(item).join(', ') }}
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="text-sm font-medium text-gray-900 truncate group-hover:text-blue-900">
                                    {{ autochargeTimeForDrops(item) }}
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="text-sm font-medium text-gray-900 truncate group-hover:text-blue-900">
                                    <BellIcon
                                      v-if="remindersForDrop(item).length"
                                      v-tippy="{
                                        content: `${remindersForDrop(item).length} ${
                                          remindersForDrop(item).length > 1 ? 'reminders' : 'reminder'
                                        } set`
                                      }"
                                      class="h-4 w-4 inline"
                                    />
                                    <Square2StackIcon
                                      v-if="duplicatorForDrop(item)"
                                      v-tippy="{
                                        content: `${duplicatorForDrop(item).attributes.display_description}`
                                      }"
                                      class="h-4 w-4 inline"
                                    />
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="flex flex-shrink-0 ml-2">
                                    <p
                                      :class="[
                                        dropErrorStatusBackground(item),
                                        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full'
                                      ]"
                                    >
                                      {{ dropErrorDescription(item) }}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Table Pagination -->
                  <table-pagination
                    :items="list"
                    :page="data.page"
                    :page-size="8"
                    @on-page-change="e => (data.page = e)"
                  />
                </TabPanel>
              </TabPanels>
            </TabGroup>
            <NeueWell v-if="!data.list.length" class="text-center">
              <EmptyState plural="drops" />
            </NeueWell>
          </NeueLoading>
        </div>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { BellIcon, Square2StackIcon } from '@heroicons/vue/20/solid'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

import ExplanationBox from '@/components/ExplanationBox.vue'
import { serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { BellIcon, Square2StackIcon, TabGroup, TabList, Tab, TabPanels, TabPanel, ExplanationBox, SellSidebar },
  setup() {
    const store = useStore()

    const loading = ref(true)

    const data = reactive({
      list: computed(() =>
        Object.values(store.state.objects.bottle_cycle || {})
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => {
            const aDay = mapRelationships(a.relationships.fulfillment_availabilities.data).sort(
              (x, y) => new Date(x.attributes.date) - new Date(y.attributes.date)
            )[0]
            const bDay = mapRelationships(b.relationships.fulfillment_availabilities.data).sort(
              (x, y) => new Date(x.attributes.date) - new Date(y.attributes.date)
            )[0]
            return new Date(aDay?.attributes.date) - new Date(bDay?.attributes.date)
          })
      ),
      page: 0
    })

    // TODO(alicja): think about the exact separation of how much formatting/processing
    // happens in those functions vs in the view template above

    const groupsForDrop = drop =>
      mapRelationships(drop.relationships.bottle_cycle_audience_pairs.data).map(x =>
        mapRelationship({ type: 'audience', id: x.attributes.audience_id })
      )

    const dropFilterSelected = () => {
      data.page = 0
    }

    const dropErrorStatusBackground = drop => {
      if (
        drop.attributes.bottle_cycle_errors.no_store_error ||
        drop.attributes.bottle_cycle_errors.no_group_error ||
        drop.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error
      ) {
        return 'bg-red-100 text-red-600'
      } else {
        return 'bg-green-100 text-green-800'
      }
    }

    const dropErrorDescription = drop => {
      if (
        drop.attributes.bottle_cycle_errors.no_store_error ||
        drop.attributes.bottle_cycle_errors.no_group_error ||
        drop.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error
      ) {
        return 'Has errors'
      } else {
        return 'Active'
      }
    }

    const storeForDrop = drop => drop.relationships.store.data && mapRelationship(drop.relationships.store.data)

    const duplicatorForDrop = drop =>
      drop.relationships.duplicator.data && mapRelationship(drop.relationships.duplicator.data)

    const autochargeTimeForDrops = drop => {
      if (!drop.relationships.bottle_autocharge_automation.data) return
      const autocharge = mapRelationship(drop.relationships.bottle_autocharge_automation.data)
      const automation = mapRelationship(autocharge.relationships.automation_scheduler.data)
      if (!automation) return
      return new Intl.DateTimeFormat([], {
        hour: 'numeric',
        minute: 'numeric'
      }).format(new Date(automation.attributes.datetime))
    }

    const remindersForDrop = drop => {
      if (!drop.relationships.bottle_reminder_automations.data) return []
      return mapRelationships(drop.relationships.bottle_reminder_automations.data)
    }

    const fulfillmentDaysForDrop = drop =>
      mapRelationships(drop.relationships.fulfillment_availabilities.data).map(x =>
        Intl.DateTimeFormat([], {
          day: 'numeric',
          month: 'short'
        }).format(serverDayToDateObject(x.attributes.date))
      )

    const getList = () => {
      const includes = [
        'audiences',
        'bottle_reminder_automations',
        'bottle_autofill_automation',
        'bottle_autocharge_automation',
        'fulfillment_availabilities',
        'duplicator',
        'store',
        'bottle_cycle_audience_pairs',
        'bottle_reminder_automations.automation_scheduler',
        'bottle_reminder_automations.message_template',
        'bottle_reminder_automations.message_template.attachments',
        'bottle_autofill_automation.automation_fields',
        'bottle_autocharge_automation.automation_scheduler'
      ]
      api.call('GET', `bottle_cycles?included=${includes.join(',')}`).then(() => {
        loading.value = false
      })
    }

    onMounted(() => {
      getList()
    })

    const onPageChange = e => {
      this.page = e
    }

    const upcomingDrops = computed(() =>
      data.list.filter(x =>
        mapRelationships(x.relationships.fulfillment_availabilities.data).some(
          y => serverDayToDateObject(y.attributes.date) > new Date()
        )
      )
    )

    const pastDrops = computed(() =>
      data.list.filter(x =>
        mapRelationships(x.relationships.fulfillment_availabilities.data).some(
          y => serverDayToDateObject(y.attributes.date) < new Date()
        )
      )
    )

    const noDaysDrops = computed(() =>
      data.list.filter(x => x.relationships.fulfillment_availabilities.data.length === 0)
    )

    return {
      data,
      onPageChange,
      mapRelationship,
      mapRelationships,
      groupsForDrop,
      storeForDrop,
      autochargeTimeForDrops,
      remindersForDrop,
      fulfillmentDaysForDrop,
      duplicatorForDrop,
      dropFilterSelected,
      upcomingDrops,
      pastDrops,
      noDaysDrops,
      dropErrorStatusBackground,
      dropErrorDescription,
      loading
    }
  }
}
</script>
