import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'


export default {
  __name: 'NewFilterDropdown',
  props: ['name', 'filters'],
  emits: ['addFilter'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(PopoverGroup), { class: "flex items-baseline space-x-8" }, {
    default: _withCtx(() => [
      _createVNode(_unref(Popover), {
        as: "div",
        class: "relative inline-block text-left"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_unref(PopoverButton), { class: "group inline-flex items-center justify-center text-sm font-semibold text-gray-900 hover:text-gray-900" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(props.name), 1),
                _createVNode(_unref(ChevronDownIcon), {
                  class: "-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500",
                  "aria-hidden": "true"
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_Transition, {
            "enter-active-class": "transition ease-out duration-100",
            "enter-from-class": "transform opacity-0 scale-95",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition ease-in duration-75",
            "leave-from-class": "transform opacity-100 scale-100",
            "leave-to-class": "transform opacity-0 scale-95"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(PopoverPanel), { class: "absolute right-0 z-20 mt-2 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none px-4 py-1" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.filters, (filter) => {
                    return (_openBlock(), _createBlock(_unref(PopoverButton), {
                      class: "text-sm font-medium whitespace-nowrap text-gray-900 cursor-pointer my-3 block",
                      onClick: $event => (emit('addFilter', filter.value))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(filter.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 256)),
                  (!props.filters.length)
                    ? (_openBlock(), _createBlock(_unref(PopoverButton), {
                        key: 0,
                        class: "text-sm font-medium whitespace-nowrap text-gray-600 italic my-3 block"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" No filters available ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

}