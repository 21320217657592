
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    hasUnsavedChanges: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['on-save', 'on-discard']
})
