
import { XMarkIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    XMarkIcon
  },
  props: {
    media: {
      type: Array,
      required: true
    },
    mediaIndex: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ['lightbox-hidden', 'lightbox-show-next', 'lightbox-show-previous', 'lightbox-hidden'],
  watch: {
    active: 'handleActiveState'
  },
  methods: {
    handleKeys() {
      window.addEventListener('keyup', e => {
        if (e.key === 'Escape') this.$emit('lightbox-hidden')
        if (e.key === 'ArrowRight') this.$emit('lightbox-show-next')
        if (e.key === 'ArrowLeft') this.$emit('lightbox-show-previous')
      })
    },
    handleActiveState() {
      if (this.$props.active) {
        this.handleKeys()
        if (this.media[this.$props.mediaIndex].attachment_type === 'video') this.handleVideo()
      }
    },
    handleVideo() {
      setTimeout(() => {
        const video = this.$refs['video']
        if (video.paused) video.play()
        else video.pause()
      }, 150)
    },
    lightboxHidden() {
      this.$emit('lightbox-hidden')
    }
  }
}
