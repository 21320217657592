
import errorIcon from '@/icon/ErrorIcon'
import infoIcon from '@/icon/InfoIcon'
import successIcon from '@/icon/SuccessIcon'

export default {
  components: {
    infoIcon,
    errorIcon,
    successIcon
  },
  props: {
    status: {
      type: String,
      required: false,
      default: 'Info'
    },
    msg: {
      type: String,
      required: true
    }
  },
  emits: ['closed'],
  computed: {
    statusTheme() {
      if (this.status === 'success') return 'green'
      else if (this.status === 'error') return 'red'
      else return 'blue'
    },
    statusIcon() {
      if (this.status === 'success') return 'successIcon'
      else if (this.status === 'error') return 'errorIcon'
      else return 'infoIcon'
    }
  },
  methods: {
    close() {
      this.$emit('closed')
    }
  }
}
