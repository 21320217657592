import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, unref as _unref } from "vue"

import DescriptionListItem from '@/components/neue/DescriptionListItem'

export default {
  __name: 'DescriptionListEditable',
  props: ['label', 'active'],
  setup(__props) {

const props = __props

return (_ctx, _cache) => {
  const _component_NeueButton = _resolveComponent("NeueButton")

  return (_openBlock(), _createBlock(_unref(DescriptionListItem), {
    label: props.label
  }, {
    contents: _withCtx(() => [
      (!props.active)
        ? _renderSlot(_ctx.$slots, "inactive", { key: 0 })
        : _createCommentVNode("", true),
      (props.active)
        ? _renderSlot(_ctx.$slots, "active", { key: 1 })
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!props.active)
        ? (_openBlock(), _createBlock(_component_NeueButton, {
            key: 0,
            type: "secondary",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:active', true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Update")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["label"]))
}
}

}