
import api from '@/store/api'

export default {
  data() {
    return {
      name: '',
      description: '',
      price: '',
      value: ''
    }
  },
  methods: {
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    handleSubmit() {
      api
        .call('POST', 'gift_cards', {
          gift_card: {
            gift_card_name: this.name,
            description: this.description,
            price_amount_cents: Math.round(this.price * 100),
            value_amount_cents: Math.round(this.value * 100)
          }
        })
        .then(response => {
          this.$router.push(`/store/gift-cards/${response.data.data.id}`)
        })
    }
  }
}
