<template>
  <div class="col-span-2 md:col-span-1">
    <section-header heading="Attributes" />
    <div class="hidden sm:block">
      <div class="flex flex-col mt-2">
        <div class="align-middle min-w-full shadow overflow-hidden overflow-x-auto sm:rounded-lg">
          <table class="min-w-full divide-cool-gray-200 divide-y">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-left text-cool-gray-500 text-xs font-medium tracking-wider leading-4 bg-cool-gray-50 uppercase"
                >
                  Key
                </th>
                <th
                  class="px-6 py-3 text-left text-cool-gray-500 text-xs font-medium tracking-wider leading-4 bg-cool-gray-50 uppercase"
                >
                  Value
                </th>
                <th
                  class="px-6 py-3 text-right text-cool-gray-500 text-xs font-medium tracking-wider leading-4 bg-cool-gray-50 uppercase"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-cool-gray-200 divide-y">
              <tr v-for="(item, i) in attrs.slice(page * 5, page * 5 + 5)" :key="`row-${i}`" class="bg-white">
                <td class="whitespace-no-wrap px-6 py-4 text-right text-cool-gray-500 text-sm leading-5">
                  <input
                    v-model="item.key"
                    type="text"
                    class="form-input w-full"
                    :class="{ 'bg-gray-50 text-black': item.active }"
                    v-bind="{ disabled: !item.active }"
                    @keyup.enter="rowButtonClick(item)"
                  />
                </td>
                <td class="whitespace-no-wrap px-6 py-4 text-right text-cool-gray-500 text-sm leading-5">
                  <input
                    v-model="item.value"
                    type="text"
                    class="form-input w-full"
                    :class="{ 'bg-gray-50 text-black': item.active }"
                    v-bind="{ disabled: !item.active }"
                    @keyup.enter="rowButtonClick(item)"
                  />
                </td>
                <td class="whitespace-no-wrap px-6 py-4 text-right text-cool-gray-500 text-sm leading-5">
                  <button @click="rowButtonClick(item)">
                    {{ rowButtonText(item.active) }}
                  </button>
                </td>
              </tr>
              <tr class="bg-white">
                <td class="whitespace-no-wrap px-6 py-4 text-right text-cool-gray-500 text-sm leading-5">
                  <input
                    v-model="newAttrKey"
                    type="text"
                    class="form-input w-full text-black bg-gray-50"
                    @keyup.enter="addAttr"
                  />
                </td>
                <td class="whitespace-no-wrap px-6 py-4 text-right text-cool-gray-500 text-sm leading-5">
                  <input
                    v-model="newAttrValue"
                    type="text"
                    class="form-input w-full text-black bg-gray-50"
                    @keyup.enter="addAttr"
                  />
                </td>
                <td class="whitespace-no-wrap px-6 py-4 text-right text-cool-gray-500 text-sm leading-5">
                  <button @click="addAttr">Add</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table-pagination :items="attrs" :page-size="5" :page="page" @on-page-change="onPageChange" />
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/store/api'

const NOTES_KEY = 'notes-01EWNTQ20SV8YJAEC2F2Q3X6C2'

export default {
  props: {
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      attrs: [],
      page: 0,
      newAttrKey: null,
      newAttrValue: null
    }
  },
  computed: {
    apiEndpoint() {
      return `conversation_user_pairs/${this.$props.conversationUserPairId}/custom_attributes`
    }
  },
  mounted() {
    api.call('GET', this.apiEndpoint).then(response => {
      response.data.data.forEach(attr => {
        if (attr.attributes.key_string_downcased === NOTES_KEY.toLowerCase()) return
        this.attrs.push({
          id: attr.attributes.id,
          key: attr.attributes.key_string_downcased,
          value: attr.attributes.value_string
        })
      })
    })
  },
  methods: {
    addAttr() {
      const key = this.newAttrKey
      const value = this.newAttrValue
      if (!key || !value) return
      this.newAttrKey = null
      this.newAttrValue = null

      api
        .call('POST', this.apiEndpoint, {
          custom_attribute: {
            key_string: key,
            value_string: value
          }
        })
        .then(response => {
          const attr = response.data.data.attributes
          this.attrs.push({
            id: attr.id,
            key: attr.key_string_downcased,
            value: attr.value_string,
            active: false
          })
        })
        .catch(error => {
          if (error.response.status === 422) {
            alert('Sorry, this key is already taken.')
          }
        })
    },
    rowButtonText(active) {
      if (active) return 'Save'
      else return 'Edit'
    },
    rowButtonClick(attr) {
      if (!attr.key) return

      const index = this.attrs.findIndex(x => x.id === attr.id)
      this.attrs[index].active = !this.attrs[index].active
      if (this.attrs[index].active) return // don't save if it's active

      if (attr.value) {
        api
          .call('PUT', this.apiEndpoint + '/' + attr.id, {
            custom_attribute: {
              key_string: attr.key,
              value_string: attr.value
            }
          })
          .then(response => {
            const newAttr = response.data.data.attributes
            const index = this.attrs.findIndex(x => x.id === attr.id)

            this.attrs[index] = {
              id: newAttr.id,
              key: newAttr.key_string_downcased,
              value: newAttr.value_string,
              active: false
            }
          })
      } else {
        api.call('DELETE', this.apiEndpoint + '/' + attr.id).then(() => {
          const index = this.attrs.findIndex(x => x.id === attr.id)
          this.attrs.splice(index, 1)
        })
      }
    },
    onPageChange(e) {
      this.page = e
    }
  }
}
</script>
