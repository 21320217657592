import script from "./SellSidebar.vue?vue&type=script&setup=true&lang=js"
export * from "./SellSidebar.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import NeueButton from "@/components/neue/NeueButton.vue";
script.components = Object.assign({}, { NeueButton }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { NeueButton }, script.__o.components);}