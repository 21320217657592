
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      handle: '',
      handleError: ''
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const businessNameQuestion = computed(
      () => onboardingQuestions.value.filter(obj => obj.attributes.target_column === 'handle')[0]
    )
    const businessNameAnswer = computed(() =>
      onboardingAnswers.value.find(
        obj => obj.relationships.onboarding_question.data.id === businessNameQuestion.value.id
      )
    )

    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )

    watch(onboardingAccount, (newValue, oldValue) => {
      data.handle = businessNameAnswer.value?.attributes.answer || ''
    })

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: emailAnswer.value?.attributes.answer,
          onboarding_business_name: data.handle
        }
      ])
    }

    const formSubmitted = () => {
      checkIfHandleExists()
    }

    const checkIfHandleExists = () => {
      api
        .call(
          'POST',
          'handle_validation',
          {
            handle: data.handle
          },
          false
        )
        .then(response => {
          if (response.status === 422) {
            data.handleError = response.data.errors[0]
            return
          } else {
            submitAnswer(businessNameQuestion.value.id)
          }
        })
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'BusinessName', answer: data.handle })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.handle,
                  onboarding_question_id: businessNameQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values['nextPage'] = 3
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            router.push(`/business-type`)
          })
        })
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const handlePlaceholder = () => {
      if (data.handle) {
        return data.handle?.replace(/[^\w ]/g, '').replace(/[^A-Z]/gi, '')
      } else {
        return 'thebananastand'
      }
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      savedValues,
      handlePlaceholder,
      checkIfHandleExists,
      populateAnswer,
      steps,
      totalProgress,
      submitAnswer,
      onboardingToken,
      identifyInHubspot
    }
  }
})
