
import api from '@/store/api'

export default {
  props: {
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tags: [],
      newTag: ''
    }
  },
  computed: {
    apiEndpoint() {
      return `conversation_user_pairs/${this.$props.conversationUserPairId}/tags`
    }
  },
  mounted() {
    api.call('GET', this.apiEndpoint).then(response => {
      response.data.data.forEach(tag => {
        this.tags.push(tag.attributes)
      })
    })
  },
  methods: {
    addTag() {
      const text = this.newTag
      this.newTag = ''
      api
        .call('POST', this.apiEndpoint, {
          tag: {
            tag_text: text
          }
        })
        .then(response => {
          this.tags.push(response.data.data.attributes)
        })
        .catch(error => {
          if (error.response.status === 422) {
            alert('Sorry, this tag is already taken.')
          }
          this.newTag = text
        })
    },
    deleteTag(tag) {
      api.call('DELETE', this.apiEndpoint + '/' + tag.id).then(() => {
        const index = this.tags.indexOf(tag)
        this.tags.splice(index, 1)
      })
    }
  }
}
