import { createStore } from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

export const objectRefreshed = mutations.objectRefreshed
delete mutations.objectRefreshed

export default createStore({
  state: structuredClone(state),
  mutations,
  getters,
  actions,
  modules: {}
})
