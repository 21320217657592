let handleOutsideClick
export const closable = {
  beforeMount(el, binding) {
    handleOutsideClick = e => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false

      exclude.forEach(refName => {
        const relAttribute = e.target.attributes.rel
        if (relAttribute) {
          if (relAttribute.nodeValue === refName) {
            clickedOnExcludedEl = true
          }
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        binding.instance[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind() {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
}
