<template>
  <div
    class="md:mt-4 md:ml-4 md:mr-4 md:mb-4 bg-white relative w-full h-full md:h-auto justify-center items-center md:rounded-3xl flex-col border-t-2 md:border-2 border-gray-900"
    :class="props.maxWidth"
  >
    <div class="absolute inset-x-0 top-0 translate-y-px transform">
      <div class="flex -translate-y-full md:-translate-y-1/2 transform justify-center">
        <span
          class="inline-flex rounded-t-lg rounded-b-sm md:rounded-full bg-gray-800 px-4 py-1 text-sm font-semibold font-manrope uppercase text-white tracking-wider"
        >
          {{ props.title }}
        </span>
      </div>
    </div>
    <slot name="stepper" />
    <form class="px-4 py-5 md:p-6 w-full" @submit.prevent="formSubmitted">
      <div class="mx-auto w-full">
        <div class="mt-2">
          <div class="space-y-6 flex flex-col">
            <slot name="body" />
            <div class="pt-2 flex justify-between items-center">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'md:max-w-3xl'
    }
  },

  setup(props, context) {
    const formSubmitted = () => {
      context.emit('form-submitted')
    }
    return {
      props,
      formSubmitted,
      context
    }
  }
})
</script>
