<template>
  <div>
    <!-- I also want to add max-h-56 to the img tag -->
    <img
      v-if="isImage"
      loading="lazy"
      class="max-w-xs rounded-lg hover:opacity-75 cursor-pointer object-cover shadow-md"
      :src="secureUrl"
      :alt="originalFilename"
      :width="width"
      :height="height"
      draggable="false"
      @click="lightboxVisible = true"
    />
    <div v-if="isVideo" class="max-w-xs">
      <video controls :poster="thumbnailUrl" width="100%">
        <source v-if="secureUrl" :src="secureUrl" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <a
      v-if="isFile"
      class="flex items-center justify-start px-3 py-2 max-w-full bg-gray-brand-100 border border-gray-brand-300 rounded-md shadow-sm hover:opacity-75 cursor-pointer overflow-hidden"
      :href="secureUrl"
      target="_blank"
    >
      <div>
        <svg
          class="w-5 h-5 text-gray-brand-800"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
          />
        </svg>
      </div>
      <div class="ml-3 text-gray-brand-800 break-all">
        <span class="text-sm">
          {{ attachment.attributes.original_filename }}
        </span>
      </div>
    </a>
    <!-- Lightbox -->
    <lightbox
      v-if="isImage"
      :active="lightboxVisible"
      :media-index="0"
      :media="[attachment.attributes]"
      @lightbox-hidden="lightboxVisible = false"
    />
  </div>
</template>
<script>
export default {
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lightboxVisible: false
    }
  },
  computed: {
    isImage: function () {
      return this.attachmentType === 'image'
    },
    isVideo: function () {
      return this.attachmentType === 'video'
    },
    isFile: function () {
      return this.attachmentType === 'file'
    },
    attachmentType: function () {
      return this.attachment?.attributes?.attachment_type
    },
    secureUrl: function () {
      return this.attachment?.attributes?.secure_url
    },
    originalFilename: function () {
      return this.attachment?.attributes?.original_filename
    },
    width: function () {
      return this.attachment?.attributes?.width
    },
    height: function () {
      return this.attachment?.attributes?.height
    },
    thumbnailUrl: function () {
      return this.attachment?.attributes?.thumbnail_url
    }
  }
}
</script>
