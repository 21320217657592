
import 'tippy.js/dist/tippy.css'

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { CheckIcon, PencilIcon } from '@heroicons/vue/20/solid'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { DatePicker } from 'v-calendar'
import { Field, Form } from 'vee-validate'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import ConversationsMenu from '@/components/customers/ConversationsMenu.vue'
import { dateObjectToServerDay, serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships, mapType } from '@/store/mappers'

export default defineComponent({
  components: {
    InformationCircleIcon,
    Field,
    Form,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    ConversationsMenu,
    PencilIcon,
    CheckIcon,
    DatePicker
  },
  setup() {
    const newOrderModalOpen = ref(false)

    const name = computed(
      () => mapRelationship({ type: 'conversation_user_pair', id: route.params.id })?.attributes.display_name
    )

    const saveName = name => {
      api.call('PUT', `conversation_user_pairs/${route.params.id}`, {
        conversation_user_pair: {
          display_name: name
        }
      })
    }

    const saveCode = () => {
      api
        .call('PUT', `conversation_user_pairs/${route.params.id}?included=referral_discount_codes`, {
          conversation_user_pair: {
            referral_discount_codes_attributes: [{ id: data.value.code.id, code: local.value.code }]
          }
        })
        .then(response => {
          data.value.code = mapRelationship(response.data.data.relationships.referral_discount_codes.data?.[0])
        })
      local.value.editingCode = false
    }

    const saveEffective = () => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: {
          effective_starting_date: dateObjectToServerDay(local.value.effective)
        }
      })
      local.value.editingEffective = false
    }

    const route = useRoute()
    const router = useRouter()

    const data = ref({
      accountCredit: '',
      lifetimeSpend: '',
      memberSince: '',
      phoneRaw: '',
      phoneFormatted: '',
      email: '',
      sortedOrders: [],
      sortedCarts: [],
      orders: computed(() => mapRelationships(data.value.sortedOrders)),
      carts: computed(() => mapRelationships(data.value.sortedCarts))
    })

    const local = ref({
      status: {
        customer: {
          isLoading: false
        },
        orders: {
          isLoading: false,
          isEmpty: false,
          hasError: false
        },
        carts: {
          isLoading: false,
          isEmpty: false,
          hasError: false
        }
      },
      modal: {
        type: '',
        content: {}
      },
      phoneSupportCopy: '<a href="mailto:team@bottle.com">Contact Bottle</a> to change the contact\'s phone number',
      code: null,
      editingCode: false,
      effective: null,
      editingEffective: false
    })

    watch(
      () => local.value.code,
      () => {
        if (!local.value.code) return
        local.value.code = local.value.code
          .toUpperCase()
          .replace(/\s/g, '-')
          .replace(/[^A-Za-z0-9_-]*$/g, '')
      }
    )

    const getCustomers = () => {
      local.value.status.customer.isLoading = true
      api.call('GET', `conversation_user_pairs/${route.params.id}/customer`).then(response => {
        const attrs = response.data.data.attributes

        data.value.accountCredit = attrs?.account_credit?.format ?? null
        data.value.lifetimeSpend = attrs?.lifetime_spend?.format ?? null
        data.value.phoneRaw = attrs?.phone_number?.raw ?? null
        data.value.phoneFormatted = attrs?.phone_number?.formatted ?? null
        data.value.email = attrs?.email ?? null
        data.value.profileLink = attrs?.profile_link ?? null

        local.value.status.customer.isLoading = false
      })
    }

    const getConversationUserPairs = () => {
      api
        .call(
          'GET',
          `conversation_user_pairs/${route.params.id}?included=referral_discount_codes,current_cart_subscription,current_cart_subscription.possible_packages`
        )
        .then(response => {
          const attrs = response.data.data.attributes

          data.value.code = mapRelationship(response.data.data.relationships.referral_discount_codes.data?.[0])
          local.value.effective = serverDayToDateObject(cartSubscription.value?.attributes.effective_starting_date)
          local.value.code = data.value.code?.attributes.code

          data.value.memberSince = attrs.created_at
            ? new Intl.DateTimeFormat('en-US', {
                dateStyle: 'medium'
              }).format(Date.parse(attrs.created_at))
            : null
        })
    }

    const getOrders = () => {
      local.value.status.orders.isLoading = true
      const includes = [
        'checkout',
        'cart',
        'cart.cart_items',
        'cart.cart_items.product',
        'store',
        'conversation_user_pair',
        'fulfillment_slot',
        'membership_tier',
        'distribution_list',
        'bottle_cycle',
        'bottle_payment'
      ]

      return api
        .call(
          'GET',
          `conversation_user_pairs/${route.params.id}/orders?included=${includes.join(
            ','
          )}&sort_by_field=bottle_payments.created_at&sort_direction=desc`
        )
        .then(r => {
          data.value.sortedOrders = r.data.data
          local.value.status.orders.isLoading = false
          if (Object.keys(r.data.data).length) local.value.status.orders.isEmpty = false
        })
        .catch(() => (local.value.status.orders.hasError = true))
    }

    const getCarts = () => {
      local.value.status.carts.isLoading = true
      const includes = [
        'checkout',
        'store',
        'conversation_user_pair',
        'fulfillment_slot',
        'membership_tier',
        'distribution_list',
        'bottle_cycle',
        'bottle_payment'
      ]

      return api
        .call(
          'GET',
          `conversation_user_pairs/${route.params.id}/carts?included=${includes.join(
            ','
          )}&sort_by_field=bottle_payments.created_at&sort_direction=desc`
        )
        .then(r => {
          data.value.sortedCarts = r.data.data
          local.value.status.carts.isLoading = false
          if (Object.keys(r.data.data).length) local.value.status.carts.isEmpty = false
        })
        .catch(() => (local.value.status.carts.hasError = true))
    }

    const saveCreditModal = () => {
      const accountCreditAmountCents = local.value.modal.content.accountCreditAmount * 100
      api
        .call(
          'POST',
          `conversation_user_pairs/${route.params.id}/account_credits`,
          {
            account_credit: {
              value: accountCreditAmountCents
            }
          },
          false
        )
        .then(response => {
          const attrs = response.data.data.attributes
          data.value.accountCredit = attrs?.account_credit?.format ?? null

          resetModalContent()
        })
        .catch(error => {
          local.value.modal.content.onSubmitMessage = 'Something went wrong!'
          local.value.modal.content.onSubmitLog = error
        })
    }

    const resetModalContent = () => {
      local.value.modal.type = ''
      local.value.modal.content = {}
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    const conversationUserPair = computed(() =>
      mapRelationship({ type: 'conversation_user_pair', id: route.params.id })
    )

    const membershipTierSelection = computed(() => {
      return conversationUserPair.value?.relationships?.membership_tier_selection?.data
        ? mapRelationship(conversationUserPair.value?.relationships?.membership_tier_selection?.data)
        : null
    })

    const membershipTier = computed(() => {
      return conversationUserPair.value?.relationships?.membership_tier?.data
        ? mapRelationship(conversationUserPair.value?.relationships?.membership_tier?.data)
        : null
    })

    const intervalOptions = computed(() =>
      mapRelationships(membershipTier.value?.relationships.cart_subscription_settings.data).filter(
        x => x.attributes.setting_name === 'interval'
      )
    )

    const packages = computed(() => mapRelationships(cartSubscription.value?.relationships.possible_packages.data))

    const membershipTiers = computed(() => mapType('membership_tier'))

    const priceLists = computed(() => mapType('price_list'))

    const mediumFormat = new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium'
    }).format

    const cartSubscription = computed(() =>
      mapRelationship(conversationUserPair.value?.relationships.current_cart_subscription.data)
    )

    const stores = computed(() =>
      mapRelationships(membershipTier.value?.relationships.cart_subscription_store_options.data)
    )

    const getMembershipTiers = () => {
      api.call('GET', 'membership_tiers?included=cart_subscription_settings,cart_subscription_store_options')
    }

    const setTier = id => {
      api
        .call(
          'POST',
          `conversation_user_pairs/${route.params.id}/membership_tier_selections?included=current_cart_subscription,membership_tier_selection`,
          {
            membership_tier_selection: { membership_tier_id: id }
          }
        )
        .then(() => {
          local.value.effective = serverDayToDateObject(cartSubscription.value?.attributes.effective_starting_date)
        })
    }

    const getPriceLists = () => {
      api.call('GET', 'price_lists?included=none')
    }

    const setPriceList = id => {
      api.call('PUT', `conversation_user_pairs/${route.params.id}?included=none`, {
        conversation_user_pair: { price_list_id: id }
      })
    }

    const setInterval = id => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: { interval_cart_subscription_setting_id: id }
      })
    }

    const setStore = id => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: { store_id: id }
      })
    }

    const setPackage = id => {
      api.call('POST', `conversation_user_pairs/${route.params.id}/cart_subscriptions`, {
        cart_subscription: { package_id: id }
      })
    }

    onMounted(() => {
      getConversationUserPairs()
      getCustomers()
      getCarts()
      getOrders()
      getMembershipTiers()
      getPriceLists()
    })

    return {
      data,
      local,
      saveCreditModal,
      resetModalContent,
      fieldIsRequired,
      mediumFormat,
      membershipTierSelection,
      membershipTier,
      route,
      name,
      saveName,
      saveCode,
      saveEffective,
      setTier,
      membershipTiers,
      cartSubscription,
      serverDayToDateObject,
      intervalOptions,
      setInterval,
      setStore,
      newOrderModalOpen,
      packages,
      setPackage,
      conversationUserPair,
      priceLists,
      setPriceList,
      stores,
      router
    }
  }
})
