import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "w-4 h-4 text-brand-800" }

import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import RemoveStarIcon from '@/icon/RemoveStarIcon'
import StarIcon from '@/icon/StarIcon'
import api from '@/store/api'


export default {
  __name: 'SidebarConversation',
  props: {
  conversationUserPair: {
    type: Object,
    required: true
  }
},
  setup(__props) {

const props = __props
const route = useRoute()

const hovering = ref(false)
const hoverable = computed(() => window.matchMedia('(hover: hover)').matches)

const starred = computed(() => props.conversationUserPair.attributes['favorited?'])
const unread = computed(() => props.conversationUserPair.attributes['has_unread?'])
const selected = computed(() => route.params.id === props.conversationUserPair.id)

const toggleStarred = () => {
  api.call(
    'POST',
    `conversation_user_pairs/${props.conversationUserPair.id}/${starred.value ? 'unfavorited' : 'favorited'}`
  )
}

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", {
    onMouseenter: _cache[0] || (_cache[0] = $event => (hovering.value = hoverable.value)),
    onMouseleave: _cache[1] || (_cache[1] = $event => (hovering.value = false))
  }, [
    _createVNode(_component_router_link, {
      to: '/messages/' + __props.conversationUserPair.id,
      class: _normalizeClass(["w-full transition-all duration-300 group flex items-center rounded-md px-3 py-1 text-sm tracking-tight text-gray-500 hover:bg-gray-50 hover:text-gray-900 truncate justify-between", {
        'bg-white': selected.value,
        'font-medium': !unread.value,
        'font-bold': unread.value
      }])
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(__props.conversationUserPair.attributes.display_name), 1),
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            "aria-label": "Star Conversation",
            class: "mr-1",
            onClick: _withModifiers(toggleStarred, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_2, [
              (starred.value)
                ? (_openBlock(), _createBlock(_unref(RemoveStarIcon), { key: 0 }))
                : (_openBlock(), _createBlock(_unref(StarIcon), { key: 1 }))
            ])
          ])
        ], 512), [
          [_vShow, hovering.value || selected.value || starred.value]
        ])
      ]),
      _: 1
    }, 8, ["to", "class"])
  ], 32))
}
}

}