<template>
  <div class="bg-gray-50 overflow-hidden shadow rounded-lg shadow rounded-md mb-4">
    <div class="px-4 py-5 sm:px-6">
      <slot name="title" />
    </div>
    <div class="bg-white px-4 py-5 sm:p-6">
      <slot name="main" />
    </div>
  </div>
</template>
