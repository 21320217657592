import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" }
const _hoisted_2 = { class: "flex justify-between max-w-xl" }
const _hoisted_3 = { class: "space-y-2 w-full" }
const _hoisted_4 = { class: "text-xl font-semibold leading-6 text-gray-900 tracking-tight" }
const _hoisted_5 = {
  key: 0,
  class: "border-gray-200",
  "aria-hidden": "true"
}
const _hoisted_6 = { class: "mt-1 text-sm text-gray-500" }

import BasicSpinner from '@/components/BasicSpinner'


export default {
  __name: 'FormSection',
  props: ['name', 'description', 'loading', 'fullwidth', 'padding'],
  setup(__props) {

const props = __props

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(["space-y-8 divide-y divide-gray-200 relative", props.padding === false ? '' : 'mb-8']),
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute h-full w-full backdrop-blur-sm scale-105 z-10 transition-opacity duration-100", props.loading ? 'opacity-100 cursor-wait' : 'opacity-0 pointer-events-none'])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(BasicSpinner), { size: "medium" })
        ])
      ], 2),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(props.name), 1),
          (props.name)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_6, _toDisplayString(props.description), 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$slots.aside && 'ml-3')
        }, [
          _renderSlot(_ctx.$slots, "aside")
        ], 2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6", {
          'max-w-xl': !props.fullwidth,
          'mt-3': props.name !== props.description,
          'mt-6': props.name && props.description
        }])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ])
  ], 34))
}
}

}