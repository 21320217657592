import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'
import { mapRelationship } from '@/store/mappers'

let getters = {
  audience: state => audienceId => {
    return state.audiences[audienceId]
  },

  loggedInUserId: () => {
    try {
      return jwtDecode(Cookies.get('auth_token')).user_id
    } catch {
      Cookies.remove('auth_token')
      if (window.location.pathname !== '/login') {
        window.location = '/login?to=' + window.location.href
      }
    }
    return undefined
  },

  country: (state, getters) => {
    const user = mapRelationship({ type: 'user', id: getters.loggedInUserId.toString() })
    return user?.attributes.country ?? 'USA' // TODO(alicja): remove once https://bottlelabs.slack.com/archives/C01GFDWLM53/p1711117045456869 is fixed
  },

  message: state => messageId => {
    return state.messages[messageId]
  },

  attachment: state => attachmentId => {
    return state.attachments[attachmentId]
  },

  user: state => userId => {
    return state.users[userId]
  },

  call: state => callId => {
    return state.calls[callId]
  },

  activity: state => activityId => {
    return state.activities[activityId]
  },

  conversationUserPair: state => conversationUserPairId => {
    return state.conversationUserPairs[conversationUserPairId]
  }
}

export default getters
