
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: 'This field is required'
    }
  }
})
