import { computed } from 'vue'
import _ from 'lodash'
import { mapRelationship, mapRelationships, mapType } from '@/store/mappers'
import { dateObjectToServerDay, serverDayToDateObject } from '@/helpers/dates'

const dateOperators = {
  '==': 'on',
  '>': 'after',
  '>=': 'on or after',
  '<': 'before',
  '<=': 'on or before'
}

const countOperators = {
  '==': 'equals',
  '!=': 'does not equal',
  '>': 'is greater than',
  '>=': 'is greater than or equal to',
  '<': 'is less than',
  '<=': 'is less than or equal to'
}

const mapNewOptions = options =>
  options.map(x => ({
    name: filterOptions[x]?.name,
    value: x
  }))

const shortDateFormat = new Intl.DateTimeFormat([], { dateStyle: 'short' }).format
const shortDatetimeFormat = new Intl.DateTimeFormat([], { dateStyle: 'short', timeStyle: 'short' }).format
const prefixDateFormat = (local, prefix) => {
  if (!local.operator || !local.field) return
  return [prefix + ' ', dateOperators[local.operator] + ' ' + shortDateFormat(local.field)]
}
const prefixDatetimeFormat = (local, prefix) => {
  if (!local.operator || !local.field || isNaN(local.field)) return
  return [prefix + ' ', dateOperators[local.operator] + ' ' + shortDatetimeFormat(local.field)]
}
const prefixDaysAgoFormat = (local, prefix) => {
  if (!local.operator || !local.field) return
  return [prefix + ' ', `${dateOperators[local.operator]} ${local.field} day${local.field == 1 ? '' : 's'} ago`]
}
const prefixDaysFutureFormat = (local, prefix) => {
  if (!local.operator || !local.field) return
  return [prefix + ' ', `${dateOperators[local.operator]} ${local.field} day${local.field == 1 ? '' : 's'} from now`]
}

export const filterOptions = {
  HasTagFilter: {
    name: 'Contact has this tag',
    shape: 'string',
    activeName: local => [local.reversed ? 'Not tagged ' : 'Tagged ', local.field],
    reverseOptions: { true: "doesn't have tag", false: 'has tag' }
  },

  ContactCreatedDaysAgoFilter: {
    name: 'Contact created with relative date',
    shape: 'operator-days-ago',
    activeName: local => prefixDaysAgoFormat(local, 'Contact created')
  },
  CartEditedDaysAgoFilter: {
    name: 'Customer last edited the cart',
    shape: 'operator-days-ago',
    activeName: local => prefixDaysAgoFormat(local, 'Customer last edited the cart')
  },
  CustomerFirstOrderedFilter: {
    name: 'Customer first ordered',
    shape: 'operator-days-ago',
    activeName: local => prefixDaysAgoFormat(local, 'Customer first ordered')
  },
  CustomerLastOrderedFilter: {
    name: 'Customer last ordered',
    shape: 'operator-days-ago',
    activeName: local => prefixDaysAgoFormat(local, 'Customer last ordered')
  },
  CustomerMessagedInFilter: {
    name: 'Customer last messaged in',
    shape: 'operator-days-ago',
    activeName: local => prefixDaysAgoFormat(local, 'Customer last messaged in')
  },
  ChurnedFilter: {
    name: 'Customer churned',
    shape: 'operator-days-ago',
    activeName: local => prefixDaysAgoFormat(local, 'Customer churned')
  },
  BottleFulfillmentDayInXDaysFilter: {
    name: 'Cart production days from now',
    shape: 'operator-days-future',
    activeName: local => prefixDaysFutureFormat(local, 'Cart production')
  },
  BottleArrivalDayInXDaysFilter: {
    name: 'Cart arrival days from now',
    shape: 'operator-days-future',
    activeName: local => prefixDaysFutureFormat(local, 'Cart arrival')
  },

  OrderCountLifetimeFilter: {
    name: 'Has ordered this many times',
    shape: 'operator-number',
    activeName: local => {
      if (!local.operator || !local.field) return
      return ['Order count ', countOperators[local.operator] + ' ' + local.field]
    }
  },

  BottleFulfillmentSlotDateFilter: {
    name: 'Order production date',
    shape: 'operator-date',
    activeName: local => prefixDateFormat(local, 'Order production date')
  },
  BottleFulfillmentSlotArrivalFilter: {
    name: 'Order fulfillment arrival',
    shape: 'operator-date',
    activeName: local => prefixDateFormat(local, 'Order fulfillment arrival')
  },
  ContactCreatedDateFilter: {
    name: 'Contact created with specific date',
    shape: 'operator-date',
    activeName: local => prefixDateFormat(local, 'Contact created')
  },

  BottlePaymentCreatedAtFilter: {
    name: 'Paid order transaction time',
    shape: 'operator-date-time',
    activeName: local => prefixDatetimeFormat(local, 'Order paid')
  },

  CustomerSubscriptionDateFilter: {
    name: 'Customer with subscription renewal date',
    shape: 'date',
    activeName: local => ['Subscription renewal date: ', shortDateFormat(local.field)]
  },

  MembershipTierFilter: {
    name: 'Membership tier',
    shape: 'search',
    search: { endpoint: 'membership_tiers', plural: 'membership tiers', name: x => x.attributes.tier_name },
    activeName: local => [
      'Membership tier: ',
      mapRelationship({ id: local.selection, type: 'membership_tier' }, true)?.attributes.tier_name
    ]
  },
  DistributionListFilter: {
    name: 'Distribution list',
    shape: 'search',
    search: { endpoint: 'distribution_lists', plural: 'distribution lists', name: x => x.attributes.name },
    activeName: local => [
      'Distribution list: ',
      mapRelationship({ id: local.selection, type: 'distribution_list' }, true)?.attributes.name
    ]
  },
  MailingListFilter: {
    name: 'Mailing list',
    shape: 'search',
    search: { endpoint: 'mailing_lists', plural: 'mailing lists', name: x => x.attributes.name },
    activeName: local => [
      'Mailing list: ',
      mapRelationship({ id: local.selection, type: 'mailing_list' }, true)?.attributes.name
    ]
  },
  BottleStoreFilter: {
    name: 'Store',
    shape: 'search',
    search: { endpoint: 'stores', plural: 'stores', name: x => x.attributes.store_name },
    activeName: local => [
      'Store: ',
      mapRelationship({ id: local.selection, type: 'store' }, true)?.attributes.store_name
    ]
  },
  BottleFulfillmentSlotMethodFilter: {
    name: 'Fulfillment method',
    shape: 'search',
    search: { endpoint: 'fulfillment_methods', plural: 'fulfillment methods', name: x => x.attributes.name },
    activeName: local => [
      'Fulfillment method: ',
      mapRelationship({ id: local.selection, type: 'fulfillment_method' }, true)?.attributes.name
    ]
  },
  BottleWithinSpanOfBottleCycleFilter: {
    name: 'Carts with production dates matching a drop',
    shape: 'search',
    search: { endpoint: 'bottle_cycles', plural: 'drops', name: x => x.attributes.description },
    activeName: local => [
      'Production dates matching: ',
      mapRelationship({ id: local.selection, type: 'bottle_cycle' }, true)?.attributes.description
    ]
  },
  BelongsToBottleCycleFilter: {
    name: 'Carts for a specific drop',
    shape: 'search',
    search: { endpoint: 'bottle_cycles', plural: 'drops', name: x => x.attributes.description },
    activeName: local => [
      'Drop: ',
      mapRelationship({ id: local.selection, type: 'bottle_cycle' }, true)?.attributes.description
    ]
  },
  BottleCartItemFilter: {
    name: 'Has order with specific product',
    shape: 'search',
    search: { endpoint: 'products', plural: 'products', name: x => x.attributes.product_name },
    activeName: local => [
      'Cart contains ',
      mapRelationship({ id: local.selection, type: 'product' }, true)?.attributes.product_name
    ]
  },

  BottleRefundStatusFilter: {
    name: 'Orders with a refund status',
    shape: 'select',
    selectOptions: () => [
      { name: 'Not fully refunded', id: 'not_fully_refunded' },
      { name: 'Partially refunded', id: 'partially_refunded' },
      { name: 'Fully refunded', id: 'fully_refunded' }
    ],
    activeName: local => [
      'Refund status: ',
      {
        not_fully_refunded: 'Not fully refunded',
        partially_refunded: 'Partially refunded',
        fully_refunded: 'Fully refunded'
      }[local.selection]
    ]
  },
  BottleCancellationStatusFilter: {
    name: 'Orders with a cancellation status',
    shape: 'select',
    selectOptions: () => [
      { name: 'Not cancelled', id: 'active' },
      { name: 'Cancelled', id: 'cancelled' }
    ],
    activeName: local => [
      'Cancellation status: ',
      {
        active: 'Not cancelled',
        cancelled: 'Cancelled'
      }[local.selection]
    ]
  },
  BottleFulfillmentSlotMethodTypeFilter: {
    name: 'Fulfillment method type',
    shape: 'select',
    selectOptions: () => [
      { name: 'Shipping', id: 'ShippingFulfillmentMethod' },
      { name: 'Delivery', id: 'DeliveryFulfillmentMethod' },
      { name: 'Pickup', id: 'PickupFulfillmentMethod' }
    ],
    activeName: local => [
      'Fulfillment type: ',
      {
        ShippingFulfillmentMethod: 'Shipping',
        DeliveryFulfillmentMethod: 'Delivery',
        PickupFulfillmentMethod: 'Pickup'
      }[local.selection]
    ]
  },

  BottlePaidForFilter: {
    name: 'Paid orders',
    shape: 'none'
  },
  BottleAbandonedCartsFilter: {
    name: 'Customer has an abandoned cart',
    shape: 'none'
  },
  AnyMembershipTierFilter: {
    name: 'Customer belongs to any membership',
    shape: 'none'
  },
  NoMembershipTierFilter: {
    name: 'Customer does not belong to any membership',
    shape: 'none'
  },

  OrderCountFilter: {
    name: 'Has ordered this many times within a specific range',
    shape: 'special-order-count',
    special: {
      operatorCount: undefined,
      fieldCount: undefined,
      operatorDays: undefined,
      fieldDays: undefined
    },
    activeName: local => {
      if (
        !local.special.operatorCount ||
        !local.special.fieldCount ||
        !local.special.operatorDays ||
        !local.special.fieldDays
      ) {
        return
      }

      return [
        'Order count ',
        countOperators[local.special.operatorCount] +
          ' ' +
          local.special.fieldCount +
          ' ' +
          dateOperators[local.special.operatorDays] +
          ' ' +
          local.special.fieldDays +
          ' days ago'
      ]
    }
  },
  ChangedMembershipTierFilter: {
    name: 'Customer changed membership tier',
    shape: 'special-changed-tier',
    selectOptions: () => mapType('membership_tier')?.map(x => ({ id: x.id, name: x.attributes.tier_name })),
    special: {
      from: undefined,
      to: undefined
    },
    activeName: local => {
      if (!local.special.from || !local.special.to || !local.operator || !local.field) {
        return
      }

      return [
        'Customer changed membership tier ',
        'from ' +
          mapRelationship({ id: local.special.from, type: 'membership_tier' })?.attributes.tier_name +
          ' to ' +
          mapRelationship({ id: local.special.to, type: 'membership_tier' })?.attributes.tier_name +
          ' ' +
          dateOperators[local.operator] +
          ' ' +
          local.field +
          ' days ago'
      ]
    }
  }
}

export const newFilterOptions = computed(() => ({
  cart: mapNewOptions([
    'BottleStoreFilter',
    'CartEditedDaysAgoFilter',
    'BottleAbandonedCartsFilter',
    'MembershipTierFilter',
    'BottlePaidForFilter',
    'DistributionListFilter',
    'MailingListFilter',
    'BottleCartItemFilter',
    'BottleRefundStatusFilter',
    'BottleCancellationStatusFilter',
    'AnyMembershipTierFilter',
    'BottlePaymentCreatedAtFilter'
  ]),
  fulfillment: mapNewOptions([
    'BottleFulfillmentSlotDateFilter',
    'BottleFulfillmentSlotArrivalFilter',
    'BottleWithinSpanOfBottleCycleFilter',
    'BelongsToBottleCycleFilter',
    'BottleFulfillmentSlotMethodFilter',
    'BottleFulfillmentSlotMethodTypeFilter',
    'BottleFulfillmentDayInXDaysFilter',
    'BottleArrivalDayInXDaysFilter'
  ]),
  contact: mapNewOptions([
    'CustomerFirstOrderedFilter',
    'CustomerLastOrderedFilter',
    'CustomerMessagedInFilter',
    'MembershipTierFilter',
    'DistributionListFilter',
    'MailingListFilter',
    'HasTagFilter',
    'AnyMembershipTierFilter',
    'NoMembershipTierFilter',
    'ContactCreatedDateFilter',
    'ContactCreatedDaysAgoFilter',
    'OrderCountFilter',
    'OrderCountLifetimeFilter',
    'CustomerSubscriptionDateFilter',
    'ChurnedFilter',
    'ChangedMembershipTierFilter'
  ])
}))

export const localFilterToServer = local => {
  if (local.type === 'raw') {
    return {
      ..._.omit(local.raw.attributes, 'id'),
      filter_field_values_attributes: mapRelationships(local.raw.relationships.filter_field_values.data).map(x =>
        _.omit(x.attributes, 'id')
      )
    }
  }

  return {
    type: local.type,
    true_on_reverse: local.reversed,
    filter_field_values_attributes: {
      string: () => [{ type: 'ValueFilterFieldValue', value: local.field }],
      select: () => [{ type: 'ValueFilterFieldValue', value: local.selection }],
      search: () => [{ type: 'ValueFilterFieldValue', value: local.selection }],
      date: () => [{ type: 'DateFilterFieldValue', value: local.field ? dateObjectToServerDay(local.field) : null }],
      'operator-days-ago': () => [
        { type: 'DatetimeOperatorFilterFieldValue', value: local.operator },
        { type: 'DaysAgoFilterFieldValue', value: local.field }
      ],
      'operator-days-future': () => [
        { type: 'DatetimeOperatorFilterFieldValue', value: local.operator },
        { type: 'DaysInFutureFilterFieldValue', value: local.field }
      ],
      'operator-number': () => [
        { type: 'OperatorFilterFieldValue', value: local.operator },
        { type: 'CountFilterFieldValue', value: local.field }
      ],
      'operator-date': () => [
        { type: 'DatetimeOperatorFilterFieldValue', value: local.operator },
        { type: 'DateFilterFieldValue', value: local.field ? dateObjectToServerDay(local.field) : null }
      ],
      'operator-date-time': () => [
        { type: 'DatetimeOperatorFilterFieldValue', value: local.operator },
        { type: 'DateTimeFilterFieldValue', value: local.field }
      ],
      'special-order-count': () => [
        { type: 'OperatorFilterFieldValue', value: local.special?.operatorCount },
        { type: 'CountFilterFieldValue', value: local.special?.fieldCount },
        { type: 'DatetimeOperatorFilterFieldValue', value: local.special?.operatorDays },
        { type: 'DaysAgoFilterFieldValue', value: local.special?.fieldDays }
      ],
      'special-changed-tier': () => [
        { type: 'ValueFilterFieldValue', value: local.special?.from },
        { type: 'ValueFilterFieldValue', value: local.special?.to },
        { type: 'DatetimeOperatorFilterFieldValue', value: local.operator },
        { type: 'DaysAgoFilterFieldValue', value: local.field }
      ],
      none: () => null
    }[filterOptions[local.type].shape]()
  }
}

export const localFilterToProps = local => {
  if (local.type === 'raw') return { raw: local.raw }
  return {
    ...filterOptions[local.type],
    activeName: filterOptions[local.type].activeName?.(local),
    selectOptions: filterOptions[local.type].selectOptions?.(),
    currentSelectOption: local.selection,
    currentOperatorOption: local.operator,
    currentField: local.field,
    special: local.special,
    reversed: local.reversed
  }
}

export const serverFilterToLocal = server => {
  // unknown filter
  if (!filterOptions[server.attributes.type]) return { type: 'raw', raw: server }

  const fieldValues = Object.fromEntries(
    mapRelationships(server.relationships.filter_field_values.data).map(x => [x.attributes.type, x.attributes.value])
  )
  const multipleFieldValues = _.mapValues(
    _.groupBy(mapRelationships(server.relationships.filter_field_values.data), 'attributes.type'),
    x => x.map(y => y.attributes.value)
  )

  const local = {
    id: server.id,
    type: server.attributes.type,
    reversed: server.attributes.true_on_reverse,
    ...{
      string: () => ({
        field: fieldValues.ValueFilterFieldValue
      }),
      select: () => ({
        selection: fieldValues.ValueFilterFieldValue
      }),
      search: () => ({
        selection: fieldValues.ValueFilterFieldValue
      }),
      date: () => ({
        field: serverDayToDateObject(fieldValues.DateFilterFieldValue)
      }),
      'operator-days-ago': () => ({
        operator: fieldValues.DatetimeOperatorFilterFieldValue,
        field: fieldValues.DaysAgoFilterFieldValue
      }),
      'operator-days-future': () => ({
        operator: fieldValues.DatetimeOperatorFilterFieldValue,
        field: fieldValues.DaysInFutureFilterFieldValue
      }),
      'operator-number': () => ({
        operator: fieldValues.OperatorFilterFieldValue,
        field: fieldValues.CountFilterFieldValue
      }),
      'operator-date': () => ({
        operator: fieldValues.DatetimeOperatorFilterFieldValue,
        field: serverDayToDateObject(fieldValues.DateFilterFieldValue)
      }),
      'operator-date-time': () => ({
        operator: fieldValues.DatetimeOperatorFilterFieldValue,
        field: new Date(fieldValues.DateTimeFilterFieldValue)
      }),
      'special-order-count': () => ({
        special: {
          operatorCount: fieldValues.OperatorFilterFieldValue,
          fieldCount: fieldValues.CountFilterFieldValue,
          operatorDays: fieldValues.DatetimeOperatorFilterFieldValue,
          fieldDays: fieldValues.DaysAgoFilterFieldValue
        }
      }),
      'special-changed-tier': () => ({
        operator: fieldValues.DatetimeOperatorFilterFieldValue,
        field: fieldValues.DaysAgoFilterFieldValue,
        special: {
          from: multipleFieldValues.ValueFilterFieldValue?.[0],
          to: multipleFieldValues.ValueFilterFieldValue?.[1]
        }
      }),
      none: () => null
    }[filterOptions[server.attributes.type].shape]()
  }
  if (local.operator === '=') local.operator = '=='
  return local
}
