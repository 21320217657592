
import { Menu, MenuItems } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import Fuse from 'fuse.js'
import { defineComponent, nextTick, onMounted, ref } from 'vue'

import api from '@/store/api'

export default defineComponent({
  components: {
    XMarkIcon,
    Menu,
    MenuItems
  },
  props: {
    helperText: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      required: false,
      default: 'absolute'
    },
    searchEndpoint: {
      type: String,
      required: false,
      default: ''
    },
    searchRequest: {
      type: Object,
      required: false
    },
    localSearchOptions: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    fuseOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          includeScore: true,
          keys: ['name']
        }
      }
    }
  },
  emits: ['on-search', 'on-results', 'on-selected'],
  setup(props, context) {
    const data = ref({
      searchResults: []
    })

    const local = ref({
      query: '',
      isSearching: false,
      spinnerTimeout: null,
      isOpen: false,
      controller: new AbortController()
    })

    const queryInput = ref(null)

    const handleRemoteSearch = () => {
      nextTick(() => {
        local.value.controller.abort()
        local.value.controller = new AbortController()
        if (local.value.query) {
          api
            .call('POST', props.searchEndpoint, props.searchRequest, {
              save: false,
              abortController: local.value.controller
            })
            .then(r => {
              // [discuss] this does come back in the store under w/e types referenced by the endpoint, but add'l logic to map those types in the parent feels unecessary. Also, any references to those types from the template get logged in state, so that could surface an edgecase where the results are muddied.
              context.emit('on-results', r.data.data)
              data.value.searchResults = r.data.data
              clearTimeout(local.value.spinnerTimeout)
              local.value.isSearching = false
            })
            .catch(e => {
              if (e.name !== 'AbortError') throw e
            })
        }
      })
    }

    const handleLocalSearch = () => {
      const fuse = new Fuse(props.localSearchOptions, props.fuseOptions)
      data.value.searchResults = fuse.search(local.value.query)
      clearTimeout(local.value.spinnerTimeout)
      local.value.isSearching = false
      context.emit('on-results', data.value.searchResults)
    }

    const onSearch = () => {
      if (local.value.query.length) {
        clearTimeout(local.value.spinnerTimeout)
        local.value.spinnerTimeout = setTimeout(() => {
          local.value.isSearching = true
        }, 200)
        local.value.isOpen = true
      } else {
        local.value.isSearching = false
        local.value.isOpen = false
      }

      context.emit('on-search', local.value.query)

      if (props.localSearchOptions.length) handleLocalSearch()
      else if (props.searchEndpoint.length) handleRemoteSearch()
      else throw new Error('Please provide a searchEndpoint or localSearchOptions')
    }

    const onResetSearch = () => {
      local.value.isOpen = false
      local.value.query = ''
    }

    const onSelected = () => {
      onResetSearch()
    }

    const focusSearchBox = () => {
      nextTick(() => {
        queryInput.value ? queryInput.value.focus() : null
      })
    }

    onMounted(() => {
      focusSearchBox()
    })

    return {
      data,
      local,
      queryInput,
      onSearch,
      onResetSearch,
      onSelected
    }
  }
})
