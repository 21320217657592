import script from "./AutomationNeue.vue?vue&type=script&setup=true&lang=js"
export * from "./AutomationNeue.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import AutomationsSidebar from "@/components/neue/AutomationsSidebar.vue";
import FulfillmentSlotCalendar from "@/components/neue/FulfillmentSlotCalendar.vue";
import ImageUploaderNeue from "@/components/neue/ImageUploaderNeue.vue";
import InlineSearchSelect from "@/components/InlineSearchSelect.vue";
import InputArea from "@/components/neue/InputArea.vue";
import InputField from "@/components/neue/InputField.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueLoading from "@/components/neue/NeueLoading.vue";
import NeueToggle from "@/components/neue/NeueToggle.vue";
import PageFooter from "@/components/PageFooter.vue";
import SaveBar from "@/components/SaveBar.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import SelectInput from "@/components/neue/SelectInput.vue";
script.components = Object.assign({}, { AutomationsSidebar, FulfillmentSlotCalendar, ImageUploaderNeue, InlineSearchSelect, InputArea, InputField, NeueButton, NeueLoading, NeueToggle, PageFooter, SaveBar, SecondaryNav, SecondaryTop, SelectInput }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { AutomationsSidebar, FulfillmentSlotCalendar, ImageUploaderNeue, InlineSearchSelect, InputArea, InputField, NeueButton, NeueLoading, NeueToggle, PageFooter, SaveBar, SecondaryNav, SecondaryTop, SelectInput }, script.__o.components);}