import store, { objectRefreshed } from '../store'
import api from '@/store/api'
import _ from 'lodash'

const fetching = new Set()

const loadObjects = (objs, options) => {
  objs = objs.filter(
    x =>
      (objectRefreshed?.[x.type]?.[x.id] ?? -Infinity) < (+options.after ?? Infinity) &&
      !fetching.has(x.type + ':' + x.id)
  )
  if (!objs.length) return
  const zygote = new URLSearchParams()
  zygote.append('included', options.includes ? options.includes.join(',') : 'none')
  zygote.append('type', objs[0].type)
  for (const [name, id] of Object.entries(options.context ?? [])) zygote.append(`context[${name}_id]`, id)
  return Promise.all(
    _.chunk(objs, 15).map(async chunk => {
      const params = new URLSearchParams(zygote)
      for (const obj of chunk) params.append('ids[]', obj.id)
      fetching.add(...chunk.map(x => x.type + ':' + x.id))
      await api.call('GET', 'relationships?' + params)
      fetching.delete(...chunk.map(x => x.type + ':' + x.id))
    })
  )
}

export function mapRelationship(rel, loadOptions = undefined) {
  if (!rel || !rel.type || !rel.id) return null
  if (loadOptions) loadObjects([rel], loadOptions)
  return store.state.objects?.[rel.type]?.[rel.id]
}

export function mapRelationships(rels, loadOptions = undefined) {
  if (!Array.isArray(rels)) return []
  if (loadOptions) loadObjects(rels, loadOptions)
  return rels
    .map(x => store.state.objects?.[x.type]?.[x.id])
    .filter(Boolean)
    .filter(x => x.attributes?.is_active !== false)
}

export function mapType(type) {
  const all = store.state.objects?.[type]
  if (!all) return []
  return Object.values(all).filter(x => x.attributes?.is_active !== false)
}

export function firstStoreObjectOfType(type) {
  if (!type || !store.state.objects[type]) {
    return null
  }
  return Object.values(store.state.objects[type])[0]
}
