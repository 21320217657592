<template>
  <div class="flex flex-col justify-between bg-white rounded-lg shadow overflow-hidden">
    <div class="pb-3 pt-5 px-5">
      <div class="flex items-center">
        <div v-if="icon" class="flex-0-0 self-start">
          <component :is="icon" class="w-6 h-6 text-cool-gray-400" />
        </div>
        <div :class="[{ 'ml-5': icon }, 'flex-0 w-full']">
          <slot name="list"></slot>
        </div>
      </div>
    </div>
    <div v-if="hasFooter" class="px-5 py-3 bg-cool-gray-50">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import { CreditCardIcon, IdentificationIcon, ScaleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ScaleIcon,
    IdentificationIcon,
    CreditCardIcon
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer
    }
  }
}
</script>
<style lang="postcss">
.info-card__footer-link {
  @apply text-gray-700;
  @apply border-b;
  @apply font-medium;
  @apply border-transparent;
  @apply transition-colors;
  @apply text-sm;
  &:hover {
    @apply border-blue-500;
    @apply text-blue-500;
  }
}
.info-card__title {
  @apply text-sm font-medium text-gray-500;
}
.info-card__title:not(:first-child) {
  @apply mt-5;
}
.info-card__text {
  @apply mt-1 text-sm text-gray-700
  /* @apply leading-normal;
  @apply mt-2;
  @apply text-gray-900;
  @apply text-sm; */;
}
.info-card__helper-text {
  @apply leading-normal;
  @apply mt-2;
  @apply text-gray-500;
  @apply text-xs;
}
/* TODO(llb): implement link utility once that exists */
.info-card__text a,
a.info-card__text {
  @apply text-gray-500;
  @apply border-b;
  @apply border-transparent;
  @apply transition-colors;
  &:focus,
  &:hover {
    @apply text-gray-900;
    @apply border-gray-900;
    @apply outline-none;
  }
}
</style>
