<template>
  <nav
    :class="[
      { 'bg-white border-cool-gray-200': !hasTransparentBg },
      'mt-3 py-3 flex items-center justify-between border-t'
    ]"
  >
    <div v-if="!datePaginated" class="hidden sm:block">
      <p v-if="totalSize === 0" class="text-cool-gray-700 text-sm leading-5">
        Showing
        <span class="font-medium">0</span>
        results
      </p>
      <p v-if="totalSize === 1" class="text-cool-gray-700 text-sm leading-5">
        Showing
        <span class="font-medium">1</span>
        result
      </p>
      <p v-if="totalSize > 1" class="text-cool-gray-700 text-sm leading-5">
        Showing
        <span class="font-medium">{{ showingFrom() }}</span>
        to
        <span class="font-medium">
          {{ Math.min(page * pageSize + pageSize, totalSize) }}
        </span>
        of
        <span class="font-medium">{{ totalSize }}</span>
        results
      </p>
    </div>
    <div v-else class="hidden sm:block">
      <p class="text-cool-gray-700 text-sm leading-5">
        Showing from
        <span class="font-medium">{{ startDate }}</span>
        to
        <span class="font-medium">
          {{ endDate }}
        </span>
      </p>
    </div>
    <div class="flex flex-1 justify-between sm:justify-end">
      <button
        class="relative inline-flex items-center px-4 py-2 hover:text-cool-gray-500 text-cool-gray-700 active:text-cool-gray-700 text-sm font-medium leading-5 active:bg-cool-gray-100 bg-white border focus:border-blue-300 border-cool-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out"
        :class="{ 'opacity-50': !prevPageAvailable }"
        :disabled="!prevPageAvailable || isLoading"
        @click="prevPage()"
      >
        Previous
      </button>
      <button
        class="relative inline-flex items-center ml-3 px-4 py-2 hover:text-cool-gray-500 text-cool-gray-700 active:text-cool-gray-700 text-sm font-medium leading-5 active:bg-cool-gray-100 bg-white border focus:border-blue-300 border-cool-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out"
        :class="{ 'opacity-50': !nextPageAvailable }"
        :disabled="!nextPageAvailable || isLoading"
        @click="nextPage()"
      >
        Next
      </button>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    initialPage: {
      type: Number,
      required: false,
      default: 0
    },
    pageSize: {
      type: Number,
      required: false,
      default: 5
    },
    hasTransparentBg: {
      type: Boolean,
      required: false,
      default: true
    },
    totalRecords: {
      type: Number,
      required: false,
      default: 0
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    page: {
      type: Number,
      required: false,
      default: 0
    },
    datePaginated: {
      type: Boolean,
      required: false,
      default: false
    },
    startDate: {
      type: String,
      required: false,
      default: ''
    },
    endDate: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['onPageChange'],
  computed: {
    nextPageAvailable() {
      if (this.datePaginated) {
        return true
      } else {
        return (this.page + 1) * this.pageSize < this.totalSize
      }
    },
    prevPageAvailable() {
      if (this.datePaginated) {
        return true
      } else {
        return this.page !== 0
      }
    },
    totalSize() {
      return this.totalRecords ? this.totalRecords : this.items.length
    }
  },
  methods: {
    nextPage() {
      if (!this.nextPageAvailable) return
      this.$emit('onPageChange', this.page + 1)
    },
    prevPage() {
      if (!this.prevPageAvailable) return
      this.$emit('onPageChange', this.page - 1)
    },
    showingFrom() {
      return this.page * this.pageSize + 1
    },
    showingTo() {}
  }
}
</script>
