
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

import api from '@/store/api'

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      handle: ''
    })

    const formSubmitted = () => {
      api
        .call(
          'POST',
          'password_reset',
          {
            handle: data.handle
          },
          false
        )
        .then(response => {
          router.push('/login?reset-success')
        })
    }

    const loginClicked = () => {
      router.push('/login')
    }

    return {
      formSubmitted,
      data,
      loginClicked
    }
  }
})
