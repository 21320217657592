
import SecondaryTop from '@/components/SecondaryTop.vue'
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { SecondaryTop, SellSidebar },
  beforeRouteLeave() {
    if (this.hasUnsavedChanges) {
      this.leaveModal = true
      return false
    } else return true
  },
  data() {
    return {
      hasUnsavedChanges: false,
      leaveModal: false,
      name: '',
      type: ''
    }
  },
  mounted() {
    api.call('GET', `labels/${this.$route.params.id}`).then(response => {
      this.name = response.data.data.attributes.label_name
      this.type = response.data.data.attributes.label_type
    })
  },
  methods: {
    del() {
      api.call('DELETE', `labels/${this.$route.params.id}`).then(this.$router.back)
    },
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    handleSubmit() {
      api.call('PUT', `labels/${this.$route.params.id}`, {
        label: {
          label_name: this.name,
          label_type: this.type
        }
      })
      this.hasUnsavedChanges = false
    },
    async handleDiscardChanges() {
      // location.reload()
      api.call('GET', `labels/${this.$route.params.id}`).then(response => {
        this.name = response.data.data.attributes.label_name
        this.type = response.data.data.attributes.label_type
      })
      this.hasUnsavedChanges = false
    },
    handleConfirmCancelChanges() {
      this.hasUnsavedChanges = false
      this.$router.push('/store/labels')
    }
  }
}
