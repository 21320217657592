<template>
  <div class="flex items-baseline select-none">
    <p class="block text-gray-brand-800 text-sm font-semibold tracking-tighter leading-none">
      {{ data.display_name }}
    </p>
    <time class="block ml-1 text-gray-brand-700 font-manrope text-xxs">
      {{ timestamp }}
    </time>
  </div>
</template>
<script>
import { format } from 'timeago.js'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timestamp: ''
    }
  },
  mounted() {
    this.timestamp = format(this.data.created_at)

    // recalculate timeago every minute
    setInterval(() => {
      this.timestamp = format(this.data.created_at)
    }, 60000)
  }
}
</script>
