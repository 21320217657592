
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { SellSidebar },
  data() {
    return { label_name: '', label_type: '' }
  },
  methods: {
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    handleSubmit() {
      api
        .call('POST', 'labels', {
          label: this.$data
        })
        .then(() => {
          this.$router.push('/store/labels/')
        })
    }
  }
}
