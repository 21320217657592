<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop :text="label_name || 'New label'" :parent="{ name: 'Labels', link: '/store/labels' }" />
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <form
          class="flex flex-1 flex-col justify-between"
          @submit.prevent="handleSubmit()"
          @keydown.enter="handleKeydown($event)"
          @keydown.meta.enter.exact="handleSubmit()"
        >
          <PageGrid>
            <MainCol>
              <BasicContainer>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <label for="name" class="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input
                      id="name"
                      v-model="label_name"
                      type="text"
                      name="name"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div class="col-span-12">
                    <label for="contains" class="block text-sm font-medium text-gray-700 mb-1">Type</label>
                    <div class="mt-2 space-y-2">
                      <div class="flex items-center">
                        <input
                          id="contains"
                          v-model="label_type"
                          name="type"
                          type="radio"
                          value="contains"
                          class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                        />
                        <label for="contains" class="block ml-3 text-gray-700 text-sm font-medium">Contains</label>
                      </div>
                      <div class="flex items-center">
                        <input
                          id="diet"
                          v-model="label_type"
                          name="type"
                          value="diet"
                          type="radio"
                          class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                        />
                        <label for="diet" class="block ml-3 text-gray-700 text-sm font-medium">Diet</label>
                      </div>
                    </div>
                  </div>
                </div>
              </BasicContainer>
            </MainCol>
          </PageGrid>
          <PageFooter>
            <template #left>
              <button class="btn-ghost mr-3" @click="$router.back">Cancel</button>
            </template>
            <template #right>
              <button class="btn-primary" type="submit">Save</button>
            </template>
          </PageFooter>
        </form>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { SellSidebar },
  data() {
    return { label_name: '', label_type: '' }
  },
  methods: {
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    handleSubmit() {
      api
        .call('POST', 'labels', {
          label: this.$data
        })
        .then(() => {
          this.$router.push('/store/labels/')
        })
    }
  }
}
</script>
