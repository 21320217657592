<template>
  <span
    :class="[
      'ml-2 px-2 capitalize inline-flex text-xs leading-5 font-semibold rounded-full',
      props.hasSuccess
        ? 'bg-green-100 text-green-800'
        : props.hasWarning
          ? 'bg-yellow-100 text-yellow-800'
          : 'bg-gray-100 text-gray-700'
    ]"
  >
    {{ name }}
  </span>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    props: {
      type: Object,
      required: false,
      default: () => {
        return {
          hasSuccess: false,
          hasWarning: false
        }
      }
    }
  }
})
</script>
