<template>
  <div class="flex flex-col justify-between h-full">
    <PageHeader
      :heading="name"
      :breadcrumbs="[{ title: 'Gift cards', route: '/store/gift-cards' }, { title: name }]"
    ></PageHeader>
    <form
      class="flex flex-1 flex-col justify-between"
      @submit.prevent="handleSubmit()"
      @keydown.enter="handleKeydown($event)"
      @keydown.meta.enter.exact="handleSubmit()"
    >
      <PageGrid>
        <MainCol>
          <BasicContainer>
            <div class="grid grid-cols-12 gap-6">
              <div class="col-span-12">
                <label for="name" class="block text-sm font-medium text-gray-700 mb-1">Name</label>
                <input
                  id="name"
                  v-model="name"
                  type="text"
                  name="name"
                  class="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div class="col-span-12">
                <label for="description" class="block text-sm font-medium text-gray-700 mb-1">Description</label>
                <textarea
                  id="description"
                  v-model="description"
                  rows="8"
                  name="description"
                  class="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </BasicContainer>
        </MainCol>
        <SideCol>
          <BasicContainer>
            <div>
              <label for="price" class="block text-sm font-medium text-gray-700 mb-1">Price</label>
              <input
                id="price"
                v-model="price"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*.?[0-9]*"
                name="price"
                class="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label for="value" class="block text-sm font-medium text-gray-700 mb-1">Value</label>
              <input
                id="value"
                v-model="value"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*.?[0-9]*"
                name="value"
                class="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </BasicContainer>
        </SideCol>
      </PageGrid>
      <PageFooter>
        <template #left>
          <button
            :disabled="status === 'deleted'"
            :class="['btn-ghost', { 'pointer-events-none opacity-50': status === 'deleted' }]"
            @click="del"
          >
            Deactivate
          </button>
        </template>
        <template #right>
          <router-link class="btn-ghost mr-3" to="/store/products">Cancel</router-link>
          <button class="btn-primary" type="submit">Save</button>
        </template>
      </PageFooter>
    </form>
  </div>
</template>
<script>
import api from '@/store/api'

export default {
  data() {
    return {
      name: '',
      description: '',
      status: '',
      price: '',
      priceFormat: '',
      value: '',
      valueFormat: ''
    }
  },
  mounted() {
    this.getGiftcards()
  },
  methods: {
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    getGiftcards() {
      api.call('GET', 'gift_cards/' + this.$route.params.id).then(response => {
        const attrs = response.data.data.attributes
        this.name = attrs.gift_card_name
        this.description = attrs.description
        this.status = attrs.status
        this.price = (attrs.price_amount.cents / 100).toFixed(2)
        this.priceFormat = attrs.price_amount.format
        this.value = (attrs.value_amount.cents / 100).toFixed(2)
        this.valueFormat = attrs.value_amount.format
      })
    },
    del() {
      api.call('DELETE', 'gift_cards/' + this.$route.params.id).then(this.$router.back)
    },
    handleSubmit() {
      api
        .call('PUT', 'gift_cards/' + this.$route.params.id, {
          gift_card: {
            gift_card_name: this.name,
            description: this.description,
            price_amount_cents: Math.round(this.price * 100),
            value_amount_cents: Math.round(this.value * 100)
          }
        })
        .then(() => this.getGiftcards())
      // TODO: replace with toast
      this.$router.push('/store/gift-cards')
    }
  }
}
</script>
