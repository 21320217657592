
import Cookies from 'js-cookie'
import { defineComponent, reactive } from 'vue'
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      password: '',
      passwordFieldLength: 0,
      shouldSubmitPassword: true
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const passwordQuestion = computed(
      () =>
        onboardingQuestions.value.filter(obj => {
          return obj.attributes.target_column === 'password'
        })[0]
    )
    const passwordAnswer = computed(() =>
      onboardingAnswers.value.find(obj => String(obj.attributes.onboarding_question_id) === passwordQuestion.value.id)
    )
    watch(onboardingAccount, (newValue, oldValue) => {
      if (passwordAnswer.value?.attributes.field_length > 0) {
        data.shouldSubmitPassword = false
        data.password = new Array(passwordAnswer.value.attributes.field_length + 1).join('•')
      }
    })

    const formSubmitted = () => {
      questionAnswered()
    }

    const questionAnswered = () => {
      submitAnswer(passwordQuestion.value.id)
    }

    const submitAnswer = id => {
      if (data.shouldSubmitPassword) {
        api
          .call(
            'POST',
            'onboarding?included=onboarding_answers',
            {
              answer: {
                onboarding_answers_attributes: [
                  {
                    answer: data.password,
                    field_length: data.password.length,
                    onboarding_question_id: passwordQuestion.value.id
                  }
                ]
              }
            },
            { authToken: Cookies.get('onboarding_auth_token') }
          )
          .then(response => {
            const values = savedValues() || {}
            values['nextPage'] = 2
            store.dispatch('setSignUpPropertyValues', values).then(() => {
              router.push('/business-name')
            })
          })
      } else {
        router.push('/business-name')
      }
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    const validateKeyPress = event => {
      if (!data.shouldSubmitPassword) {
        data.password = ''
        data.shouldSubmitPassword = true
      }
      return true
    }

    const validateBackSpace = event => {
      if (event.keyCode === 8 && !data.shouldSubmitPassword) {
        data.password = ''
        data.shouldSubmitPassword = true
      }

      return true
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      questionAnswered,
      savedValues,
      populateAnswer,
      steps,
      totalProgress,
      onboardingToken,
      getQuestions,
      submitAnswer,
      validateKeyPress,
      validateBackSpace
    }
  }
})
