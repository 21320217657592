<template>
  <div class="h-full overflow-auto">
    <div
      :class="props.imagePosition === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'"
      class="flex min-h-screen w-full md:bg-sign-up-brand flex-col"
    >
      <div class="bg-yellow-200 w-full md:w-64 flex h-40 md:h-auto justify-center items-center">
        <a
          href="https://bottle.com"
          class="absolute top-0 left-0 ml-4 mt-4 inline-flex items-center bg-white border-2 border-gray-800 rounded-md font-bold text-sm hover:shadow-lg transition-all px-4 py-3.5 leading-tight lg:leading-tight"
        >
          <span class="mr-2 translate-y-180 rotate-180 w-4 sm:w-8 md:w-10">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 13">
              <path
                d="M3.11,6.82A20,20,0,0,1,1.2,6a.46.46,0,0,0-.67.15A2.32,2.32,0,0,0,0,7.25C0,7.64,0,7.84.3,8c.59.25,1.18.52,1.78.72"
                transform="translate(0 0)"
              ></path>
              <path
                d="M1.83,8.58A23.56,23.56,0,0,0,7.36,10a36.41,36.41,0,0,0,8.79,0A53.55,53.55,0,0,0,29.69,6.72l.19-.07,0,0c-.33.4-.65.8-1,1.19-.72.87-1.45,1.73-2.16,2.61a1.75,1.75,0,0,0,0,2.28.68.68,0,0,0,.52.22.73.73,0,0,0,.5-.26c.67-.78,1.33-1.58,2-2.39l3.65-4.41A2.13,2.13,0,0,0,34,4.5,1.24,1.24,0,0,0,33.91,4a.87.87,0,0,0-.28-.36,1.55,1.55,0,0,0-.32-.2L26.59.11c-.65-.31-1.25.05-1.54.92a1.22,1.22,0,0,0,.4,1.5c.95.49,1.91,1,2.86,1.42l1.78.89-.19.08A55.6,55.6,0,0,1,17.44,8a36.08,36.08,0,0,1-8.87.12A21.78,21.78,0,0,1,2.82,6.7c-.3-.12-.73-.1-1-.25"
                fill="#000000"
                transform="translate(0 0)"
              ></path>
            </svg>
          </span>
          <img
            class="inset-0 h-4 md:h-8"
            src="https://storage.googleapis.com/sendbottles.com/brand/assets/runningman_allblack.png"
            alt=""
          />
        </a>
        <img
          class="hidden md:block h-auto w-auto img-shelf-position px-8 h-32"
          src="https://storage.googleapis.com/sendbottles.com/brand/assets/Bottle_Shelf_Short.png"
          alt=""
        />
        <button
          v-if="route.path !== '/login'"
          class="absolute top-0 right-0 mr-4 mt-4 inline-flex items-center bg-white border-2 border-gray-800 rounded-md font-bold text-sm hover:shadow-lg transition-all px-3 py-2.5 leading-tight"
          @click="logOut"
        >
          Log out
        </button>
      </div>
      <div class="w-full flex flex-1 md:items-center justify-center">
        <SignupQuestionContainer :title="props.title" :max-width="props.maxWidth" @form-submitted="formSubmitted">
          <template #stepper>
            <slot name="stepper" />
          </template>
          <template #body>
            <slot name="body" />
          </template>
          <template #footer>
            <slot name="footer" />
          </template>
        </SignupQuestionContainer>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import initialState from '@/store/state'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    imagePosition: {
      type: String,
      required: false,
      default: 'left'
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'md:max-w-3xl'
    }
  },
  setup(props, context) {
    const formSubmitted = () => {
      context.emit('form-submitted')
    }

    const store = useStore()
    const router = useRouter()
    const logOut = () => {
      Cookies.remove('auth_token')
      router.push('/login').then(() => {
        store.replaceState(initialState)
      })
    }

    const route = useRoute()

    return {
      props,
      formSubmitted,
      logOut,
      route
    }
  }
})
</script>
