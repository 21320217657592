
export default {
  props: {
    heading: {
      type: String,
      required: true,
      default: 'Heading Here'
    }
  }
}
