<template>
  <!-- we only set message to trusted strings -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-html="message" />
</template>

<script>
import * as Sentry from '@sentry/vue'
import Cookies from 'js-cookie'

export default {
  metaInfo: {
    title: 'Auth'
  },
  data() {
    return {
      message: 'Authenticating...',
      token: this.$route.query.token
    }
  },
  computed: {},
  mounted() {
    Sentry.captureMessage('Auth.vue accessed')
    if (this.token && this.token.length) {
      Cookies.set('auth_token', this.token, { expires: 6 * 31 })
      this.$store.dispatch('getLoggedInUser')
      this.$router.push('/')
    } else {
      this.message = 'Error authenticating. <a href="/login">Login</a>'
    }
  },
  methods: {}
}
</script>
