<template>
  <div class="p-5 bg-white border-b" :class="{ 'pb-3': saveStatus }">
    <nav v-if="breadcrumbs" class="hidden select-none sm:flex" aria-label="Breadcrumb">
      <ol class="flex items-center space-x-4">
        <li v-for="(crumb, i) in breadcrumbs" :key="`breadcrumb-item-${i}`">
          <div class="flex items-center">
            <ChevronRightIcon v-if="i > 0" class="flex-shrink-0 w-5 h-5 text-gray-400" />
            <router-link v-if="i == 0" :to="crumb.route" class="text-gray-500 hover:text-gray-700 text-sm font-medium">
              {{ crumb.title }}
            </router-link>
            <span v-else aria-current="page" class="ml-4 text-gray-500 hover:text-gray-700 text-sm font-medium">
              {{ crumb.title }}
            </span>
          </div>
        </li>
      </ol>
    </nav>
    <div class="mt-2 md:flex md:items-center md:justify-between">
      <div>
        <div v-if="hasEditMode" class="inline-flex items-center min-w-0 cursor-pointer" @click="editHeading">
          <h2 v-if="!editingMode" class="text-gray-900 text-2xl font-bold leading-7 sm:text-3xl max-w-3xl">
            {{ heading }}
          </h2>
          <span v-if="editingMode">
            <input
              ref="headingInput"
              v-model="newHeading"
              class="text-gray-900 text-lg font-semibold tracking-tighter"
              :style="{ width: 15 + newHeading.length * 9 + 'px' }"
              @keyup.enter="saveHeading"
              @blur="saveHeading"
            />
          </span>
          <button v-if="editingEnabled && !editingMode" class="ml-2 w-4 h-4">
            <PencilIcon />
          </button>
          <button v-if="editingMode" class="ml-2 w-4 h-4">
            <CheckIcon />
          </button>
        </div>
        <div v-else class="inline-flex items-center min-w-0">
          <div v-show="useBackArrow" class="mr-4">
            <router-link
              :to="prevRoute"
              class="group transition-colors duration-150 rounded block rounded p-2 border border-gray-300 hover:border-blue-500"
            >
              <ArrowLeftIcon class="w-5 h-5 text-gray-500 group-hover:text-blue-500" />
            </router-link>
          </div>
          <div>
            <div class="flex items-center mb-1">
              <h2 class="text-gray-900 text-2xl font-bold leading-7 sm:text-3xl max-w-3xl">
                <div>{{ heading }}</div>
              </h2>
              <div class="ml-2"><slot name="tag-list" /></div>
            </div>
            <slot name="meta" />
          </div>
        </div>
        <SaveStatus v-if="saveStatus" />
      </div>
      <div class="mt-4 md:ml-4 md:mt-0">
        <slot name="aside" />
      </div>
    </div>
    <slot name="pageDescription" />
  </div>
</template>
<script>
import { ArrowLeftIcon, CheckIcon, ChevronRightIcon, PencilIcon } from '@heroicons/vue/20/solid'
import { nextTick } from 'vue'

export default {
  components: {
    ArrowLeftIcon,
    ChevronRightIcon,
    PencilIcon,
    CheckIcon
  },
  props: {
    heading: {
      type: String,
      required: true,
      default: 'Heading Here'
    },
    useBackArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    prevRoute: {
      type: String,
      required: false,
      default: ''
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null
      // Example data:
      // [
      //   {
      //     title: 'Parent',
      //     route: '/'
      //   },
      //   {
      //     title: 'Crumb',
      //     route: '/messages'
      //   }
      // ]
    },
    saveStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    hasEditMode: {
      type: Boolean,
      required: false,
      default: false
    },
    editingEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    close: { type: Function }
  },
  data() {
    return {
      editingMode: false,
      newHeading: null
    }
  },
  methods: {
    editHeading() {
      this.newHeading = this.heading
      this.editingMode = true
      nextTick(() => this.$refs.headingInput.select())
    },
    saveHeading() {
      this.editingMode = false
      this.close(this.newHeading)
    }
  }
}
</script>
