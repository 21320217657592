<template>
  <button type="button" v-bind="attrs" :class="classList">
    <span v-show="accessibilityLabel && isLoading" class="sr-only">{{ accessibilityLabel }}</span>
    <BasicSpinner v-show="isLoading" class="mr-3" />
    <slot />
  </button>
</template>
<script>
import { ref } from 'vue'
export default {
  props: {
    accessibilityLabel: {
      type: String,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isPrimary: {
      type: Boolean,
      required: false,
      default: true
    },
    isDestructive: {
      type: Boolean,
      required: false,
      default: false
    },
    isGhost: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const attrs = ref({
      ariaLabel: props.accessibilityLabel && !props.isLoading ? props.accessibilityLabel : null,
      disabled: props.isDisabled ?? null
    })
    const classList = ref({
      'btn-disabled': props.isDisabled,
      'btn-primary': props.isPrimary,
      'btn-ghost': props.isGhost,
      'btn-alert': props.isDestructive
    })
    return {
      attrs,
      classList
    }
  }
}
</script>
