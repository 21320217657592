import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "py-1 px-2" }
const _hoisted_2 = { class: "text-sm font-regular text-gray-700" }
const _hoisted_3 = { class: "font-regular italic ml-1" }
const _hoisted_4 = {
  key: 0,
  class: "mt-1 text-yellow-600"
}
const _hoisted_5 = {
  key: 1,
  class: "mt-1 text-yellow-600"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "p-1 mt-1"
}
const _hoisted_9 = ["onChange", "value"]
const _hoisted_10 = ["value", "selected"]

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue'

import { mapRelationships } from '@/store/mappers'


export default {
  __name: 'VariantRow',
  props: ['variant', 'open', 'item'],
  emits: ['setOption', 'setQuantity', 'click'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const hasQuantity = computed(() => props.variant.attributes.quantity_option === 'custom_quantities')
const options = computed(() =>
  mapRelationships(props.variant?.relationships.variant_options.data).sort((a, b) => a.id - b.id)
)
const currentOptions = computed(
  () =>
    props.item?.attributes.variant_options_selection
      .sort((a, b) => a.variant_option_id - b.variant_option_id)
      .filter(x => x.variant_id == props.variant?.id) ?? []
)
const isCurrentOption = option => currentOptions.value.some(x => x.variant_option_id == option.id)

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", {
      class: "flex items-center cursor-pointer",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('click')))
    }, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(props.variant.attributes.name), 1),
      (props.open)
        ? (_openBlock(), _createBlock(_unref(ChevronDownIcon), {
            key: 0,
            class: "ml-1 h-3 w-3"
          }))
        : (_openBlock(), _createBlock(_unref(ChevronRightIcon), {
            key: 1,
            class: "ml-1 h-3 w-3"
          })),
      _createElementVNode("span", _hoisted_3, _toDisplayString(currentOptions.value.map(x => x.variant_option_name + (hasQuantity.value ? ` (${x.quantity})` : '')).join(', ')), 1)
    ]),
    (props.open && props.variant.attributes.minimum > currentOptions.value.reduce((sum, x) => sum + x.quantity, 0))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Selected options sum up to less than minimum for the variant "))
      : _createCommentVNode("", true),
    (props.open && props.variant.attributes.maximum < currentOptions.value.reduce((sum, x) => sum + x.quantity, 0))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Selected options sum up to more than maximum for the variant "))
      : _createCommentVNode("", true),
    (props.open)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 2,
          class: _normalizeClass(["flex flex-wrap items-center mt-2", hasQuantity.value ? 'gap-2' : 'gap-1'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (option) => {
            return (_openBlock(), _createElementBlock("button", {
              key: option.id,
              class: _normalizeClass([
          { 'bg-blue-100 border-blue-400 text-blue-800': isCurrentOption(option) },
          {
            'bg-white border-gray-300 text-gray-700 ': !isCurrentOption(option)
          },
          props.variant.attributes.quantity_option === 'choose_one' ? 'rounded-full' : 'rounded-md',
          hasQuantity.value ? 'cursor-auto py-2' : 'hover:bg-gray-100 hover:border-gray-400',
          'mb-1 px-3 py-0.5 text-sm transition-colors duration-150 border'
        ]),
              onClick: $event => (
          props.variant.attributes.quantity_option !== 'custom_quantities' &&
            _ctx.$emit('setOption', props.item, props.variant, option)
        )
            }, [
              _createTextVNode(_toDisplayString(option.attributes.name) + " ", 1),
              (option.attributes.price.cents)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "(" + _toDisplayString(option.attributes.price.format) + ")", 1))
                : _createCommentVNode("", true),
              (hasQuantity.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("select", {
                      class: "w-18 rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-brand-500 focus:outline-none focus:ring-1 focus:ring-brand-500 sm:text-sm",
                      onChange: $event => (_ctx.$emit('setQuantity', props.item, props.variant, option, parseInt($event.target.value))),
                      value: currentOptions.value.find(x => x.variant_option_id == option.id)?.quantity ?? 0
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from({ length: 100 }, (_, x) => x), (i) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: i,
                          selected: currentOptions.value.find(x => x.variant_option_id == option.id)?.quantity == i
                        }, _toDisplayString(i), 9, _hoisted_10))
                      }), 256))
                    ], 40, _hoisted_9)
                  ]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_6))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

}