
import { DatePicker } from 'v-calendar'
import { Field, Form } from 'vee-validate'
import { computed, onMounted, reactive, ref } from 'vue'
import { defineComponent } from 'vue'
import Multiselect from 'vue-multiselect'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { toSnakeCase } from '@/helpers/CaseHelper'
import { serverDayToDateObject } from '@/helpers/dates'
import { availableFulfillmentTypes } from '@/models/fulfillmentMethodTypes'
import usaStates from '@/models/usaStates'
import ApiService from '@/services/api.service'
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'
export const FulfillmentMethodService = new ApiService({
  uri: 'fulfillment_methods'
})

export default defineComponent({
  components: {
    DatePicker,
    Form,
    Field,
    Multiselect,
    SellSidebar
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const methodId = router.currentRoute.value.params.i

    const data = reactive({
      statesMapped: [],
      type: '',
      name: '',
      description: '',
      isTaxed: false,
      usaStates: usaStates,
      stateOptions: [],
      zipcodeOptions: [],
      selectedStates: [],
      selectedZipcodes: [],
      storePairs: [],
      storeMap: { string: false },
      address: {
        address1: '',
        selectedStateTitle: ''
      },
      slots: [],
      availableStores: computed(() => Object.values(store.state.objects.store || {}))
    })

    // We need to store refs to pickers to force the other ones to close when one of them is clicked.
    const datePickerRefs = ref({})

    // Select Options + Methods
    const pushTag = (tag, id) => {
      if (typeof tag !== 'object') return data[id].push({ value: tag, label: tag })
    }
    const availableMethodTypes = availableFulfillmentTypes

    // Modal Data + Methods
    const slotModal = reactive({
      isVisible: false,
      mode: 'edit',
      slot: {},
      datePickerConfigs: {
        popover: {
          visibility: 'click'
        },
        kebabMask: {
          type: 'string',
          mask: 'YYYY-MM-DD'
        }
      }
    })

    const resetFulfillmentSlotModal = () => {
      slotModal.slot = {}
      slotModal.isVisible = false
    }

    const timeFormat = new Intl.DateTimeFormat('en-CA', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    })

    const addSlot = () => {
      const now = new Date()
      now.setHours(8, 0, 0, 0)
      slotModal.mode = 'add'
      slotModal.slot = {
        limitQuantity: false,
        startTime: now,
        endTime: now
      }
      slotModal.isVisible = true
    }

    const editSlot = i => {
      const now = new Date()
      slotModal.mode = 'edit'
      data.slotToEdit = i

      slotModal.slot = {
        id: data.slots[i].id,
        limitQuantity: data.slots[i].limitQuantity,
        displayCutoff: data.slots[i].displayCutoff,
        actualCutoff: data.slots[i].actualCutoff,
        date: data.slots[i].date,
        startTime: data.slots[i].startTime,
        endTime: data.slots[i].endTime,
        set_quantity_changes_attributes: [
          {
            value: data.slots[i].quantityLimit
          }
        ]
      }
      slotModal.isVisible = true
    }

    const saveSlot = () => {
      if (slotModal.mode === 'add') {
        data.slots.push(slotModal.slot)
      } else if (slotModal.mode === 'edit') {
        data.slots.splice(data.slotToEdit, 1, slotModal.slot)
      }

      resetFulfillmentSlotModal()
    }

    const removeSlot = i => {
      deleteSlot(i)
      data.slots.splice(i, 1)
    }

    const deleteSlot = i => {
      api.call('DELETE', `fulfillment_methods/${methodId}/fulfillment_slots/${data.slots[i].id}`)
    }

    const clickedStore = i => {
      data.storeMap[i] = !data.storeMap[i]
    }

    const taxableClicked = () => {
      data.isTaxed = !data.isTaxed
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    const fieldErrors = ref({
      pickupState: false
    })

    const handleInvalidMultiSelects = () => {
      if (data.type === 'PickupFulfillmentMethod' && !data.address.selectedState) {
        fieldErrors.value.pickupState = true
        return true
      }
      return false
    }

    const onInvalidSubmit = () => {
      handleInvalidMultiSelects()
      document.querySelector('form').scrollIntoView({ behavior: 'smooth' })
    }

    const handleSubmit = () => {
      if (handleInvalidMultiSelects()) return

      const fulfillmentMethod = {
        type: data.type,
        name: data.name,
        taxable: data.isTaxed,
        description: data.description,
        price_cents: Math.round(data.price * 100)
      }

      if (data.selectedStates.length) {
        fulfillmentMethod.eligibleStates = data.selectedStates.map(x => x.value)
      }

      if (data.selectedZipcodes.length) {
        fulfillmentMethod.eligibleZipCodes = data.selectedZipcodes.map(x => x.value)
      }

      if (data.type === 'PickupFulfillmentMethod') {
        fulfillmentMethod.deliveryAddressAttributes = {
          address1: data.address.address1,
          address2: data.address.address2,
          city: data.address.city,
          state: data.address.selectedState.value,
          zip: data.address.zip
        }
      }

      let fulfillmentMethods = []
      Object.keys(data.storeMap).map(k => {
        if (data.storeMap[k]) {
          fulfillmentMethods.push({ storeId: parseInt(k) })
        }
      })
      fulfillmentMethod.fulfillmentMethodStorePairsAttributes = fulfillmentMethods

      if (data.slots) {
        fulfillmentMethod.fulfillmentSlotsAttributes = [
          ...data.slots.map(slot => ({
            start_time: timeFormat.format(slot.startTime),
            end_time: timeFormat.format(slot.endTime),
            display_cutoff: slot.displayCutoff.toISOString(),
            actual_cutoff: slot.actualCutoff.toISOString(),
            date: slot.fulfillmentDate,
            limit_quantity: slot.limitQuantity,
            set_quantity_changes_attributes: [
              {
                value: slot.quantityLimit
              }
            ]
          }))
        ]
      }

      const resource = toSnakeCase({ fulfillmentMethod: fulfillmentMethod })
      FulfillmentMethodService.createResource(resource).then(() => {
        router.push('/store/fulfillment-methods')
      })
    }

    const handleClickPicker = refName => {
      // When clicking directly inside another picker, the previous one doesn't close.
      // Its a v-calendar bug and this is the workaround for now.
      Object.keys(datePickerRefs.value).forEach(itemName => {
        if (itemName != refName.target.name) {
          datePickerRefs.value[itemName].hidePopover()
        }
      })
    }

    const handleKeydown = event => {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    }

    onMounted(() => {
      api.call('GET', 'stores')
    })

    return {
      data,
      onInvalidSubmit,
      fieldErrors,
      fieldIsRequired,
      handleSubmit,
      pushTag,
      availableMethodTypes,
      slotModal,
      resetFulfillmentSlotModal,
      taxableClicked,
      addSlot,
      saveSlot,
      removeSlot,
      clickedStore,
      editSlot,
      handleClickPicker,
      datePickerRefs,
      handleKeydown,
      serverDayToDateObject
    }
  }
})
