<template>
  <div class="relative">
    <div ref="el" class="h-8 w-64" />
    <div
      :class="{
        'opacity-0': data.hidden,
        'bg-white border rounded-full pl-2 pr-3 fixed top-4': data.pill,
        'absolute top-0': !data.pill
      }"
      class="h-8 flex items-center transition-opacity duration-200"
    >
      <div v-if="savingCount !== 0" class="flex">
        <BasicSpinner size="small" color="gray-900" />
        <span class="ml-1.5">Saving...</span>
      </div>
      <div v-else class="flex">
        <CheckCircleIcon class="w-5 h-5" aria-hidden="true" />
        <span class="ml-1.5">Saved all changes</span>
      </div>
    </div>
  </div>
</template>
<script>
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { CheckCircleIcon },
  setup() {
    const store = useStore()
    const data = reactive({ hidden: true, timeout: null, pill: false })
    const savingCount = computed(() => store.state.savingCount)

    watch(savingCount, () => {
      clearTimeout(data.timeout)
      if (savingCount.value !== 0) {
        data.hidden = false
      } else {
        data.timeout = setTimeout(() => {
          data.hidden = true
        }, 5000)
      }
    })

    const el = ref(null)

    onMounted(() => {
      new IntersectionObserver(
        entries =>
          entries.forEach(entry => {
            data.pill = !entry.isIntersecting
          }),
        { threshold: 0.5 }
      ).observe(el.value)
    })

    return { data, savingCount, el }
  }
})
</script>
