import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex mt-8 max-w-3xl opacity-50" }
const _hoisted_2 = { class: "ml-3" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "message-body mt-2 text-gray-brand-800 text-nearly-base leading-5",
  style: {"white-space":"pre-line"}
}


export default {
  __name: 'PendingMessage',
  props: {
  message: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true
  }
},
  setup(__props) {



return (_ctx, _cache) => {
  const _component_avatar = _resolveComponent("avatar")
  const _component_card_meta = _resolveComponent("card-meta")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_avatar, { user: __props.user }, null, 8, ["user"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_card_meta, {
        data: {
          display_name: __props.user.attributes.display_name
        },
        status: "pending"
      }, null, 8, ["data"]),
      (__props.message.src)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: __props.message.src,
            class: "mt-3 max-w-xs bg-gray-brand-100 rounded-md shadow-sm opacity-50 object-cover"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (__props.message.body)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(__props.message.body), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

}