import 'core-js/actual'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import mitt from 'mitt'
import { createApp, h } from 'vue'
import linkify from 'vue-linkify'
import VueTippy from 'vue-tippy'

import App from './App'
import router from './router'
import store from './store'

const app = createApp({
  router,
  store,
  render: () => h(App)
})

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://be196de2c3c440dcac39c083435aab88@o1224056.ingest.sentry.io/6368769',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'api.bottle.com', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
  })

  Sentry.setUser({ domain: window.location.hostname })
}

const bus = mitt()
app.provide('bus', bus)

app.config.globalProperties.window = window
app.config.globalProperties.$store = store
app.provide('$store', store)

app.use(router)
app.use(store)

app.use(VueTippy, {
  defaultProps: {
    placement: 'bottom',
    arrow: true,
    theme: 'dark',
    allowHTML: true,
    delay: [300, null]
  }
})

app.directive('linkified', linkify)
app.mount('#app')

// Toasts
// https://github.com/Maronato/vue-toastification
import 'vue-toastification/dist/index.css'

import Toast, { PluginOptions } from 'vue-toastification'

const options = {
  position: 'bottom-right',
  hideProgressBar: true,
  icon: false,
  closeButton: false,
  toastClassName: 'tw',
  timeout: 4000
}

app.use(Toast, options)
