import script from "./ConversationsMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./ConversationsMenu.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import NeueButton from "@/components/neue/NeueButton.vue";
import NewContactModal from "@/components/neue/NewContactModal.vue";
import SidebarConversation from "@/components/customers/SidebarConversation.vue";
script.components = Object.assign({}, { NeueButton, NewContactModal, SidebarConversation }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { NeueButton, NewContactModal, SidebarConversation }, script.__o.components);}