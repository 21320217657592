
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    steps: {
      type: Array,
      required: true,
      default: []
    },
    totalProgress: {
      type: Number,
      required: true,
      default: 0
    },
    currentStep: {
      type: Number,
      required: true,
      default: 0
    }
  },

  setup(props) {
    return {
      props
    }
  }
})
