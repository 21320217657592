<template>
  <div class="w-8 h-8">
    <div class="aspect-w-8 aspect-h-8">
      <img
        v-if="user.attributes?.avatar_url"
        class="w-full h-full object-center object-cover rounded select-none"
        :src="user.attributes.avatar_url"
        :alt="user.attributes.display_name"
        draggable="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
