
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { CheckCircleIcon },
  setup() {
    const store = useStore()
    const data = reactive({ hidden: true, timeout: null, pill: false })
    const savingCount = computed(() => store.state.savingCount)

    watch(savingCount, () => {
      clearTimeout(data.timeout)
      if (savingCount.value !== 0) {
        data.hidden = false
      } else {
        data.timeout = setTimeout(() => {
          data.hidden = true
        }, 5000)
      }
    })

    const el = ref(null)

    onMounted(() => {
      new IntersectionObserver(
        entries =>
          entries.forEach(entry => {
            data.pill = !entry.isIntersecting
          }),
        { threshold: 0.5 }
      ).observe(el.value)
    })

    return { data, savingCount, el }
  }
})
