<template>
  <SignupMainContainer :title="'Try Bottle for free'" :image-position="'left'" @form-submitted="formSubmitted">
    <template #stepper>
      <SignupHeaderComponent :total-progress="totalProgress()" :steps="steps" :current-step="3"></SignupHeaderComponent>
    </template>
    <template #body>
      <div class="mx-auto w-full">
        <div class="mt-2">
          <div class="space-y-6 flex flex-col">
            <h2 class="text-gray-800 text-xl tracking-tighter">What type of business do you run or plan to run?</h2>
            <h2 class="text-gray-600 text-md tracking-tight">
              No matter what you sell, Bottle has the tools to take your business where you want it to go.
            </h2>
            <div>
              <fieldset class="mt-2">
                <div class="grid grid-cols-1 gap-3">
                  <label
                    v-for="(option, index) in data.dropdownOptions.items"
                    :key="option.name"
                    :class="
                      option.checked
                        ? 'bg-yellow-200 border-gray-800'
                        : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50'
                    "
                    class="border-2 rounded-md py-3 px-4 flex items-center justify-start text-sm font-semibold tracking-wide sm:flex-1 cursor-pointer focus:outline-none hover:bg-light-yellow hover:border-gray-800 text-gray-800 hover:border-2"
                    @click="optionSelected(index)"
                  >
                    <input
                      type="radio"
                      name="memory-option"
                      :value="option.name"
                      class="sr-only"
                      aria-labelledby="memory-option-0-label"
                    />
                    <span id="memory-option-0-label">{{ option.name }}</span>
                  </label>
                </div>
                <div v-if="data.showSpecification" class="mt-4">
                  <label for="handle" class="block text-gray-700 text-sm font-medium leading-5">Please specify</label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      v-model="data.business_type"
                      type="text"
                      required
                      class="placeholder-gray-400 block px-3 py-3.5 rounded-lg w-full focus:border-gray-800 border-2 border-gray-200 focus:ring-0 appearance-none transition duration-150 ease-in-out text-sm leading-5 focus:outline-none font-semibold tracking-wide"
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end w-full">
        <button
          type="submit"
          class="ml-2 inline-flex items-center border-gray-800 border-2 font-bold text-md hover:shadow-lg transition-all rounded-full px-6 py-3.5 leading-tight bg-yellow-200 text-gray-800"
        >
          <span class="ml-auto transform -translate-y-px mr-4">Next</span>
          <span class="mr-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="13" viewBox="0 0 34 13">
              <path
                d="M3.11,6.82A20,20,0,0,1,1.2,6a.46.46,0,0,0-.67.15A2.32,2.32,0,0,0,0,7.25C0,7.64,0,7.84.3,8c.59.25,1.18.52,1.78.72"
                transform="translate(0 0)"
              ></path>
              <path
                d="M1.83,8.58A23.56,23.56,0,0,0,7.36,10a36.41,36.41,0,0,0,8.79,0A53.55,53.55,0,0,0,29.69,6.72l.19-.07,0,0c-.33.4-.65.8-1,1.19-.72.87-1.45,1.73-2.16,2.61a1.75,1.75,0,0,0,0,2.28.68.68,0,0,0,.52.22.73.73,0,0,0,.5-.26c.67-.78,1.33-1.58,2-2.39l3.65-4.41A2.13,2.13,0,0,0,34,4.5,1.24,1.24,0,0,0,33.91,4a.87.87,0,0,0-.28-.36,1.55,1.55,0,0,0-.32-.2L26.59.11c-.65-.31-1.25.05-1.54.92a1.22,1.22,0,0,0,.4,1.5c.95.49,1.91,1,2.86,1.42l1.78.89-.19.08A55.6,55.6,0,0,1,17.44,8a36.08,36.08,0,0,1-8.87.12A21.78,21.78,0,0,1,2.82,6.7c-.3-.12-.73-.1-1-.25"
                fill="#000000"
                transform="translate(0 0)"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </template>
  </SignupMainContainer>
</template>
<script>
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      handle: '',
      showSpecification: false,
      business_type: '',
      dropdownOptions: {
        items: [
          { name: 'Bakery', showInputFieldOnSelect: false },
          { name: 'Meal delivery', showInputFieldOnSelect: false },
          { name: 'Beverage', showInputFieldOnSelect: false },
          { name: 'Charcuterie', showInputFieldOnSelect: false },
          { name: 'Flowers', showInputFieldOnSelect: false },
          { name: 'Grocery / Market', showInputFieldOnSelect: false },
          { name: 'Delivery / Pickup', showInputFieldOnSelect: false },
          { name: 'Meat', showInputFieldOnSelect: false },
          { name: 'Seafood', showInputFieldOnSelect: false },
          { name: 'Other', showInputFieldOnSelect: true }
        ]
      }
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const businessTypeQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'business_type')
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const businessTypeAnswer = computed(() =>
      onboardingAnswers.value.find(
        obj => obj.relationships.onboarding_question.data.id === businessTypeQuestion.value.id
      )
    )

    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )

    watch(onboardingAccount, (newValue, oldValue) => {
      data.business_type = businessTypeAnswer.value?.attributes.answer || ''
      if (optionForBusinessType(data.business_type)) {
        optionForBusinessType(data.business_type).checked = true
      } else if (data.business_type) {
        data.dropdownOptions.items[data.dropdownOptions.items.length - 1].checked = true
        data.showSpecification = true
      } else {
      }
    })

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: emailAnswer.value?.attributes.answer,
          onboarding_business_type: data.business_type
        }
      ])
    }

    const optionSelected = index => {
      uncheckSelectedOption()
      data.dropdownOptions.items[index].checked = true
      if (data.dropdownOptions.items[index].showInputFieldOnSelect === true) {
        data.showSpecification = true
        data.business_type = ''
      } else if (data.dropdownOptions.items[index].showInputFieldOnSelect === false) {
        data.showSpecification = false
        data.business_type = data.dropdownOptions.items[index].name
        submitAnswer(businessTypeQuestion.value.id)
      }
    }

    const uncheckSelectedOption = () => {
      if (selectedOption()) {
        selectedOption().checked = false
      }
    }

    const selectedOption = () => {
      return data.dropdownOptions.items.find(option => option.checked)
    }

    const optionForBusinessType = businessType => {
      return data.dropdownOptions.items.find(option => option.name === businessType)
    }

    const formSubmitted = () => {
      submitAnswer(businessTypeQuestion.value.id)
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'BusinessType', answer: data.business_type })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.business_type,
                  onboarding_question_id: businessTypeQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values.nextPage = 4
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            router.push('/referral')
          })
        })
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      savedValues,
      optionSelected,
      uncheckSelectedOption,
      selectedOption,
      optionForBusinessType,
      steps,
      totalProgress,
      populateAnswer,
      onboardingToken,
      getQuestions,
      submitAnswer,
      identifyInHubspot
    }
  }
})
</script>
