import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5 sm:flex items-center" }
const _hoisted_2 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_3 = { class: "mt-1 flex items-center text-sm text-gray-900 sm:col-span-2 sm:mt-0" }
const _hoisted_4 = {
  key: 0,
  class: "flex-grow"
}
const _hoisted_5 = {
  key: 1,
  class: "flex-grow"
}
const _hoisted_6 = {
  key: 2,
  class: "flex-grow"
}
const _hoisted_7 = {
  key: 3,
  class: "ml-4 flex-shrink-0"
}


export default {
  __name: 'DescriptionListItem',
  props: ['label', 'contents'],
  setup(__props) {

const props = __props

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dt", _hoisted_2, _toDisplayString(props.label), 1),
    _createElementVNode("dd", _hoisted_3, [
      (props.contents)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.contents), 1))
        : _createCommentVNode("", true),
      (_ctx.$slots.contents)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _renderSlot(_ctx.$slots, "contents")
          ]))
        : _createCommentVNode("", true),
      (!props.contents && !_ctx.$slots.contents)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

}