import script from "./DomainsNeue.vue?vue&type=script&setup=true&lang=js"
export * from "./DomainsNeue.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import FormElement from "@/components/neue/FormElement.vue";
import FormSection from "@/components/neue/FormSection.vue";
import InputField from "@/components/neue/InputField.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueTable from "@/components/neue/NeueTable.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import TableCell from "@/components/neue/TableCell.vue";
import TableHeadCell from "@/components/neue/TableHeadCell.vue";
import TableRow from "@/components/neue/TableRow.vue";
import TheModal from "@/components/TheModal.vue";
script.components = Object.assign({}, { FormElement, FormSection, InputField, NeueButton, NeueTable, SecondaryNav, SecondaryTop, TableCell, TableHeadCell, TableRow, TheModal }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { FormElement, FormSection, InputField, NeueButton, NeueTable, SecondaryNav, SecondaryTop, TableCell, TableHeadCell, TableRow, TheModal }, script.__o.components);}