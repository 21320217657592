<template>
  <div class="bg-gray-100">
    <page-header heading="Gift cards" :breadcrumbs="[{ title: 'Gift cards', route: '/store/gift-cards' }]">
      <template #aside>
        <router-link class="btn-primary" to="/store/gift-cards/new">New</router-link>
      </template>
    </page-header>

    <div class="hidden sm:block">
      <div class="mx-auto px-4 max-w-6xl sm:px-6 lg:px-8">
        <div class="flex flex-col mt-4">
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div v-show="giftCards.length" class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Discount
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Value
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody ref="cards" class="bg-white divide-y divide-gray-200">
                      <tr
                        v-for="giftCard in giftCards.slice(page * 9, page * 9 + 9)"
                        :key="giftCard.id"
                        class="cursor-move"
                        @click="$router.push('/store/gift-cards/' + giftCard.id)"
                      >
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div
                                class="text-sm font-medium text-gray-900 max-w-2xl truncate group-hover:text-blue-900"
                              >
                                {{ giftCard.attributes.gift_card_name }}
                              </div>
                              <div class="text-sm text-gray-500 max-w-2xl truncate">
                                {{ giftCard.attributes.description }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <span
                            :class="[
                              'px-2 capitalize inline-flex text-xs leading-5 font-semibold rounded-full',
                              giftCard.attributes.status === 'deleted'
                                ? 'bg-red-100 text-red-800'
                                : 'bg-green-100 text-green-800'
                            ]"
                          >
                            {{ giftCard.attributes.status == 'deleted' ? 'Inactive' : giftCard.attributes.status }}
                          </span>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="text-left text-sm text-gray-900 max-w-2xl truncate">
                            {{ giftCard.attributes.price_amount.format }}
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="text-left text-sm text-gray-900 max-w-2xl truncate">
                            {{ giftCard.attributes.value_amount.format }}
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm">
                          <button class="text-blue-600 group-hover:text-blue-900">Edit</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <SimpleEmptyState
                  v-show="!giftCards.length"
                  class="mt-4 mb-6"
                  heading="No gift cards yet"
                  description="Get started by adding your first gift card"
                  icon="ClipboardDocumentListIcon"
                />
              </div>
            </div>
          </div>
          <table-pagination
            class="mt-5"
            :items="giftCards"
            :page="page"
            :page-size="9"
            @on-page-change="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from 'sortablejs'

import api from '@/store/api'

export default {
  data() {
    return { giftCards: [], page: 0, sortable: null }
  },
  mounted() {
    const that = this
    this.sortable = new Sortable(this.$refs.cards, {
      onEnd(e) {
        const oldId = that.giftCards[e.oldIndex].id
        that.giftCards.sort((a, b) => a.attributes.order_value - b.attributes.order_value)
        let newOrder
        if (e.newIndex === 0) {
          // first
          newOrder = that.giftCards[0].attributes.order_value / 2
        } else if (e.newIndex === that.giftCards.length - 1) {
          // last
          newOrder = that.giftCards[that.giftCards.length - 1].attributes.order_value * 2
        } else {
          const calcCards = that.giftCards.filter(x => x.id !== oldId)
          const prev = calcCards[e.newIndex - 1]
          const next = calcCards[e.newIndex]
          newOrder = (prev.attributes.order_value + next.attributes.order_value) / 2
        }
        that.giftCards[e.oldIndex].attributes.order_value = newOrder
        that.giftCards.sort((a, b) => a.attributes.order_value - b.attributes.order_value)
        api.call('PUT', 'gift_cards/' + oldId, {
          gift_card: {
            order_value: newOrder
          }
        })
      }
    })
    api.call('GET', 'gift_cards').then(response => {
      response.data.data.forEach(giftCard => {
        this.giftCards.push(giftCard)
      })
      this.giftCards.sort((a, b) => a.attributes.order_value - b.attributes.order_value)
    })
  },
  methods: {
    onPageChange(e) {
      this.page = e
    }
  }
}
</script>
