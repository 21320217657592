<template>
  <div>
    <section-header class="mt-12 mb-4" heading="Settings">
      <template #aside>
        <span
          class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 border border-red-500 bg-red-100 text-red-800"
        >
          Danger Zone
          <div
            class="flex-shrink-0 -mr-0.5 ml-1.5 inline-flex text-red-500 focus:outline-none focus:text-red-800"
            aria-label="Remove large badge"
          >
            <ExclamationTriangleIcon class="w-5 h-5" />
          </div>
        </span>
      </template>
    </section-header>
    <div class="mb-20 shadow overflow-hidden rounded-md">
      <div class="border-b border-cool-gray-200">
        <div class="px-4 py-5 bg-white">
          <h3 class="text-base leading-6 font-medium text-gray-900">Archive Thread</h3>
          <div class="sm:flex sm:items-start sm:justify-between mt-2">
            <div class="max-w-xl text-sm text-gray-500">
              <p>Permanently archive this contact's conversation</p>
            </div>
            <button type="button" class="btn-ghost" @click="archiveConversationUserPair()">Archive Thread</button>
          </div>
        </div>
      </div>
      <div class="border-b border-cool-gray-200">
        <div class="px-4 py-5 bg-white">
          <h3 class="text-base leading-6 font-medium text-gray-900">Block SMS</h3>
          <div class="sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500 mt-2">
              <p>Block SMS messages to this number</p>
            </div>
            <button
              type="button"
              :class="[
                `relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                blockingSms ? 'bg-blue-600' : 'bg-gray-200'
              ]"
              role="switch"
              :aria-checked="blockingSms"
              aria-labelledby="method-taxed-label"
              @click="toggleBlockSms()"
            >
              <span
                aria-hidden="true"
                :class="[
                  `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                  blockingSms ? 'translate-x-5' : 'translate-x-0'
                ]"
              ></span>
            </button>
          </div>
        </div>
      </div>
      <div class="border-b border-cool-gray-200">
        <div class="px-4 py-5 bg-white">
          <h3 class="text-base leading-6 font-medium text-gray-900">Block Calls</h3>
          <div class="sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500 mt-2">
              <p>Block incoming calls from this number</p>
            </div>
            <button
              type="button"
              :class="[
                `relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                blockingCalls ? 'bg-blue-600' : 'bg-gray-200'
              ]"
              role="switch"
              :aria-checked="blockingCalls"
              aria-labelledby="method-taxed-label"
              @click="toggleBlockCalls()"
            >
              <span
                aria-hidden="true"
                :class="[
                  `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                  blockingCalls ? 'translate-x-5' : 'translate-x-0'
                ]"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

import api from '@/store/api'
import { mapRelationship } from '@/store/mappers'

export default {
  components: {
    ExclamationTriangleIcon
  },
  props: {
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  computed: {
    blockingCalls() {
      const conversationUserPair = mapRelationship({ type: 'conversation_user_pair', id: this.$route.params.id })
      return conversationUserPair?.attributes['block_calls?']
    },
    blockingSms() {
      const conversationUserPair = mapRelationship({ type: 'conversation_user_pair', id: this.$route.params.id })
      return conversationUserPair?.attributes['do_not_text?']
    }
  },
  methods: {
    toggleBlockCalls() {
      api.call('PUT', `conversation_user_pairs/${this.conversationUserPairId}`, {
        conversation_user_pair: {
          block_calls: this.blockingCalls ? 'off' : 'on'
        }
      })
    },
    toggleBlockSms() {
      api.call('PUT', `conversation_user_pairs/${this.conversationUserPairId}`, {
        conversation_user_pair: {
          do_not_text: this.blockingSms ? 'off' : 'on'
        }
      })
    },
    archiveConversationUserPair() {
      api.call('DELETE', `conversation_user_pairs/${this.conversationUserPairId}`)
      this.$router.push('/messages')
    }
  }
}
</script>
