import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = {
  key: 0,
  class: "absolute z-10 left-0 top-0 w-full"
}

import Cookies from 'js-cookie'
import Pusher from 'pusher-js'
import { ulid } from 'ulid'
import { computed, onBeforeMount, onUnmounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import ConversationsMenu from '@/components/customers/ConversationsMenu'
import SecondaryNav from '@/components/SecondaryNav'
import api from '@/store/api'
import { mapRelationship } from '@/store/mappers'


export default {
  __name: 'MessagesTab',
  setup(__props) {

const store = useStore()
const route = useRoute()
const router = useRouter()

const data = reactive({
  alertStatus: '',
  alertMsg: '',
  channel: null
})

const name = computed(
  () => mapRelationship({ type: 'conversation_user_pair', id: route.params.id })?.attributes.display_name
)

const saveName = name => {
  api.call('PUT', `conversation_user_pairs/${route.params.id}`, {
    conversation_user_pair: {
      display_name: name
    }
  })
}

onBeforeMount(() => {
  if (!store.state.pusher) {
    store.commit(
      'SET_PUSHER',
      new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        authEndpoint: process.env.VUE_APP_API_SERVER + 'pusher/auth'
      })
    )
  }
  data.channel = store.state.pusher.subscribe('u' + store.getters.loggedInUserId)
})

onUnmounted(() => {
  store.state.pusher.unsubscribe(data.channel.name)
})

const uploadFile = file => {
  const key = 'message_' + ulid()

  const reader = new FileReader()
  reader.onload = e => {
    store.commit('ADD_PENDING_MESSAGE', {
      message: {
        src: e.target.result,
        object_key: key
      },
      id: route.params.id
    })
  }
  reader.readAsDataURL(file)

  const formData = new FormData()
  formData.append('message[file]', file)
  formData.append('message[object_key]', key)

  fetch(process.env.VUE_APP_API_SERVER + `conversation_user_pairs/${route.params.id}/messages`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Cookies.get('auth_token')}`,
      Accept: 'application/json'
    },
    body: formData
  }).catch(() => {
    data.alertStatus = 'error'
    data.alertMsg = 'Upload failed'
  })
}

const uploadFiles = files => {
  files.forEach(x => uploadFile(x))
}

return (_ctx, _cache) => {
  const _component_SecondaryTop = _resolveComponent("SecondaryTop")
  const _component_message_stream = _resolveComponent("message-stream")
  const _component_message_bar = _resolveComponent("message-bar")
  const _component_alert = _resolveComponent("alert")

  return (_openBlock(), _createBlock(_unref(SecondaryNav), null, {
    sidebar: _withCtx(() => [
      _createVNode(_unref(ConversationsMenu))
    ]),
    top: _withCtx(() => [
      _createVNode(_component_SecondaryTop, {
        parent: { name: 'Messages', link: '/messages' },
        text: name.value,
        editable: true,
        sections: [
          { name: 'Messages', active: true },
          { name: 'Profile', active: false, action: () => _unref(router).push('/profile/' + _unref(route).params.id) }
        ],
        onChange: saveName
      }, null, 8, ["text", "sections"])
    ]),
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createBlock(_component_message_stream, {
          key: _unref(route).params.id,
          channel: data.channel,
          class: "overflow-y-auto h-full",
          onSubmitFiles: uploadFiles
        }, null, 8, ["channel"])),
        _createVNode(_component_message_bar, { onSubmitFiles: uploadFiles }),
        (data.alertMsg)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_alert, {
                status: data.alertStatus,
                msg: data.alertMsg,
                onClosed: _cache[0] || (_cache[0] = $event => (data.alertMsg = ''))
              }, null, 8, ["status", "msg"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

}