
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const refs = ref({})
    const router = useRouter()
    const data = reactive({
      areaCode: ''
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const areaCodeQuestion = computed(
      () => onboardingQuestions.value.filter(obj => obj.attributes.target_column === 'preferred_provision_area_code')[0]
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const areaCodeAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === areaCodeQuestion.value.id)
    )

    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )

    watch(data, (currentValue, oldValue) => {
      if (currentValue.areaCode.length > 3) {
        data.areaCode = currentValue.areaCode.slice(0, 3)
      }
    })
    watch(onboardingAccount, (newValue, oldValue) => {
      data.areaCode = areaCodeAnswer.value?.attributes.answer || ''
    })

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: emailAnswer.value?.attributes.answer,
          onboarding_area_code: data.areaCode
        }
      ])
    }

    const identifySignupInHubspot = signupDetails => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: signupDetails?.email,
          bottle_id: signupDetails?.id,
          bottle_handle: signupDetails?.handle,
          stripe_customer_id: signupDetails?.stripe_customer_id
        }
      ])
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    const formSubmitted = () => {
      submitAnswer(areaCodeQuestion.value.id)
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const areaInputContainerClicked = () => {
      refs.value.areaCodeInputRef.focus()
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'AreaCode', answer: data.areaCode })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.areaCode,
                  onboarding_question_id: areaCodeQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values['nextPage'] = 6
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            api.call('POST', 'signup', { onboarding_account_secret: onboardingToken() }, false).then(response => {
              Cookies.set('auth_token', response.data.token, { expires: 6 * 31 })
              store.dispatch('getLoggedInUser')
              store.dispatch('clearSignUpPropertyValues')
              Cookies.remove('onboarding_auth_token', null)
              identifySignupInHubspot(response.data.merchant_details)
              router.push('/stripe-integration-required')
            })
          })
        })
    }

    const validateKeyPress = event => {
      if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13) {
        return true
      } else {
        event.preventDefault()
      }
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      savedValues,
      areaInputContainerClicked,
      refs,
      steps,
      totalProgress,
      validateKeyPress,
      getQuestions,
      onboardingToken,
      populateAnswer,
      identifyInHubspot,
      identifySignupInHubspot
    }
  }
})
