
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      email: ''
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )
    watch(onboardingAccount, (newValue, oldValue) => {
      data.email = emailAnswer.value?.attributes.answer || data.email
    })

    const formSubmitted = () => {
      if (onboardingToken()) {
        submitAnswer(emailQuestion.value.id)
      } else {
        getAuthToken()
      }
    }

    const getAuthToken = () => {
      api.call('POST', 'onboarding/tokens').then(response => {
        saveTokenToLocalStorage(response.data.token)
        getQuestionsAndSubmitAnswer()
      })
    }

    const getQuestionsAndSubmitAnswer = () => {
      api
        .call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
          authToken: Cookies.get('onboarding_auth_token')
        })
        .then(response => {
          const emailQuestion = response.data.included.filter(
            question => question.attributes.target_column === 'email'
          )[0]
          submitAnswer(emailQuestion.id)
        })
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: data.email,
          onboarding_email: data.email
        }
      ])
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'Email', answer: data.email })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.email,
                  onboarding_question_id: emailQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values['nextPage'] = 1
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            router.push('/password')
          })
        })
    }

    const saveTokenToLocalStorage = token => {
      Cookies.set('onboarding_auth_token', token, { expires: 6 * 31 })
    }

    const loginClicked = () => {
      router.push('/login')
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      getAuthToken,
      savedValues,
      loginClicked,
      steps,
      totalProgress,
      saveTokenToLocalStorage,
      getQuestionsAndSubmitAnswer,
      populateAnswer,
      getQuestions,
      onboardingToken,
      identifyInHubspot
    }
  }
})
