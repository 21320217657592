<template>
  <div class="flex flex-shrink items-center justify-between min-w-0">
    <span
      class="flex flex-auto items-center min-w-0 text-cool-gray-900 text-lg font-medium tracking-tighter leading-snug truncate"
    >
      <h2>
        {{ heading }}
      </h2>
      <slot name="info"></slot>
    </span>
    <slot name="aside"></slot>
  </div>
</template>
<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
      default: 'Heading Here'
    }
  }
}
</script>
