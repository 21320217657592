<template>
  <div class="border-t border-gray-100">
    <div class="px-4 py-3 bg-gray-50 sm:px-6 flex justify-between">
      <div class="flex justify-start">
        <div class="mr-3">
          <slot name="left" />
        </div>
      </div>
      <div class="text-right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>
