<template>
  <div
    v-closable="{
      exclude: ['dropdownButton', 'dropdownList'],
      handler: 'closeDropdown'
    }"
    class="relative inline-block text-left"
  >
    <div>
      <button
        id="options-menu"
        class="inline-flex justify-center px-4 py-2 w-full text-gray-700 text-sm font-medium hover:bg-gray-50 bg-white border border-gray-300 rounded-md focus:outline-none shadow-sm focus:ring-indigo-500 focus:ring-offset-gray-100 focus:ring-offset-2 focus:ring-2"
        type="button"
        :aria-expanded="isVisible ? true : false"
        :aria-haspopup="isVisible ? true : false"
        rel="dropdownButton"
        @click="isVisible = !isVisible"
      >
        {{ buttonText }}
        <ChevronDownIcon class="-mr-1 ml-2 w-5 h-5" />
      </button>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="isVisible && !preventOptionsToShowOnClick"
        :class="`origin-top-${dropdownOrigin} ${position} ${dropdownOrigin}-0 mt-2 w-${width} rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`"
        role="menu"
        rel="dropdownList"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <input
          v-if="showSearch"
          type="text"
          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="filter"
          @input="searchInputChanged($event.target.value)"
        />
        <div class="py-1" role="none" @click="closeDropdown()">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

import { closable } from '@/directives/closable'

export default {
  directives: { closable },
  components: { ChevronDownIcon },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    dropdownOrigin: {
      type: String,
      required: false,
      default: 'left'
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    position: {
      type: String,
      required: false,
      default: 'absolute'
    },
    width: {
      type: String,
      required: false,
      default: '56'
    },
    preventOptionsToShowOnClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['search-input-changed'],
  data: () => ({
    isVisible: false
  }),
  methods: {
    closeDropdown() {
      this.isVisible = false
    },
    searchInputChanged(value) {
      this.$emit('search-input-changed', value)
    }
  }
}
</script>
