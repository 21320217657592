<template>
  <div
    :class="[itemOutline, { 'rounded-lg shadow ring-1 overflow-hidden w-full': true, 'cursor-pointer': answerable }]"
    @click="itemClick()"
  >
    <div :class="[itemTheme, 'flex items-center px-5 py-6 rounded-lg transition ease-in-out duration-150']">
      <div
        :class="[iconBoxTheme, 'flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md sm:h-12 sm:w-12']"
      >
        <PhoneIcon class="p-3"></PhoneIcon>
      </div>
      <div class="ml-4">
        <p class="text-gray-800 text-sm">Call from {{ userName }}</p>
        <time class="block pt-1 text-gray-brand-700 font-manrope text-xxs">
          {{ timestamp }}
        </time>
      </div>
    </div>
  </div>
</template>
<script>
import { PhoneIcon } from '@heroicons/vue/24/outline'
import { format } from 'timeago.js'

export default {
  components: {
    PhoneIcon
  },
  props: {
    call: {
      type: Object,
      required: true
    }
  },
  data() {
    return { timestamp: '' }
  },
  computed: {
    userName() {
      return this.$store.getters.user(this.call.relationships.user.data.id)?.attributes.display_name
    },
    answerable() {
      return this.call.attributes.status === 'pending' && !this.$store.state.ongoingCall
    },
    // TODO:@llb verbose logic here, consolidate theme conditionals
    iconBoxTheme() {
      return {
        'bg-green-300 text-white': this.call.attributes.status === 'pending',
        // 'bg-blue-300 text-white': this.call.attributes.status === 'in-progress',
        'bg-gray-300 text-gray-500': this.call.attributes.status === 'completed'
        // 'bg-red-300 text-red-500': this.call.attributes.status === 'missed'
      }
    },
    itemTheme() {
      return {
        'bg-green-50 hover:bg-green-100': this.call.attributes.status === 'pending',
        // 'bg-blue-50 hover:bg-blue-100': this.call.attributes.status === 'in-progress',
        'bg-white hover:bg-gray-50': this.call.attributes.status === 'completed'
        // 'bg-red-50 hover:bg-red-100': this.call.attributes.status === 'missed'
      }
    },
    itemOutline() {
      return {
        'ring-green-200': this.call.attributes.status === 'pending',
        'ring-gray-300': this.call.attributes.status === 'completed'
        // 'ring-red-200': this.call.attributes.status === 'missed'
      }
    }
  },
  mounted() {
    this.timestamp = format(this.call.attributes.created_at)

    // recalculate timeago every minute
    setInterval(() => {
      this.timestamp = format(this.call.attributes.created_at)
    }, 60000)
  },
  methods: {
    itemClick() {
      if (this.answerable) {
        this.$store.dispatch('setOngoingCall', {
          conversationUserPairId: this.$route.params.id
        })
      }
    }
  }
}
</script>
