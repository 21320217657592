import script from "./ReportsNeue.vue?vue&type=script&setup=true&lang=js"
export * from "./ReportsNeue.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import BasicSpinner from "@/components/BasicSpinner.vue";
import DescriptionHeader from "@/components/neue/DescriptionHeader.vue";
import EmptyState from "@/components/neue/EmptyState.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueTablist from "@/components/neue/NeueTablist.vue";
import NeueWell from "@/components/neue/NeueWell.vue";
import NewReportModal from "@/components/neue/NewReportModal.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import TablistHeader from "@/components/neue/TablistHeader.vue";
import TablistRow from "@/components/neue/TablistRow.vue";
script.components = Object.assign({}, { BasicSpinner, DescriptionHeader, EmptyState, NeueButton, NeueTablist, NeueWell, NewReportModal, SecondaryNav, SecondaryTop, TablistHeader, TablistRow }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { BasicSpinner, DescriptionHeader, EmptyState, NeueButton, NeueTablist, NeueWell, NewReportModal, SecondaryNav, SecondaryTop, TablistHeader, TablistRow }, script.__o.components);}