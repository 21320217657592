import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import { computed } from 'vue'

export default {
  __name: 'NeueButton',
  props: ['type', 'disabled'],
  setup(__props) {

const props = __props

const style = computed(
  () =>
    ({
      primary: 'border-transparent shadow-md shadow-inner text-white bg-gray-brand-700 hover:bg-gray-brand-900',
      secondary: 'border-gray-300 shadow-sm shadow-inner text-gray-700 bg-white hover:bg-gray-50',
      inactive: 'border-transparent text-brand-500 bg-brand-100 cursor-not-allowed',
      red: 'text-red-700 bg-red-100 border-red-300 hover:bg-red-300'
    })[props.type || 'primary']
)

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["transition-all inline-flex items-center rounded-md px-2 py-1 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 hover:transform hover:scale-105 border border-gray-400 shrink-0", style.value]),
    disabled: props.disabled ?? props.type === 'inactive'
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

}