import { resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { DatePicker } from 'v-calendar'

export default {
  __name: 'InlineDatePicker',
  setup(__props) {


return (_ctx, _cache) => {
  const _component_InputField = _resolveComponent("InputField")

  return (_openBlock(), _createBlock(_unref(DatePicker), { popover: { visibility: 'click' } }, {
    default: _withCtx(({ inputValue, inputEvents }) => [
      _createVNode(_component_InputField, _mergeProps({
        "model-value": inputValue,
        shrinkwrap: true
      }, _toHandlers(inputEvents)), null, 16, ["model-value"])
    ]),
    _: 1
  }))
}
}

}