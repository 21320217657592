import _ from 'lodash'

export function toSnakeCase(anything) {
  const thing = _.cloneDeep(anything)

  if (_.isEmpty(thing) || (!_.isObject(thing) && !_.isArray(thing))) {
    return thing
  }

  if (_.isArray(thing)) {
    const arr = thing
    return arr.map(el => toSnakeCase(el))
  }

  // thing can be only not empty object here
  const objWithMappedKeys = _.mapKeys(thing, (value, key) => _.snakeCase(key))
  const objWithMappedValues = _.mapValues(objWithMappedKeys, value => toSnakeCase(value))

  return objWithMappedValues
}

export function toCamelCase(anything) {
  const thing = _.cloneDeep(anything)

  if (_.isEmpty(thing) || (!_.isObject(thing) && !_.isArray(thing))) {
    return thing
  }

  if (_.isArray(thing)) {
    const arr = thing
    return arr.map(el => toCamelCase(el))
  }

  // thing can be only not empty object here
  const objWithMappedKeys = _.mapKeys(thing, (value, key) => _.camelCase(key))
  const objWithMappedValues = _.mapValues(objWithMappedKeys, value => toCamelCase(value))

  return objWithMappedValues
}

export function capatilizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
