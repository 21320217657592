import api from '@/store/api'

class CloudinaryService {
  files
  constructor() {}

  uploadImages(files) {
    this.files = files
    return Promise.all(files.map((x, i) => this.uploadFileToCloudinary(x, i)))
  }

  uploadFileToCloudinary(file, index) {
    return api
      .call('POST', 'attachments', null, { receive: false })
      .then(response => {
        const signature = response.data
        const form = new FormData()
        form.append('file', file)
        form.append('api_key', '544862598138348')
        form.append('cloud_name', 'hpwejnwbc')
        form.append('source', 'uw')
        form.append('folder', 'merchant-frontend')
        form.append('timestamp', signature.timestamp)
        form.append('signature', signature.signature)

        return fetch('https://api.cloudinary.com/v1_1/hpwejnwbc/image/upload', {
          method: 'POST',
          body: form
        })
      })
      .then(async response => {
        const retData = await response.json()
        retData.fileIndex = index
        return retData
      })
  }
}

export default CloudinaryService
