import script from "./AudienceNeue.vue?vue&type=script&setup=true&lang=js"
export * from "./AudienceNeue.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import AudiencesSidebar from "@/components/customers/AudiencesSidebar.vue";
import BasicSpinner from "@/components/BasicSpinner.vue";
import BottleRow from "@/components/neue/BottleRow.vue";
import DescriptionHeader from "@/components/neue/DescriptionHeader.vue";
import EmptyState from "@/components/neue/EmptyState.vue";
import FilterDropdown from "@/components/neue/FilterDropdown.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueLoading from "@/components/neue/NeueLoading.vue";
import NeuePagination from "@/components/neue/NeuePagination.vue";
import NeueTablist from "@/components/neue/NeueTablist.vue";
import NeueWell from "@/components/neue/NeueWell.vue";
import NewFilterDropdown from "@/components/neue/NewFilterDropdown.vue";
import NewReportModal from "@/components/neue/NewReportModal.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import SelectInput from "@/components/neue/SelectInput.vue";
import TablistHeader from "@/components/neue/TablistHeader.vue";
import TablistRow from "@/components/neue/TablistRow.vue";
script.components = Object.assign({}, { AudiencesSidebar, BasicSpinner, BottleRow, DescriptionHeader, EmptyState, FilterDropdown, NeueButton, NeueLoading, NeuePagination, NeueTablist, NeueWell, NewFilterDropdown, NewReportModal, SecondaryNav, SecondaryTop, SelectInput, TablistHeader, TablistRow }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { AudiencesSidebar, BasicSpinner, BottleRow, DescriptionHeader, EmptyState, FilterDropdown, NeueButton, NeueLoading, NeuePagination, NeueTablist, NeueWell, NewFilterDropdown, NewReportModal, SecondaryNav, SecondaryTop, SelectInput, TablistHeader, TablistRow }, script.__o.components);}