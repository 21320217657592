<template>
  <div class="col-span-2 md:col-span-1">
    <section-header heading="Tags" />

    <div class="mt-3">
      <span
        v-for="tag in tags"
        :key="tag.id"
        class="inline-flex items-center mr-1 px-2 py-0.5 text-brand-800 text-xs font-medium bg-brand-100 rounded-full"
      >
        {{ tag.tag_text_downcased }}
        <button
          type="button"
          class="inline-flex flex-shrink-0 ml-1.5 text-brand-500 focus:text-brand-700 focus:outline-none"
          aria-label="Remove small badge"
          @click="deleteTag(tag)"
        >
          <!-- <delete-tag-icon class="h-2 w-2" /> -->
          &#215;
        </button>
      </span>
    </div>

    <div class="mt-3">
      <label for="newtag" class="block text-gray-brand-700 text-sm font-medium">Add a tag</label>
      <div class="flex flex-wrap items-center justify-start mt-2">
        <input
          id="newtag"
          v-model.trim="newTag"
          class="form-input mr-3 px-2.5 py-2.5 text-xs leading-4 rounded-md transition duration-150 ease-in-out"
          @keyup.enter="addTag()"
        />
        <button class="btn-ghost -xs" @click="addTag()">Add</button>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/store/api'

export default {
  props: {
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tags: [],
      newTag: ''
    }
  },
  computed: {
    apiEndpoint() {
      return `conversation_user_pairs/${this.$props.conversationUserPairId}/tags`
    }
  },
  mounted() {
    api.call('GET', this.apiEndpoint).then(response => {
      response.data.data.forEach(tag => {
        this.tags.push(tag.attributes)
      })
    })
  },
  methods: {
    addTag() {
      const text = this.newTag
      this.newTag = ''
      api
        .call('POST', this.apiEndpoint, {
          tag: {
            tag_text: text
          }
        })
        .then(response => {
          this.tags.push(response.data.data.attributes)
        })
        .catch(error => {
          if (error.response.status === 422) {
            alert('Sorry, this tag is already taken.')
          }
          this.newTag = text
        })
    },
    deleteTag(tag) {
      api.call('DELETE', this.apiEndpoint + '/' + tag.id).then(() => {
        const index = this.tags.indexOf(tag)
        this.tags.splice(index, 1)
      })
    }
  }
}
</script>
