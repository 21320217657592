
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    props: {
      type: Object,
      required: false,
      default: () => {
        return {
          hasSuccess: false,
          hasWarning: false
        }
      }
    }
  }
})
