<template>
  <div class="relative space-y-1">
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="senderIsBottlebot" class="relative w-full group flex items-center space-x-2 flex-row">
      <div class="bg-gray-200 border-gray-500 px-4 py-2 w-full rounded-lg">
        <p
          v-if="message.attributes.body_decoded"
          v-linkified:options="{
            className: 'hover:underline font-semibold text-gray-brand-900 hover:text-gray-brand-800'
          }"
          class="text-gray-800"
          v-html="message.attributes.body_decoded"
        />
      </div>
    </div>
    <div v-else class="relative w-full max-w-xl" :class="senderIsMerchant && 'ml-auto'">
      <!-- timestamp element -->
      <div class="group flex items-center space-x-2" :class="senderIsMerchant ? 'flex-row-reverse flex' : 'flex-row '">
        <!-- Container holds linkified elements -->
        <div>
          <p
            v-if="message.attributes.body_decoded"
            v-linkified:options="{
              className:
                'hover:underline font-semibold ' +
                (senderIsMerchant
                  ? 'text-gray-brand-100 hover:text-gray-brand-300'
                  : 'text-gray-brand-900 hover:text-gray-brand-800')
            }"
            :class="computedClasses"
            v-html="message.attributes.body_decoded"
          />
        </div>
        <SparklesIcon v-if="message.attributes.originating_class" class="w-4 h-4 text-blue-900 flex-shrink-0" />
        <div
          class="opacity-0 group-hover:opacity-100 transition-all duration-200 font-manrope text-xs flex-shrink-0 px-2 py-0.5 rounded-full bg-gray-100 text-gray-700 mx-2"
          :class="{ 'text-right': senderIsMerchant, 'text-left': !senderIsMerchant }"
        >
          {{ absoluteTimestamp }}
        </div>
      </div>
    </div>

    <!-- eslint-enable vue/no-v-html -->

    <div v-if="attachments.length" :class="senderIsMerchant ? 'w-full flex justify-end' : 'w-full grid grid-cols-9'">
      <attachment v-for="attachment in attachments" :key="attachment.id" :attachment="attachment" />
    </div>
  </div>
</template>
<script>
import { BoltIcon, SparklesIcon } from '@heroicons/vue/24/outline'

import { mapRelationship, mapRelationships } from '@/store/mappers'

export default {
  components: {
    BoltIcon,
    SparklesIcon
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    senderIsMerchant() {
      return this.conversationItemUser.attributes.user_type === 'merchant'
    },
    senderIsBottlebot() {
      return this.senderIsMerchant && this.conversationItemUser.attributes.display_name === '@bottlebot'
    },
    attachments() {
      return mapRelationships(this.message.relationships.attachments.data)
    },
    conversationItemUser() {
      return mapRelationship(this.message.relationships.user.data)
    },
    undelivered() {
      const send = this.message.attributes.sms_send_status
      const delivery = this.message.attributes.sms_delivery_status
      return (
        delivery === 'undelivered' ||
        send === 'do_not_text' ||
        send === 'block_marketing_messages' ||
        send === 'not_allowed_to_send_texts'
      )
    },
    absoluteTimestamp() {
      return new Intl.DateTimeFormat('en-US', {
        timeStyle: 'short'
      }).format(new Date(this.message?.attributes.created_at))
    },

    // classes

    backgroundClass() {
      if (this.undelivered) {
        return 'bg-gray-600'
      }

      if (this.message.attributes.originating_class) {
        return 'bg-blue-500'
      }

      if (this.senderIsMerchant) {
        return 'bg-blue-600'
      }

      return 'bg-gray-200'
    },

    textClass() {
      return this.senderIsMerchant ? 'text-gray-100 ml-2' : 'text-gray-800'
    },

    roundedClass() {
      return this.message.attributes.originating_class ? 'rounded-md' : 'rounded-2xl'
    },

    borderClass() {
      if (this.undelivered) {
        return 'border border-red-400 transform translate-x-px -translate-y-px'
      }

      if (this.message.attributes.originating_class) {
        return 'border border-blue-900 transform translate-x-px -translate-y-px'
      }

      return ''
    },

    computedClasses() {
      return [
        this.backgroundClass,
        this.textClass,
        'text-nearly-base',
        'leading-5',
        'whitespace-pre-line',
        'w-fit',
        'py-2',
        'px-2.5',
        'right-0',
        this.roundedClass,
        'max-w-full',
        'overflow-hidden',
        this.borderClass
      ]
    }
  }
}
</script>
