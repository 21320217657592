<template>
  <div
    v-if="!isLoading && !bottles.length"
    class="bg-gray-50 shadow-none h-80 mt-4 flex flex-col items-center text-gray-500 content-center justify-center border-2 border-dashed sm:rounded-md"
  >
    <div class="text-xl font-medium text-brand-800">{{ emptyStateHeader }}</div>
    <div>{{ emptyStateSubheader }}</div>
  </div>
  <TableContainer :is-empty="isEmpty" :is-loading="isLoading" :has-error="hasError">
    <template v-if="bottles.length" #table>
      <table class="min-w-full shadow sm:rounded-lg bg-white">
        <thead class="border-b border-cool-gray-200 bg-cool-gray-50">
          <tr class="text-left">
            <th scope="col" class="pl-4 py-3 text-gray-700 text-xs font-medium tracking-wider leading-4 uppercase">
              Order
            </th>
            <th scope="col" class="px-2 py-3 text-gray-700 text-xs font-medium tracking-wider leading-4 uppercase">
              Details
            </th>
            <th scope="col" class="px-2 py-3 text-gray-700 text-xs font-medium tracking-wider leading-4 uppercase">
              Fulfillment
            </th>
            <th scope="col" class="px-2 py-3 text-gray-700 text-xs font-medium tracking-wider leading-4 uppercase">
              Cart
            </th>
            <th
              v-if="false"
              scope="col"
              class="pr-6 py-3 text-gray-700 text-xs font-medium tracking-wider leading-4 uppercase"
            ></th>
          </tr>
        </thead>
        <tbody class="divide-cool-gray-200 divide-y">
          <tr
            v-for="item in bottles.slice(local.page * pageSize, local.page * pageSize + pageSize)"
            :key="item.id"
            class="leading-none"
            :class="`${item.expanded ? '' : 'h-16'}`"
          >
            <!-- COLUMN: Order id, payment, cancellation, refund, skip status -->
            <td class="whitespace-no-wrap pl-4 py-2">
              <div class="text-gray-700">
                <!-- Main order ID -->
                <div class="flex items-center -ml-1 mb-1">
                  <!-- ORDER ID -->
                  <router-link
                    :to="`/order/${item.id}`"
                    class="mr-1 cursor-pointer whitespace-nowrap px-1 capitalize inline-flex text-md leading-5 font-semibold rounded-sm hover:underline"
                    :class="
                      getBottleCancellationStatus(item) !== 'cancelled' &&
                      paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id).classTheme ==
                        'success'
                        ? 'bg-green-100 text-green-900'
                        : {
                            'bg-red-100 text-red-800': getBottleCancellationStatus(item) === 'cancelled',
                            'bg-gray-100 text-gray-800': getBottleCancellationStatus(item) !== 'cancelled'
                          }
                    "
                  >
                    <span class="tracking-tight">{{ item.attributes?.display_id }}</span>
                    <!-- Add "Edit" language here that opens order view -->
                    <!-- Add an "Open link" icon here to open order in external window / open receipt -->
                  </router-link>
                </div>

                <!-- Cancellation status -->
                <div v-if="getBottleCancellationStatus(item) === 'cancelled'" class="flex items-center -ml-1 mb-1">
                  <!-- CANCELLATION STATUS -->
                  <div
                    class="whitespace-nowrap px-1 capitalize inline-flex text-sm leading-5 font-semibold rounded-md mr-1 bg-red-100 text-red-800"
                  >
                    <span class="manrope text-xxs font-medium uppercase tracking-wide">Cancelled</span>
                  </div>
                </div>

                <!-- Order payment status and other info and info -->
                <div
                  v-if="
                    item?.attributes?.refund_status !== 'fully_refunded' &&
                    item?.attributes?.refund_status !== 'partially_refunded'
                  "
                  class="flex items-center -ml-1 mb-1"
                >
                  <!-- PAYMENT STATUS -->
                  <div
                    v-if="
                      paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id).classTheme !=
                      'default'
                    "
                    class="whitespace-nowrap px-1 capitalize inline-flex text-sm leading-5 font-semibold rounded-md mr-1"
                    :class="{
                      'bg-gray-100 text-gray-700':
                        paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id)
                          .classTheme == 'default',
                      'bg-green-100 text-green-800':
                        paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id)
                          .classTheme == 'success',
                      'bg-red-100 text-red-800':
                        paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id)
                          .classTheme == 'error',
                      'bg-yellow-100 text-yellow-800':
                        paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id)
                          .classTheme == 'warning'
                    }"
                  >
                    <span
                      v-if="
                        getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.attributes?.reason ===
                          'stripe_payment' &&
                        getCheckoutObject(
                          getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.relationships?.checkout
                            ?.data?.id
                        )?.attributes?.bottle_total?.format
                      "
                      class="manrope text-xxs font-medium uppercase tracking-wide"
                    >
                      {{ paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id)?.text }}
                      {{
                        getCheckoutObject(
                          getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.relationships?.checkout
                            ?.data?.id
                        )?.attributes?.bottle_total?.format
                      }}
                    </span>
                    <span v-else class="manrope text-xxs font-medium uppercase tracking-wide">
                      {{ paymentStatus(item.attributes.status, item.relationships?.bottle_payment?.data?.id)?.text }}
                    </span>
                  </div>
                </div>

                <!-- Order charged and account credit used -->
                <div
                  v-if="getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.attributes"
                  class="flex items-center -ml-1 mb-1"
                >
                  <!-- PAYMENT STATUS -->
                  <div
                    class="whitespace-nowrap px-1 capitalize text-sm leading-5 font-semibold rounded-md mr-1 bg-gray-100 text-gray-700"
                  >
                    <span
                      v-if="
                        getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.attributes?.charged_amount
                          ?.format
                      "
                      class="manrope text-xxs font-medium uppercase tracking-wide"
                    >
                      {{
                        getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.attributes?.charged_amount
                          ?.format
                      }}&nbsp;Charged
                    </span>
                    <span
                      v-if="
                        getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.attributes
                          ?.account_credit_amount?.amount > 0
                      "
                      class="manrope text-xxs font-medium uppercase tracking-wide"
                    >
                      {{
                        getBottlePaymentObject(item.relationships.bottle_payment?.data?.id)?.attributes?.charged_amount
                          ?.format
                      }}&nbsp;Credit Used
                    </span>
                  </div>
                </div>

                <!-- Refund status -->
                <div
                  v-if="
                    item?.attributes?.refund_status == 'fully_refunded' ||
                    item?.attributes?.refund_status == 'partially_refunded'
                  "
                  class="flex items-center -ml-1 mb-1"
                >
                  <!-- REFUND STATUS -->
                  <div
                    class="whitespace-nowrap px-1 capitalize inline-flex text-sm leading-5 font-semibold rounded-md mr-1 bg-yellow-100 text-yellow-800"
                  >
                    <span
                      v-if="item.attributes.refund_status === 'partially_refunded'"
                      class="manrope text-xxs font-medium uppercase tracking-wide"
                    >
                      Refunded {{ refundAmount(item) }}
                    </span>
                    <span
                      v-if="item.attributes.refund_status === 'fully_refunded'"
                      class="manrope text-xxs font-medium uppercase tracking-wide"
                    >
                      Fully Refunded
                    </span>
                  </div>
                </div>

                <!-- Skipped info, if skipped -->
                <div v-if="item.attributes.status === 'skipped'" class="flex items-center -ml-1">
                  <!-- SKIPPED STATUS -->
                  <div
                    class="whitespace-nowrap px-1 capitalize inline-flex text-sm leading-5 font-semibold rounded-md mr-1 bg-gray-100 text-gray-800"
                  >
                    <span class="manrope text-xxs font-medium uppercase tracking-wide">Skipped</span>
                  </div>
                </div>
              </div>
            </td>

            <!-- COLUMN: Order details including buyer's name, production day and method, store, drop if applicable, and any errors that need to be addressed -->
            <td class="whitespace-no-wrap pl-4 py-2 pb-3">
              <!-- CUSTOMER NAME -->
              <router-link
                v-if="
                  item.relationships?.conversation_user_pair?.data &&
                  getCustomerObject(item.relationships.conversation_user_pair?.data?.id)
                "
                class="mt-1 leading-5 truncate max-w-xxs block text-sm cursor-pointer text-gray-800 hover:text-gray-900 hover:underline font-semibold tracking-tight"
                :to="'/profile/' + getCustomerObject(item.relationships.conversation_user_pair?.data?.id)?.id"
              >
                {{ getCustomerObject(item.relationships.conversation_user_pair?.data?.id)?.attributes?.display_name }}
              </router-link>

              <!-- paid date -->
              <div
                v-if="
                  item.relationships?.bottle_payment?.data && getPaidDate(item.relationships?.bottle_payment?.data?.id)
                "
                class="mt-1"
              >
                <span class="text-gray-500 text-xs leading-5 whitespace-nowrap tracking-tight font-semibold">
                  {{ getPaidDate(item.relationships.bottle_payment?.data?.id) }}
                  <span
                    v-if="
                      item.relationships?.bottle_payment?.data &&
                      getPaidTime(item.relationships?.bottle_payment?.data?.id)
                    "
                  >
                    at {{ getPaidTime(item.relationships.bottle_payment?.data?.id) }}
                  </span>
                </span>
                <div class="text-xxs font-manrope uppercase tracking-wider text-gray-500">Paid time</div>
              </div>

              <!-- membership tier and list, if applicable -->
              <div
                v-if="
                  item.relationships?.membership_tier?.data &&
                  getMembershipTier(item.relationships?.membership_tier?.data?.id)
                "
                class="mt-1"
              >
                <router-link
                  :to="
                    '/store/membership-tiers/' + getMembershipTier(item.relationships?.membership_tier?.data?.id)?.id
                  "
                  class="text-gray-500 text-xs leading-5 whitespace-nowrap font-semibold tracking-tight hover:underline"
                >
                  {{ getMembershipTier(item.relationships?.membership_tier?.data?.id)?.attributes?.tier_name }}
                </router-link>
                <div class="text-xxs font-manrope uppercase tracking-wider text-gray-500">Membership</div>
              </div>

              <!-- name of drop, if applicable -->
              <div
                v-if="
                  item.attributes?.bottle_cycle_details &&
                  getBottleCycle(item.attributes?.bottle_cycle_details?.bottle_cycle_id)
                "
                class="mt-1"
              >
                <router-link
                  :to="'/store/drops/' + getBottleCycle(item.attributes?.bottle_cycle_details?.bottle_cycle_id)?.id"
                  class="text-gray-500 text-xs leading-5 whitespace-nowrap font-semibold tracking-tight hover:underline"
                >
                  {{ getBottleCycle(item.attributes?.bottle_cycle_details?.bottle_cycle_id)?.attributes?.description }}
                </router-link>
                <div class="text-xxs font-manrope uppercase tracking-wider text-gray-500">Drop</div>
              </div>
            </td>

            <!-- COLUMN: Fulfillment info -->
            <td class="whitespace-no-wrap px-2 py-2">
              <div
                v-if="
                  item.relationships?.fulfillment_slot?.data?.id &&
                  getFulfilledDate(item.relationships?.fulfillment_slot?.data?.id)
                "
                class="mt-1"
              >
                <router-link
                  :to="'/lists/' + getFulfillmentSlot(item)?.relationships?.audience_for_date?.data?.id"
                  class="text-gray-500 text-xs leading-5 whitespace-nowrap font-semibold tracking-tight hover:underline"
                >
                  {{ getFulfilledDate(item.relationships.fulfillment_slot?.data?.id) }}
                </router-link>
                <div class="text-xxs font-manrope uppercase tracking-wider text-gray-500">Production Day</div>
              </div>

              <div
                v-if="
                  item.relationships?.fulfillment_slot?.data?.id &&
                  getFulfillmentMethod(item.relationships?.fulfillment_slot?.data?.id)
                "
                class="mt-1"
              >
                <router-link
                  :to="
                    '/store/fulfillment-methods/' +
                    getFulfillmentMethod(item.relationships.fulfillment_slot.data.id)?.id
                  "
                  class="text-gray-500 text-xs leading-5 whitespace-nowrap font-semibold tracking-tight hover:underline"
                >
                  {{ getFulfillmentMethod(item.relationships.fulfillment_slot?.data?.id)?.attributes?.name }}
                </router-link>
                <div class="text-xxs font-manrope uppercase tracking-wider text-gray-500">Method</div>
              </div>

              <button
                v-if="false"
                v-show="item.attributes.validations?.all_errors?.length"
                v-tippy="{
                  content: `${item.attributes.validations?.all_errors?.[0]}`,
                  placement: 'right'
                }"
                class="text-gray-500 hover:text-blue-500 ml-2"
              >
                <InformationCircleIcon class="w-4 h-4" />
              </button>
            </td>

            <!-- COLUMN: Cart and underlying cart items -->
            <td class="max-w-xxs px-2 py-2">
              <div class="bg-gray-100 rounded-lg pb-1 w-full overflow-hidden">
                <!-- Store info -->
                <router-link
                  v-if="item.relationships?.store?.data?.id && getStoreObject(item.relationships.store?.data?.id)"
                  class="w-full truncate block text-gray-500 text-xs leading-5 whitespace-nowrap font-semibold tracking-tight hover:underline whitespace-no-wrap bg-gray-200 px-2 py-1"
                  :to="'/store/stores/' + getStoreObject(item.relationships.store?.data?.id)?.id"
                >
                  {{ getStoreObject(item.relationships.store.data?.id)?.attributes?.store_name }}
                </router-link>

                <!-- Items in the cart -->
                <div
                  class="flex flex-col px-2 py-1 text-gray-500 text-xs leading-5 whitespace-nowrap font-semibold tracking-tight"
                >
                  <div v-for="cartItem in cartItemsForBottle(item, item.expanded)" :key="cartItem?.id">
                    <div class="leading-5 truncate">
                      <span class="font-semibold text-right">{{ cartItem.attributes.quantity }}</span>
                      <span class="font-medium ml-1">
                        {{ mapRelationship(cartItem.relationships.product.data).attributes.product_name }}
                      </span>
                    </div>
                  </div>
                  <button
                    v-if="cartItemsForBottle(item, item.expanded).length > 2"
                    class="w-max mt-1 justify-center text-gray-500 text-xs hover:underline focus:outline-none tracking-tighter font-bold"
                    @click="expandCollapseCartItemsForRow(item)"
                  >
                    {{ item.expanded ? 'Show fewer' : 'Show more items' }}
                  </button>
                </div>
              </div>
            </td>

            <!-- COLUMN: Actions to take -->
            <td v-if="showActions && item.attributes.actions" class="text-right pr-4 py-2">
              <DropdownButton
                :menu-items="getItemActionsMenu(item.attributes)"
                :item-id="parseFloat(item.id)"
                @on-actions-menu-click="handleActionsMenuClick"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-if="bottles.length" #tablePagination>
      <TablePagination
        v-if="pageSize < bottles.length"
        :items="bottles"
        :page-size="pageSize"
        :page="local.page"
        :initial-page="initialPage"
        :total-records="totalRecords || bottles.length"
        @on-page-change="handlePageChange"
      />
    </template>
  </TableContainer>
  <!-- Account credit modal -->
  <TheModal
    :is-open="local.modal.type === 'refundModal'"
    modal-title="Issue Refund"
    @close-modal="local.modal.type = ''"
  >
    <template #main>
      <Form v-slot="{ errors }" class="grid grid-cols-12 gap-4" @submit.prevent="saveRefundModal()">
        <div class="col-span-12">
          <FormLabel for="accountCredit" label="Refund amount" />
          <div class="flex rounded-md shadow-sm">
            <span
              class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
            >
              $
            </span>
            <Field
              id="accountCredit"
              v-model="local.modal.content.refundAmount"
              :rules="fieldIsRequired"
              type="text"
              name="accountCredit"
              inputmode="decimal"
              pattern="[0-9]*.?[0-9]*"
              class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            />
          </div>
          <InputError v-show="errors.accountCredit" />
          <div v-if="local.modal.content.onSubmitMessage" class="mt-4 text-red-600">
            <div class="text-sm font-medium">
              {{ local.modal.content.onSubmitMessage }}
            </div>
            <div v-if="local.modal.content.onSubmitLog" class="mt-1 text-sm">{{ local.modal.content.onSubmitLog }}</div>
          </div>
        </div>
      </Form>
    </template>
    <template #footer>
      <Button
        :is-primary="true"
        :is-loading="local.status.isLoading.refundModal"
        class="justify-center mr-3"
        @click="saveRefundModal()"
      >
        Save
      </Button>
      <button class="btn-ghost justify-center" type="button" @click="resetModalContent()">Cancel</button>
    </template>
  </TheModal>
</template>
<script>
import { ClipboardDocumentListIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import { Field, Form } from 'vee-validate'
import { defineComponent, ref } from 'vue'

import { serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'

export default defineComponent({
  components: {
    ClipboardDocumentListIcon,
    InformationCircleIcon,
    Field,
    Form
  },
  props: {
    bottles: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    isEmpty: {
      type: Boolean,
      required: true,
      default: false
    },
    hasError: {
      type: Boolean,
      required: true,
      default: false
    },
    emitPagination: {
      type: Boolean,
      required: false,
      default: false
    },
    pageSize: {
      type: Number,
      required: false,
      default: 25
    },
    onPageChange: {
      type: Function,
      required: false,
      default: null
    },
    totalRecords: {
      type: Number,
      required: false,
      default: null
    },
    initialPage: {
      type: Number,
      required: false,
      default: 0
    },
    emptyStateHeader: {
      type: String,
      required: false,
      default: 'No results found'
    },
    emptyStateSubheader: {
      type: String,
      required: false,
      default: "Try adjusting your search to find what you're looking for"
    },
    tab: {
      type: String,
      required: true,
      default: 'contacts'
    }
  },
  emits: ['on-page-change', 'on-action-executed'],
  setup(props, { emit }) {
    const local = ref({
      bottles: props.bottles.map(bottle => {
        return {
          ...bottle,
          expanded: false
        }
      }),
      page: 0,
      modal: {
        mode: '',
        type: '',
        content: {}
      },
      status: {
        isLoading: {
          refundModal: false
        }
      }
    })

    const getStoreObject = id => {
      return mapRelationship({ type: 'store', id: id })
    }

    const expandCollapseCartItemsForRow = bottle => {
      bottle.expanded = !bottle.expanded
    }

    const getCheckoutObject = id => {
      return mapRelationship({ type: 'checkout', id: id })
    }

    const getBottlePaymentObject = id => {
      return mapRelationship({ type: 'bottle_payment', id: id })
    }

    const getFulfilledDate = id => {
      const fulfillment = mapRelationship({ type: 'fulfillment_slot', id: id })
      if (!fulfillment) return null
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium'
      }).format(serverDayToDateObject(fulfillment.attributes.fulfillment_date))
    }

    const cartItemsForBottle = (bottle, expanded) => {
      const cart = mapRelationship(bottle.relationships.cart.data)
      if (expanded) {
        return mapRelationships(cart.relationships.cart_items.data)
      } else {
        return mapRelationships(cart?.relationships.cart_items.data).slice(0, 3)
      }
    }

    const getFulfillmentSlot = item => {
      return mapRelationship(item?.relationships?.fulfillment_slot?.data)
    }

    const getFulfillmentMethod = id => {
      const fulfillmentSlot = mapRelationship({ type: 'fulfillment_slot', id: id })
      const fulfillmentMethod = mapRelationship(fulfillmentSlot?.relationships.fulfillment_method.data)
      if (!fulfillmentMethod) return null
      return fulfillmentMethod
    }

    const getCustomerObject = id => {
      return mapRelationship({ type: 'conversation_user_pair', id: id })
    }

    const getPaidDate = id => {
      const payment = mapRelationship({ type: 'bottle_payment', id: id })
      if (!payment) return null
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium'
      }).format(Date.parse(payment.attributes.created_at))
    }

    const getPaidTime = id => {
      const payment = mapRelationship({ type: 'bottle_payment', id: id })
      if (!payment) return null
      return new Intl.DateTimeFormat([], {
        hour: 'numeric',
        minute: '2-digit'
      }).format(new Date(payment.attributes.created_at))
    }

    const getMembershipTier = id => {
      return mapRelationship({ type: 'membership_tier', id: id })
    }

    const getDistributionList = id => {
      return mapRelationship({ type: 'distribution_list', id: id })
    }

    const getBottleCycle = id => {
      return mapRelationship({ type: 'bottle_cycle', id: id })
    }

    const getBottleCancellationStatus = bottle => {
      return bottle?.attributes?.cancellation_status
    }

    const getItemActionsMenu = attributes => {
      let actionsMenu = {
        label: 'Actions',
        items: []
      }
      if (attributes.link)
        actionsMenu.items.push({
          name: 'View order',
          handler: 'handleViewOrder'
        })
      if (attributes.actions.refund)
        actionsMenu.items.push({
          name: 'Refund order',
          handler: 'handleRefund'
        })
      if (attributes.actions.mark_as_paid)
        actionsMenu.items.push({
          name: 'Mark as paid',
          handler: 'handleMarkAsPaid'
        })
      if (attributes.actions.mark_as_unpaid)
        actionsMenu.items.push({
          name: 'Mark as unpaid',
          handler: 'handleMarkAsUnpaid'
        })
      if (attributes.actions.cancel_order)
        actionsMenu.items.push({
          name: 'Cancel order',
          handler: 'handlerCancelOrder'
        })
      if (attributes.actions.un_cancel_order)
        actionsMenu.items.push({
          name: 'Reactivate order',
          handler: 'handlerReactivateOrder'
        })
      return actionsMenu
    }

    // POST: mark as paid
    const handleMarkAsPaid = id => {
      api.call('POST', `bottles/${id}/pay`, {}, { receive: false }).then(() => {
        // Update status
        props.bottles.find(x => parseFloat(x.id) === id).attributes.status = 'paid'
      })
    }

    // POST: mark as unpaid
    const handleMarkAsUnpaid = id => {
      api.call('POST', `bottles/${id}/unpay`, {}, { receive: false }).then(() => {
        // Update status
        props.bottles.find(x => parseFloat(x.id) === id).attributes.status = 'pending'
      })
    }

    // POST: cancel order
    const handlerCancelOrder = id => {
      api
        .call('PUT', `bottles/${id}`, {
          bottle: {
            cancellation_status: 'cancelled'
          }
        })
        .then(() => {
          emit('on-action-executed')
        })
    }

    // POST: reactivate order
    const handlerReactivateOrder = id => {
      api
        .call('PUT', `bottles/${id}`, {
          bottle: {
            cancellation_status: 'active'
          }
        })
        .then(() => {
          emit('on-action-executed')
        })
    }

    // POST: apply refund
    const saveRefundModal = () => {
      const id = local.value.modal.content.bottleId
      const refundAmountCents = local.value.modal.content.refundAmount * 100
      local.value.status.isLoading.refundModal = true

      api
        .call('POST', `bottles/${id}/refunds`, {
          refund: {
            amount: refundAmountCents
          }
        })
        .then(() => {
          local.value.status.isLoading.refundModal = false
          resetModalContent()
        })
        .catch(error => {
          local.value.status.isLoading.refundModal = false
          local.value.modal.content.onSubmitMessage = 'Something went wrong!'
          local.value.modal.content.onSubmitLog = error
        })
    }

    const handleRefund = id => {
      local.value.modal.type = 'refundModal'
      local.value.modal.mode = 'add'
      local.value.modal.content = {
        bottleId: id,
        refundAmount: null
      }
    }

    const refundAmount = item => {
      if (item.attributes.refund_status !== 'not_refunded') {
        return ` - ${getCheckoutObject(item.relationships.checkout?.data?.id)?.attributes?.refunded_amount?.format}`
      }
    }

    const handleViewOrder = id => {
      const bottleLink = props.bottles.find(x => parseFloat(x.id) === id).attributes.link ?? null
      if (bottleLink) window.open(bottleLink, '_blank')
      return
    }

    const handleActionsMenuClick = (action, id) => {
      switch (action) {
        case 'handleMarkAsPaid':
          return handleMarkAsPaid(id)
        case 'handleMarkAsUnpaid':
          return handleMarkAsUnpaid(id)
        case 'handleRefund':
          return handleRefund(id)
        case 'handleViewOrder':
          return handleViewOrder(id)
        case 'handlerCancelOrder':
          return handlerCancelOrder(id)
        case 'handlerReactivateOrder':
          return handlerReactivateOrder(id)
      }
    }

    const handlePageChange = p => {
      if (props.emitPagination) emit('on-page-change', p)
      else local.value.page = p
    }

    const resetModalContent = () => {
      local.value.modal.type = ''
      local.value.modal.mode = ''
      local.value.modal.content = {}
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    const paymentStatus = (paymentStatus, bottlePaymentId) => {
      // return paymentStatus, bottlePaymentId
      if (bottlePaymentId) {
        const bottlePayment = getBottlePaymentObject(bottlePaymentId)
        const orderMarkedAsPaid =
          bottlePayment?.attributes?.reason == 'mark_as_paid' || bottlePayment?.attributes?.reason == 'less_than_50c'
        if (orderMarkedAsPaid && paymentStatus == 'paid')
          return {
            text: 'Marked Paid',
            classTheme: 'success'
          }
      }
      if (paymentStatus == 'paid')
        return {
          text: 'Paid',
          classTheme: 'success'
        }
      else if (paymentStatus == 'expired')
        return {
          text: 'Expired',
          classTheme: 'error'
        }
      else if (paymentStatus == 'benign' || paymentStatus == 'skipped')
        return {
          text: paymentStatus.replace('-', ' '),
          classTheme: 'default'
        }
      else
        return {
          text: paymentStatus.replace('-', ' '),
          classTheme: 'warning'
        }
    }

    return {
      local,
      getStoreObject,
      getCheckoutObject,
      getBottlePaymentObject,
      getFulfilledDate,
      getFulfillmentMethod,
      getFulfillmentSlot,
      getCustomerObject,
      getPaidDate,
      refundAmount,
      getPaidTime,
      getItemActionsMenu,
      getMembershipTier,
      getDistributionList,
      getBottleCycle,
      handleActionsMenuClick,
      saveRefundModal,
      resetModalContent,
      fieldIsRequired,
      handlePageChange,
      paymentStatus,
      handlerCancelOrder,
      handlerReactivateOrder,
      getBottleCancellationStatus,
      cartItemsForBottle,
      expandCollapseCartItemsForRow,
      mapRelationship,
      serverDayToDateObject
    }
  }
})
</script>
