
export default {
  props: {
    isSkeleton: {
      type: Boolean,
      required: false,
      default: false
    },
    skeletonRows: {
      type: Number,
      required: false,
      default: 3
    }
  }
}
