import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Cookies from 'js-cookie'
import { onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

import OmniSearch from '@/components/OmniSearch'


export default {
  __name: 'App',
  setup(__props) {

const store = useStore()
const channel = ref(null)

// TODO(alicja): centralize init stuff in some other way
let pusherInitialized = false
window.pusherInit = () => {
  if (pusherInitialized || !Cookies.get('auth_token')) return
  if (!store.state.pusher) {
    store.commit(
      'SET_PUSHER',
      new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        authEndpoint: process.env.VUE_APP_API_SERVER + 'pusher/auth'
      })
    )
  }
  channel.value = store.state.pusher.subscribe(store.getters.loggedInUserId + '-merchant-channel')
  channel.value.bind('data-download', data => {
    store.commit('RECEIVE_OBJECT', data)
  })
  pusherInitialized = true
}

onMounted(() => {
  if (!Cookies.get('auth_token')) return
  if (!store.state.loggedInUser.attributes) store.dispatch('getLoggedInUser')
  pusherInit()
})

onUnmounted(() => {
  store.state.pusher.unsubscribe(store.getters.loggedInUserId + '-merchant-channel')
})

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_unref(OmniSearch))
  ], 64))
}
}

}