
import api from '@/store/api'

export default {
  data() {
    return {
      name: '',
      description: '',
      status: '',
      price: '',
      priceFormat: '',
      value: '',
      valueFormat: ''
    }
  },
  mounted() {
    this.getGiftcards()
  },
  methods: {
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    getGiftcards() {
      api.call('GET', 'gift_cards/' + this.$route.params.id).then(response => {
        const attrs = response.data.data.attributes
        this.name = attrs.gift_card_name
        this.description = attrs.description
        this.status = attrs.status
        this.price = (attrs.price_amount.cents / 100).toFixed(2)
        this.priceFormat = attrs.price_amount.format
        this.value = (attrs.value_amount.cents / 100).toFixed(2)
        this.valueFormat = attrs.value_amount.format
      })
    },
    del() {
      api.call('DELETE', 'gift_cards/' + this.$route.params.id).then(this.$router.back)
    },
    handleSubmit() {
      api
        .call('PUT', 'gift_cards/' + this.$route.params.id, {
          gift_card: {
            gift_card_name: this.name,
            description: this.description,
            price_amount_cents: Math.round(this.price * 100),
            value_amount_cents: Math.round(this.value * 100)
          }
        })
        .then(() => this.getGiftcards())
      // TODO: replace with toast
      this.$router.push('/store/gift-cards')
    }
  }
}
