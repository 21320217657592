<template>
  <SignupMainContainer :title="'Try Bottle for free'" :image-position="'left'" @form-submitted="formSubmitted">
    <template #stepper>
      <SignupHeaderComponent :total-progress="totalProgress()" :steps="steps" :current-step="0"></SignupHeaderComponent>
    </template>
    <template #body>
      <h2 class="text-gray-800 text-xl tracking-tighter">Let's get your account set up. What's your email?</h2>
      <div>
        <div class="mt-1 rounded-md">
          <input
            id="email"
            v-model="data.email"
            type="email"
            placeholder="michael@bluthcompany.com"
            required=""
            autocomplete="email"
            class="placeholder-gray-400 block px-3 py-3.5 rounded-lg w-full focus:border-gray-800 border-2 border-gray-200 focus:ring-0 appearance-none transition duration-150 ease-in-out text-sm leading-5 focus:outline-none font-semibold tracking-wide"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <button
        type="button"
        class="text-sm inline-flex items-center justify-center border-gray-800 border-2 text-md hover:shadow-lg transition-all rounded-lg px-3 md:px-8 py-3.5 leading-tight bg-white text-gray-800 tracking-tighter"
        @click="loginClicked"
      >
        <span class="hidden md:block">
          Already have an account?
          <span class="font-bold">Log in</span>
        </span>
        <span class="block md:hidden">
          Have an account?
          <span class="font-bold">Log in</span>
        </span>
      </button>
      <button
        type="submit"
        class="ml-2 inline-flex items-center border-gray-800 border-2 font-bold text-md hover:shadow-lg transition-all rounded-full px-6 py-3.5 leading-tight bg-yellow-200 text-gray-800"
      >
        <span class="ml-auto transform -translate-y-px mr-4">Next</span>
        <span class="mr-auto">
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="13" viewBox="0 0 34 13">
            <path
              d="M3.11,6.82A20,20,0,0,1,1.2,6a.46.46,0,0,0-.67.15A2.32,2.32,0,0,0,0,7.25C0,7.64,0,7.84.3,8c.59.25,1.18.52,1.78.72"
              transform="translate(0 0)"
            ></path>
            <path
              d="M1.83,8.58A23.56,23.56,0,0,0,7.36,10a36.41,36.41,0,0,0,8.79,0A53.55,53.55,0,0,0,29.69,6.72l.19-.07,0,0c-.33.4-.65.8-1,1.19-.72.87-1.45,1.73-2.16,2.61a1.75,1.75,0,0,0,0,2.28.68.68,0,0,0,.52.22.73.73,0,0,0,.5-.26c.67-.78,1.33-1.58,2-2.39l3.65-4.41A2.13,2.13,0,0,0,34,4.5,1.24,1.24,0,0,0,33.91,4a.87.87,0,0,0-.28-.36,1.55,1.55,0,0,0-.32-.2L26.59.11c-.65-.31-1.25.05-1.54.92a1.22,1.22,0,0,0,.4,1.5c.95.49,1.91,1,2.86,1.42l1.78.89-.19.08A55.6,55.6,0,0,1,17.44,8a36.08,36.08,0,0,1-8.87.12A21.78,21.78,0,0,1,2.82,6.7c-.3-.12-.73-.1-1-.25"
              fill="#000000"
              transform="translate(0 0)"
            ></path>
          </svg>
        </span>
      </button>
    </template>
  </SignupMainContainer>
</template>
<script>
const steps = [
  { url: '/sign-up' },
  { url: '/password' },
  { url: '/business-name' },
  { url: '/business-type' },
  { url: '/referral' },
  { url: '/area-code' }
]

import Cookies from 'js-cookie'
import { computed, defineComponent, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'

import store from '@/store'
import api from '@/store/api'
import { firstStoreObjectOfType, mapRelationships } from '@/store/mappers'

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      email: ''
    })

    const onboardingAccount = computed(() => firstStoreObjectOfType('onboarding_account'))
    const onboardingQuestions = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_questions.data)
    )
    const onboardingAnswers = computed(() =>
      mapRelationships(onboardingAccount.value?.relationships.onboarding_answers.data)
    )
    const emailQuestion = computed(() =>
      onboardingQuestions.value.find(obj => obj.attributes.target_column === 'email')
    )
    const emailAnswer = computed(() =>
      onboardingAnswers.value.find(obj => obj.relationships.onboarding_question.data.id === emailQuestion.value.id)
    )
    watch(onboardingAccount, (newValue, oldValue) => {
      data.email = emailAnswer.value?.attributes.answer || data.email
    })

    const formSubmitted = () => {
      if (onboardingToken()) {
        submitAnswer(emailQuestion.value.id)
      } else {
        getAuthToken()
      }
    }

    const getAuthToken = () => {
      api.call('POST', 'onboarding/tokens').then(response => {
        saveTokenToLocalStorage(response.data.token)
        getQuestionsAndSubmitAnswer()
      })
    }

    const getQuestionsAndSubmitAnswer = () => {
      api
        .call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
          authToken: Cookies.get('onboarding_auth_token')
        })
        .then(response => {
          const emailQuestion = response.data.included.filter(
            question => question.attributes.target_column === 'email'
          )[0]
          submitAnswer(emailQuestion.id)
        })
    }

    const getQuestions = () => {
      api.call('GET', 'onboarding?included=onboarding_questions,onboarding_answers', null, {
        authToken: Cookies.get('onboarding_auth_token')
      })
    }

    const identifyInHubspot = () => {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: data.email,
          onboarding_email: data.email
        }
      ])
    }

    const submitAnswer = id => {
      fbq('trackCustom', 'OnboardingFlowAnswer', { question: 'Email', answer: data.email })
      api
        .call(
          'POST',
          'onboarding?included=onboarding_answers',
          {
            answer: {
              onboarding_answers_attributes: [
                {
                  answer: data.email,
                  onboarding_question_id: emailQuestion.value.id
                }
              ]
            }
          },
          { authToken: Cookies.get('onboarding_auth_token') }
        )
        .then(response => {
          const values = savedValues() || {}
          values['nextPage'] = 1
          identifyInHubspot()
          store.dispatch('setSignUpPropertyValues', values).then(() => {
            router.push('/password')
          })
        })
    }

    const saveTokenToLocalStorage = token => {
      Cookies.set('onboarding_auth_token', token, { expires: 6 * 31 })
    }

    const loginClicked = () => {
      router.push('/login')
    }

    const savedValues = () => {
      return JSON.parse(localStorage.getItem('signupPropertyValues'))
    }

    const onboardingToken = () => {
      return Cookies.get('onboarding_auth_token')
    }

    const populateAnswer = () => {
      if (onboardingToken()) {
        getQuestions()
      }
    }

    const totalProgress = () => {
      return onboardingAnswers.value.length
    }

    populateAnswer()

    return {
      formSubmitted,
      data,
      getAuthToken,
      savedValues,
      loginClicked,
      steps,
      totalProgress,
      saveTokenToLocalStorage,
      getQuestionsAndSubmitAnswer,
      populateAnswer,
      getQuestions,
      onboardingToken,
      identifyInHubspot
    }
  }
})
</script>
