import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "mt-1" }
const _hoisted_3 = ["id", "value", "placeholder", "aria-describedby"]
const _hoisted_4 = ["id"]

import { ulid } from 'ulid'

export default {
  __name: 'InputArea',
  props: ['modelValue', 'name', 'description', 'placeholder'],
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const id = ulid()

const props = __props
const emit = __emit

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (props.name)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _unref(id),
          class: "block text-sm font-medium text-gray-700 mb-1"
        }, _toDisplayString(props.name), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("textarea", {
        id: _unref(id),
        rows: "4",
        class: "block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm placeholder:text-gray-500",
        value: props.modelValue,
        placeholder: props.placeholder,
        "aria-describedby": _unref(id) + '-description',
        onInput: _cache[0] || (_cache[0] = e => emit('update:modelValue', e.target.value))
      }, null, 40, _hoisted_3)
    ]),
    (props.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          id: _unref(id) + '-description',
          class: "mt-2 text-sm text-gray-500"
        }, _toDisplayString(props.description), 9, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

}