import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sm:flex sm:items-center"
}
const _hoisted_2 = { class: "sm:flex-auto" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_5 = { class: "mt-4 sm:mt-0 sm:ml-16 sm:flex-none" }
const _hoisted_6 = { class: "-my-2 -mx-4 overflow-x-auto sm:-mx-6" }
const _hoisted_7 = { class: "inline-block min-w-full py-2 align-middle sm:px-6" }
const _hoisted_8 = { class: "overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg shadow" }
const _hoisted_9 = { class: "min-w-full divide-y divide-gray-300" }
const _hoisted_10 = { class: "bg-gray-50" }
const _hoisted_11 = { class: "divide-y divide-gray-200 bg-white" }


export default {
  __name: 'NeueTable',
  props: ['name', 'description'],
  setup(__props) {

const props = __props

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.name || props.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(props.name), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(props.description), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "aside")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col", { 'mt-6': props.name && !props.description, 'mt-8': props.name && props.description }])
    }, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("table", _hoisted_9, [
              _createElementVNode("thead", _hoisted_10, [
                _createElementVNode("tr", null, [
                  _renderSlot(_ctx.$slots, "head")
                ])
              ]),
              _createElementVNode("tbody", _hoisted_11, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ])
        ])
      ])
    ], 2)
  ], 64))
}
}

}