
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    initialPage: {
      type: Number,
      required: false,
      default: 0
    },
    pageSize: {
      type: Number,
      required: false,
      default: 5
    },
    hasTransparentBg: {
      type: Boolean,
      required: false,
      default: true
    },
    totalRecords: {
      type: Number,
      required: false,
      default: 0
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    page: {
      type: Number,
      required: false,
      default: 0
    },
    datePaginated: {
      type: Boolean,
      required: false,
      default: false
    },
    startDate: {
      type: String,
      required: false,
      default: ''
    },
    endDate: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['onPageChange'],
  computed: {
    nextPageAvailable() {
      if (this.datePaginated) {
        return true
      } else {
        return (this.page + 1) * this.pageSize < this.totalSize
      }
    },
    prevPageAvailable() {
      if (this.datePaginated) {
        return true
      } else {
        return this.page !== 0
      }
    },
    totalSize() {
      return this.totalRecords ? this.totalRecords : this.items.length
    }
  },
  methods: {
    nextPage() {
      if (!this.nextPageAvailable) return
      this.$emit('onPageChange', this.page + 1)
    },
    prevPage() {
      if (!this.prevPageAvailable) return
      this.$emit('onPageChange', this.page - 1)
    },
    showingFrom() {
      return this.page * this.pageSize + 1
    },
    showingTo() {}
  }
}
