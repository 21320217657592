
import { MenuItem } from '@headlessui/vue'
import { LockClosedIcon } from '@heroicons/vue/20/solid'
import { ClipboardDocumentCheckIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import _ from 'lodash'
import { DatePicker } from 'v-calendar'
import { computed, onMounted, reactive, ref } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { dateObjectToServerDay, serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'
import SellSidebar from '@/view/store/SellSidebar'

import BannerCard from '../../components/BannerCard.vue'
import ExplanationBox from '../../components/ExplanationBox.vue'

export default {
  components: {
    PencilIcon,
    XMarkIcon,
    CheckIcon,
    MenuItem,
    BannerCard,
    InformationCircleIcon,
    ClipboardDocumentCheckIcon,
    DatePicker,
    LockClosedIcon,
    MenuItem,
    ExplanationBox,
    SellSidebar
  },
  setup() {
    const router = useRouter()
    const vStore = useStore()

    onBeforeRouteLeave(() => {
      if (drop.hasUnsavedChanges) {
        drop.leaveModal = true
        return false
      } else return true
    })

    const drop = reactive({
      id: router.currentRoute._rawValue.params.id,
      description: '',
      loading: true,
      hasUnsavedChanges: false,
      leaveModal: false
    })

    const modal = reactive({
      visible: false,
      date: null,
      message: null,
      time: true,
      interval_type: null,
      through_interval_type: null,
      interval_number: null,
      through_interval_number: null,
      has_duplicated: false
    })

    const vDrop = computed(() => vStore.state.objects.bottle_cycle?.[drop.id])

    const storeError = computed(() => {
      if (vDrop.value.attributes.bottle_cycle_errors.no_store_error) {
        return router.currentRoute._rawValue.hash === '#new'
          ? vDrop.value.attributes.bottle_cycle_errors.no_store_error.error_friendly
          : vDrop.value.attributes.bottle_cycle_errors.no_store_error.error
      }
    })

    const groupsError = computed(() => {
      if (vDrop.value.attributes.bottle_cycle_errors.no_group_error) {
        return router.currentRoute._rawValue.hash === '#new'
          ? vDrop.value.attributes.bottle_cycle_errors.no_group_error.error_friendly
          : vDrop.value.attributes.bottle_cycle_errors.no_group_error.error
      }
    })

    const fulfillmentAvailabilityDaysError = computed(() => {
      if (vDrop.value.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error) {
        return router.currentRoute._rawValue.hash === '#new'
          ? vDrop.value.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error.error_friendly
          : vDrop.value.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error.error
      }
    })

    const errorClasses = computed(() => {
      return router.currentRoute._rawValue.hash === '#new' ? 'bg-yellow-100 text-yellow-600' : 'bg-red-100 text-red-600'
    })

    const vAllStores = computed(() => Object.values(vStore.state.objects.store || {}))

    const vSelectedStore = computed(
      () => vDrop.value.relationships.store.data && mapRelationship(vDrop.value.relationships.store.data)
    )

    const vAudiences = computed(() => Object.values(vStore.state.objects.audience || {}))

    const vAudiencePairs = computed(() => mapRelationships(vDrop.value.relationships.bottle_cycle_audience_pairs.data))

    const vSelectedAudiences = computed(() =>
      vAudiencePairs.value.map(x => mapRelationship({ type: 'audience', id: x.attributes.audience_id })).filter(x => x)
    )

    const readableAudiencesNames = computed(() => {
      const audiencesNames = vSelectedAudiences.value.map(audience => {
        return audience.attributes.audience_name
      })
      if (!audiencesNames.length) {
        return ''
      } else if (audiencesNames.length === 1) {
        return `${audiencesNames[0]} group`
      } else {
        return `${customJoin(audiencesNames, ', ', ' and ')} groups`
      }
    })
    const readableRemindersTimes = computed(() => {
      const reminderTimes = vReminders.value.map(reminder => {
        return format(vScheduler(reminder))
      })
      if (!reminderTimes.length) {
        return ''
      } else if (reminderTimes.length === 1) {
        return `${reminderTimes[0]}`
      } else {
        return `${customJoin(reminderTimes, ', ', ' and ')}`
      }
    })
    const readablePossibleFulfillmentDaysText = computed(() => {
      const possibleFulfillmentDaysText = vDays.value.map(day => {
        return formatDay(day.attributes.date)
      })
      if (!possibleFulfillmentDaysText.length) {
        return ''
      } else if (possibleFulfillmentDaysText.length === 1) {
        return `${possibleFulfillmentDaysText[0]}`
      } else {
        return `${customJoin(possibleFulfillmentDaysText, ', ', ' and ')}`
      }
    })
    const customJoin = (arr, s1, s2) => {
      return arr
        .slice(0, -1)
        .join(s1)
        .concat(arr.length > 1 ? s2 : '', arr.slice(-1))
    }
    const displayReminderAutomationMessage = computed(() => {
      return false
      // return (
      //   !!readableAudiencesNames.value && !!readableRemindersTimes.value && !!readablePossibleFulfillmentDaysText.value
      // )
    })
    const displayAutoChargeAutomationMessage = computed(() => {
      // return !!readableAudiencesNames.value && !!vAutocharge.value && !!readablePossibleFulfillmentDaysText.value
      return false
    })
    const displayAutoFillAutoChargeAutomationMessage = computed(() => {
      // return (
      //   !!readableAudiencesNames.value && !!isAutofillAutocharge.value && !!readablePossibleFulfillmentDaysText.value
      // )
      return false
    })
    const displayAutoFillReminderAutomationMessage = computed(() => {
      // return (
      //   !!readableAudiencesNames.value && !!isAutofillReminders.value && !!readablePossibleFulfillmentDaysText.value
      // )
      return false
    })
    const reminderAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${readableRemindersTimes.value} will receive this reminder unless the customer has already skipped or already paid you for an order spanning ${readablePossibleFulfillmentDaysText.value}.`
    })
    const autoChargeAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${format(
        vScheduler(vAutocharge.value)
      )} will be automatically charged unless the customer has already skipped or already paid you for an order spanning ${
        readablePossibleFulfillmentDaysText.value
      }.`
    })
    const autoFillAutoChargeAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${format(
        vScheduler(vAutocharge.value)
      )} will have their orders automatically filled before auto charging unless the customer has already skipped or already paid you for an order spanning ${
        readablePossibleFulfillmentDaysText.value
      }.`
    })
    const autoFillReminderAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${readableRemindersTimes.value} will have their orders automatically filled before reminders are sent out unless the customer has already skipped or already paid you for an order spanning ${readablePossibleFulfillmentDaysText.value}.`
    })

    const vAudienceQuery = ref('')
    const vAudienceResults = reactive([])

    const vAudienceDetail = audience => mapRelationship(audience.relationships.audience_detail.data)

    const vAudienceFilters = audience =>
      mapRelationships(audience.relationships.filter_groups.data).flatMap(x =>
        mapRelationships(x.relationships.filters.data)
      )

    const vFilterString = filter =>
      mapRelationships(filter.relationships.filter_field_values.data).reduce(
        (str, x) => str.replace(`<FilterFieldValue${x.attributes.predicate_position}>`, mappedFieldValue(filter, x)),
        filter.attributes.predicate
      )

    const mappedFieldValue = (filter, value) => {
      const fieldType = filter.attributes.field_types[value.attributes.predicate_position]
      if (fieldType.data_type === 'dropdown') {
        const valueMap = Object.fromEntries(fieldType.options.map(y => [y[1], y[0]]))
        return valueMap[value.attributes.value]
      }
      return value.attributes.value
    }

    const vScheduler = object => {
      if (object) {
        return (
          object.relationships &&
          new Date(mapRelationship(object.relationships.automation_scheduler.data).attributes.datetime)
        )
      }
    }

    const vTemplate = reminder => mapRelationship(reminder.relationships.message_template.data)

    const vMessage = reminder => vTemplate(reminder).attributes.body

    const vImages = reminder => mapRelationships(vTemplate(reminder).relationships.attachments.data)

    const vImage = reminder => vImages(reminder)[0]?.attributes

    const vAutofill = computed(() => {
      const rel = vDrop.value.relationships.bottle_autofill_automation.data
      if (rel) return mapRelationship(rel)
    })

    const vAutofillFields = computed(() =>
      vAutofill.value ? mapRelationships(vAutofill.value.relationships.automation_fields.data) : []
    )

    const vAutofillType = computed(
      () => vAutofillFields.value.find(x => x.attributes.key === 'type')?.attributes.text_value
    )

    const isAutofillReminders = computed(() =>
      vAutofillFields.value.some(x => x.attributes.key === 'run_before' && x.attributes.text_value === 'reminders')
    )

    const isAutofillAutocharge = computed(() =>
      vAutofillFields.value.some(x => x.attributes.key === 'run_before' && x.attributes.text_value === 'autocharge')
    )

    const vAutocharge = computed(() => {
      const rel = vDrop.value.relationships.bottle_autocharge_automation.data
      if (rel) return mapRelationship(rel)
    })

    const vDuplicator = computed(() => {
      const duplicatorData = vDrop.value.relationships.duplicator.data
      if (duplicatorData) return mapRelationship(vDrop.value.relationships.duplicator.data)
    })

    const vDuplicatorOptions = computed(() => [
      ['weeks', 'Week(s)'],
      ['days', 'Day(s)'],
      ['minutes', 'Minute(s)'],
      ['months', 'Month(s)'],
      ['years', 'Year(s)']
    ])

    const vReminders = computed(() => mapRelationships(vDrop.value.relationships.bottle_reminder_automations.data))

    const vDays = computed(() => mapRelationships(vDrop.value.relationships.fulfillment_availabilities.data))

    const newReminder = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_reminder_automations_attributes: [
            {
              automation_scheduler_attributes: { datetime: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString() },
              message_template_attributes: { body: 'Reminder text goes here.' }
            }
          ]
        }
      })
    }

    function hasDuplicate() {
      if (vDuplicator.value) {
        return vDuplicator.value.attributes.has_duplicated
      }
      return false
    }

    function duplicationText() {
      if (this.hasDuplicate()) {
        return 'Every ~1 hour, the system checks to see if it should make a copy of this drop.'
      } else {
        return "Every ~1 hour, the system checks to see if it should make a copy of this drop. Currently it's pending to duplicate."
      }
    }

    const newDay = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          fulfillment_availabilities_attributes: [
            {
              date: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString()
            }
          ]
        }
      })
    }

    const deleteReminder = reminder => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_reminder_automations_attributes: [
            {
              id: reminder.id,
              _destroy: true
            }
          ]
        }
      })
    }

    const deleteDay = day => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          fulfillment_availabilities_attributes: [
            {
              id: day.id,
              _destroy: true
            }
          ]
        }
      })
    }

    const deleteAutofill = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_autofill_automation_attributes: {
            _destroy: true
          }
        }
      })
    }

    const deleteDuplicator = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          duplicator_attributes: {
            id: vDuplicator.value.attributes.id,
            _destroy: true
          }
        }
      })
    }

    const deleteAutocharge = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_autocharge_automation_attributes: {
            _destroy: true
          }
        }
      })
    }

    const saveDrop = () => {
      api
        .call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            description: drop.description
          }
        })
        .then(() => {
          drop.hasUnsavedChanges = false
        })
    }

    const deleteDrop = () => {
      api.call('DELETE', `bottle_cycles/${drop.id}`).then(router.back)
    }

    const selectStore = store => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          store_id: store.id
          // store_attributes: { store_id: store.id }
        }
      })
    }

    const setPreference = pref => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          store_preference: pref
        }
      })
    }

    const setDuplicatorProperty = (property, option) => {
      modal[property] = option
    }

    function showDuplicator() {
      return vDays.value.length > 0 ? true : false
    }

    function isOriginalDuplication(item) {
      if (item) {
        return item.attributes.display_description !== 'Duplicated'
      }
      return false
    }

    function duplicatorDescription() {
      if (vDuplicator.value) {
        return `Every ${vDuplicator.value.attributes.interval_number} ${vDuplicator.value.attributes.interval_type} for the next ${vDuplicator.value.attributes.through_interval_humanized}`
      } else {
        return `None`
      }
    }
    function duplicatorIntervalType() {
      if (modal.interval_type) {
        if (typeof modal.interval_type === 'object') {
          return modal.interval_type[1]
        } else {
          return this.indexForInterval(modal.interval_type)[1]
        }
      } else {
        return 'Select interval type'
      }
    }
    function duplicatorThroughIntervalType() {
      if (modal.through_interval_type) {
        if (typeof modal.through_interval_type === 'object') {
          return modal.through_interval_type[1]
        } else {
          return this.indexForInterval(modal.through_interval_type)[1]
        }
      } else {
        return 'Select interval type'
      }
    }

    const intervalChanged = (value, property) => {
      modal[property] = value
    }

    const isAudienceSelected = audience => vSelectedAudiences.value.includes(audience)

    const toggleAudience = audience => {
      if (isAudienceSelected(audience)) {
        const pairId = vAudiencePairs.value.find(x => x.attributes.audience_id == audience.id).id
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_cycle_audience_pairs_attributes: [{ id: pairId, _destroy: true }]
          }
        })
      } else {
        api.call('GET', `audiences/${audience.id}/audience_details`)
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_cycle_audience_pairs_attributes: [{ audience_id: audience.id }]
          }
        })
      }
    }

    const updateAutofillCheckboxes = (which, e) => {
      if (e.target.checked) {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_autofill_automation_attributes: {
              automation_fields_attributes: [{ key: 'run_before', text_value: which }]
            }
          }
        })
      } else {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_autofill_automation_attributes: {
              automation_fields_attributes: vAutofillFields.value
                .filter(x => x.attributes.key === 'run_before' && x.attributes.text_value === which)
                .map(x => ({ id: x.id, _destroy: true }))
            }
          }
        })
      }
    }

    const updateAutofillType = type => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_autofill_automation_attributes: {
            automation_fields_attributes: [
              ...vAutofillFields.value
                .filter(x => x.attributes.key === 'type')
                .map(x => ({ id: x.id, _destroy: true })),
              { key: 'type', text_value: type }
            ]
          }
        }
      })
    }

    const updateTipAutofill = value => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          autofill_tip: value
        }
      })
    }

    onMounted(() => {
      Promise.all([
        api.call('GET', `bottle_cycles/${drop.id}`).then(response => {
          drop.description = response.data.data.attributes.description
        }),
        api.call('GET', 'stores?included=none')
      ]).then(() => {
        drop.loading = false
        vSelectedAudiences.value.forEach(x => {
          api.call('GET', `audiences/${x.id}/audience_details`)
        })
      })
    })

    const setModal = object => {
      modal.object = object
      if (object.type === 'fulfillment_availability') {
        modal.date = serverDayToDateObject(object.attributes.date)
        modal.time = false
      } else {
        modal.date = vScheduler(object)
        modal.time = true
      }
      if (object.type === 'bottle_reminder_automation') {
        modal.message = vMessage(object)
        modal.image = vImage(object)
      } else {
        modal.message = null
        modal.image = null
      }
      if (object.type === 'duplicator' && object.attributes) {
        modal.interval_number = object.attributes.interval_number
        modal.through_interval_number = object.attributes.through_interval_number
        modal.interval_type = object.attributes.interval_type
        modal.through_interval_type = object.attributes.through_interval_type
      }

      modal.visible = true
    }

    const setImage = attachments => {
      modal.image = attachments[0]
    }

    const deleteImage = () => {
      modal.image = null
    }

    function indexForInterval(interval) {
      switch (interval) {
        case 'weeks':
          return vDuplicatorOptions.value[0]
        case 'days':
          return vDuplicatorOptions.value[1]
        case 'minutes':
          return vDuplicatorOptions.value[2]
        case 'months':
          return vDuplicatorOptions.value[3]
        case 'years':
          return vDuplicatorOptions.value[4]
        default:
          return vDuplicatorOptions.value[0]
      }
    }

    const saveModal = () => {
      if (modal.object.type === 'bottle_autocharge_automation') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_autocharge_automation_attributes: {
              automation_scheduler_attributes: {
                datetime: modal.date.toISOString()
              }
            }
          }
        })
      } else if (modal.object.type === 'bottle_reminder_automation') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_reminder_automations_attributes: {
              id: modal.object.id,
              automation_scheduler_attributes: { datetime: modal.date.toISOString() },
              message_template_attributes: {
                id: vTemplate(modal.object).id,
                body: modal.message,
                attachments_attributes: [
                  ...vImages(modal.object)
                    .map(x => ({ id: x.id, _destroy: true }))
                    .filter(x => x.id != modal.image?.id),
                  modal.image
                ]
              }
            }
          }
        })
      } else if (modal.object.type === 'fulfillment_availability') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            fulfillment_availabilities_attributes: {
              id: modal.object.id,
              date: dateObjectToServerDay(modal.date)
            }
          }
        })
      } else if (modal.object.type === 'duplicator') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            duplicator_attributes: {
              interval_number: modal.interval_number,
              interval_type: modal.interval_type[0],
              through_interval_number: modal.through_interval_number,
              through_interval_type: modal.through_interval_type[0]
            }
          }
        })
      }
      modal.visible = false
    }

    const handleDiscardChanges = async () => {
      await api.call('GET', `bottle_cycles/${drop.id}`).then(response => {
        drop.description = response.data.data.attributes.description
      })
      drop.hasUnsavedChanges = false
    }

    const handleConfirmCancelChanges = () => {
      drop.hasUnsavedChanges = false
      router.push('/store/drops')
    }

    const browserTZ = computed(() =>
      new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4)
    )
    const format = x =>
      new Intl.DateTimeFormat([], { dateStyle: 'full', timeStyle: 'short' }).format(x) + ` (${browserTZ.value})`
    const formatDay = x => new Intl.DateTimeFormat([], { dateStyle: 'full' }).format(serverDayToDateObject(x))

    return {
      saveDrop,
      deleteDrop,
      drop,
      readableAudiencesNames,
      readableRemindersTimes,
      readablePossibleFulfillmentDaysText,
      customJoin,
      reminderAutomationMessage,
      autoFillAutoChargeAutomationMessage,
      autoFillReminderAutomationMessage,
      autoChargeAutomationMessage,
      displayReminderAutomationMessage,
      displayAutoChargeAutomationMessage,
      displayAutoFillAutoChargeAutomationMessage,
      displayAutoFillReminderAutomationMessage,
      vDrop,
      storeError,
      groupsError,
      fulfillmentAvailabilityDaysError,
      errorClasses,
      vAllStores,
      vSelectedStore,
      selectStore,
      vAudiences,
      vSelectedAudiences,
      toggleAudience,
      isAudienceSelected,
      vReminders,
      vScheduler,
      vMessage,
      newReminder,
      deleteReminder,
      format,
      modal,
      setModal,
      vAutofill,
      vAutocharge,
      saveModal,
      deleteAutofill,
      deleteAutocharge,
      vDays,
      newDay,
      formatDay,
      deleteDay,
      updateAutofillType,
      updateTipAutofill,
      updateAutofillCheckboxes,
      isAutofillReminders,
      isAutofillAutocharge,
      vAutofillType,
      vDuplicator,
      vDuplicatorOptions,
      setDuplicatorProperty,
      intervalChanged,
      duplicatorDescription,
      duplicatorIntervalType,
      duplicatorThroughIntervalType,
      deleteDuplicator,
      showDuplicator,
      isOriginalDuplication,
      hasDuplicate,
      indexForInterval,
      duplicationText,
      handleDiscardChanges,
      handleConfirmCancelChanges,
      vAudienceResults,
      vAudienceQuery,
      vAudienceDetail,
      vAudienceFilters,
      vFilterString,
      browserTZ,
      setImage,
      deleteImage,
      setPreference
    }
  }
}
