
import { mapRelationship } from '@/store/mappers'

export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    user() {
      return mapRelationship(this.message.relationships.user.data)
    },
    undelivered() {
      const send = this.message.attributes.sms_send_status
      const delivery = this.message.attributes.sms_delivery_status
      return (
        delivery === 'undelivered' ||
        send === 'do_not_text' ||
        send === 'block_marketing_messages' ||
        send === 'not_allowed_to_send_texts'
      )
    }
  }
}
