
import { defineComponent } from 'vue'
import initialState from '@/store/state'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    imagePosition: {
      type: String,
      required: false,
      default: 'left'
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'md:max-w-3xl'
    }
  },
  setup(props, context) {
    const formSubmitted = () => {
      context.emit('form-submitted')
    }

    const store = useStore()
    const router = useRouter()
    const logOut = () => {
      Cookies.remove('auth_token')
      router.push('/login').then(() => {
        store.replaceState(initialState)
      })
    }

    const route = useRoute()

    return {
      props,
      formSubmitted,
      logOut,
      route
    }
  }
})
