import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "italic text-gray-300"
}
const _hoisted_4 = { key: 1 }

import { nextTick, ref, watch } from 'vue'

import DescriptionListItem from '@/components/neue/DescriptionListItem'

export default {
  __name: 'DescriptionListEditableInput',
  props: ['label', 'value', 'active', 'placeholder'],
  setup(__props) {

const props = __props

const input = ref(null)
watch(
  () => props.active,
  value => value && nextTick(() => input.value.$el.focus())
)

return (_ctx, _cache) => {
  const _component_DescriptionListInput = _resolveComponent("DescriptionListInput")
  const _component_NeueButton = _resolveComponent("NeueButton")

  return (_openBlock(), _createBlock(_unref(DescriptionListItem), {
    label: props.label
  }, {
    contents: _withCtx(() => [
      (!props.active)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            (props.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(props.value), 1))
              : _createCommentVNode("", true),
            (!props.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.placeholder), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (props.active)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createVNode(_component_DescriptionListInput, {
              ref_key: "input",
              ref: input,
              placeholder: props.placeholder,
              "model-value": props.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = e => _ctx.$emit('update:value', e))
            }, null, 8, ["placeholder", "model-value"])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!props.active)
        ? (_openBlock(), _createBlock(_component_NeueButton, {
            key: 0,
            type: "secondary",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:active', true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Update")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label"]))
}
}

}