<template>
  <Teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 "
      enter-to-class="transform opacity-100"
      leave-active-class="transition ease-in duration-100"
      leave-class="transform opacity-100 "
      leave-to-class="transform opacity-0 "
    >
      <nav v-show="hasUnsavedChanges" class="fixed top-0 left-0 z-20 w-full bg-white border-b border-gray-100 shadow">
        <div class="md:pl-80">
          <div class="flex justify-between items-center px-5 py-13">
            <h3 class="text-sm font-medium text-gray-900">Unsaved changes</h3>
            <div class="space-x-1 flex items-center">
              <Button :is-ghost="true" @click="$emit('on-discard')">Discard</Button>
              <Button :is-primary="true" @click="$emit('on-save')">Save</Button>
            </div>
          </div>
        </div>
      </nav>
    </transition>
  </Teleport>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    hasUnsavedChanges: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['on-save', 'on-discard']
})
</script>

<style scoped>
.py-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}
</style>
