
import { ArrowLeftIcon, CheckIcon, ChevronRightIcon, PencilIcon } from '@heroicons/vue/20/solid'
import { nextTick } from 'vue'

export default {
  components: {
    ArrowLeftIcon,
    ChevronRightIcon,
    PencilIcon,
    CheckIcon
  },
  props: {
    heading: {
      type: String,
      required: true,
      default: 'Heading Here'
    },
    useBackArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    prevRoute: {
      type: String,
      required: false,
      default: ''
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null
      // Example data:
      // [
      //   {
      //     title: 'Parent',
      //     route: '/'
      //   },
      //   {
      //     title: 'Crumb',
      //     route: '/messages'
      //   }
      // ]
    },
    saveStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    hasEditMode: {
      type: Boolean,
      required: false,
      default: false
    },
    editingEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    close: { type: Function }
  },
  data() {
    return {
      editingMode: false,
      newHeading: null
    }
  },
  methods: {
    editHeading() {
      this.newHeading = this.heading
      this.editingMode = true
      nextTick(() => this.$refs.headingInput.select())
    },
    saveHeading() {
      this.editingMode = false
      this.close(this.newHeading)
    }
  }
}
