
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    listLength: {
      type: Number,
      required: true
    },
    emptyStateHeading: {
      type: String,
      required: false,
      default: 'No items yet'
    },
    emptyStateDescription: {
      type: String,
      required: false,
      default: "Get started by clicking 'New'"
    }
  }
})
