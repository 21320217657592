<template>
  <div class="text-center">
    <div v-show="isLoading" class="mx-auto h-5 w-5 mt-5">
      <BasicSpinner color="gray-900" />
    </div>
    <component :is="icon" v-if="icon" class="mx-auto h-12 w-12 text-gray-400" />
    <h3 class="mt-2 text-sm font-medium text-gray-900">{{ heading }}</h3>
    <p class="mt-1 text-sm text-gray-500">{{ description }}</p>
  </div>
</template>
<script>
import {
  ChartBarIcon,
  CheckCircleIcon,
  ClipboardDocumentListIcon,
  ExclamationCircleIcon,
  FolderOpenIcon
} from '@heroicons/vue/20/solid'

export default {
  components: {
    ChartBarIcon,
    CheckCircleIcon,
    ClipboardDocumentListIcon,
    ExclamationCircleIcon,
    FolderOpenIcon
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    heading: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
