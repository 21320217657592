<template>
  <div v-if="isLoading" class="flex justify-center flex-col pt-16 pb-10">
    <SimpleEmptyState
      :is-loading="isLoading"
      class="my-10"
      heading="Fetching the things..."
      description="Hang on while we get that for you"
    />
  </div>
  <div v-if="!isLoading && hasError" class="flex justify-center flex-col pt-16 pb-10">
    <SimpleEmptyState
      :has-error="hasError"
      class="my-10"
      heading="Uh-Oh, error..."
      description="Looks like something went wrong"
    />
  </div>
  <div v-if="!isLoading && !isEmpty && !hasError">
    <div class="flex flex-col sm:px-0 px-4">
      <div class="flex flex-col">
        <div class="-my-2">
          <div class="py-2 align-middle inline-block min-w-full">
            <slot name="table" />
            <slot name="tablePagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isLoading && !hasError && isEmpty" class="flex justify-center flex-col pt-16 pb-10">
    <SimpleEmptyState :heading="emptyStateHeading" :description="emptyStateText" icon="ClipboardDocumentListIcon" />
  </div>
</template>
<script>
export default {
  props: {
    isEmpty: {
      type: Boolean,
      required: true,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    hasError: {
      type: Boolean,
      required: true,
      default: false
    },
    emptyStateHeading: {
      type: String,
      required: false,
      default: 'No items yet' // No orders yet
    },
    emptyStateText: {
      type: String,
      required: false,
      default: 'Items will appear here' // Paid orders will appear here
    }
  }
}
</script>
