import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'

export default {
  __name: 'FormElement',
  props: ['size'],
  setup(__props) {

const props = __props

// needs to be written out so Tailwind can pick it up
const style = computed(
  () =>
    ({
      1: 'sm:col-span-1',
      2: 'sm:col-span-2',
      3: 'sm:col-span-3',
      4: 'sm:col-span-4',
      5: 'sm:col-span-5',
      6: 'sm:col-span-6',
      undefined: 'sm:col-span-6'
    })[props.size]
)

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(style.value)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

}