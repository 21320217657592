
import Sortable from 'sortablejs'

import api from '@/store/api'

export default {
  data() {
    return { giftCards: [], page: 0, sortable: null }
  },
  mounted() {
    const that = this
    this.sortable = new Sortable(this.$refs.cards, {
      onEnd(e) {
        const oldId = that.giftCards[e.oldIndex].id
        that.giftCards.sort((a, b) => a.attributes.order_value - b.attributes.order_value)
        let newOrder
        if (e.newIndex === 0) {
          // first
          newOrder = that.giftCards[0].attributes.order_value / 2
        } else if (e.newIndex === that.giftCards.length - 1) {
          // last
          newOrder = that.giftCards[that.giftCards.length - 1].attributes.order_value * 2
        } else {
          const calcCards = that.giftCards.filter(x => x.id !== oldId)
          const prev = calcCards[e.newIndex - 1]
          const next = calcCards[e.newIndex]
          newOrder = (prev.attributes.order_value + next.attributes.order_value) / 2
        }
        that.giftCards[e.oldIndex].attributes.order_value = newOrder
        that.giftCards.sort((a, b) => a.attributes.order_value - b.attributes.order_value)
        api.call('PUT', 'gift_cards/' + oldId, {
          gift_card: {
            order_value: newOrder
          }
        })
      }
    })
    api.call('GET', 'gift_cards').then(response => {
      response.data.data.forEach(giftCard => {
        this.giftCards.push(giftCard)
      })
      this.giftCards.sort((a, b) => a.attributes.order_value - b.attributes.order_value)
    })
  },
  methods: {
    onPageChange(e) {
      this.page = e
    }
  }
}
