
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

import api from '@/store/api'
import { mapRelationship } from '@/store/mappers'

export default {
  components: {
    ExclamationTriangleIcon
  },
  props: {
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  computed: {
    blockingCalls() {
      const conversationUserPair = mapRelationship({ type: 'conversation_user_pair', id: this.$route.params.id })
      return conversationUserPair?.attributes['block_calls?']
    },
    blockingSms() {
      const conversationUserPair = mapRelationship({ type: 'conversation_user_pair', id: this.$route.params.id })
      return conversationUserPair?.attributes['do_not_text?']
    }
  },
  methods: {
    toggleBlockCalls() {
      api.call('PUT', `conversation_user_pairs/${this.conversationUserPairId}`, {
        conversation_user_pair: {
          block_calls: this.blockingCalls ? 'off' : 'on'
        }
      })
    },
    toggleBlockSms() {
      api.call('PUT', `conversation_user_pairs/${this.conversationUserPairId}`, {
        conversation_user_pair: {
          do_not_text: this.blockingSms ? 'off' : 'on'
        }
      })
    },
    archiveConversationUserPair() {
      api.call('DELETE', `conversation_user_pairs/${this.conversationUserPairId}`)
      this.$router.push('/messages')
    }
  }
}
