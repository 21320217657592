
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

import { closable } from '@/directives/closable'

export default {
  directives: { closable },
  components: { ChevronDownIcon },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    dropdownOrigin: {
      type: String,
      required: false,
      default: 'left'
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    position: {
      type: String,
      required: false,
      default: 'absolute'
    },
    width: {
      type: String,
      required: false,
      default: '56'
    },
    preventOptionsToShowOnClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['search-input-changed'],
  data: () => ({
    isVisible: false
  }),
  methods: {
    closeDropdown() {
      this.isVisible = false
    },
    searchInputChanged(value) {
      this.$emit('search-input-changed', value)
    }
  }
}
