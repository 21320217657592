
import { closable } from '@/directives/closable'
import { capatilizeFirstLetter } from '@/helpers/CaseHelper'
import api from '@/store/api'

const NOTES_KEY = 'notes-01EWNTQ20SV8YJAEC2F2Q3X6C2'

export default {
  directives: {
    closable
  },
  props: {
    conversationUserPairId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      note: { value: '' },
      taxBoxActive: false,
      conversationUserPairTaxStructure: 'Not Selected'
    }
  },
  computed: {
    apiEndpoint() {
      return `conversation_user_pairs/${this.$props.conversationUserPairId}`
    },
    buttonText() {
      if (!this.taxBoxActive) return 'Edit'
      else return 'Save'
    },
    selectedTaxStructureName() {
      if (!this.conversationUserPairTaxStructure) return 'Not Selected'
      return this.conversationUserPairTaxStructure
    }
  },
  watch: {
    '$store.state.objects.conversation_user_pair': function () {
      if (this.$store.state.objects.conversation_user_pair[this.conversationUserPairId].attributes.tax_structure) {
        this.conversationUserPairTaxStructure = capatilizeFirstLetter(
          this.$store.state.objects.conversation_user_pair[this.conversationUserPairId].attributes.tax_structure
        )
      }
    }
  },
  methods: {
    taxOptionSelected(selectedOption) {
      this.conversationUserPairTaxStructure = selectedOption
    },
    taxButtonClick() {
      if (this.taxBoxActive) return this.saveTaxOption()
      else return this.noteboxFocus()
    },
    noteboxFocus() {
      this.taxBoxActive = true
    },
    saveTaxOption() {
      this.taxBoxActive = false
      api.call('PUT', this.apiEndpoint, {
        tax_structure: this.conversationUserPairTaxStructure
          ? this.conversationUserPairTaxStructure.toLowerCase()
          : null
      })
    }
  }
}
