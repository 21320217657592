<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop
        :text="drop.description || 'Drop'"
        :parent="{ name: 'Drops', link: '/store/drops' }"
        :save-status="true"
      >
        <template #right>
          <!-- <router-link :to="`/automation/drop/${$route.params.id}`">
            <NeueButton type="secondary">
              <span class="hidden sm:inline">Go to the new version of this page</span>
              <span class="inline sm:hidden">Go to new version</span>
            </NeueButton>
          </router-link> -->
        </template>
      </SecondaryTop>
      <SaveBar
        :has-unsaved-changes="drop.hasUnsavedChanges"
        @on-discard="handleDiscardChanges()"
        @on-save="saveDrop()"
      />
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <form class="flex flex-1 flex-col justify-between" @submit.prevent="saveDrop">
          <PageGrid>
            <MainCol>
              <div v-if="drop.loading" class="h-full w-full flex justify-center items-center">
                <BasicSpinner color="gray-900" size="medium" />
              </div>
              <div v-if="!drop.loading">
                <TheModal
                  v-if="modal.visible"
                  :modal-title="
                    modal.object.type !== 'duplicator' && modal.time
                      ? modal.message !== null
                        ? 'Edit drop reminder'
                        : `Set time (${browserTZ})`
                      : 'Set date'
                  "
                  :is-open="modal.visible"
                  @close-modal="modal.visible = false"
                >
                  <template #main>
                    <div v-if="modal.object.type === 'duplicator'" class="pb-40">
                      <div class="col-span-12 flex justify-between">
                        <div class="col-span-6 w-full">
                          <label for="new-fulfillment-start-time" class="block text-sm font-medium text-gray-700">
                            Recur every:
                          </label>
                          <div class="mt-1">
                            <input
                              id="drop-description"
                              v-model="modal.interval_number"
                              placeholder="Interval"
                              type="number"
                              name="tier-name"
                              class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              @change="intervalChanged($event.target.value, 'interval_number')"
                            />
                          </div>
                        </div>
                        <div class="col-span-6 w-full ml-5">
                          <label for="new-fulfillment-start-time" class="block text-sm font-medium text-gray-700">
                            &nbsp;
                          </label>

                          <button-dropdown
                            v-model="modal.interval_type"
                            :position="'absolute'"
                            class="mt-1 w-full"
                            :button-text="duplicatorIntervalType()"
                          >
                            <button
                              v-for="option in vDuplicatorOptions"
                              :key="option[1]"
                              type="button"
                              class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                              role="menuitem"
                              @click="setDuplicatorProperty('interval_type', option)"
                            >
                              {{ option[1] }}
                            </button>
                          </button-dropdown>
                        </div>
                      </div>
                      <br />
                      <div class="col-span-12 flex justify-between">
                        <div class="col-span-6 w-full">
                          <label for="new-fulfillment-start-time" class="block text-sm font-medium text-gray-700">
                            For this far into the future:
                          </label>
                          <div class="mt-1">
                            <input
                              id="drop-description"
                              v-model="modal.through_interval_number"
                              placeholder="Interval"
                              type="number"
                              name="tier-name"
                              class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              @change="intervalChanged($event.target.value, 'through_interval_number')"
                            />
                          </div>
                        </div>
                        <div class="col-span-6 w-full ml-5">
                          <label for="new-fulfillment-start-time" class="block text-sm font-medium text-gray-700">
                            &nbsp;
                          </label>

                          <button-dropdown
                            v-model="modal.through_interval_type"
                            :position="'absolute'"
                            class="mt-1 block w-full"
                            :button-text="duplicatorThroughIntervalType()"
                          >
                            <button
                              v-for="option in vDuplicatorOptions"
                              :key="option[1]"
                              type="button"
                              class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                              role="menuitem"
                              @click="setDuplicatorProperty('through_interval_type', option)"
                            >
                              {{ option[1] }}
                            </button>
                          </button-dropdown>
                        </div>
                      </div>
                    </div>

                    <div v-else class="gap-1">
                      <DescriptionHeader
                        v-if="modal.message !== null"
                        :name="`1. Choose a time (${browserTZ}) for the reminder to go out`"
                      />
                      <DatePicker v-model="modal.date" :mode="modal.time ? 'dateTime' : 'date'" />

                      <DescriptionHeader v-if="modal.message !== null" name="2. Set the body of the text message" />
                      <textarea
                        v-if="modal.message !== null"
                        v-model="modal.message"
                        class="shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md w-full"
                        rows="10"
                      ></textarea>
                      <div v-if="modal.object.type === 'bottle_reminder_automation'" class="flex mt-1">
                        <h1 class="ml-1 mt-1 text-gray-600 text-sm">
                          <div>
                            <div class="px-4 py-4 bg-gray-50 overflow-hidden shadow rounded-lg mb-4 flex">
                              <InformationCircleIcon class="h-6 w-12 ml-1 text-gray-400" />
                              <div class="ml-2">
                                A unique link, logging the customer in and taking the customer directly to their cart,
                                is automatically included at the end of the message.
                              </div>
                            </div>
                          </div>
                        </h1>
                      </div>

                      <DescriptionHeader v-if="modal.message !== null" name="3. (Optional) Add an attachment" />
                      <ImageUploader
                        v-if="modal.message !== null"
                        class="w-64"
                        :existing-upload-thumbnail-path="modal.image?.secure_url"
                        @uploaded="setImage"
                        @removed-upload="deleteImage"
                      />
                    </div>
                  </template>
                  <template #footer>
                    <SmartLink class="btn-primary justify-center mr-3" type="button" @click.prevent="saveModal">
                      Save
                    </SmartLink>
                    <SmartLink class="btn-ghost justify-center" type="button" @click.prevent="modal.visible = false">
                      Cancel
                    </SmartLink>
                  </template>
                </TheModal>
                <BasicContainer>
                  <div class="grid grid-cols-12 gap-6 duration-150 transition-opacity">
                    <div class="col-span-12">
                      <label for="tier-name" class="block text-sm font-medium text-gray-700">Description</label>
                      <div class="mt-1">
                        <input
                          id="drop-description"
                          v-model="drop.description"
                          type="text"
                          name="tier-name"
                          class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          @input="drop.hasUnsavedChanges = true"
                        />
                      </div>
                    </div>
                  </div>
                </BasicContainer>

                <BannerCard>
                  <template #title>
                    <div class="flex">
                      <div>
                        <InformationCircleIcon class="w-4 mt-1 text-gray-400" />
                      </div>

                      <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">The menu that gets presented to the customers.</h1>
                    </div>
                  </template>
                  <template #main>
                    <div class="col-span-12">
                      <label class="block text-sm font-medium text-gray-700">Store</label>
                      <button-dropdown
                        ref="selectedAudiencesInput"
                        :position="'relative'"
                        class="mt-2 block z-20"
                        name="selectedAudiences"
                        :button-text="vSelectedStore ? vSelectedStore.attributes.store_name : 'Select a store'"
                      >
                        <button
                          v-for="store in vAllStores"
                          :key="store.id"
                          type="button"
                          class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                          :class="{ 'bg-gray-200': store.id === vSelectedStore?.id }"
                          role="menuitem"
                          @click="selectStore(store)"
                        >
                          {{ store.attributes.store_name }}
                        </button>
                      </button-dropdown>
                    </div>
                    <div v-if="storeError" class="col-span-12 mt-4">
                      <div class="flex flex-shrink-0">
                        <p
                          :class="`${errorClasses}`"
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        >
                          {{ storeError }}
                        </p>
                      </div>
                    </div>

                    <div class="mt-4">
                      <SelectInput
                        name="Store to use for carts created"
                        :model-value="vDrop.attributes.store_preference"
                        @update:modelValue="setPreference"
                        :options="[
                          { name: 'Use the drop store', value: 'bottle-cycle' },
                          {
                            name: 'Use the store the customer is subscribed to, and default back to the drop store',
                            value: 'cart-subscription'
                          }
                        ]"
                      />
                    </div>
                  </template>
                </BannerCard>
                <BannerCard>
                  <template #title>
                    <div class="flex">
                      <div>
                        <InformationCircleIcon class="w-4 mt-1 text-gray-400" />
                      </div>

                      <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                        The groups set to be associated with the drop. Each customer will have a cart associated with
                        them, and be sent unique links to their cart if there are reminder messages setup.
                      </h1>
                    </div>
                  </template>
                  <template #main>
                    <header class="md:grid md:grid-cols-12 md:gap-6 pb-2">
                      <div class="col-span-12">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Groups</h3>
                      </div>
                    </header>
                    <InlineSearchSelect
                      :position="'relative'"
                      :helper-text="{ typingPrompt: 'Add groups', noResult: 'No groups found' }"
                      search-endpoint="audiences/search"
                      :search-request="{ search: { text: vAudienceQuery } }"
                      @on-search="x => (vAudienceQuery = x)"
                      @on-results="x => (vAudienceResults = x)"
                    >
                      <template #searchResults>
                        <MenuItem v-for="item in vAudienceResults" :key="item.id">
                          <button
                            class="group rounded-md w-full px-2 py-2 text-sm text-left hover:bg-gray-100 duration-200 transition-colors text-gray-700"
                            @click="toggleAudience(item)"
                          >
                            {{ item.attributes.audience_name }}
                          </button>
                        </MenuItem>
                      </template>
                    </InlineSearchSelect>
                    <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 pt-2">
                      <router-link
                        v-for="item in vSelectedAudiences"
                        :key="item.id"
                        :to="`/lists/${item.id}`"
                        class="relative rounded-lg border border-gray-200 bg-white px-6 shadow py-5 flex items-start space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                      >
                        <div class="flex flex-1 h-full min-w-0 justify-between flex-col space-y-2">
                          <p v-if="item.attributes.audience_name" class="text-sm font-medium text-gray-900 max-w-xxs">
                            {{ item.attributes.audience_name }}
                          </p>
                          <div>
                            <div class="text-xl font-bold leading-wide text-blue-500">
                              {{ vAudienceDetail(item)?.attributes.number_of_contacts }}
                            </div>
                            <div class="text-sm font-normal leading-normal capitalize text-gray-500">
                              {{ 'contact' + (vAudienceDetail(item)?.attributes.number_of_contacts === 1 ? '' : 's') }}
                            </div>
                          </div>
                          <ul class="flex justify-start">
                            <li v-for="filter in vAudienceFilters(item)" :key="filter.id" class="-ml-2">
                              <BasicBadge :name="vFilterString(filter)" />
                            </li>
                          </ul>
                        </div>
                        <!-- TODO(alicja): could use better styling -->
                        <div class="absolute right-1 top-1">
                          <button
                            class="ml-1 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                            @click.prevent="toggleAudience(item)"
                          >
                            <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                          </button>
                        </div>
                      </router-link>
                    </div>
                    <div v-if="groupsError" class="col-span-12 mt-4">
                      <div class="flex flex-shrink-0">
                        <p
                          :class="`${errorClasses}`"
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        >
                          {{ groupsError }}
                        </p>
                      </div>
                    </div>
                  </template>
                </BannerCard>
                <BannerCard>
                  <template #title>
                    <div class="flex">
                      <div>
                        <InformationCircleIcon class="w-4 mt-1 text-gray-400" />
                      </div>
                      <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                        The production day(s) that the customer should be selecting from for this Drop. These dates will
                        be used to only show fulfillment slots that match these production days, and only show products
                        available on these production days.
                      </h1>
                    </div>
                  </template>
                  <template #main>
                    <header class="md:grid md:grid-cols-12 md:gap-6">
                      <div class="col-span-6 mb-2">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Possible production days</h3>
                      </div>
                      <div class="col-span-6 md:text-right md:mt-0 mt-4">
                        <button class="btn-primary" @click.prevent="newDay">+ Day</button>
                      </div>
                    </header>
                    <div v-for="day in vDays" :key="day.id" class="flex items-start gap-4 mb-2 mt-2">
                      <div class="flex-1 min-w-0">
                        <button
                          class="text-gray-900 hover:text-blue-600 transition-colors duration-150 text-left"
                          @click.prevent="setModal(day)"
                        >
                          <p class="text-sm">{{ formatDay(day.attributes.date) }}</p>
                        </button>
                      </div>
                      <div>
                        <button
                          v-tippy="{ content: 'Edit' }"
                          class="inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                          @click.prevent="setModal(day)"
                        >
                          <PencilIcon class="h-4 w-4" aria-hidden="true" />
                        </button>
                        <button
                          v-tippy="{ content: 'Delete' }"
                          class="ml-1 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                          @click.prevent="deleteDay(day)"
                        >
                          <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div v-if="fulfillmentAvailabilityDaysError" class="col-span-12 mt-4">
                      <div class="flex flex-shrink-0">
                        <p
                          :class="`${errorClasses}`"
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        >
                          {{ fulfillmentAvailabilityDaysError }}
                        </p>
                      </div>
                    </div>
                  </template>
                </BannerCard>
                <BannerCard>
                  <template #title>
                    <div>
                      <div class="flex">
                        <div>
                          <InformationCircleIcon class="w-4 mt-1 text-gray-400" />
                        </div>
                        <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                          Reminder messages sent to each customer in the groups above. Each reminder gets a unique link
                          appended to the message which logs the customer into their account and takes them straight to
                          their cart. Customers who have skipped, are no longer in the group, or who already have a paid
                          order spanning the possible production days will not receive a message.
                        </h1>
                      </div>
                      <div v-if="displayReminderAutomationMessage" class="flex">
                        <ClipboardDocumentCheckIcon class="h-6 w-7 ml-1 text-gray-400" />
                        <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                          {{ reminderAutomationMessage }}
                        </h1>
                      </div>
                    </div>
                  </template>
                  <template #main>
                    <header class="md:grid md:grid-cols-12 md:gap-6 mb-2">
                      <div class="col-span-6">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Reminders</h3>
                      </div>
                      <div class="col-span-6 md:text-right md:mt-0 mt-4">
                        <button class="btn-primary" @click.prevent="newReminder">+ Reminder</button>
                      </div>
                    </header>
                    <div v-for="reminder in vReminders" :key="reminder.id" class="flex items-start gap-4">
                      <div class="flex-1 min-w-0">
                        <div
                          v-if="reminder.attributes.status === 'completed'"
                          class="text-gray-900 hover:text-blue-600 transition-colors duration-150 text-left"
                        >
                          <p class="text-sm font-medium inline">{{ format(vScheduler(reminder)) }}</p>
                          <CheckIcon class="ml-1 w-4 h-4 inline" />
                          <p class="text-sm text-gray-500 truncate max-w-xs">{{ vMessage(reminder) }}</p>
                        </div>
                        <button
                          v-else
                          class="text-gray-900 hover:text-blue-600 transition-colors duration-150 text-left"
                          @click.prevent="setModal(reminder)"
                        >
                          <p class="text-sm font-medium">{{ format(vScheduler(reminder)) }}</p>
                          <p class="text-sm text-gray-500 truncate max-w-xs">{{ vMessage(reminder) }}</p>
                        </button>
                      </div>
                      <div v-if="reminder.attributes.status !== 'completed'">
                        <button
                          v-tippy="{ content: 'Edit' }"
                          class="inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                          @click.prevent="setModal(reminder)"
                        >
                          <PencilIcon class="h-4 w-4" aria-hidden="true" />
                        </button>
                        <button
                          v-tippy="{ content: 'Delete' }"
                          class="ml-1 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                          @click.prevent="deleteReminder(reminder)"
                        >
                          <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </template>
                </BannerCard>
                <BannerCard>
                  <template #title>
                    <div>
                      <div class="flex">
                        <div>
                          <InformationCircleIcon class="w-4 mt-1 text-gray-400" />
                        </div>
                        <h1 class="mt-0.5 ml-1 text-gray-600 text-sm">
                          The logic to follow when autofilling each customer’s cart.
                        </h1>
                      </div>
                      <div v-if="displayAutoFillAutoChargeAutomationMessage" class="flex">
                        <ClipboardDocumentCheckIcon class="h-6 w-7 ml-1 text-gray-400" />
                        <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                          {{ autoFillAutoChargeAutomationMessage }}
                        </h1>
                      </div>
                      <div v-if="displayAutoFillReminderAutomationMessage" class="flex">
                        <ClipboardDocumentCheckIcon class="h-6 w-7 ml-1 text-gray-400" />
                        <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                          {{ autoFillReminderAutomationMessage }}
                        </h1>
                      </div>
                    </div>
                  </template>
                  <template #main>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Autofill</h3>
                    <div class="flex items-end">
                      <div class="grid grid-cols-12 gap-6 mt-2">
                        <div class="col-span-12">
                          <span class="w-4 h-4 mr-2">
                            <input
                              id="run-before-reminders"
                              name="run-before-reminders"
                              type="checkbox"
                              class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              :checked="isAutofillReminders"
                              @change="e => updateAutofillCheckboxes('reminders', e)"
                            />
                          </span>
                          <label for="run-before-reminders" class="text-sm font-medium text-gray-700">
                            Run before reminders
                          </label>
                        </div>
                        <div class="col-span-12">
                          <span class="w-4 h-4 mr-2">
                            <input
                              id="run-before-autocharge"
                              name="run-before-autocharge"
                              type="checkbox"
                              class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              :checked="isAutofillAutocharge"
                              @change="e => updateAutofillCheckboxes('autocharge', e)"
                            />
                          </span>
                          <label for="run-before-autocharge" class="text-sm font-medium text-gray-700">
                            Run before autocharge
                          </label>
                        </div>
                        <div class="col-span-12">
                          <button-dropdown
                            class="mt-2 block"
                            :position="'relative'"
                            :button-text="
                              {
                                undefined: 'Select type',
                                RefillAutofill: 'Refill the cart from the last order',
                                CategoryAutofill: 'Match the number of items in each category'
                              }[vAutofillType]
                            "
                          >
                            <button
                              type="button"
                              class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                              role="menuitem"
                              @click="updateAutofillType('RefillAutofill')"
                            >
                              Refill the cart from the last order
                            </button>
                            <button
                              type="button"
                              class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                              role="menuitem"
                              @click="updateAutofillType('CategoryAutofill')"
                            >
                              Match the number of items in each category
                            </button>
                          </button-dropdown>
                          <FormElement>
                            <NeueToggle
                              v-model="vDrop.attributes.autofill_tip"
                              name="Autofill Tip"
                              description="When enabled the tip applied to the previous order will be autofilled."
                              class="mt-3"
                              @change="updateTipAutofill"
                            />
                          </FormElement>
                        </div>
                      </div>
                    </div>
                  </template>
                </BannerCard>
                <BannerCard>
                  <template #title>
                    <div>
                      <div class="flex">
                        <div>
                          <InformationCircleIcon class="w-4 mt-1 text-gray-400" />
                        </div>
                        <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                          If set, customers in the groups above will have their carts charged unless they’ve already
                          skipped, paid for an order spanning the possible production days, or are no longer in the
                          group.
                        </h1>
                      </div>
                      <div v-if="displayAutoChargeAutomationMessage" class="flex">
                        <ClipboardDocumentCheckIcon class="h-6 w-7 ml-1 text-gray-400" />
                        <h1 class="ml-1 mt-0.5 text-gray-600 text-sm">
                          {{ autoChargeAutomationMessage }}
                        </h1>
                      </div>
                    </div>
                  </template>
                  <template #main>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Autocharge</h3>
                    <p class="text-gray-500 text-sm mt-2"></p>
                    <div class="flex items-end gap-4">
                      <div class="flex-1 min-w-0">
                        <button
                          class="text-gray-900 hover:text-blue-600 transition-colors duration-150 text-left"
                          @click.prevent="
                            () =>
                              vAutocharge ? setModal(vAutocharge) : setModal({ type: 'bottle_autocharge_automation' })
                          "
                        >
                          {{ vAutocharge ? format(vScheduler(vAutocharge)) : 'None' }}
                        </button>
                      </div>
                      <div>
                        <button
                          v-tippy="{ content: 'Edit' }"
                          class="inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                          @click.prevent="
                            () =>
                              vAutocharge ? setModal(vAutocharge) : setModal({ type: 'bottle_autocharge_automation' })
                          "
                        >
                          <PencilIcon class="h-4 w-4" aria-hidden="true" />
                        </button>
                        <button
                          v-tippy="{ content: 'Delete' }"
                          class="ml-1 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                          @click.prevent="deleteAutocharge"
                        >
                          <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </template>
                </BannerCard>
                <BasicContainer v-if="showDuplicator()">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">Make the drop recurring</h3>
                  <div class="flex items-end gap-4">
                    <div class="flex-1 min-w-0">
                      <button
                        v-if="!hasDuplicate()"
                        class="text-gray-900 hover:text-blue-600 transition-colors duration-150 text-left"
                        @click.prevent="() => (vDuplicator ? setModal(vDuplicator) : setModal({ type: 'duplicator' }))"
                      >
                        {{ duplicatorDescription() }}
                      </button>
                      <div v-if="isOriginalDuplication(vDuplicator)">
                        {{ duplicationText() }}
                      </div>
                      <div v-if="hasDuplicate()">This drop was duplicated already</div>
                    </div>
                    <div>
                      <button
                        v-if="!hasDuplicate()"
                        v-tippy="{ content: 'Edit' }"
                        class="inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                        @click.prevent="() => (vDuplicator ? setModal(vDuplicator) : setModal({ type: 'duplicator' }))"
                      >
                        <PencilIcon class="h-4 w-4" aria-hidden="true" />
                      </button>
                      <button
                        v-tippy="{ content: 'Delete' }"
                        class="ml-1 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 text-gray-500 hover:text-blue-600"
                        @click.prevent="deleteDuplicator"
                      >
                        <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </BasicContainer>
              </div>
            </MainCol>
            <SideCol></SideCol>
          </PageGrid>
          <PageFooter>
            <template #left>
              <button class="btn-alert" type="button" @click.prevent="deleteDrop">Delete</button>
            </template>
            <template #right>
              <button v-if="drop.hasUnsavedChanges" class="btn-primary" type="submit">Save</button>
              <router-link v-else class="btn-primary" type="button" to="/store/drops">Done</router-link>
            </template>
          </PageFooter>
        </form>
        <!-- Confirm cancel modal -->
        <TheModal
          v-show="drop.leaveModal"
          :is-open="drop.leaveModal"
          modal-title="Abandon changes"
          modal-description="Are you sure you'd like to leave without saving your changes?"
          @close-modal="drop.leaveModal = false"
        >
          <template #footer>
            <button class="btn-alert justify-center mr-3" @click="handleConfirmCancelChanges()">Leave</button>
            <button class="btn-ghost justify-center" type="button" @click="drop.leaveModal = false">Stay</button>
          </template>
        </TheModal>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import { MenuItem } from '@headlessui/vue'
import { LockClosedIcon } from '@heroicons/vue/20/solid'
import { ClipboardDocumentCheckIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import _ from 'lodash'
import { DatePicker } from 'v-calendar'
import { computed, onMounted, reactive, ref } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { dateObjectToServerDay, serverDayToDateObject } from '@/helpers/dates'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'
import SellSidebar from '@/view/store/SellSidebar'

import BannerCard from '../../components/BannerCard.vue'
import ExplanationBox from '../../components/ExplanationBox.vue'

export default {
  components: {
    PencilIcon,
    XMarkIcon,
    CheckIcon,
    MenuItem,
    BannerCard,
    InformationCircleIcon,
    ClipboardDocumentCheckIcon,
    DatePicker,
    LockClosedIcon,
    MenuItem,
    ExplanationBox,
    SellSidebar
  },
  setup() {
    const router = useRouter()
    const vStore = useStore()

    onBeforeRouteLeave(() => {
      if (drop.hasUnsavedChanges) {
        drop.leaveModal = true
        return false
      } else return true
    })

    const drop = reactive({
      id: router.currentRoute._rawValue.params.id,
      description: '',
      loading: true,
      hasUnsavedChanges: false,
      leaveModal: false
    })

    const modal = reactive({
      visible: false,
      date: null,
      message: null,
      time: true,
      interval_type: null,
      through_interval_type: null,
      interval_number: null,
      through_interval_number: null,
      has_duplicated: false
    })

    const vDrop = computed(() => vStore.state.objects.bottle_cycle?.[drop.id])

    const storeError = computed(() => {
      if (vDrop.value.attributes.bottle_cycle_errors.no_store_error) {
        return router.currentRoute._rawValue.hash === '#new'
          ? vDrop.value.attributes.bottle_cycle_errors.no_store_error.error_friendly
          : vDrop.value.attributes.bottle_cycle_errors.no_store_error.error
      }
    })

    const groupsError = computed(() => {
      if (vDrop.value.attributes.bottle_cycle_errors.no_group_error) {
        return router.currentRoute._rawValue.hash === '#new'
          ? vDrop.value.attributes.bottle_cycle_errors.no_group_error.error_friendly
          : vDrop.value.attributes.bottle_cycle_errors.no_group_error.error
      }
    })

    const fulfillmentAvailabilityDaysError = computed(() => {
      if (vDrop.value.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error) {
        return router.currentRoute._rawValue.hash === '#new'
          ? vDrop.value.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error.error_friendly
          : vDrop.value.attributes.bottle_cycle_errors.no_fulfillment_availability_days_error.error
      }
    })

    const errorClasses = computed(() => {
      return router.currentRoute._rawValue.hash === '#new' ? 'bg-yellow-100 text-yellow-600' : 'bg-red-100 text-red-600'
    })

    const vAllStores = computed(() => Object.values(vStore.state.objects.store || {}))

    const vSelectedStore = computed(
      () => vDrop.value.relationships.store.data && mapRelationship(vDrop.value.relationships.store.data)
    )

    const vAudiences = computed(() => Object.values(vStore.state.objects.audience || {}))

    const vAudiencePairs = computed(() => mapRelationships(vDrop.value.relationships.bottle_cycle_audience_pairs.data))

    const vSelectedAudiences = computed(() =>
      vAudiencePairs.value.map(x => mapRelationship({ type: 'audience', id: x.attributes.audience_id })).filter(x => x)
    )

    const readableAudiencesNames = computed(() => {
      const audiencesNames = vSelectedAudiences.value.map(audience => {
        return audience.attributes.audience_name
      })
      if (!audiencesNames.length) {
        return ''
      } else if (audiencesNames.length === 1) {
        return `${audiencesNames[0]} group`
      } else {
        return `${customJoin(audiencesNames, ', ', ' and ')} groups`
      }
    })
    const readableRemindersTimes = computed(() => {
      const reminderTimes = vReminders.value.map(reminder => {
        return format(vScheduler(reminder))
      })
      if (!reminderTimes.length) {
        return ''
      } else if (reminderTimes.length === 1) {
        return `${reminderTimes[0]}`
      } else {
        return `${customJoin(reminderTimes, ', ', ' and ')}`
      }
    })
    const readablePossibleFulfillmentDaysText = computed(() => {
      const possibleFulfillmentDaysText = vDays.value.map(day => {
        return formatDay(day.attributes.date)
      })
      if (!possibleFulfillmentDaysText.length) {
        return ''
      } else if (possibleFulfillmentDaysText.length === 1) {
        return `${possibleFulfillmentDaysText[0]}`
      } else {
        return `${customJoin(possibleFulfillmentDaysText, ', ', ' and ')}`
      }
    })
    const customJoin = (arr, s1, s2) => {
      return arr
        .slice(0, -1)
        .join(s1)
        .concat(arr.length > 1 ? s2 : '', arr.slice(-1))
    }
    const displayReminderAutomationMessage = computed(() => {
      return false
      // return (
      //   !!readableAudiencesNames.value && !!readableRemindersTimes.value && !!readablePossibleFulfillmentDaysText.value
      // )
    })
    const displayAutoChargeAutomationMessage = computed(() => {
      // return !!readableAudiencesNames.value && !!vAutocharge.value && !!readablePossibleFulfillmentDaysText.value
      return false
    })
    const displayAutoFillAutoChargeAutomationMessage = computed(() => {
      // return (
      //   !!readableAudiencesNames.value && !!isAutofillAutocharge.value && !!readablePossibleFulfillmentDaysText.value
      // )
      return false
    })
    const displayAutoFillReminderAutomationMessage = computed(() => {
      // return (
      //   !!readableAudiencesNames.value && !!isAutofillReminders.value && !!readablePossibleFulfillmentDaysText.value
      // )
      return false
    })
    const reminderAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${readableRemindersTimes.value} will receive this reminder unless the customer has already skipped or already paid you for an order spanning ${readablePossibleFulfillmentDaysText.value}.`
    })
    const autoChargeAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${format(
        vScheduler(vAutocharge.value)
      )} will be automatically charged unless the customer has already skipped or already paid you for an order spanning ${
        readablePossibleFulfillmentDaysText.value
      }.`
    })
    const autoFillAutoChargeAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${format(
        vScheduler(vAutocharge.value)
      )} will have their orders automatically filled before auto charging unless the customer has already skipped or already paid you for an order spanning ${
        readablePossibleFulfillmentDaysText.value
      }.`
    })
    const autoFillReminderAutomationMessage = computed(() => {
      return `All customers in the ${readableAudiencesNames.value} at ${readableRemindersTimes.value} will have their orders automatically filled before reminders are sent out unless the customer has already skipped or already paid you for an order spanning ${readablePossibleFulfillmentDaysText.value}.`
    })

    const vAudienceQuery = ref('')
    const vAudienceResults = reactive([])

    const vAudienceDetail = audience => mapRelationship(audience.relationships.audience_detail.data)

    const vAudienceFilters = audience =>
      mapRelationships(audience.relationships.filter_groups.data).flatMap(x =>
        mapRelationships(x.relationships.filters.data)
      )

    const vFilterString = filter =>
      mapRelationships(filter.relationships.filter_field_values.data).reduce(
        (str, x) => str.replace(`<FilterFieldValue${x.attributes.predicate_position}>`, mappedFieldValue(filter, x)),
        filter.attributes.predicate
      )

    const mappedFieldValue = (filter, value) => {
      const fieldType = filter.attributes.field_types[value.attributes.predicate_position]
      if (fieldType.data_type === 'dropdown') {
        const valueMap = Object.fromEntries(fieldType.options.map(y => [y[1], y[0]]))
        return valueMap[value.attributes.value]
      }
      return value.attributes.value
    }

    const vScheduler = object => {
      if (object) {
        return (
          object.relationships &&
          new Date(mapRelationship(object.relationships.automation_scheduler.data).attributes.datetime)
        )
      }
    }

    const vTemplate = reminder => mapRelationship(reminder.relationships.message_template.data)

    const vMessage = reminder => vTemplate(reminder).attributes.body

    const vImages = reminder => mapRelationships(vTemplate(reminder).relationships.attachments.data)

    const vImage = reminder => vImages(reminder)[0]?.attributes

    const vAutofill = computed(() => {
      const rel = vDrop.value.relationships.bottle_autofill_automation.data
      if (rel) return mapRelationship(rel)
    })

    const vAutofillFields = computed(() =>
      vAutofill.value ? mapRelationships(vAutofill.value.relationships.automation_fields.data) : []
    )

    const vAutofillType = computed(
      () => vAutofillFields.value.find(x => x.attributes.key === 'type')?.attributes.text_value
    )

    const isAutofillReminders = computed(() =>
      vAutofillFields.value.some(x => x.attributes.key === 'run_before' && x.attributes.text_value === 'reminders')
    )

    const isAutofillAutocharge = computed(() =>
      vAutofillFields.value.some(x => x.attributes.key === 'run_before' && x.attributes.text_value === 'autocharge')
    )

    const vAutocharge = computed(() => {
      const rel = vDrop.value.relationships.bottle_autocharge_automation.data
      if (rel) return mapRelationship(rel)
    })

    const vDuplicator = computed(() => {
      const duplicatorData = vDrop.value.relationships.duplicator.data
      if (duplicatorData) return mapRelationship(vDrop.value.relationships.duplicator.data)
    })

    const vDuplicatorOptions = computed(() => [
      ['weeks', 'Week(s)'],
      ['days', 'Day(s)'],
      ['minutes', 'Minute(s)'],
      ['months', 'Month(s)'],
      ['years', 'Year(s)']
    ])

    const vReminders = computed(() => mapRelationships(vDrop.value.relationships.bottle_reminder_automations.data))

    const vDays = computed(() => mapRelationships(vDrop.value.relationships.fulfillment_availabilities.data))

    const newReminder = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_reminder_automations_attributes: [
            {
              automation_scheduler_attributes: { datetime: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString() },
              message_template_attributes: { body: 'Reminder text goes here.' }
            }
          ]
        }
      })
    }

    function hasDuplicate() {
      if (vDuplicator.value) {
        return vDuplicator.value.attributes.has_duplicated
      }
      return false
    }

    function duplicationText() {
      if (this.hasDuplicate()) {
        return 'Every ~1 hour, the system checks to see if it should make a copy of this drop.'
      } else {
        return "Every ~1 hour, the system checks to see if it should make a copy of this drop. Currently it's pending to duplicate."
      }
    }

    const newDay = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          fulfillment_availabilities_attributes: [
            {
              date: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString()
            }
          ]
        }
      })
    }

    const deleteReminder = reminder => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_reminder_automations_attributes: [
            {
              id: reminder.id,
              _destroy: true
            }
          ]
        }
      })
    }

    const deleteDay = day => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          fulfillment_availabilities_attributes: [
            {
              id: day.id,
              _destroy: true
            }
          ]
        }
      })
    }

    const deleteAutofill = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_autofill_automation_attributes: {
            _destroy: true
          }
        }
      })
    }

    const deleteDuplicator = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          duplicator_attributes: {
            id: vDuplicator.value.attributes.id,
            _destroy: true
          }
        }
      })
    }

    const deleteAutocharge = () => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_autocharge_automation_attributes: {
            _destroy: true
          }
        }
      })
    }

    const saveDrop = () => {
      api
        .call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            description: drop.description
          }
        })
        .then(() => {
          drop.hasUnsavedChanges = false
        })
    }

    const deleteDrop = () => {
      api.call('DELETE', `bottle_cycles/${drop.id}`).then(router.back)
    }

    const selectStore = store => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          store_id: store.id
          // store_attributes: { store_id: store.id }
        }
      })
    }

    const setPreference = pref => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          store_preference: pref
        }
      })
    }

    const setDuplicatorProperty = (property, option) => {
      modal[property] = option
    }

    function showDuplicator() {
      return vDays.value.length > 0 ? true : false
    }

    function isOriginalDuplication(item) {
      if (item) {
        return item.attributes.display_description !== 'Duplicated'
      }
      return false
    }

    function duplicatorDescription() {
      if (vDuplicator.value) {
        return `Every ${vDuplicator.value.attributes.interval_number} ${vDuplicator.value.attributes.interval_type} for the next ${vDuplicator.value.attributes.through_interval_humanized}`
      } else {
        return `None`
      }
    }
    function duplicatorIntervalType() {
      if (modal.interval_type) {
        if (typeof modal.interval_type === 'object') {
          return modal.interval_type[1]
        } else {
          return this.indexForInterval(modal.interval_type)[1]
        }
      } else {
        return 'Select interval type'
      }
    }
    function duplicatorThroughIntervalType() {
      if (modal.through_interval_type) {
        if (typeof modal.through_interval_type === 'object') {
          return modal.through_interval_type[1]
        } else {
          return this.indexForInterval(modal.through_interval_type)[1]
        }
      } else {
        return 'Select interval type'
      }
    }

    const intervalChanged = (value, property) => {
      modal[property] = value
    }

    const isAudienceSelected = audience => vSelectedAudiences.value.includes(audience)

    const toggleAudience = audience => {
      if (isAudienceSelected(audience)) {
        const pairId = vAudiencePairs.value.find(x => x.attributes.audience_id == audience.id).id
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_cycle_audience_pairs_attributes: [{ id: pairId, _destroy: true }]
          }
        })
      } else {
        api.call('GET', `audiences/${audience.id}/audience_details`)
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_cycle_audience_pairs_attributes: [{ audience_id: audience.id }]
          }
        })
      }
    }

    const updateAutofillCheckboxes = (which, e) => {
      if (e.target.checked) {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_autofill_automation_attributes: {
              automation_fields_attributes: [{ key: 'run_before', text_value: which }]
            }
          }
        })
      } else {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_autofill_automation_attributes: {
              automation_fields_attributes: vAutofillFields.value
                .filter(x => x.attributes.key === 'run_before' && x.attributes.text_value === which)
                .map(x => ({ id: x.id, _destroy: true }))
            }
          }
        })
      }
    }

    const updateAutofillType = type => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          bottle_autofill_automation_attributes: {
            automation_fields_attributes: [
              ...vAutofillFields.value
                .filter(x => x.attributes.key === 'type')
                .map(x => ({ id: x.id, _destroy: true })),
              { key: 'type', text_value: type }
            ]
          }
        }
      })
    }

    const updateTipAutofill = value => {
      api.call('PUT', `bottle_cycles/${drop.id}`, {
        bottle_cycle: {
          autofill_tip: value
        }
      })
    }

    onMounted(() => {
      Promise.all([
        api.call('GET', `bottle_cycles/${drop.id}`).then(response => {
          drop.description = response.data.data.attributes.description
        }),
        api.call('GET', 'stores?included=none')
      ]).then(() => {
        drop.loading = false
        vSelectedAudiences.value.forEach(x => {
          api.call('GET', `audiences/${x.id}/audience_details`)
        })
      })
    })

    const setModal = object => {
      modal.object = object
      if (object.type === 'fulfillment_availability') {
        modal.date = serverDayToDateObject(object.attributes.date)
        modal.time = false
      } else {
        modal.date = vScheduler(object)
        modal.time = true
      }
      if (object.type === 'bottle_reminder_automation') {
        modal.message = vMessage(object)
        modal.image = vImage(object)
      } else {
        modal.message = null
        modal.image = null
      }
      if (object.type === 'duplicator' && object.attributes) {
        modal.interval_number = object.attributes.interval_number
        modal.through_interval_number = object.attributes.through_interval_number
        modal.interval_type = object.attributes.interval_type
        modal.through_interval_type = object.attributes.through_interval_type
      }

      modal.visible = true
    }

    const setImage = attachments => {
      modal.image = attachments[0]
    }

    const deleteImage = () => {
      modal.image = null
    }

    function indexForInterval(interval) {
      switch (interval) {
        case 'weeks':
          return vDuplicatorOptions.value[0]
        case 'days':
          return vDuplicatorOptions.value[1]
        case 'minutes':
          return vDuplicatorOptions.value[2]
        case 'months':
          return vDuplicatorOptions.value[3]
        case 'years':
          return vDuplicatorOptions.value[4]
        default:
          return vDuplicatorOptions.value[0]
      }
    }

    const saveModal = () => {
      if (modal.object.type === 'bottle_autocharge_automation') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_autocharge_automation_attributes: {
              automation_scheduler_attributes: {
                datetime: modal.date.toISOString()
              }
            }
          }
        })
      } else if (modal.object.type === 'bottle_reminder_automation') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            bottle_reminder_automations_attributes: {
              id: modal.object.id,
              automation_scheduler_attributes: { datetime: modal.date.toISOString() },
              message_template_attributes: {
                id: vTemplate(modal.object).id,
                body: modal.message,
                attachments_attributes: [
                  ...vImages(modal.object)
                    .map(x => ({ id: x.id, _destroy: true }))
                    .filter(x => x.id != modal.image?.id),
                  modal.image
                ]
              }
            }
          }
        })
      } else if (modal.object.type === 'fulfillment_availability') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            fulfillment_availabilities_attributes: {
              id: modal.object.id,
              date: dateObjectToServerDay(modal.date)
            }
          }
        })
      } else if (modal.object.type === 'duplicator') {
        api.call('PUT', `bottle_cycles/${drop.id}`, {
          bottle_cycle: {
            duplicator_attributes: {
              interval_number: modal.interval_number,
              interval_type: modal.interval_type[0],
              through_interval_number: modal.through_interval_number,
              through_interval_type: modal.through_interval_type[0]
            }
          }
        })
      }
      modal.visible = false
    }

    const handleDiscardChanges = async () => {
      await api.call('GET', `bottle_cycles/${drop.id}`).then(response => {
        drop.description = response.data.data.attributes.description
      })
      drop.hasUnsavedChanges = false
    }

    const handleConfirmCancelChanges = () => {
      drop.hasUnsavedChanges = false
      router.push('/store/drops')
    }

    const browserTZ = computed(() =>
      new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4)
    )
    const format = x =>
      new Intl.DateTimeFormat([], { dateStyle: 'full', timeStyle: 'short' }).format(x) + ` (${browserTZ.value})`
    const formatDay = x => new Intl.DateTimeFormat([], { dateStyle: 'full' }).format(serverDayToDateObject(x))

    return {
      saveDrop,
      deleteDrop,
      drop,
      readableAudiencesNames,
      readableRemindersTimes,
      readablePossibleFulfillmentDaysText,
      customJoin,
      reminderAutomationMessage,
      autoFillAutoChargeAutomationMessage,
      autoFillReminderAutomationMessage,
      autoChargeAutomationMessage,
      displayReminderAutomationMessage,
      displayAutoChargeAutomationMessage,
      displayAutoFillAutoChargeAutomationMessage,
      displayAutoFillReminderAutomationMessage,
      vDrop,
      storeError,
      groupsError,
      fulfillmentAvailabilityDaysError,
      errorClasses,
      vAllStores,
      vSelectedStore,
      selectStore,
      vAudiences,
      vSelectedAudiences,
      toggleAudience,
      isAudienceSelected,
      vReminders,
      vScheduler,
      vMessage,
      newReminder,
      deleteReminder,
      format,
      modal,
      setModal,
      vAutofill,
      vAutocharge,
      saveModal,
      deleteAutofill,
      deleteAutocharge,
      vDays,
      newDay,
      formatDay,
      deleteDay,
      updateAutofillType,
      updateTipAutofill,
      updateAutofillCheckboxes,
      isAutofillReminders,
      isAutofillAutocharge,
      vAutofillType,
      vDuplicator,
      vDuplicatorOptions,
      setDuplicatorProperty,
      intervalChanged,
      duplicatorDescription,
      duplicatorIntervalType,
      duplicatorThroughIntervalType,
      deleteDuplicator,
      showDuplicator,
      isOriginalDuplication,
      hasDuplicate,
      indexForInterval,
      duplicationText,
      handleDiscardChanges,
      handleConfirmCancelChanges,
      vAudienceResults,
      vAudienceQuery,
      vAudienceDetail,
      vAudienceFilters,
      vFilterString,
      browserTZ,
      setImage,
      deleteImage,
      setPreference
    }
  }
}
</script>
