<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        :class="[
          { 'btn-primary': isPrimary },
          { 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50': !isPrimary },
          'inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500',
          buttonClass
        ]"
      >
        {{ menuItems.label ? menuItems.label : 'Options' }}
        <ChevronDownIcon
          :class="[{ 'text-white': isPrimary }, { 'text-gray-400': !isPrimary }, '-mr-1 ml-2 h-5 w-5']"
          aria-hidden="true"
        />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        :class="absolute ? 'absolute' : ''"
        class="origin-top-right right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <MenuItems
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
        >
          <div class="py-1">
            <MenuItem v-for="(item, i) in menuItems.items" :key="i" v-slot="{ active }">
              <button
                :type="submitType"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'w-full text-left block px-4 py-2 text-sm'
                ]"
                @click="onMenuItemClick(item.handler, itemId)"
              >
                {{ item.name }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon
  },
  props: {
    menuItems: {
      type: Object,
      required: true
    },
    itemId: {
      type: Number,
      required: false,
      default: 0
    },
    isPrimary: {
      type: Boolean,
      required: false,
      default: false
    },
    absolute: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonClass: {
      type: String,
      required: false,
      default: 'border'
    },
    submitType: {
      type: String,
      required: false,
      default: 'submit'
    }
  },
  emits: ['on-actions-menu-click'],
  setup(props, context) {
    const onMenuItemClick = (handler, id) => {
      context.emit('on-actions-menu-click', handler, id)
    }

    return {
      onMenuItemClick
    }
  }
})
</script>
