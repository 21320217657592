import script from "./ConversationItem.vue?vue&type=script&setup=true&lang=js"
export * from "./ConversationItem.vue?vue&type=script&setup=true&lang=js"

import "./ConversationItem.vue?vue&type=style&index=0&id=7a31224f&lang=css"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import ActivityCard from "@/components/conversationItem/ActivityCard.vue";
import CallCard from "@/components/conversationItem/CallCard.vue";
import MessageCard from "@/components/conversationItem/MessageCard.vue";
script.components = Object.assign({}, { ActivityCard, CallCard, MessageCard }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { ActivityCard, CallCard, MessageCard }, script.__o.components);}