
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    for: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Field'
    },
    isInline: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
