<template>
  <SignupMainContainer
    :title="'Forgot Password'"
    :image-position="'left'"
    :max-width="'md:max-w-xl'"
    @form-submitted="formSubmitted"
  >
    <template #body>
      <h2 class="text-gray-800 text-xl tracking-tighter">Enter your username and we will email you your password.</h2>
      <div>
        <div>
          <div class="flex justify-end text-gray-700 text-sm font-medium leading-5 tracking-tighter mt-4">
            <a class="underline" href="mailto:team@bottle.com?subject=I%20forgot%20my%20username">
              <i>Forgot your username?</i>
            </a>
          </div>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input
              id="handle"
              v-model="data.handle"
              type="text"
              pattern="[a-zA-Z0-9]+"
              required=""
              autocomplete="off"
              class="placeholder-gray-400 block px-3 py-3.5 rounded-l-lg w-1/2 focus:border-gray-800 border-2 border-gray-200 border-r-0 focus:ring-0 appearance-none transition duration-150 ease-in-out text-sm leading-5 focus:outline-none font-semibold tracking-wide"
              placeholder="BananaStand"
            />
            <span
              class="w-full inline-flex items-center rounded-r-lg border-2 border-l-0 border-gray-200 bg-gray-100 px-3 text-gray-600 text-sm tracking-tight"
            >
              .bottle.com
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-col">
        <div class="w-full flex justify-between">
          <button
            type="button"
            class="text-sm inline-flex items-center justify-center border-gray-800 border-2 text-md hover:shadow-lg transition-all rounded-lg px-3 md:px-8 py-3.5 leading-tight bg-white text-gray-800 tracking-tighter"
            @click="loginClicked"
          >
            <span class="hidden md:block">
              <span class="font-bold">Log in</span>
            </span>
            <span class="block md:hidden">
              <span class="font-bold">Log in</span>
            </span>
          </button>
          <button
            type="submit"
            class="ml-2 inline-flex items-center border-gray-800 border-2 font-bold text-md hover:shadow-lg transition-all rounded-full px-6 py-3.5 leading-tight bg-yellow-200 text-gray-800"
          >
            <span class="ml-auto transform -translate-y-px mr-4">Send me my password</span>
            <span class="mr-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="13" viewBox="0 0 34 13">
                <path
                  d="M3.11,6.82A20,20,0,0,1,1.2,6a.46.46,0,0,0-.67.15A2.32,2.32,0,0,0,0,7.25C0,7.64,0,7.84.3,8c.59.25,1.18.52,1.78.72"
                  transform="translate(0 0)"
                ></path>
                <path
                  d="M1.83,8.58A23.56,23.56,0,0,0,7.36,10a36.41,36.41,0,0,0,8.79,0A53.55,53.55,0,0,0,29.69,6.72l.19-.07,0,0c-.33.4-.65.8-1,1.19-.72.87-1.45,1.73-2.16,2.61a1.75,1.75,0,0,0,0,2.28.68.68,0,0,0,.52.22.73.73,0,0,0,.5-.26c.67-.78,1.33-1.58,2-2.39l3.65-4.41A2.13,2.13,0,0,0,34,4.5,1.24,1.24,0,0,0,33.91,4a.87.87,0,0,0-.28-.36,1.55,1.55,0,0,0-.32-.2L26.59.11c-.65-.31-1.25.05-1.54.92a1.22,1.22,0,0,0,.4,1.5c.95.49,1.91,1,2.86,1.42l1.78.89-.19.08A55.6,55.6,0,0,1,17.44,8a36.08,36.08,0,0,1-8.87.12A21.78,21.78,0,0,1,2.82,6.7c-.3-.12-.73-.1-1-.25"
                  fill="#000000"
                  transform="translate(0 0)"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </template>
  </SignupMainContainer>
</template>
<script>
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

import api from '@/store/api'

export default defineComponent({
  setup() {
    const router = useRouter()
    const data = reactive({
      handle: ''
    })

    const formSubmitted = () => {
      api
        .call(
          'POST',
          'password_reset',
          {
            handle: data.handle
          },
          false
        )
        .then(response => {
          router.push('/login?reset-success')
        })
    }

    const loginClicked = () => {
      router.push('/login')
    }

    return {
      formSubmitted,
      data,
      loginClicked
    }
  }
})
</script>
