import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"

import "./asset/style/app.css?vue&type=style&index=0&lang=css&external"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import OmniSearch from "@/components/OmniSearch.vue";
script.components = Object.assign({}, { OmniSearch }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { OmniSearch }, script.__o.components);}