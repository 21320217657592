import store from '@/store'
import api from '@/store/api'

class ApiService {
  uri
  resource
  included
  constructor(options) {
    const { uri, resource, included } = options
    this.uri = uri
    this.resource = resource
    this.included = included
  }

  createResource(data) {
    return api.call('POST', this.uri, data).then(response => {
      store.commit('RECEIVE_OBJECT', response.data)
      return response.data
    })
  }
  updateResource(data) {
    return api.call('PUT', this.uri, data).then(response => {
      store.commit('RECEIVE_OBJECT', response.data)
      return response.data
    })
  }
  deleteResource(id) {
    // TODO: Implement
  }
  getResource() {
    return api.call('GET', this.uri).then(response => {
      return response.data
    })
  }
  getManyResources(options) {
    store.commit('SET_LOADING_STATE', `${this.resource}`)
    const { direction, cursor, offset, datePaginated } = options || {}
    let url
    if (datePaginated) {
      // Date paginated
      url =
        `${this.uri}?cursor=${cursor}&date_paginated=${datePaginated}&direction=${direction}` +
        `&included=${this.included}`
    } else if (direction) {
      // Cursor paginated
      url = `${this.uri}?cursor=${cursor}&offset=${offset}&direction=${direction}` + `&included=${this.included}`
    } else {
      url = this.uri + `?included=${this.included}`
    }
    return api.call('GET', url).then(response => {
      const data = direction ? { ...response.data, direction: direction } : response.data
      store.commit('RECEIVE_OBJECT', data)
      store.commit('SET_LOADED_STATE', `${this.resource}`)
    })
  }
}

export default ApiService
