
import { CreditCardIcon, IdentificationIcon, ScaleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ScaleIcon,
    IdentificationIcon,
    CreditCardIcon
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer
    }
  }
}
