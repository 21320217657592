<template>
  <div
    class="w-full rounded-lg overflow-hidden hover:bg-gray-100 transition-all duration-200"
    :class="{ 'md:hover:pl-1': itemRoute }"
  >
    <router-link
      :to="itemRoute"
      class="group flex justify-between items-center pr-2 py-1.5 rounded-lg transition ease-in-out duration-150"
      :class="itemRoute == '' ? 'cursor-text' : 'cursor-pointer'"
    >
      <div class="flex justify-start items-center">
        <div :class="itemTheme" class="text-gray-brand-500 flex flex-shrink-0 items-center justify-center rounded-md">
          <component :is="itemIcon" class="p-0.5 md:p-2 w-6 h-6 sm:w-8 sm:h-8"></component>
        </div>
        <div class="ml-2">
          <p class="text-gray-600 text-xs font-semibold tracking-tight">{{ activity.attributes.description }}</p>
          <p v-if="activity.attributes.secondary_text" class="text-gray-500 text-xs mb-1">
            {{ activity.attributes.secondary_text }}
          </p>
        </div>
      </div>
      <time
        class="transition-all duration-100 items-center inline-flex flex-shrink-0 px-2 py-0.5 rounded-full bg-gray-100 text-gray-700 font-manrope text-xs ml-2"
        :class="{
          'group-hover:text-gray-100 group-hover:bg-gray-700 group-hover:px-2.5 group-hover:py-1.5 group-hover:rounded-lg':
            itemRoute
        }"
      >
        <span :class="{ 'group-hover:hidden': itemRoute }">{{ timestamp }}</span>
        <span class="hidden" :class="{ 'group-hover:inline': itemRoute }">View</span>
        <ArrowRightIcon class="hidden" :class="{ 'ml-0.5 h-3 w-3 group-hover:inline': itemRoute }" aria-hidden="true" />
      </time>
    </router-link>
  </div>
</template>
<script>
import {
  ArrowRightIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  BanknotesIcon,
  BuildingLibraryIcon,
  CheckIcon,
  ClipboardDocumentListIcon,
  CursorArrowRaysIcon,
  ExclamationCircleIcon,
  HandThumbUpIcon,
  PlusIcon,
  ShoppingBagIcon,
  SparklesIcon,
  StarIcon,
  ReceiptRefundIcon,
  WindowIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline'

// TODO(llb): customize how types set themes and icons based on logic (TBD)
export default {
  components: {
    ExclamationCircleIcon,
    SparklesIcon,
    HandThumbUpIcon,
    CursorArrowRaysIcon,
    CheckIcon,
    WindowIcon,
    ArrowUturnRightIcon,
    ArrowUturnLeftIcon,
    BanknotesIcon,
    XMarkIcon,
    PlusIcon,
    ClipboardDocumentListIcon,
    ArrowRightOnRectangleIcon,
    BuildingLibraryIcon,
    ArrowRightIcon,
    StarIcon,
    ShoppingBagIcon,
    ReceiptRefundIcon
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    timestamp() {
      return new Intl.DateTimeFormat('en-US', {
        timeStyle: 'short'
      }).format(new Date(this.activity?.attributes.created_at))
    },
    // TODO:@llb verbose logic here, consolidate theme conditionals
    itemIcon() {
      if (this.activity.attributes.type === 'LinkClickActivity') return 'CursorArrowRaysIcon'
      if (this.activity.attributes.type === 'ConfirmationActivity') return 'CheckIcon'
      if (this.activity.attributes.type === 'ProceedToCheckoutActivity') return 'WindowIcon'
      if (this.activity.attributes.type === 'SkipActivity') return 'ArrowUturnRightIcon'
      if (this.activity.attributes.type === 'UnskipActivity') return 'ArrowUturnLeftIcon'
      if (this.activity.attributes.type === 'PaidActivity') return 'BanknotesIcon'
      if (this.activity.attributes.type === 'RefundActivity') return 'ReceiptRefundIcon'
      if (this.activity.attributes.type === 'CancelledActivity') return 'XMarkIcon'
      if (this.activity.attributes.type === 'NewContactActivity') return 'PlusIcon'
      if (this.activity.attributes.type === 'AnswerActivity') return 'ClipboardDocumentListIcon'
      if (this.activity.attributes.type === 'LoginActivity') return 'ArrowRightOnRectangleIcon'
      if (
        this.activity.attributes.type === 'AutomationActivity' ||
        this.activity.attributes.type === 'IntegrationActivity'
      )
        return 'SparklesIcon'
      if (this.activity.attributes.type === 'IssuedCreditActivity') return 'BuildingLibraryIcon'
      if (this.activity.attributes.type === 'MembershipTierActivity') return 'StarIcon'
      if (this.activity.attributes.type === 'ReferredActivity' || this.activity.attributes.type === 'ReferrerActivity')
        return 'HandThumbUpIcon'
      if (this.activity.attributes.type === 'AutofillActivity') return 'ShoppingBagIcon'

      return 'info-icon'
    },
    itemTheme() {
      if (this.activity.attributes.type === 'LinkClickActivity') return 'text-gray-brand-500'
      if (this.activity.attributes.type === 'ConfirmationActivity') return 'text-green-400'
      if (this.activity.attributes.type === 'ProceedToCheckoutActivity') return 'text-gray-brand-500'
      if (this.activity.attributes.type === 'PaidActivity') return 'text-green-500'
      if (this.activity.attributes.type === 'RefundActivity') return 'text-green-900'
      if (this.activity.attributes.type === 'CancelledActivity') return 'text-red-300'
      if (this.activity.attributes.type === 'NewContactActivity') return 'text-gray-brand-500'
      if (this.activity.attributes.type === 'AnswerActivity') return 'text-gray-brand-700'
      if (this.activity.attributes.type === 'LoginActivity') return 'text-gray-brand-800'
      if (
        this.activity.attributes.type === 'AutomationActivity' ||
        this.activity.attributes.type === 'IntegrationActivity'
      )
        return 'text-blue-800'
      if (this.activity.attributes.type === 'IssuedCreditActivity') return 'text-green-600'

      return 'text-blue-300'
    },
    itemRoute() {
      if (this.activity.relationships.bottle.data) return '/order/' + this.activity.relationships.bottle.data?.id
      return ''
    }
  }
}
</script>
