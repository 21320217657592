
export default {
  props: {
    isEmpty: {
      type: Boolean,
      required: true,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    hasError: {
      type: Boolean,
      required: true,
      default: false
    },
    emptyStateHeading: {
      type: String,
      required: false,
      default: 'No items yet' // No orders yet
    },
    emptyStateText: {
      type: String,
      required: false,
      default: 'Items will appear here' // Paid orders will appear here
    }
  }
}
