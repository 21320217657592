
import { Field, Form } from 'vee-validate'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: {
    Form,
    Field,
    SellSidebar
  },
  setup() {
    const router = useRouter()

    const product_data = reactive({
      name: '',
      default_price: '',
      description: '',
      sku: '',
      available_labels: [],
      labels: [],
      label_map: {},
      option_to_edit: null,
      has_quantity_limit: false,
      quantity_limit: null,
      type: 'Product'
    })

    const onInvalidSubmit = () => {
      document.querySelector('form').scrollIntoView({ behavior: 'smooth' })
    }

    const getLabels = () => {
      api.call('GET', 'labels').then(response => {
        product_data.available_labels = response.data.data
      })
    }

    const handleSubmit = () => {
      api
        .call('POST', 'products', {
          product: {
            product_name: product_data.name,
            default_price_cents: Math.round(product_data.default_price * 100),
            description: product_data.description,
            merchant_sku: product_data.sku,
            has_quantity_limit: product_data.has_quantity_limit,
            set_quantity_changes_attributes: [
              {
                value: product_data.quantity_limit
              }
            ],
            label_product_pairs_attributes: [
              // TODO(alicja): I know this is kinda horrible but it'll do for now
              ...Object.entries(product_data.label_map)
                .filter(([x, y]) => y)
                .map(([x, y]) => ({ label_id: x }))
            ],
            attachments_attributes: [product_data.image],
            type: product_data.type,
            account_credit_value_cents: Math.round(product_data.account_credit_value * 100)
          }
        })
        .then(response => {
          router.push(`/store/products/${response.data.data.id}`)
        })
    }

    const removeExisting = () => {
      product_data.existingImage = null
    }

    const uploaded = object => {
      product_data.image = object
    }

    const handleKeydown = event => {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    return {
      product_data,
      getLabels,
      onInvalidSubmit,
      handleSubmit,
      removeExisting,
      uploaded,
      handleKeydown,
      fieldIsRequired
    }
  },

  mounted() {
    this.getLabels()
  }
}
