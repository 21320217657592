
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { ref, watch } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    XMarkIcon,
    CheckCircleIcon
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props) {
    const data = ref({
      show: props.show
    })

    watch(
      () => props.show,
      newValue => {
        if (newValue) {
          data.value.show = newValue
          startTimerToHideNotification()
        }
      }
    )

    const closeClicked = () => {
      data.value.show = false
    }

    const startTimerToHideNotification = () => {
      setTimeout(function () {
        data.value.show = false
      }, 3000)
    }

    return {
      props,
      data,
      closeClicked
    }
  }
})
