import script from "./MembershipTierNew.vue?vue&type=script&setup=true&lang=js"
export * from "./MembershipTierNew.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import BasicContainer from "@/components/BasicContainer.vue";
import MainCol from "@/components/MainCol.vue";
import PageFooter from "@/components/PageFooter.vue";
import PageGrid from "@/components/PageGrid.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import SideCol from "@/components/SideCol.vue";
script.components = Object.assign({}, { BasicContainer, MainCol, PageFooter, PageGrid, SecondaryNav, SecondaryTop, SideCol }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { BasicContainer, MainCol, PageFooter, PageGrid, SecondaryNav, SecondaryTop, SideCol }, script.__o.components);}