import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "placeholder", "value"]


export default {
  __name: 'DescriptionListInput',
  props: ['placeholder', 'type', 'modelValue'],
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("input", {
    type: props.type || 'text',
    class: "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm",
    placeholder: props.placeholder,
    value: props.modelValue,
    onInput: _cache[0] || (_cache[0] = e => emit('update:modelValue', e.target.value))
  }, null, 40, _hoisted_1))
}
}

}