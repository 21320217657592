
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon
  },
  props: {
    menuItems: {
      type: Object,
      required: true
    },
    itemId: {
      type: Number,
      required: false,
      default: 0
    },
    isPrimary: {
      type: Boolean,
      required: false,
      default: false
    },
    absolute: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonClass: {
      type: String,
      required: false,
      default: 'border'
    },
    submitType: {
      type: String,
      required: false,
      default: 'submit'
    }
  },
  emits: ['on-actions-menu-click'],
  setup(props, context) {
    const onMenuItemClick = (handler, id) => {
      context.emit('on-actions-menu-click', handler, id)
    }

    return {
      onMenuItemClick
    }
  }
})
