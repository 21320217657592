
import { closable } from '@/directives/closable'
import api from '@/store/api'

const NOTES_KEY = 'notes-01EWNTQ20SV8YJAEC2F2Q3X6C2'

export default {
  directives: {
    closable
  },
  props: {
    conversationUserPairId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      note: { value: '' },
      noteboxActive: false
    }
  },
  computed: {
    apiEndpoint() {
      return `conversation_user_pairs/${this.$props.conversationUserPairId}/custom_attributes`
    },
    buttonText() {
      if (!this.noteboxActive) return 'Edit'
      else return 'Save'
    },
    noteboxValue() {
      if (this.note.value.length) return this.note.value
      return 'Click here to add a note.'
    }
  },
  mounted() {
    api.call('GET', this.apiEndpoint).then(response => {
      if (!this.setNote(response)) {
        api
          .call('POST', this.apiEndpoint, {
            custom_attribute: {
              key_string: NOTES_KEY,
              value_string: ''
            }
          })
          .then(this.setNote)
      }
    })
  },
  methods: {
    setNote(response) {
      let attr
      if (Array.isArray(response.data.data)) {
        attr = response.data.data.find(attr => attr.attributes.key_string_downcased === NOTES_KEY.toLowerCase())
      } else {
        attr = response.data.data
      }
      if (attr) {
        this.note = {
          id: attr.attributes.id,
          key: attr.attributes.key_string_downcased,
          value: attr.attributes.value_string
        }
        return true
      }
      return false
    },
    noteButtonClick() {
      // If notebox is focused, click emits saveNote()
      // Else focus the cursor
      if (this.noteboxActive) return this.saveNote()
      else return this.noteboxFocus()
    },
    noteboxFocus() {
      this.noteboxActive = true
      // nextTick doesn't work here because of the transition (100 ms)
      setTimeout(() => {
        this.$refs.notes.select()
      }, 150)
    },
    noteboxInactive() {
      this.noteboxActive = false
    },
    saveNote() {
      // Set state and button text
      this.noteboxActive = false

      api.call('PUT', this.apiEndpoint + '/' + this.note.id, {
        custom_attribute: {
          key_string: NOTES_KEY,
          value_string: this.note.value
        }
      })
    }
  }
}
