import script from "./ProductIngredients.vue?vue&type=script&setup=true&lang=js"
export * from "./ProductIngredients.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import EmptyState from "@/components/neue/EmptyState.vue";
import FormElement from "@/components/neue/FormElement.vue";
import FormSection from "@/components/neue/FormSection.vue";
import InputArea from "@/components/neue/InputArea.vue";
import InputField from "@/components/neue/InputField.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueTablist from "@/components/neue/NeueTablist.vue";
import SelectInput from "@/components/neue/SelectInput.vue";
import TablistHeader from "@/components/neue/TablistHeader.vue";
import TablistRow from "@/components/neue/TablistRow.vue";
import TheModal from "@/components/TheModal.vue";
script.components = Object.assign({}, { EmptyState, FormElement, FormSection, InputArea, InputField, NeueButton, NeueTablist, SelectInput, TablistHeader, TablistRow, TheModal }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { EmptyState, FormElement, FormSection, InputArea, InputField, NeueButton, NeueTablist, SelectInput, TablistHeader, TablistRow, TheModal }, script.__o.components);}