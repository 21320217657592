<template>
  <div>
    <slot name="header" />
    <!-- Loaded -->
    <div v-if="!isLoading && listLength">
      <div class="mx-auto px-4 max-w-6xl sm:px-6 lg:px-8">
        <div class="flex flex-col mt-4">
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <slot name="table" />
                </div>
                <slot name="pagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="isLoading && !listLength" class="flex justify-center flex-col h-3/4">
      <div class="mx-auto h-5 w-5 mt-5">
        <BasicSpinner color="gray-900" />
      </div>
      <SimpleEmptyState
        class="mt-6 mb-4"
        heading="Fetching the things..."
        description="Hang on while we get that for you"
      />
    </div>
    <!-- Empty -->
    <div v-if="!isLoading && !listLength" class="flex justify-center flex-col h-3/4">
      <SimpleEmptyState
        :heading="emptyStateHeading"
        :description="emptyStateDescription"
        icon="ClipboardDocumentListIcon"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    listLength: {
      type: Number,
      required: true
    },
    emptyStateHeading: {
      type: String,
      required: false,
      default: 'No items yet'
    },
    emptyStateDescription: {
      type: String,
      required: false,
      default: "Get started by clicking 'New'"
    }
  }
})
</script>
