<template>
  <BasicContainer>
    <div class="flex justify-between">
      <label class="block text-sm font-medium text-gray-700">
        <span>Image</span>
        <button
          v-show="existingUploadThumbnailPath"
          class="block text-xs text-gray-500 hover:text-gray-700"
          @click.prevent="removeExistingUpload"
        >
          Remove image
        </button>
        <button
          v-show="files.length && !existingUploadThumbnailPath"
          class="block text-xs text-gray-500 hover:text-gray-700"
        >
          Remove image
        </button>
      </label>
      <div>
        <BasicSpinner v-show="isLoading.active" color="gray-900" />
        <FileUpload
          v-show="!isLoading.active"
          ref="upload"
          v-model="files"
          :input-id="id"
          :name="id"
          :custom-action="uploadToCloudinary"
          class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-xs leading-5 font-medium rounded-md text-gray-500 bg-white transition-colors duration-150 hover:border-blue-600 hover:text-blue-600"
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          :multiple="false"
          :drop="true"
          :size="1024 * 1024 * 10"
          @input-filter="inputFilter"
          @input-file="inputFile"
        >
          {{ files.length || existingUploadThumbnailPath ? 'Replace' : 'Select file' }}
        </FileUpload>
      </div>
    </div>
    <div class="flex items-center relative">
      <div v-show="files.length || existingUploadThumbnailPath" class="w-full">
        <div class="aspect-w-8 aspect-h-8">
          <img
            class="w-full h-full object-center object-cover rounded"
            :src="existingUploadThumbnailPath ? existingUploadThumbnailPath : files[0]?.thumb"
            alt=""
          />
        </div>
      </div>
      <div v-show="!files.length && !existingUploadThumbnailPath" class="w-full">
        <div class="aspect-w-8 aspect-h-8">
          <div
            :class="[
              'w-full h-full flex flex-col border border-gray-50 text-xs leading-5 font-medium justify-center items-center bg-gray-50 text-gray-500 transition-colors duration-200 rounded-lg overflow-hidden',
              { 'text-blue-500 bg-gray-100 border-blue-600 ': $refs.upload && $refs.upload.dropActive }
            ]"
          >
            <ArrowUpTrayIcon class="h-5 w-5" />
            <p class="mt-2">Drop files to upload</p>
          </div>
        </div>
      </div>
    </div>
  </BasicContainer>
</template>
<script>
import { ArrowUpTrayIcon } from '@heroicons/vue/24/outline'
import { ulid } from 'ulid'
import { ref } from 'vue'
import FileUpload from 'vue-upload-component'

import CloudinaryService from '@/services/cloudinary.service'

export const UploadService = new CloudinaryService()

export default {
  components: {
    FileUpload,
    ArrowUpTrayIcon
  },
  props: {
    existingUploadThumbnailPath: {
      type: String,
      required: false,
      default: null
    },
    attachmentId: {
      type: Number || String,
      required: false
    }
  },
  emits: ['add-upload', 'updated-upload', 'removed-upload', 'uploaded'],
  setup(props, context) {
    const id = ulid()
    const upload = ref({})

    const isLoading = ref({ active: false })

    const removeExistingUpload = () => {
      context.emit('removed-upload', props.attachmentId)
      files.value = []
    }

    const files = ref([])
    function inputFilter(newFile, oldFile, prevent) {
      // Before adding a file
      if (newFile && !oldFile) {
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
      // Handle thumbnail
      if (newFile && newFile.error === '' && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    }
    function inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        upload.value.active = true
        context.emit('add-upload', newFile)
      }
      if (newFile && oldFile) {
        context.emit('updated-upload', newFile)
        upload.value.active = true
      }
      if (!newFile && oldFile) {
        context.emit('removed-upload', '')
      }
    }

    const uploadToCloudinary = async file => {
      isLoading.value.active = true

      UploadService.uploadImages([file.file]).then(response => {
        isLoading.value.active = false
        const responseObject = response
        if (files.value.length) {
          context.emit('uploaded', responseObject)
        }
      })
    }

    return {
      files,
      upload,
      inputFilter,
      inputFile,
      removeExistingUpload,
      uploadToCloudinary,
      isLoading,
      id
    }
  }
}
</script>
<style>
.file-uploads label {
  @apply cursor-pointer;
}
</style>
