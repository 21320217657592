<template>
  <div class="w-full flex justify-center pt-12 pb-2">
    <nav aria-label="Progress" class="w-full md:w-1/2 px-4 md:px-0">
      <ol role="list" class="flex space-y-0 space-x-2">
        <li v-for="(step, index) in steps" class="flex-1">
          <a
            v-if="index <= props.currentStep"
            :href="step.url"
            class="flex flex-col bg-gray-800 h-1 rounded-full"
            aria-current="step"
          ></a>
          <a
            v-else-if="index <= props.totalProgress"
            :href="step.url"
            class="group flex flex-col bg-gray-200 hover:bg-gray-300 h-1 rounded-full"
          ></a>
          <a v-else class="group flex flex-col bg-gray-200 hover:bg-gray-300 h-1 rounded-full"></a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    steps: {
      type: Array,
      required: true,
      default: []
    },
    totalProgress: {
      type: Number,
      required: true,
      default: 0
    },
    currentStep: {
      type: Number,
      required: true,
      default: 0
    }
  },

  setup(props) {
    return {
      props
    }
  }
})
</script>
