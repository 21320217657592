
import { MenuItem } from '@headlessui/vue'
import {
  CheckIcon,
  CurrencyDollarIcon,
  HashtagIcon,
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon
} from '@heroicons/vue/20/solid'
import * as R from 'ramda'
import { DatePicker } from 'v-calendar'
import { Field, Form } from 'vee-validate'
import { computed, onMounted, reactive } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import Draggable from 'vuedraggable'
import { useStore } from 'vuex'

import { dateObjectToServerDay, serverDayToDateObject } from '@/helpers/dates'
import CloudinaryService from '@/services/cloudinary.service'
import api from '@/store/api'
import { mapRelationship, mapRelationships } from '@/store/mappers'
import SellSidebar from '@/view/store/SellSidebar'

const UploadService = new CloudinaryService()

export default {
  components: {
    CurrencyDollarIcon,
    PencilIcon,
    XMarkIcon,
    Draggable,
    MenuItem,
    CheckIcon,
    Field,
    Form,
    DatePicker,
    HashtagIcon,
    InformationCircleIcon,
    SellSidebar
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    onBeforeRouteLeave(() => {
      if (vLocal.status.hasUnsavedChanges) {
        vLocal.modal.type = 'confirmCancelChangesStoreModal'
        return false
      } else return true
    })

    const id = route.params.id

    const vLocal = reactive({
      name: '',
      defaultPrice: '',
      accountCreditValue: '',
      description: '',
      image: {},
      lastSavedImage: '',
      taxInputSearch: '',
      sku: '',
      uniqueQuantityNameSingle: '',
      startingQuantity: '',
      step: '',
      hasQuantityLimit: false,
      quantityLimit: null,
      modal: {
        type: null,
        mode: null,
        content: {}
      },
      allProducts: [],
      images: null,
      variants: null,
      options: {},
      status: {
        fallbackProduct: {
          isLoading: false,
          hasError: false
        },
        hasUnsavedChanges: false
      },
      pairs: []
    })

    const vStore = useStore()

    const vProduct = computed(() => mapRelationship({ type: 'product', id }))

    const vImage = product => {
      const images = mapRelationships(product?.relationships?.attachments.data)
      return images[0]?.attributes ? images[0].attributes.secure_url : null
    }

    const vImages = computed(() =>
      mapRelationships(vProduct.value?.relationships.attachments.data).sort(
        (a, b) => a.attributes.order - b.attributes.order
      )
    )

    const vStoreImage = store => {
      const images = mapRelationships(store?.relationships?.attachments.data)
      return images[0]?.attributes ? images[0].attributes.secure_url : null
    }

    const allProductTaxCodes = computed(() => {
      if (!vStore.state?.objects?.product_tax_code) {
        return [{ code: '', name: 'None', description: 'None' }]
      }

      return [{ code: '', name: 'None', description: 'None' }].concat(
        Object.values(vStore.state?.objects?.product_tax_code)
          .map(taxCode => {
            return {
              id: taxCode.attributes.id,
              code: taxCode.attributes.code,
              description: taxCode.attributes.description,
              name: taxCode.attributes.name
            }
          })
          .filter(
            taxCode =>
              taxCode.name.toLowerCase().includes(vLocal.taxInputSearch.toLowerCase()) ||
              taxCode.code.toLowerCase().includes(vLocal.taxInputSearch.toLowerCase())
          )
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      )
    })

    const getAllProducts = () => {
      api.call('GET', 'products?included=attachments').then(response => {
        vLocal.allProducts = response.data.data
      })
    }

    const getAllTaxProductTaxCodes = () => {
      api.call('GET', 'products/tax_codes')
    }

    const taxSearchInputChanged = value => {
      vLocal.taxInputSearch = value
    }

    const taxOptionSelected = option => {
      vLocal.status.hasUnsavedChanges = true
      vLocal.taxSelectedCode = option.code
    }

    const indexForSelectedTaxOption = allTaxProducts => {
      if (!vLocal.taxSelectedCode) {
        return 0
      }

      return allTaxProducts.findIndex(taxObject => {
        return taxObject.code === vLocal.taxSelectedCode
      })
    }

    const taxCodeForProduct = product => {
      if (product?.name === 'None') return ''
      return `#${product?.code}`
    }

    const vVariants = computed(() => mapRelationships(vProduct.value?.relationships.variants.data))

    const vOptions = variant => mapRelationships(variant.relationships.variant_options.data)

    const vAllLabels = computed(() => vStore.state.objects.label && Object.values(vStore.state.objects.label))

    const vLabelChecked = label => {
      const labelPairs = mapRelationships(vProduct.value?.relationships.label_product_pairs.data)
      return labelPairs.some(x => x.relationships.label.data.id === label.id)
    }

    const optionModal = reactive({
      visible: false,
      variant: null,
      option: null,
      mode: '',
      name: '',
      matchName: '',
      description: '',
      price: '0.00'
    })

    const variantModal = reactive({
      visible: false,
      variant: null,
      mode: '',
      name: '',
      required: true,
      quantity: 'choose_one',
      minimum: 1,
      maximum: 100,
      customerVisible: true
    })

    const pairModal = reactive({ mode: '', visible: false, content: {} })

    const addVariant = () => {
      variantModal.mode = 'add'
      variantModal.visible = true
    }

    const removeVariant = variant => {
      api.call('PUT', 'products/' + route.params.id, {
        product: { variants_attributes: [{ id: variant.id, _destroy: true }] }
      })
    }

    const resetOptionModal = () => {
      optionModal.visible = false
      optionModal.name = ''
      optionModal.matchName = ''
      optionModal.description = ''
      optionModal.price = '0.00'
    }

    const resetVariantModal = () => {
      variantModal.visible = false
      variantModal.name = ''
      variantModal.required = true
      variantModal.quantity = 'choose_one'
      variantModal.minimum = 1
      variantModal.maximum = 100
      variantModal.customerVisible = true
    }

    const addOption = variant => {
      optionModal.mode = 'add'
      optionModal.variant = variant
      optionModal.visible = true
    }

    const editOption = option => {
      optionModal.mode = 'edit'
      optionModal.name = option.attributes.name
      optionModal.matchName = option.attributes.autofill_preference_override
      optionModal.description = option.attributes.description
      optionModal.price = (option.attributes.price.cents / 100).toFixed(2)
      optionModal.option = option
      optionModal.visible = true
    }

    const editVariant = variant => {
      variantModal.mode = 'edit'
      variantModal.name = variant.attributes.name
      variantModal.required = variant.attributes.required
      variantModal.quantity = variant.attributes.quantity_option
      variantModal.variant = variant
      variantModal.visible = true
      variantModal.minimum = variant.attributes.minimum
      variantModal.maximum = variant.attributes.maximum
      variantModal.customerVisible = variant.attributes.is_visible
    }

    const addPair = () => {
      pairModal.mode = 'add'
      pairModal.content.stores = Object.values(vStore.state.objects.store || {}).filter(x => x.attributes.is_active)
      pairModal.visible = true
    }

    const vStoreCategories = storeId => {
      const store = mapRelationship({ type: 'store', id: storeId })
      return mapRelationships(store?.relationships.categories.data).filter(x => x.attributes.type !== 'UpsellCategory')
    }

    const editPair = pair => {
      pairModal.mode = 'edit'
      const rawPrices = mapRelationships(pair.relationships.product_store_membership_prices.data)
      const prices = rawPrices.map(price => {
        const tier = mapRelationship(price.relationships.membership_tier.data)
        return {
          priceId: price.id,
          tierId: tier.id,
          name: tier.attributes.tier_name,
          price: price.attributes.price.cents / 100,
          subject: price.attributes.subject_to_pricing_tiers,
          available: price.attributes.available_for_membership_tier,
          default: price.attributes.use_default_price
        }
      })
      pairModal.content = {
        pair,
        store: mapRelationship(pair.relationships.store.data),
        product: vPairProduct(pair),
        image: vImage(vPairProduct(pair)),
        labels: mapRelationships(vPairProduct(pair).relationships.labels.data),
        membershipPrices: prices,
        taxable: pair.attributes.taxable,
        allowsAutofill: pair.attributes.allows_autofill,
        showPrice: pair.attributes.show_prices,
        productCardStyle: pair.attributes.product_card_style,
        quantityDisplay: pair.attributes.quantity_selector_type,
        alwaysAvailable: pair.attributes.available_for_all_fulfillment_availabilities,
        newDay: new Date(),
        hasInventoryLimit: Object.fromEntries(vDays(pair).map(x => [x.id, x.attributes.limit_quantity])),
        inventoryLimit: Object.fromEntries(vDays(pair).map(x => [x.id, x.attributes.quantity_limit])),
        fallback: mapRelationship(vPairProduct(pair).relationships.replace_with_product.data),
        reverseFallback: mapRelationships(vPairProduct(pair).relationships.replaces_products.data)
      }
      // isTaxable: categories.visibleCategories[i].products[ii].hasTaxable,
      // hasDiscounts: categories.visibleCategories[i].products[ii].hasDiscounts,
      // fulfillmentMethods: categories.visibleCategories[i].products[ii].fulfillmentMethods,
      pairModal.visible = true
    }

    const makePair = () => {
      api
        .call('POST', `stores/${pairModal.content.store}/category_product_store_pairs`, {
          category_id: pairModal.content.category,
          product_id: vProduct.value.id
        })
        .then(() => {
          // TODO(alicja): the proper solution is to change mapRel behavior
          // so we can have the list of stores as computed instead of loading it in getProduct
          getProduct()
        })
      pairModal.visible = false
    }

    const putPair = () => {
      api.call(
        'PUT',
        `stores/${pairModal.content.pair.relationships.store.data.id}/category_product_store_pairs/${pairModal.content.pair.id}?included=fulfillment_availabilities,product_store_membership_prices`,
        {
          category_product_store_pair: {
            taxable: pairModal.content.taxable,
            allows_autofill: pairModal.content.allowsAutofill,
            show_prices: pairModal.content.showPrice,
            product_card_style: pairModal.content.productCardStyle,
            quantity_selector_type: pairModal.content.quantityDisplay,
            available_for_all_fulfillment_availabilities: pairModal.content.alwaysAvailable,
            product_store_membership_prices_attributes: pairModal.content.membershipPrices.map(x => ({
              id: x.priceId,
              membership_tier_id: x.tierId,
              price_cents: Math.round(x.price * 100),
              subject_to_pricing_tiers: x.subject,
              available_for_membership_tier: x.available,
              use_default_price: x.default
            })),
            fulfillment_availabilities_attributes: vDays(pairModal.content.pair).map(x => ({
              id: x.id,
              quantity_limit: pairModal.content.inventoryLimit[x.id],
              limit_quantity: pairModal.content.hasInventoryLimit[x.id]
            }))
          }
        }
      )
    }

    const deletePair = async pair => {
      await api.call(
        'DELETE',
        `stores/${pair.relationships.store.data.id}/category_product_store_pairs/${pair.id}`,
        {},
        { delete: false }
      )
      // TODO(alicja): the proper solution is to change mapRel behavior
      // so we can have the list of stores as computed instead of loading it in getProduct
      getProduct()
    }

    const formatDay = x => new Intl.DateTimeFormat([], { dateStyle: 'full' }).format(serverDayToDateObject(x))

    const postDay = () => {
      api
        .call(
          'PUT',
          `stores/${pairModal.content.pair.relationships.store.data.id}/category_product_store_pairs/${pairModal.content.pair.id}?included=fulfillment_availabilities`,
          {
            category_product_store_pair: {
              fulfillment_availabilities_attributes: [
                {
                  date: dateObjectToServerDay(pairModal.content.newDay)
                }
              ]
            }
          }
        )
        .then(response => {
          pairModal.content.pair = response.data.data
        })
    }

    const deleteDay = day => {
      api
        .call(
          'PUT',
          `stores/${pairModal.content.pair.relationships.store.data.id}/category_product_store_pairs/${pairModal.content.pair.id}`,
          {
            category_product_store_pair: {
              fulfillment_availabilities_attributes: [
                {
                  id: day.id,
                  _destroy: true
                }
              ]
            }
          }
        )
        .then(response => {
          pairModal.content.pair = response.data.data
        })
    }

    const vPairProduct = pair => vStore.state.objects.product[pair.attributes.product_id]

    const vDays = pair => mapRelationships(pair.relationships.fulfillment_availabilities.data)

    const saveOption = () => {
      if (optionModal.mode === 'add') {
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                id: optionModal.variant.id,
                variant_options_attributes: [
                  {
                    name: optionModal.name,
                    autofill_preference_override: optionModal.matchName,
                    description: optionModal.description,
                    price_cents: Math.round(optionModal.price * 100)
                  }
                ]
              }
            ]
          }
        })
      }
      if (optionModal.mode === 'edit') {
        const variant = mapRelationship(optionModal.option.relationships.variant.data)
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                id: variant.id,
                variant_options_attributes: [
                  {
                    id: optionModal.option.id,
                    name: optionModal.name,
                    autofill_preference_override: optionModal.matchName,
                    description: optionModal.description,
                    price_cents: Math.round(optionModal.price * 100)
                  }
                ]
              }
            ]
          }
        })
      }

      resetOptionModal()
    }

    const saveVariant = () => {
      if (variantModal.mode === 'add') {
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                name: variantModal.name,
                required: variantModal.required,
                quantity_option: variantModal.quantity,
                minimum: variantModal.minimum,
                maximum: variantModal.maximum,
                is_visible: variantModal.customerVisible
              }
            ]
          }
        })
      }
      if (variantModal.mode === 'edit') {
        api.call('PUT', 'products/' + route.params.id, {
          product: {
            variants_attributes: [
              {
                id: variantModal.variant.id,
                name: variantModal.name,
                required: variantModal.required,
                quantity_option: variantModal.quantity,
                minimum: variantModal.minimum,
                maximum: variantModal.maximum,
                is_visible: variantModal.customerVisible
              }
            ]
          }
        })
      }

      resetVariantModal()
    }

    const toggleLabel = label => {
      const labelPairs = mapRelationships(vProduct.value.relationships.label_product_pairs.data)
      const pair = labelPairs.find(x => x.relationships.label.data.id === label.id)
      if (pair) {
        // uncheck
        api.call('PUT', 'products/' + route.params.id, {
          product: { label_product_pairs_attributes: [{ id: pair.id, _destroy: true }] }
        })
      } else {
        // check
        api.call('PUT', 'products/' + route.params.id, {
          product: { label_product_pairs_attributes: [{ label_id: label.id }] }
        })
      }
    }

    const removeOption = option => {
      const variant = mapRelationship(option.relationships.variant.data)
      api.call('PUT', 'products/' + route.params.id, {
        product: {
          variants_attributes: [{ id: variant.id, variant_options_attributes: [{ id: option.id, _destroy: true }] }]
        }
      })
    }

    const handleSubmit = () => {
      api
        .call('PUT', 'products/' + route.params.id, {
          product: {
            product_name: vLocal.name,
            default_price_cents: Math.round(vLocal.defaultPrice * 100),
            account_credit_value_cents: Math.round(vLocal.accountCreditValue * 100),
            description: vLocal.description,
            merchant_sku: vLocal.sku,
            unique_quantity_name_single: vLocal.uniqueQuantityNameSingle,
            starting_quantity: vLocal.startingQuantity,
            step: vLocal.step,
            tax_code: vLocal.taxSelectedCode,
            has_quantity_limit: vLocal.hasQuantityLimit,
            requires_fulfillment: vLocal.requiresFulfillment,
            set_quantity_changes_attributes: [
              {
                value: vLocal.quantityLimit
              }
            ]
          }
        })
        .then(() => {
          vLocal.status.hasUnsavedChanges = false
        })
    }

    const deleteProduct = () => {
      api.call('DELETE', 'products/' + route.params.id).then(router.back)
    }

    const copyProduct = () => {
      api.call('POST', `products/${route.params.id}/copies`).then(async response => {
        await router.push(response.data.data.id)
        router.go(0)
      })
    }

    const deleteImage = attachment => {
      api.call('PUT', `products/${id}`, {
        product: {
          attachments_attributes: [{ id: attachment.id, _destroy: true }]
        }
      })
    }

    const saveImage = (file, id) => {
      const maxOrder = vImages.value.reduce((max, x) => Math.max(max, x.attributes.order), -1)

      UploadService.uploadImages([file]).then(response => {
        api.call('PUT', `products/${id}`, {
          product: {
            attachments_attributes: [
              {
                ...response[0],
                order: maxOrder + 1
              }
            ]
          }
        })
      })
    }

    const dragImage = e => {
      const list = vImages.value
      const moved = list[e.oldIndex]
      list.splice(e.oldIndex, 1)
      list.splice(e.newIndex, 0, moved)
      vLocal.images = list
      api
        .call('PUT', `products/${id}`, {
          product: {
            attachments_attributes: list.map((x, i) => ({
              id: x.id,
              order: i
            }))
          }
        })
        .then(() => {
          vLocal.images = null
        })
    }

    const dragVariant = e => {
      const list = vVariants.value
      const moved = list[e.oldIndex]
      list.splice(e.oldIndex, 1)
      list.splice(e.newIndex, 0, moved)
      vLocal.variants = list
      api
        .call('PUT', `products/${id}`, {
          product: {
            variants_attributes: list.map((x, i) => ({
              id: x.id,
              order: i
            }))
          }
        })
        .then(() => {
          vLocal.variants = null
        })
    }

    const dragOption = (variant, e) => {
      const list = vOptions(variant)
      const moved = list[e.oldIndex]
      list.splice(e.oldIndex, 1)
      list.splice(e.newIndex, 0, moved)
      vLocal.options[variant.id] = list
      api
        .call('PUT', `products/${id}`, {
          product: {
            variants_attributes: [
              {
                id: variant.id,
                variant_options_attributes: list.map((x, i) => ({
                  id: x.id,
                  order: i
                }))
              }
            ]
          }
        })
        .then(() => {
          vLocal.options[variant.id] = null
        })
    }

    const resetModalContent = () => {
      vLocal.modal.content = {}
      vLocal.modal.mode = 'add'
      vLocal.modal.type = null
    }

    const addFallbackProduct = () => {
      vLocal.modal.mode = 'add'
      vLocal.modal.type = 'fallbackProductModal'
      vLocal.modal.content = {
        fallbackProduct: []
      }
    }

    const saveFallbackProduct = productId => {
      const replaceWith = productId ? vLocal.modal.content.fallbackProduct[0].id : null
      const includes = ['replace_with_product']

      vLocal.status.fallbackProduct.isLoading = true

      api
        .call('PUT', `products/${id}?store=true&included=${includes.join(',')}`, {
          product: {
            replace_with_product_id: replaceWith
          }
        })
        .then(response => {
          const attributes = response.data.data.attributes

          vLocal.fallbackProduct = mapRelationship({ type: 'product', id: attributes.replace_with_product_id })

          vLocal.status.fallbackProduct.isLoading = false
          resetModalContent()
        })
        .catch(error => {
          vLocal.status.fallbackProduct.isLoading = false
          vLocal.status.fallbackProduct.hasError = false
          resetModalContent()
        })
    }

    const getProduct = () => {
      const includes = [
        'attachments',
        'variants',
        'variants.variant_options',
        'label_product_pairs',
        'replace_with_product',
        'category_product_store_pairs',
        'category_product_store_pairs.store',
        'category_product_store_pairs.store.attachments',
        'category_product_store_pairs.category',
        'category_product_store_pairs.product_store_membership_prices',
        'category_product_store_pairs.store.user.membership_tiers',
        'ingredients'
      ]
      api.call('GET', `products/${id}?store=true&included=${includes.join(',')}`).then(response => {
        const attributes = response.data.data.attributes
        const included = response.data.included

        vLocal.lastSavedImage = included.filter(x => x.type === 'attachment')[0]?.attributes.secure_url
        vLocal.name = attributes.product_name
        vLocal.type = attributes.type
        vLocal.defaultPrice = attributes.default_price?.cents ? (attributes.default_price?.cents / 100).toFixed(2) : ''
        vLocal.accountCreditValue = attributes.account_credit_value?.cents
          ? (attributes.account_credit_value?.cents / 100).toFixed(2)
          : ''
        vLocal.description = attributes.description
        vLocal.sku = attributes.merchant_sku
        vLocal.step = attributes.step
        vLocal.startingQuantity = attributes.starting_quantity
        vLocal.uniqueQuantityNameSingle = attributes.unique_quantity_name_single
        vLocal.quantityLimit = attributes.inventory_remaining
        vLocal.hasQuantityLimit = attributes.has_quantity_limit
        vLocal.requiresFulfillment = attributes.requires_fulfillment
        vLocal.fallbackProduct = mapRelationship({ type: 'product', id: attributes.replace_with_product_id })
        vLocal.taxSelectedCode = attributes.tax_code

        const pairStoreValid = R.pipe(
          R.path(['relationships', 'store', 'data']),
          mapRelationship,
          R.path(['attributes', 'is_active'])
        )

        // pair -> store -> categories -> ids
        const pairToCategoriesIds = R.pipe(
          R.path(['relationships', 'store', 'data']),
          mapRelationship,
          R.path(['relationships', 'categories', 'data']),
          R.pluck('id')
        )
        // pair -> category -> id
        const pairToCategoryId = R.path(['relationships', 'category', 'data', 'id'])
        const pairs = mapRelationships(response.data.data.relationships.category_product_store_pairs.data)
        // set pairs and filter for valid ones (still existing on the store)
        vLocal.pairs = pairs.filter(
          pair => pairToCategoriesIds(pair).includes(pairToCategoryId(pair)) && pairStoreValid(pair)
        )
      })
    }

    const getLabels = () => {
      api.call('GET', 'labels')
    }

    const getStores = () => {
      api.call('GET', 'stores?included=categories')
    }

    const handleKeydown = event => {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    }

    const handleProductSearchQuery = searchQuery => (vLocal.modal.searchQuery = searchQuery)
    const handleProductSearchResults = searchResults => (vLocal.modal.searchResults = searchResults)
    const handleProductSearchSelected = product => {
      vLocal.modal.content.fallbackProduct = [product]
    }
    const handleProductSearchRemoveSelected = () => {
      vLocal.modal.content.fallbackProduct = []
    }
    const searchSelectHelperText = {
      noResult: 'No results found',
      noneSelected: 'Select items',
      typingPrompt: 'Chicken Parmesan'
    }

    const handleDiscardChanges = async () => {
      // location.reload()
      await getProduct()
      vLocal.status.hasUnsavedChanges = false
    }

    const handleConfirmCancelChanges = () => {
      vLocal.status.hasUnsavedChanges = false
      router.push('/store/products')
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    onMounted(() => {
      getLabels()
      getProduct()
      getAllProducts()
      getAllTaxProductTaxCodes()
      getStores()
    })

    return {
      vLocal,
      vImage,
      handleSubmit,
      addVariant,
      removeVariant,
      optionModal,
      variantModal,
      addOption,
      saveOption,
      saveVariant,
      resetOptionModal,
      resetVariantModal,
      removeOption,
      editOption,
      editVariant,
      saveImage,
      vVariants,
      vOptions,
      vAllLabels,
      toggleLabel,
      vLabelChecked,
      handleKeydown,
      deleteProduct,
      addFallbackProduct,
      saveFallbackProduct,
      resetModalContent,
      id,
      vImages,
      deleteImage,
      dragImage,
      dragVariant,
      dragOption,
      handleProductSearchQuery,
      handleProductSearchResults,
      handleProductSearchSelected,
      handleProductSearchRemoveSelected,
      searchSelectHelperText,
      handleDiscardChanges,
      handleConfirmCancelChanges,
      getAllTaxProductTaxCodes,
      allProductTaxCodes,
      taxSearchInputChanged,
      taxOptionSelected,
      indexForSelectedTaxOption,
      taxCodeForProduct,
      mapRelationship,
      vStoreImage,
      editPair,
      pairModal,
      vPairProduct,
      vDays,
      putPair,
      deletePair,
      fieldIsRequired,
      postDay,
      deleteDay,
      formatDay,
      makePair,
      addPair,
      vStoreCategories,
      copyProduct
    }
  }
}
