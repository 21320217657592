<template>
  <Menu as="div" class="relative inline-block text-right w-full z-10">
    <div class="relative">
      <input
        ref="queryInput"
        v-model="local.query"
        type="text"
        class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 sm:text-sm border-gray-300 rounded"
        :placeholder="helperText.typingPrompt"
        @input="onSearch"
      />
      <button
        v-show="local.query.length"
        class="absolute pointer hover:text-gray-700 transition-colors duration-100 inset-y-0 right-0 flex cursor-pointer items-center pr-2"
        @click.prevent="onResetSearch"
      >
        <XMarkIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
      </button>
    </div>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        v-show="local.isOpen"
        static
        :class="position"
        class="absolute right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        @click.prevent="onSelected"
      >
        <div v-if="local.isSearching" class="px-1 py-1">
          <div class="mx-auto h-5 w-5 my-5">
            <BasicSpinner color="gray-900" />
          </div>
        </div>
        <div v-if="!local.isSearching && data.searchResults.length" class="px-1 py-1">
          <slot name="searchResults" />
        </div>
        <div v-if="!local.isSearching && !data.searchResults.length" class="px-3 py-3 text-left text-sm">
          {{ helperText.noResult }}
        </div>
      </MenuItems>
    </transition>
  </Menu>
  <slot name="selectedItems" />
</template>
<script>
import { Menu, MenuItems } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import Fuse from 'fuse.js'
import { defineComponent, nextTick, onMounted, ref } from 'vue'

import api from '@/store/api'

export default defineComponent({
  components: {
    XMarkIcon,
    Menu,
    MenuItems
  },
  props: {
    helperText: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      required: false,
      default: 'absolute'
    },
    searchEndpoint: {
      type: String,
      required: false,
      default: ''
    },
    searchRequest: {
      type: Object,
      required: false
    },
    localSearchOptions: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    fuseOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          includeScore: true,
          keys: ['name']
        }
      }
    }
  },
  emits: ['on-search', 'on-results', 'on-selected'],
  setup(props, context) {
    const data = ref({
      searchResults: []
    })

    const local = ref({
      query: '',
      isSearching: false,
      spinnerTimeout: null,
      isOpen: false,
      controller: new AbortController()
    })

    const queryInput = ref(null)

    const handleRemoteSearch = () => {
      nextTick(() => {
        local.value.controller.abort()
        local.value.controller = new AbortController()
        if (local.value.query) {
          api
            .call('POST', props.searchEndpoint, props.searchRequest, {
              save: false,
              abortController: local.value.controller
            })
            .then(r => {
              // [discuss] this does come back in the store under w/e types referenced by the endpoint, but add'l logic to map those types in the parent feels unecessary. Also, any references to those types from the template get logged in state, so that could surface an edgecase where the results are muddied.
              context.emit('on-results', r.data.data)
              data.value.searchResults = r.data.data
              clearTimeout(local.value.spinnerTimeout)
              local.value.isSearching = false
            })
            .catch(e => {
              if (e.name !== 'AbortError') throw e
            })
        }
      })
    }

    const handleLocalSearch = () => {
      const fuse = new Fuse(props.localSearchOptions, props.fuseOptions)
      data.value.searchResults = fuse.search(local.value.query)
      clearTimeout(local.value.spinnerTimeout)
      local.value.isSearching = false
      context.emit('on-results', data.value.searchResults)
    }

    const onSearch = () => {
      if (local.value.query.length) {
        clearTimeout(local.value.spinnerTimeout)
        local.value.spinnerTimeout = setTimeout(() => {
          local.value.isSearching = true
        }, 200)
        local.value.isOpen = true
      } else {
        local.value.isSearching = false
        local.value.isOpen = false
      }

      context.emit('on-search', local.value.query)

      if (props.localSearchOptions.length) handleLocalSearch()
      else if (props.searchEndpoint.length) handleRemoteSearch()
      else throw new Error('Please provide a searchEndpoint or localSearchOptions')
    }

    const onResetSearch = () => {
      local.value.isOpen = false
      local.value.query = ''
    }

    const onSelected = () => {
      onResetSearch()
    }

    const focusSearchBox = () => {
      nextTick(() => {
        queryInput.value ? queryInput.value.focus() : null
      })
    }

    onMounted(() => {
      focusSearchBox()
    })

    return {
      data,
      local,
      queryInput,
      onSearch,
      onResetSearch,
      onSelected
    }
  }
})
</script>
