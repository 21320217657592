<template>
  <div class="shadow rounded-md mb-4">
    <div v-if="!isSkeleton" class="bg-gray-100 rounded-md py-6 px-4 space-y-1 sm:p-6"><slot /></div>
    <div v-else class="bg-white rounded-md py-6 px-4 space-y-1 sm:p-6 animate-pulse">
      <ul :class="`space-y-${skeletonRows}`">
        <li v-for="(item, i) in skeletonRows - 1" :key="i" class="bg-gray-100 rounded p-2"></li>
        <li class="bg-gray-100 rounded p-2 w-3/4"></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isSkeleton: {
      type: Boolean,
      required: false,
      default: false
    },
    skeletonRows: {
      type: Number,
      required: false,
      default: 3
    }
  }
}
</script>
