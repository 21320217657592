
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'md:max-w-3xl'
    }
  },

  setup(props, context) {
    const formSubmitted = () => {
      context.emit('form-submitted')
    }
    return {
      props,
      formSubmitted,
      context
    }
  }
})
