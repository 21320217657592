import script from "./OmniSearch.vue?vue&type=script&setup=true&lang=js"
export * from "./OmniSearch.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import BasicSpinner from "@/components/BasicSpinner.vue";
script.components = Object.assign({}, { BasicSpinner }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { BasicSpinner }, script.__o.components);}