<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop :text="name" :parent="{ name: 'Labels', link: '/store/labels' }" :save-status="true" />
      <SaveBar
        :has-unsaved-changes="hasUnsavedChanges"
        @on-discard="handleDiscardChanges()"
        @on-save="handleSubmit()"
      />
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <form
          class="flex flex-1 flex-col justify-between"
          @submit.prevent="handleSubmit()"
          @keydown.enter="handleKeydown($event)"
          @keydown.meta.enter.exact="handleSubmit()"
        >
          <PageGrid>
            <MainCol>
              <BasicContainer>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <label for="name" class="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input
                      id="name"
                      v-model="name"
                      type="text"
                      name="name"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      @input="hasUnsavedChanges = true"
                    />
                  </div>
                  <div class="col-span-12">
                    <label for="contains" class="block text-sm font-medium text-gray-700 mb-1">Type</label>
                    <div class="mt-2 space-y-2">
                      <div class="flex items-center">
                        <input
                          id="contains"
                          v-model="type"
                          name="type"
                          type="radio"
                          value="contains"
                          class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                          @input="hasUnsavedChanges = true"
                        />
                        <label for="contains" class="block ml-3 text-gray-700 text-sm font-medium">Contains</label>
                      </div>
                      <div class="flex items-center">
                        <input
                          id="diet"
                          v-model="type"
                          name="type"
                          value="diet"
                          type="radio"
                          class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                          @input="hasUnsavedChanges = true"
                        />
                        <label for="diet" class="block ml-3 text-gray-700 text-sm font-medium">Diet</label>
                      </div>
                    </div>
                  </div>
                </div>
              </BasicContainer>
            </MainCol>
          </PageGrid>
          <PageFooter>
            <template #left>
              <button class="btn-alert" type="button" @click.prevent="del">Delete</button>
            </template>
            <template #right>
              <button v-if="hasUnsavedChanges" class="btn-primary" type="submit">Save</button>
              <router-link v-else class="btn-primary" type="button" to="/store/labels">Done</router-link>
            </template>
          </PageFooter>
        </form>
        <!-- Confirm cancel modal -->
        <TheModal
          v-show="leaveModal"
          :is-open="leaveModal"
          modal-title="Abandon changes"
          modal-description="Are you sure you'd like to leave without saving your changes?"
          @close-modal="leaveModal = false"
        >
          <template #footer>
            <button class="btn-alert justify-center mr-3" @click="handleConfirmCancelChanges()">Leave</button>
            <button class="btn-ghost justify-center" type="button" @click="leaveModal = false">Stay</button>
          </template>
        </TheModal>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import SecondaryTop from '@/components/SecondaryTop.vue'
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { SecondaryTop, SellSidebar },
  beforeRouteLeave() {
    if (this.hasUnsavedChanges) {
      this.leaveModal = true
      return false
    } else return true
  },
  data() {
    return {
      hasUnsavedChanges: false,
      leaveModal: false,
      name: '',
      type: ''
    }
  },
  mounted() {
    api.call('GET', `labels/${this.$route.params.id}`).then(response => {
      this.name = response.data.data.attributes.label_name
      this.type = response.data.data.attributes.label_type
    })
  },
  methods: {
    del() {
      api.call('DELETE', `labels/${this.$route.params.id}`).then(this.$router.back)
    },
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    handleSubmit() {
      api.call('PUT', `labels/${this.$route.params.id}`, {
        label: {
          label_name: this.name,
          label_type: this.type
        }
      })
      this.hasUnsavedChanges = false
    },
    async handleDiscardChanges() {
      // location.reload()
      api.call('GET', `labels/${this.$route.params.id}`).then(response => {
        this.name = response.data.data.attributes.label_name
        this.type = response.data.data.attributes.label_type
      })
      this.hasUnsavedChanges = false
    },
    handleConfirmCancelChanges() {
      this.hasUnsavedChanges = false
      this.$router.push('/store/labels')
    }
  }
}
</script>
