import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import api from '@/store/api'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { useRouter } from 'vue-router'


export default {
  __name: 'NewContactModal',
  props: ['isOpen'],
  emits: ['closeModal'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const store = useStore()
const router = useRouter()

const phonebox = ref(null)
const iti = ref(null)

const name = ref('')
const number = ref('')

watch(
  () => props.isOpen,
  async () => {
    iti.value?.destroy()
    if (props.isOpen) {
      await nextTick()
      iti.value = intlTelInput(phonebox.value.querySelector('input'), {
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@19.5.6/build/js/utils.js', // TODO(alicja): import in some other way?
        preferredCountries: [{ USA: 'US', AUS: 'AU' }[store.getters.country]],
        countrySearch: false
      })
    }
  }
)

const valid = ref(false)
watch(number, () => {
  valid.value = iti.value.isValidNumber()
})

const add = () => {
  if (!valid.value) return
  api
    .call('POST', 'conversation_user_pairs', {
      conversation_type: 'dm',
      to: iti.value.getNumber(),
      conversation_user_pair: {
        display_name: name.value
      }
    })
    .then(response => {
      router.push(`/messages/${response.data.data.id}`)
    })
  emit('closeModal')
}

return (_ctx, _cache) => {
  const _component_InputField = _resolveComponent("InputField")
  const _component_FormElement = _resolveComponent("FormElement")
  const _component_FormSection = _resolveComponent("FormSection")
  const _component_NeueButton = _resolveComponent("NeueButton")
  const _component_TheModal = _resolveComponent("TheModal")

  return (_openBlock(), _createBlock(_component_TheModal, {
    "is-open": props.isOpen,
    "modal-title": "Add a contact",
    onCloseModal: _cache[3] || (_cache[3] = $event => (emit('closeModal')))
  }, {
    main: _withCtx(() => [
      _createVNode(_component_FormSection, null, {
        default: _withCtx(() => [
          _createVNode(_component_FormElement, null, {
            default: _withCtx(() => [
              _createVNode(_component_InputField, {
                name: "Name",
                onKeyup: _withKeys(add, ["enter"]),
                placeholder: "Pedro Pascal",
                description: "Optional",
                modelValue: name.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((name).value = $event)),
                tabindex: "1"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_FormElement, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                ref_key: "phonebox",
                ref: phonebox
              }, [
                _createVNode(_component_InputField, {
                  name: "Phone number",
                  onKeyup: _withKeys(add, ["enter"]),
                  modelValue: number.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((number).value = $event)),
                  tabindex: "2",
                  class: "w-full"
                }, null, 8, ["modelValue"])
              ], 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_NeueButton, {
        type: "secondary",
        onClick: _cache[2] || (_cache[2] = $event => (emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Cancel")
        ]),
        _: 1
      }),
      _createVNode(_component_NeueButton, {
        type: valid.value ? 'primary' : 'inactive',
        onClick: add
      }, {
        default: _withCtx(() => [
          _createTextVNode("Add")
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

}