<template>
  <SecondaryNav>
    <template #sidebar>
      <SellSidebar />
    </template>
    <template #top>
      <SecondaryTop
        :text="product_data.name || 'New product'"
        :parent="{ name: 'Product library', link: '/store/products' }"
      />
    </template>
    <template #main>
      <div class="flex flex-col justify-between h-full">
        <Form
          v-slot="{ errors }"
          class="flex flex-1 flex-col justify-between"
          @submit="handleSubmit()"
          @keydown.enter="handleKeydown($event)"
          @invalid-submit="onInvalidSubmit()"
        >
          <PageGrid>
            <MainCol>
              <!-- General Detail-->
              <BasicContainer>
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-12">
                    <FormLabel for="itemName" label="Title" />
                    <Field
                      id="itemName"
                      v-model="product_data.name"
                      type="text"
                      name="itemName"
                      :rules="fieldIsRequired"
                      :class="[
                        errors.itemName ? 'border-red-300' : 'border-gray-300',
                        'mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                      ]"
                    />
                    <InputError v-show="errors.itemName" />
                  </div>

                  <div class="col-span-12">
                    <FormLabel for="itemDescription" label="Description" />
                    <textarea
                      id="itemDescription"
                      v-model="product_data.description"
                      name="itemDescription"
                      rows="8"
                      class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                    ></textarea>
                  </div>

                  <div class="col-span-6">
                    <FormLabel for="itemSku" label="SKU" />
                    <input
                      id="itemSku"
                      v-model="product_data.sku"
                      type="text"
                      name="itemSku"
                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-mono"
                    />
                  </div>

                  <div class="col-span-6">
                    <FormLabel for="itemPrice" label="Base price" />
                    <div class="max-w-lg flex rounded-md shadow-sm">
                      <span
                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                      >
                        $
                      </span>
                      <Field
                        id="itemPrice"
                        v-model="product_data.default_price"
                        type="text"
                        inputmode="numeric"
                        pattern="[0-9]*.?[0-9]*"
                        name="itemPrice"
                        :rules="fieldIsRequired"
                        :class="[
                          errors.itemPrice ? 'border-red-300' : 'border-gray-300',
                          'flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm'
                        ]"
                      />
                    </div>
                    <InputError v-show="errors.itemPrice" />
                  </div>

                  <div class="col-span-6">
                    <FormLabel for="type" label="Type" />
                    <button-dropdown
                      id="type"
                      :button-text="
                        { Product: 'Product', FoodProduct: 'Food product', GiftCardProduct: 'Gift card' }[
                          product_data.type
                        ]
                      "
                    >
                      <button
                        type="button"
                        class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                        role="menuitem"
                        @click="product_data.type = 'Product'"
                      >
                        Product
                      </button>
                      <button
                        type="button"
                        class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                        role="menuitem"
                        @click="product_data.type = 'FoodProduct'"
                      >
                        Food product
                      </button>
                      <button
                        type="button"
                        class="block px-4 py-2 w-full text-left text-gray-700 hover:text-gray-900 text-sm hover:bg-gray-100"
                        role="menuitem"
                        @click="product_data.type = 'GiftCardProduct'"
                      >
                        Gift card
                      </button>
                    </button-dropdown>
                    <InputError v-show="errors.type" />
                  </div>

                  <div v-if="product_data.type === 'GiftCardProduct'" class="col-span-6">
                    <FormLabel for="accountCreditValue" label="Account credit value" />
                    <div class="max-w-lg flex rounded-md shadow-sm">
                      <span
                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                      >
                        $
                      </span>
                      <Field
                        id="accountCreditValue"
                        v-model="product_data.account_credit_value"
                        type="text"
                        inputmode="numeric"
                        pattern="[0-9]*.?[0-9]*"
                        name="accountCreditValue"
                        :rules="fieldIsRequired"
                        :class="[
                          errors.accountCreditValue ? 'border-red-300' : 'border-gray-300',
                          'flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm'
                        ]"
                      />
                    </div>
                    <InputError v-show="errors.accountCreditValue" />
                  </div>

                  <div class="col-span-6">
                    <FormLabel for="newLimit">
                      Overall inventory available
                      <InformationCircleIcon
                        v-tippy="{
                          content:
                            'The amount this product can be ordered before being marked \'out of stock\' - must be reset manually'
                        }"
                        class="w-4 h-4 inline"
                      />
                    </FormLabel>
                    <button
                      id="newLimit"
                      name="newLimit"
                      :class="[
                        `mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
                        product_data.has_quantity_limit ? 'bg-blue-600' : 'bg-gray-200'
                      ]"
                      type="button"
                      role="switch"
                      aria-labelledby="newLimit"
                      @click="product_data.has_quantity_limit = !product_data.has_quantity_limit"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`,
                          product_data.has_quantity_limit ? 'translate-x-5' : 'translate-x-0'
                        ]"
                      ></span>
                    </button>
                  </div>
                  <div v-show="product_data.has_quantity_limit" class="col-span-6">
                    <label for="new-fulfillment-qty" class="block text-sm font-medium text-gray-700">
                      Quantity Limit
                    </label>
                    <div class="mt-1">
                      <input
                        id="new-fulfillment-qty"
                        v-model="product_data.quantity_limit"
                        type="number"
                        name="new-fulfillment-qty"
                        class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </BasicContainer>
            </MainCol>
            <SideCol>
              <!-- <ImageUploader @uploaded="uploaded" @removed-upload="removeExisting" @updated-upload="removeExisting" /> -->
              <!-- Labels -->
              <BasicContainer>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <div class="mb-3">
                      <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">Labels</h3>
                      <div
                        v-for="(label, i) in product_data.available_labels"
                        :key="label.attributes.id"
                        class="relative flex items-start mb-3"
                      >
                        <div class="flex items-center h-5">
                          <input
                            :id="label.attributes.label_name"
                            :aria-describedby="`label-${i}-description`"
                            :name="`label-${i}`"
                            type="checkbox"
                            class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            @click="
                              product_data.label_map[label.attributes.id] = !product_data.label_map[label.attributes.id]
                            "
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <FormLabel :for="label.attributes.label_name" :label="label.attributes.label_name" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BasicContainer>
            </SideCol>
          </PageGrid>
          <PageFooter>
            <template #left>
              <router-link class="btn-ghost" to="/store/products">Cancel</router-link>
            </template>
            <template #right>
              <button class="btn-primary" type="submit">Save</button>
            </template>
          </PageFooter>
        </Form>
      </div>
    </template>
  </SecondaryNav>
</template>
<script>
import { Field, Form } from 'vee-validate'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: {
    Form,
    Field,
    SellSidebar
  },
  setup() {
    const router = useRouter()

    const product_data = reactive({
      name: '',
      default_price: '',
      description: '',
      sku: '',
      available_labels: [],
      labels: [],
      label_map: {},
      option_to_edit: null,
      has_quantity_limit: false,
      quantity_limit: null,
      type: 'Product'
    })

    const onInvalidSubmit = () => {
      document.querySelector('form').scrollIntoView({ behavior: 'smooth' })
    }

    const getLabels = () => {
      api.call('GET', 'labels').then(response => {
        product_data.available_labels = response.data.data
      })
    }

    const handleSubmit = () => {
      api
        .call('POST', 'products', {
          product: {
            product_name: product_data.name,
            default_price_cents: Math.round(product_data.default_price * 100),
            description: product_data.description,
            merchant_sku: product_data.sku,
            has_quantity_limit: product_data.has_quantity_limit,
            set_quantity_changes_attributes: [
              {
                value: product_data.quantity_limit
              }
            ],
            label_product_pairs_attributes: [
              // TODO(alicja): I know this is kinda horrible but it'll do for now
              ...Object.entries(product_data.label_map)
                .filter(([x, y]) => y)
                .map(([x, y]) => ({ label_id: x }))
            ],
            attachments_attributes: [product_data.image],
            type: product_data.type,
            account_credit_value_cents: Math.round(product_data.account_credit_value * 100)
          }
        })
        .then(response => {
          router.push(`/store/products/${response.data.data.id}`)
        })
    }

    const removeExisting = () => {
      product_data.existingImage = null
    }

    const uploaded = object => {
      product_data.image = object
    }

    const handleKeydown = event => {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    }

    const fieldIsRequired = val => {
      return val ? true : 'This field is required'
    }

    return {
      product_data,
      getLabels,
      onInvalidSubmit,
      handleSubmit,
      removeExisting,
      uploaded,
      handleKeydown,
      fieldIsRequired
    }
  },

  mounted() {
    this.getLabels()
  }
}
</script>
