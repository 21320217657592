
import { InformationCircleIcon } from '@heroicons/vue/20/solid'
export default {
  components: {
    InformationCircleIcon
  },
  props: {
    explanationText: {
      type: String,
      required: true
    }
  }
}
