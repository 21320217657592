import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-3 items-start" }

import _ from 'lodash'
import { onMounted, ref, toRaw, watch } from 'vue'


export default {
  __name: 'GoogleMapComponent',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const mapsPromise = google.maps.importLibrary('maps')
const drawingPromise = google.maps.importLibrary('drawing')

const props = __props
const emit = __emit
const el = ref(null)

const map = ref(null)
const drawingManager = ref(null)
const polygon = ref(null)

const mapToModel = () => {
  emit(
    'update:modelValue',
    polygon.value
      .getPath()
      .getArray()
      .map(x => [x.lat(), x.lng()])
  )
}

const modelToMap = async () => {
  zoomMap()
  if (!props.modelValue.length) {
    if (polygon.value) {
      toRaw(polygon.value).setMap(null)
      polygon.value = null
    }
    return
  }
  if (
    polygon.value &&
    _.isEqual(
      props.modelValue,
      polygon.value
        .getPath()
        .getArray()
        .map(x => [x.lat(), x.lng()])
    )
  ) {
    return
  }

  const { Polygon } = await mapsPromise
  if (polygon.value) toRaw(polygon.value).setMap(null)
  polygon.value = new Polygon({
    paths: props.modelValue.map(x => ({ lat: x[0], lng: x[1] })),
    editable: true,
    map: map.value
  })
  google.maps.event.addListener(polygon.value.getPath(), 'set_at', mapToModel)
  google.maps.event.addListener(polygon.value.getPath(), 'insert_at', mapToModel)
  google.maps.event.addListener(polygon.value.getPath(), 'remove_at', mapToModel)
}

const zoomMap = () => {
  if (props.modelValue.length) {
    const bounds = new google.maps.LatLngBounds()
    for (const point of props.modelValue.map(x => ({ lat: x[0], lng: x[1] }))) bounds.extend(point)
    map.value.fitBounds(bounds)
  } else {
    map.value.setCenter({ lat: 39.8283, lng: -98.5795 })
    map.value.setZoom(4)
  }
}

const newPolygon = () => {
  if (polygon.value) toRaw(polygon.value).setMap(null)
  drawingManager.value.setDrawingMode('polygon')
  google.maps.event.addListener(drawingManager.value, 'polygoncomplete', poly => {
    drawingManager.value.setDrawingMode(null)
    polygon.value = poly
    mapToModel()
  })
}

watch(() => props.modelValue, modelToMap)

onMounted(async () => {
  const { Map } = await mapsPromise
  const { DrawingManager } = await drawingPromise

  map.value = new Map(el.value)

  drawingManager.value = new DrawingManager({
    drawingMode: null,
    drawingControl: false,
    polygonOptions: {
      editable: true
    }
  })
  drawingManager.value.setMap(map.value)

  modelToMap()
})

return (_ctx, _cache) => {
  const _component_NeueButton = _resolveComponent("NeueButton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NeueButton, {
      type: "secondary",
      onClick: newPolygon
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(polygon.value ? 'Replace' : 'Add') + " region", 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      ref_key: "el",
      ref: el,
      class: "rounded-xl overflow-hidden w-full h-[70vh] max-h-[50rem]"
    }, null, 512)
  ]))
}
}

}