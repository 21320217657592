import script from "./OrderDetailNeue.vue?vue&type=script&setup=true&lang=js"
export * from "./OrderDetailNeue.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__

/* vue-cli-plugin-import-components */

import BasicSpinner from "@/components/BasicSpinner.vue";
import DescriptionHeader from "@/components/neue/DescriptionHeader.vue";
import DescriptionListInput from "@/components/neue/DescriptionListInput.vue";
import EmptyState from "@/components/neue/EmptyState.vue";
import FormElement from "@/components/neue/FormElement.vue";
import FormSection from "@/components/neue/FormSection.vue";
import InlineSearchSelect from "@/components/InlineSearchSelect.vue";
import InputField from "@/components/neue/InputField.vue";
import NeueButton from "@/components/neue/NeueButton.vue";
import NeueLoading from "@/components/neue/NeueLoading.vue";
import NeueTablist from "@/components/neue/NeueTablist.vue";
import NeueWell from "@/components/neue/NeueWell.vue";
import NewReportModal from "@/components/neue/NewReportModal.vue";
import Payment from "@/components/Payment.vue";
import SecondaryNav from "@/components/SecondaryNav.vue";
import SecondaryTop from "@/components/SecondaryTop.vue";
import SelectInput from "@/components/neue/SelectInput.vue";
import TablistHeader from "@/components/neue/TablistHeader.vue";
import TablistRow from "@/components/neue/TablistRow.vue";
import TheModal from "@/components/TheModal.vue";
import VariantRow from "@/components/neue/VariantRow.vue";
script.components = Object.assign({}, { BasicSpinner, DescriptionHeader, DescriptionListInput, EmptyState, FormElement, FormSection, InlineSearchSelect, InputField, NeueButton, NeueLoading, NeueTablist, NeueWell, NewReportModal, Payment, SecondaryNav, SecondaryTop, SelectInput, TablistHeader, TablistRow, TheModal, VariantRow }, script.components);if ('__vccOpts' in script) { script.__o = script.__o || {}; script.__o.components = Object.assign({}, { BasicSpinner, DescriptionHeader, DescriptionListInput, EmptyState, FormElement, FormSection, InlineSearchSelect, InputField, NeueButton, NeueLoading, NeueTablist, NeueWell, NewReportModal, Payment, SecondaryNav, SecondaryTop, SelectInput, TablistHeader, TablistRow, TheModal, VariantRow }, script.__o.components);}