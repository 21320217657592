
import api from '@/store/api'
import SellSidebar from '@/view/store/SellSidebar'

export default {
  components: { SellSidebar },
  data() {
    return {
      type: '',
      discount_code_name: '',
      code: '',
      discount_rate: '',
      maximum_discount_amount: { dollars: '' },
      discount_amount: { dollars: '' },
      max_use_times_per_user: '',
      can_be_combined: false,
      can_be_combined_always: false,
      code_can_expire: false,
      expiration_date: '',
      first_time_customer_only: false
    }
  },
  watch: {
    code() {
      const regexPattern = new RegExp('[^A-Za-z0-9_-]*$', 'g')
      this.code = this.code.toUpperCase().replace(/\s/g, '-').replace(regexPattern, '')
    }
  },
  methods: {
    handleKeydown(event) {
      // 'Enter' outside textarea
      if (event.keyCode === 13 && event.target.localName !== 'textarea') event.preventDefault()
    },
    handleSubmit() {
      this.discount_rate = this.discount_rate / 100

      const sendData = { ...this.$data }

      sendData.discount_amount_cents = sendData.discount_amount.dollars * 100
      delete sendData.discount_amount
      sendData.maximum_discount_amount_cents = sendData.maximum_discount_amount.dollars * 100
      delete sendData.maximum_discount_amount

      api
        .call('POST', `discount_codes`, {
          discount_code: sendData
        })
        .then(response => {
          this.$router.push('/store/discount-codes/' + response.data.data.id)
        })
    }
  }
}
