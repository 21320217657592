
export default {
  props: {
    to: {
      type: String,
      required: false,
      default: undefined
    },
    type: {
      type: String,
      required: true,
      default: 'router-link'
    }
  }
}
