
import { ArrowUpTrayIcon } from '@heroicons/vue/24/outline'
import { ulid } from 'ulid'
import { ref } from 'vue'
import FileUpload from 'vue-upload-component'

import CloudinaryService from '@/services/cloudinary.service'

export const UploadService = new CloudinaryService()

export default {
  components: {
    FileUpload,
    ArrowUpTrayIcon
  },
  props: {
    existingUploadThumbnailPath: {
      type: String,
      required: false,
      default: null
    },
    attachmentId: {
      type: Number || String,
      required: false
    }
  },
  emits: ['add-upload', 'updated-upload', 'removed-upload', 'uploaded'],
  setup(props, context) {
    const id = ulid()
    const upload = ref({})

    const isLoading = ref({ active: false })

    const removeExistingUpload = () => {
      context.emit('removed-upload', props.attachmentId)
      files.value = []
    }

    const files = ref([])
    function inputFilter(newFile, oldFile, prevent) {
      // Before adding a file
      if (newFile && !oldFile) {
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
      // Handle thumbnail
      if (newFile && newFile.error === '' && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    }
    function inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        upload.value.active = true
        context.emit('add-upload', newFile)
      }
      if (newFile && oldFile) {
        context.emit('updated-upload', newFile)
        upload.value.active = true
      }
      if (!newFile && oldFile) {
        context.emit('removed-upload', '')
      }
    }

    const uploadToCloudinary = async file => {
      isLoading.value.active = true

      UploadService.uploadImages([file.file]).then(response => {
        isLoading.value.active = false
        const responseObject = response
        if (files.value.length) {
          context.emit('uploaded', responseObject)
        }
      })
    }

    return {
      files,
      upload,
      inputFilter,
      inputFile,
      removeExistingUpload,
      uploadToCloudinary,
      isLoading,
      id
    }
  }
}
