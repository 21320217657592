<template>
  <div class="mt-6 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 rounded-md mb-4">
    <div class="bg-gray-100 rounded-md py-6 px-4 space-y-1 sm:p-6">
      <div class="flex">
        <InformationCircleIcon class="h-6 w-6 ml-1 text-gray-400" />
        <h1 class="ml-1 mt-0.5 text-gray-700">{{ explanationText }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from '@heroicons/vue/20/solid'
export default {
  components: {
    InformationCircleIcon
  },
  props: {
    explanationText: {
      type: String,
      required: true
    }
  }
}
</script>
