import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" }

import BasicSpinner from '@/components/BasicSpinner'


export default {
  __name: 'NeueLoading',
  props: ['loading'],
  setup(__props) {

const props = __props

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute h-full w-full backdrop-blur-sm scale-105 z-20 transition-opacity duration-100", props.loading ? 'opacity-100 cursor-wait' : 'opacity-0 pointer-events-none'])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(BasicSpinner), { size: "medium" })
      ])
    ], 2),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

}